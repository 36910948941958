import React from "react"
import Chart from "react-apexcharts"

export function TotalAreaOccupancyGraph({ series, colors }) {
	return (
		<Chart options={getChartOptions(colors)} series={series || []} type="treemap" height="100%" />
	)
}

function getChartOptions(colors) {
	return {
		chart: {
			type: "treemap",
			sparkline: { enabled: true },
			toolbar: { show: false }
		},
		legend: { show: false },
		noData: {
			text: "No data to display.",
			style: { fontSize: "16px" }
		},
		colors: colors,
		dataLabels: {
			enabled: true,
			style: {
				fontSize: "12px"
			},
			formatter: function (text, op) {
				return [text, op.value]
			},
			offsetY: -4
		},
		plotOptions: {
			treemap: {
				distributed: true,
				enableShades: false
			}
		}
	}
}

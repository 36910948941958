import onlineAnchor from "../../../../assets/dashboard_markers/newMarkers/anchors/anchor active.png"
import createdAnchor from "../../../../assets/dashboard_markers/newMarkers/anchors/anchor created.png"
import lostAnchor from "../../../../assets/dashboard_markers/newMarkers/anchors/anchor lost.png"
import removedAnchor from "../../../../assets/dashboard_markers/newMarkers/anchors/anchor removed.png"
import { percentageToSize, pixelToSize, ruleOfThree } from "./ActionHelpers"

export function addAnchorsHasLayers(params) {
	const {
		map,
		anchors,
		//  showLayersBesidesTags,
		anchorsSize
	} = params

	const geoJsonArrayAnchors = anchors
		? anchors.map(d => {
				return {
					type: "Feature",
					properties: {
						...d
					},
					geometry: {
						type: "Point",
						coordinates: [d?.gpsData.longitude, d?.gpsData.latitude, 0.0]
					}
				}
		  })
		: []

	geoJsonArrayAnchors &&
		geoJsonArrayAnchors.forEach(feature => {
			const anchor = feature.properties

			// Reset iconToMarker and iconVisible for each feature
			let iconToMarker = onlineAnchor
			let imageId = "online-anchor"

			// ━━━ Change Anchors images for each status
			if (anchor?.status === "online") {
				iconToMarker = onlineAnchor
				imageId = "online-anchor"
			} else if (anchor?.status === "created") {
				iconToMarker = createdAnchor
				imageId = "created-anchor"
			} else if (anchor?.status === "lost") {
				iconToMarker = lostAnchor
				imageId = "lost-anchor"
			} else if (anchor?.status === "removed") {
				iconToMarker = removedAnchor
				imageId = "removed-anchor"
			}

			map.loadImage(iconToMarker, (error, img) => {
				// console.log("🚀  iconToMarker:", iconToMarker)
				// console.log("🚀  img:", img)
				if (error) {
					// console.log(`Error loading image ->`, error)
				}
				if (!img || error) return

				// If map already has the image we need to remove so there aren't any re-renders
				const mapHasImage = map.hasImage(imageId)
				// ↓ So don't get error of loading image with same sourceId
				if (mapHasImage) {
					map.removeImage(imageId)
				}

				// add icon image
				map.addImage(imageId, img)
			})

			// Set the iconImage property in the GeoJSON feature
			feature.properties.iconImage = imageId
		})

	// Remove source/layers if they already exists before adding new source/layers
	if (typeof map.getLayer("anchors-layer") !== "undefined") {
		map.removeLayer("anchors-layer")
	}
	if (typeof map.getSource("anchors-source") !== "undefined") {
		// map.removeSource("anchors-source")

		// Note: instead of removing the source, with the setData() method we update the source with the newData and doesn't flicker all the tags when filtering.
		map.getSource("anchors-source").setData({
			type: "FeatureCollection",
			features: geoJsonArrayAnchors
		})
	}

	if (!map.getSource("anchors-source")) {
		map.addSource("anchors-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: geoJsonArrayAnchors
			}
		})
	}

	// const size = ruleOfThree(0.4, 24, 100)
	// console.log("🚀  anchorsSize:", anchorsSize)
	// console.log("🚀  size:", size)

	// const correspondentOfOneHundredPercent = 0.8
	const newSize = anchorsSize || anchorsSize === 0 ? pixelToSize(anchorsSize) : 0.5

	const newSizeFixed = Number(newSize.toFixed(3))

	if (!map.getLayer("anchors-layer")) {
		map.addLayer(
			{
				id: "anchors-layer",
				type: "symbol",
				source: "anchors-source",
				// filter: ["!", ["has", "point_count"]],
				layout: {
					"icon-image": ["get", "iconImage"],
					"icon-size": newSizeFixed ? newSizeFixed : 0.4,
					// "icon-size": 0.4,
					// "icon-size": 0.8,
					"symbol-sort-key": ["get", "iconImage"],
					"icon-allow-overlap": true, // Allow icons to overlap if needed (this allows markers to overlap and not disappear)
					"icon-ignore-placement": true // Ignore placement for better clustering behavior
					// visibility: showLayersBesidesTags ? "visible" : "none"
				}
			},
			"state-label"
		)
	}
}

import React from "react"
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar
} from "../../../_metronic/_partials/controls"
import { PlatformUsersTable } from "./PlatformUsersTable"
import { useHistory } from "react-router-dom"

export function PlatformUsersCard() {
	/*const [orgs, setOrgs] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      var params = {
        pageSize: 3,
        pageToken: 1,
        pageNumber: 1,
      }
      var r = await findCustomers(params)
      console.log("This: ", r.entities)

      var count = r.entities.length;
      var i = 0
      var orgData = []
      while (i < count) {
        orgData.push([
          [r.entities[i].uid],
          [r.entities[i].email],
          [r.entities[i].customClaims]
        ])
        i++
      }
      setOrgs(orgData)
    }
    fetchData();
  }, []);*/

	const history = useHistory()

	const routeChange = () => {
		let path = "/create-user"
		history.push(path)
	}

	return (
		<Card>
			<CardHeader title="User List">
				<CardHeaderToolbar>
					<button
						type="button"
						className="btn btn-warning"
						//onClick={customersUIProps.newCustomerButtonClick}
						onClick={routeChange}
					>
						New User
					</button>
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				{/* <CustomersFilter /> */}
				{/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
				<PlatformUsersTable />
			</CardBody>
		</Card>
	)
}

/*<div>
    {orgs.map(([value, name, claims]) => (
      <div>
        <div>{"Email: " + value}</div>
        <div>{"Uid: " + name}</div>
        <div>{"Claims: " + JSON.stringify(claims)}</div>
        <br/>
      </div>
    ))}
  </div>*/

import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const managePeopleSlice = createSlice({
	name: "managePeople",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		peopleFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.people = action.payload
		}
		// personCreated: (state, action) => {
		// 	state.actionsLoading = false
		// 	state.error = null
		// 	state.people = state.people ? [...state.people, action.payload] : state.people
		// },
		// personEdited: (state, action) => {
		// 	state.actionsLoading = false
		// 	state.error = null
		// 	state.people =
		// 		state.people &&
		// 		state.people.map(entity => {
		// 			if (entity.id === action.payload.id) return action.payload
		// 			return entity
		// 		})
		// },
		// personDeleted: (state, action) => {
		// 	state.actionsLoading = false
		// 	state.error = null
		// 	state.people = state.people.filter(val => val.id !== action.payload)
		// },
		// peopleDisabled: (state, action) => {
		// 	state.actionsLoading = false
		// 	state.error = null
		// 	state.people =
		// 		state.people &&
		// 		state.people.map(entity => {
		// 			const findPeopleToDisable = action.payload.peopleToDisable.find(
		// 				element => element.id === entity.id
		// 			)
		// 			return {
		// 				...entity,
		// 				disabled: findPeopleToDisable ? findPeopleToDisable.disabled : entity.disabled
		// 			}
		// 		})
		// }
	}
})

import React, { useState, useEffect } from "react"
import * as actionsInventory from "../../../_redux/admin/inventory/InventoryActions"
import { AnchorsPositioningCard } from "./AnchorsPositioningCard"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { firestore as db } from "../../../../../firebase"

// import AnchorsTable from "./AnchorsTable/AnchorsTable"

import { collection, getCountFromServer, query, where, getDocs } from "firebase/firestore"
import { DesignStudioSlice } from "../../../_redux/admin/designStudio/DesignStudioSlice"
import { SvgIcon } from "@material-ui/core"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
const actionsDesignStudio = DesignStudioSlice.actions

const AnchorsPositioningPage = () => {
	const dispatch = useDispatch()

	const { selectedSite, currentFloorPlan } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			currentFloorPlan: state.profile?.currentFloorPlan
		}),
		shallowEqual
	)

	// ━━━━━━━━━━━ States ━━━━━━━━━━━
	// const [activeAnchorsCount, setActiveAnchorsCount] = useState(0)
	// const [createdAnchorsCount, setCreatedAnchorsCount] = useState(0)
	const [assignedAnchorsCount, setAssignedAnchorsCount] = useState(0)
	const [plannedAnchorsCount, setPlannedAnchorsCount] = useState(0)
	const [placedAnchorsCount, setPlacedAnchorsCount] = useState(0)
	const [approvedAnchorsCount, setApprovedAnchorsCount] = useState(0)
	const [onlineAnchorsCount, setOnlineAnchorsCount] = useState(0)
	const [lostAnchorsCount, setLostAnchorsCount] = useState(0)
	const [removedAnchorsCount, setRemovedAnchorsCount] = useState(0)
	const [collectionChanged, setCollectionChanged] = useState(false) // if we create / edit a anchor we fetch updated anchor data to send that data to count boxes
	const [allTotalCount, setAllTotalCount] = useState([])
	const [isFetching, setIsFetching] = useState(false)

	useEffect(() => {
		setCollectionChanged(false)
	}, [collectionChanged])

	// Fetch planned and placed Anchors and set count to states.
	useEffect(() => {
		if (!selectedSite || !currentFloorPlan) return

		setIsFetching(true)

		const fetchData = async () => {
			await fetchAssignedAnchors()
			await fetchPlannedAnchors()
			await fetchPlacedAnchors()
			await fetchActiveAnchors()
			await fetchActiveAnchorsCollectionCount()

			//fetch models for drag and drop table
			await fetchModels()

			setIsFetching(false)
		}

		fetchData()
	}, [currentFloorPlan, selectedSite, collectionChanged])

	// const fetchActiveAnchorsCollectionCount = async () => {
	//   // assigned
	//   const collCreated = collection(
	//     firestoreOld,
	//     `Sites/${selectedSite.id}/AnchorsPosition`
	//   );
	//   const qCreated = query(collCreated, where("status", "==", "assigned"));
	//   const snapshotCreated = await getCountFromServer(qCreated);
	//   const assignedLength = snapshotCreated.data().count; // nodes / online anchors total count

	//   // planned
	//   const coll3 = collection(
	//     firestoreOld,
	//     `Sites/${selectedSite.id}/AnchorsPosition`
	//   );
	//   const q3 = query(coll3, where("status", "==", "planned"));
	//   const snapshot3 = await getCountFromServer(q3);
	//   const plannedLength = snapshot3.data().count; // planned anchors total count

	//   // placed on site
	//   const coll2 = collection(
	//     firestoreOld,
	//     `Sites/${selectedSite.id}/AnchorsPosition`
	//   );
	//   const q2 = query(coll2, where("status", "==", "placed"));
	//   const snapshot2 = await getCountFromServer(q2);
	//   const placedLength = snapshot2.data().count; // placed on site anchors total count

	//   // approved
	//   const collApproved = collection(
	//     firestoreOld,
	//     `Sites/${selectedSite.id}/Nodes`
	//   );
	//   const qapproved = query(
	//     collApproved,
	//     where("nodeType", "==", "anchor"),
	//     where("status", "==", "approved")
	//   );
	//   const snapshotApproved = await getCountFromServer(qapproved);
	//   const approvedLength = snapshotApproved.data().count; // nodes / approved anchors total count

	//   // online
	//   const coll = collection(firestoreOld, `Sites/${selectedSite.id}/Nodes`);
	//   const q = query(
	//     coll,
	//     where("nodeType", "==", "anchor"),
	//     where("status", "==", "online")
	//   );
	//   const snapshot = await getCountFromServer(q);
	//   const onlineLength = snapshot.data().count; // nodes / online anchors total count

	//   // lost
	//   const collLost = collection(firestoreOld, `Sites/${selectedSite.id}/Nodes`);
	//   const qLost = query(
	//     collLost,
	//     where("nodeType", "==", "anchor"),
	//     where("status", "==", "lost")
	//   );
	//   const snapshotLost = await getCountFromServer(qLost);
	//   const lostLength = snapshotLost.data().count; // nodes / online anchors total count

	//   // removed
	//   const collRemoved = collection(
	//     firestoreOld,
	//     `Sites/${selectedSite.id}/Nodes`
	//   );
	//   const qRemoved = query(
	//     collRemoved,
	//     where("nodeType", "==", "anchor"),
	//     where("status", "==", "removed")
	//   );
	//   const snapshotRemoved = await getCountFromServer(qRemoved);
	//   const removedLength = snapshotRemoved.data().count; // nodes / online anchors total count

	//   const resultToState = Promise.all([
	//     assignedLength,
	//     plannedLength,
	//     placedLength,
	//     approvedLength,
	//     onlineLength,
	//     lostLength,
	//     removedLength,
	//   ]);

	//   resultToState.then((res) => {
	//     return setAllTotalCount({
	//       assigned: res[0] || 0,
	//       planned: res[1] || 0,
	//       placed: res[2] || 0,
	//       approved: res[3] || 0,
	//       online: res[4] || 0,
	//       lost: res[5] || 0,
	//       removed: res[6] || 0,
	//     });
	//   });
	// };

	const fetchActiveAnchorsCollectionCount = async () => {
		try {
			const siteId = selectedSite.id

			// Define a utility function for getting counts
			const getCount = async (collectionPath, ...conditions) => {
				const coll = collection(db, collectionPath)
				const q = query(coll, ...conditions)
				const snapshot = await getCountFromServer(q)
				return snapshot.data().count
			}

			// Fetch counts
			const assignedLength = await getCount(
				`Sites/${siteId}/AnchorsPosition`,
				where("status", "==", "assigned")
			)
			const plannedLength = await getCount(
				`Sites/${siteId}/AnchorsPosition`,
				where("status", "==", "planned")
			)
			const placedLength = await getCount(
				`Sites/${siteId}/AnchorsPosition`,
				where("status", "==", "placed")
			)
			const approvedLength = await getCount(
				`Sites/${siteId}/Nodes`,
				where("nodeType", "==", "anchor"),
				where("status", "==", "approved")
			)
			const onlineLength = await getCount(
				`Sites/${siteId}/Nodes`,
				where("nodeType", "==", "anchor"),
				where("status", "==", "online")
			)
			const lostLength = await getCount(
				`Sites/${siteId}/Nodes`,
				where("nodeType", "==", "anchor"),
				where("status", "==", "lost")
			)
			const removedLength = await getCount(
				`Sites/${siteId}/Nodes`,
				where("nodeType", "==", "anchor"),
				where("status", "==", "removed")
			)

			// Set state
			setAllTotalCount({
				assigned: assignedLength || 0,
				planned: plannedLength || 0,
				placed: placedLength || 0,
				approved: approvedLength || 0,
				online: onlineLength || 0,
				lost: lostLength || 0,
				removed: removedLength || 0
			})
		} catch (error) {
			console.error("Error fetching active anchors collection count:", error)
		}
	}

	// const fetchActiveAnchorsCollectionCount = async () => {
	// 	// nodes
	// 	const coll = collection(firestoreOld, `Sites/${selectedSite.id}/Nodes`)
	// 	const q = query(coll, where("nodeType", "==", "anchor"))
	// 	const snapshot = await getCountFromServer(q)
	// 	const nodesLength = snapshot.data().count // nodes / active anchors total count
	// 	// placed on site
	// 	const coll2 = collection(firestoreOld, `Sites/${selectedSite.id}/AnchorsPosition`)
	// 	const q2 = query(coll2, where("placedOnSite", "==", true))
	// 	// const q2 = query(coll2, where("status", "==", "placed"))
	// 	const snapshot2 = await getCountFromServer(q2)
	// 	const placedLength = snapshot2.data().count // placed on site anchors total count
	// 	// planned
	// 	const coll3 = collection(firestoreOld, `Sites/${selectedSite.id}/AnchorsPosition`)
	// 	const q3 = query(coll3, where("placedOnSite", "==", false))
	// 	// const q3 = query(coll3, where("status", "==", "planned"))
	// 	const snapshot3 = await getCountFromServer(q3)
	// 	const plannedLength = snapshot3.data().count // planned anchors total count
	// 	const resultToState = Promise.all([nodesLength, placedLength, plannedLength])
	// 	resultToState.then(res =>
	// 		setAllTotalCount({ nodes: res[0] || 0, placed: res[1] || 0, planned: res[2] || 0 })
	// 	)
	// }

	// const fetchActiveAnchors = async () => {
	//   const snapshot = await firestoreOld
	//     .collection(`Sites/${selectedSite.id}/Nodes`)
	//     .where("floorPlanId", "==", currentFloorPlan.id)
	//     .where("nodeType", "==", "anchor")
	//     .get();

	//   const newAnchors = snapshot.docs.map((doc) => {
	//     return {
	//       id: doc.id,
	//       ...doc.data(),
	//     };
	//   });
	//   // console.log("🚀  newAnchors:", newAnchors)

	//   // newAnchors.length > 0 &&
	//   // 	newAnchors &&
	//   if (newAnchors && newAnchors.length >= 0)
	//     dispatch(actionsDesignStudio.addAnchorsDesignStudio(newAnchors));
	//   else dispatch(actionsDesignStudio.addAnchorsDesignStudio([]));
	//   // online anchors
	//   const onlineAnchors = newAnchors.filter((val) => val.status === "online");

	//   onlineAnchors && setOnlineAnchorsCount(onlineAnchors.length);

	//   const approvedAnchors = newAnchors.filter(
	//     (val) => val.status === "approved"
	//   );

	//   approvedAnchors && setApprovedAnchorsCount(approvedAnchors.length);

	//   // created anchors
	//   //commented beecause when created they are not online
	//   //const createdAnchors = newAnchors.filter(val => val.status === "created")

	//   //createdAnchors && setCreatedAnchorsCount(createdAnchors.length)

	//   // lost anchors
	//   const lostAnchors = newAnchors.filter((val) => val.status === "lost");

	//   lostAnchors && setLostAnchorsCount(lostAnchors.length);

	//   // removed anchors
	//   const removedAnchors = newAnchors.filter((val) => val.status === "removed");

	//   removedAnchors && setRemovedAnchorsCount(removedAnchors.length);
	// };
	const fetchActiveAnchors = async () => {
		try {
			const siteId = selectedSite.id
			const floorPlanId = currentFloorPlan.id

			// Define the query
			const anchorsQuery = query(
				collection(db, `Sites/${siteId}/Nodes`),
				where("floorPlanId", "==", floorPlanId),
				where("nodeType", "==", "anchor")
			)

			// Execute the query
			const querySnapshot = await getDocs(anchorsQuery)

			// Map the documents to an array of anchor objects
			const newAnchors = querySnapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			// Dispatch the new anchors
			dispatch(
				actionsDesignStudio.addAnchorsDesignStudio(newAnchors.length > 0 ? newAnchors : [])
			)

			// Calculate and set the counts
			const onlineAnchorsCount = newAnchors.filter(val => val.status === "online").length
			const approvedAnchorsCount = newAnchors.filter(val => val.status === "approved").length
			const lostAnchorsCount = newAnchors.filter(val => val.status === "lost").length
			const removedAnchorsCount = newAnchors.filter(val => val.status === "removed").length

			setOnlineAnchorsCount(onlineAnchorsCount)
			setApprovedAnchorsCount(approvedAnchorsCount)
			setLostAnchorsCount(lostAnchorsCount)
			setRemovedAnchorsCount(removedAnchorsCount)
		} catch (error) {
			console.error("Error fetching active anchors:", error)
		}
	}

	// const fetchActiveAnchors = async () => {
	// 	const snapshot = await firestoreOld
	// 		.collection(`Sites/${selectedSite.id}/Nodes`)
	// 		.where("floorPlanId", "==", currentFloorPlan.id)
	// 		.where("nodeType", "==", "anchor")
	// 		.get()
	// 	const newAnchors = snapshot.docs.map(doc => {
	// 		return {
	// 			id: doc.id,
	// 			...doc.data()
	// 		}
	// 	})
	// 	newAnchors.length > 0 &&
	// 		newAnchors &&
	// 		dispatch(actionsDesignStudio.addAnchorsDesignStudio(newAnchors))
	// 	newAnchors && setActiveAnchorsCount(newAnchors.length)
	// }

	// const fetchCreatedAnchors = async () => {
	// 	const snapshot = await firestoreOld
	// 		.collection(`Sites/${selectedSite.id}/AnchorsPosition`)
	// 		.where("floorPlanId", "==", currentFloorPlan?.id)
	// 		// .where("placedOnSite", "==", false)
	// 		.where("status", "==", "assigned")
	// 		.get()

	// 	const createdAnchorsParsed = snapshot.docs.map(doc => {
	// 		return {
	// 			id: doc.id,
	// 			...doc.data()
	// 		}
	// 	})

	// 	createdAnchorsParsed && setCreatedAnchorsCount(createdAnchorsParsed.length)
	// }

	// const fetchAssignedAnchors = async () => {
	//   const snapshot = await firestoreOld
	//     .collection(`Sites/${selectedSite.id}/AnchorsPosition`)
	//     .where("floorPlanId", "==", currentFloorPlan?.id)
	//     .where("status", "==", "assigned")
	//     .get();

	//   const assignedAnchorsCount = snapshot.docs.map((doc) => {
	//     return {
	//       id: doc.id,
	//       ...doc.data(),
	//     };
	//   });

	//   assignedAnchorsCount &&
	//     setAssignedAnchorsCount(assignedAnchorsCount.length);
	// };
	const fetchAssignedAnchors = async () => {
		try {
			const anchorsRef = collection(db, `Sites/${selectedSite.id}/AnchorsPosition`)
			const q = query(
				anchorsRef,
				where("floorPlanId", "==", currentFloorPlan?.id),
				where("status", "==", "assigned")
			)

			const snapshot = await getDocs(q)

			const assignedAnchors = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			setAssignedAnchorsCount(assignedAnchors.length)
		} catch (error) {
			console.error("Error fetching assigned anchors:", error)
		}
	}

	// const fetchPlacedAnchors = async () => {
	//   const snapshot = await firestoreOld
	//     .collection(`Sites/${selectedSite.id}/AnchorsPosition`)
	//     .where("floorPlanId", "==", currentFloorPlan?.id)
	//     // .where("placedOnSite", "==", true)
	//     .where("status", "==", "placed")
	//     .get();

	//   const placedAnchorsParsed = snapshot.docs.map((doc) => {
	//     return {
	//       id: doc.id,
	//       ...doc.data(),
	//     };
	//   });

	//   placedAnchorsParsed && setPlacedAnchorsCount(placedAnchorsParsed.length);
	// };
	const fetchPlacedAnchors = async () => {
		try {
			const siteId = selectedSite.id
			const floorPlanId = currentFloorPlan?.id

			// Define the query
			const anchorsQuery = query(
				collection(db, `Sites/${siteId}/AnchorsPosition`),
				where("floorPlanId", "==", floorPlanId),
				where("status", "==", "placed")
			)

			// Execute the query
			const querySnapshot = await getDocs(anchorsQuery)

			// Map the documents to an array of anchor objects
			const placedAnchorsParsed = querySnapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			// Update the state with the count of placed anchors
			setPlacedAnchorsCount(placedAnchorsParsed.length)
		} catch (error) {
			console.error("Error fetching placed anchors:", error)
		}
	}

	// const fetchPlannedAnchors = async () => {
	//   const snapshot = await firestoreOld
	//     .collection(`Sites/${selectedSite.id}/AnchorsPosition`)
	//     .where("floorPlanId", "==", currentFloorPlan?.id)
	//     // .where("placedOnSite", "==", false)
	//     .where("status", "==", "planned")
	//     .get();

	//   const plannedAnchorsParsed = snapshot.docs.map((doc) => {
	//     return {
	//       id: doc.id,
	//       ...doc.data(),
	//     };
	//   });

	//   plannedAnchorsParsed && setPlannedAnchorsCount(plannedAnchorsParsed.length);
	// };
	const fetchPlannedAnchors = async () => {
		try {
			const siteId = selectedSite.id
			const floorPlanId = currentFloorPlan?.id

			// Define the query
			const anchorsQuery = query(
				collection(db, `Sites/${siteId}/AnchorsPosition`),
				where("floorPlanId", "==", floorPlanId),
				where("status", "==", "planned")
			)

			// Execute the query
			const querySnapshot = await getDocs(anchorsQuery)

			// Map the documents to an array of anchor objects
			const plannedAnchorsParsed = querySnapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			// Update the state with the count of planned anchors
			setPlannedAnchorsCount(plannedAnchorsParsed.length)
		} catch (error) {
			console.error("Error fetching planned anchors:", error)
		}
	}

	const fetchModels = async () => {
		dispatch(actionsInventory.getModels({ technology: "Wirepas" }))
	}

	// return (
	// 	<div className="mt-3">
	// 		<div className="h-100 d-flex flex-column flex-md-row justify-content-between mb-0 mb-md-3">
	// 			<div className="col pl-0 pr-0 pr-md-4 mb-4 mb-md-4">
	// 				<GlobalPeopleInfo
	// 					value={activeAnchorsCount || 0}
	// 					text="Active anchors"
	// 					icon="/markers/anchor.svg"
	// 					color="#2649AA"
	// 					total={allTotalCount?.nodes || 0}
	// 				/>
	// 			</div>
	// 			<div className="col pl-0 pr-0 pr-md-4 pl-md-4 mb-4 mb-md-4">
	// 				<GlobalPeopleInfo
	// 					value={placedAnchorsCount || 0}
	// 					text="Placed anchors"
	// 					icon="/markers/placedAnchors.svg"
	// 					color="#6CA4FF"
	// 					total={allTotalCount?.placed || 0}
	// 				/>
	// 			</div>
	// 			<div className="col pl-0 pr-0 pl-md-4 mb-4 mb-md-4">
	// 				<GlobalPeopleInfo
	// 					value={plannedAnchorsCount || 0}
	// 					text="Planned anchors"
	// 					icon="/markers/plannedAnchors.svg"
	// 					color="#8C8CA1"
	// 					total={allTotalCount?.planned || 0}
	// 				/>
	// 			</div>
	// 		</div>
	// 		<div>
	// 			<AnchorsPositioningCard setCollectionChanged={setCollectionChanged} />
	// 		</div>
	// 	</div>
	// )

	return (
		<div className="mt-3" data-testid="anchors-positioning-page">
			{/* titles */}
			<div className="row" id="top_row_anchors_page_desktop">
				<div className="col-5 d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M4.5 22.5H9V21H4.5V3H16.5V10.5H18V3C17.9996 2.6023 17.8414 2.22101 17.5602 1.9398C17.279 1.65859 16.8977 1.50042 16.5 1.5H4.5C4.1023 1.50042 3.72101 1.65859 3.4398 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V21C3.00042 21.3977 3.15859 21.779 3.4398 22.0602C3.72101 22.3414 4.1023 22.4996 4.5 22.5ZM6 4.5H15V6H6V4.5ZM6 7.5H15V9H6V7.5ZM10.5 10.5H6V12H10.5V10.5ZM6 18H9V19.5H6V18ZM22.2803 14.4698L20.0303 12.2198C19.9606 12.1501 19.878 12.0948 19.787 12.0571C19.696 12.0194 19.5985 12 19.5 12C19.4015 12 19.304 12.0194 19.213 12.0571C19.122 12.0948 19.0394 12.1501 18.9697 12.2198L12 19.1895V22.5H15.3106L22.2803 15.5303C22.3499 15.4607 22.4052 15.378 22.4429 15.287C22.4806 15.196 22.5 15.0985 22.5 15C22.5 14.9015 22.4806 14.804 22.4429 14.713C22.4052 14.622 22.3499 14.5394 22.2803 14.4698ZM14.6894 21H13.5V19.8106L17.25 16.0606L18.4394 17.25L14.6894 21ZM19.5 16.1895L18.3106 15L19.5 13.8106L20.6894 15L19.5 16.1895Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Planning</b>
					</h2>
				</div>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M8.5575 10.5L10.5 12.4425L15.4425 7.5L16.5 8.5575L10.5 14.5575L7.5 11.5575L8.5575 10.5Z"
								fill="#4A4A68"
							/>
							<path
								d="M7.36815 20.0303L12 22.5L16.6319 20.0303C17.9524 19.3278 19.0567 18.279 19.8263 16.9964C20.5959 15.7138 21.0016 14.2458 21 12.75V3C20.9996 2.6023 20.8414 2.22101 20.5602 1.9398C20.279 1.65859 19.8977 1.50042 19.5 1.5H4.5C4.10231 1.50042 3.72102 1.65859 3.43981 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V12.75C2.9984 14.2458 3.40415 15.7138 4.17372 16.9964C4.94328 18.279 6.0476 19.3278 7.36815 20.0303ZM4.5 12.75V3H19.5V12.75C19.5013 13.9739 19.1693 15.175 18.5396 16.2244C17.9099 17.2738 17.0063 18.132 15.9258 18.7067L12 20.8L8.0742 18.7067C6.9937 18.132 6.0901 17.2738 5.46041 16.2244C4.83072 15.175 4.49871 13.9739 4.5 12.75Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Approval</b>
					</h2>
				</div>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12.75 6H11.25V14.25H12.75V6ZM11.375 16.6896C11.56 16.566 11.7775 16.5 12 16.5C12.2984 16.5 12.5845 16.6185 12.7955 16.8295C13.0065 17.0405 13.125 17.3266 13.125 17.625C13.125 17.8475 13.059 18.065 12.9354 18.25C12.8118 18.435 12.6361 18.5792 12.4305 18.6644C12.225 18.7495 11.9988 18.7718 11.7805 18.7284C11.5623 18.685 11.3618 18.5778 11.2045 18.4205C11.0472 18.2632 10.94 18.0627 10.8966 17.8445C10.8532 17.6262 10.8755 17.4 10.9606 17.1945C11.0458 16.9889 11.19 16.8132 11.375 16.6896Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Others</b>
					</h2>
				</div>
			</div>

			{/* lines */}
			{isFetching && (
				<ModalProgressBar
					variant="query"
					date-testid="modal_progress_bar"
					style={{ width: "101%", marginBottom: "1.5rem" }}
				/>
			)}
			{!isFetching && (
				<div
					id="top_row_anchors_page_desktop"
					className="row"
					style={{ marginBottom: "1.5rem" }}
				>
					<div className="col-5" style={{ marginRight: "15px", paddingRight: "0" }}>
						<div
							style={{
								width: "100%",
								height: "4px",
								borderRadius: "1px",
								background: "var(--Utilitarian-Neutrals-Grey, #E4E4E8)"
							}}
						></div>
					</div>
					<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
						<div
							style={{
								width: "100%",
								height: "4px",
								borderRadius: "1px",
								background: "var(--navigation-blue-blue-dde-9-fd, #DDE9FD)"
							}}
						></div>
					</div>
					<div className="col" style={{ paddingRight: "0" }}>
						<div
							style={{
								width: "100%",
								height: "4px",
								borderRadius: "1px",
								background: "var(--Utilitarian-Red-20, #FBE6DE)"
							}}
						></div>
					</div>
				</div>
			)}
			{/* boxes */}
			<div id="top_row_anchors_page_desktop" className="row" style={{ marginBottom: "3rem" }}>
				<GlobalPeopleInfo
					isDesktop={true}
					value={assignedAnchorsCount || 0}
					text="Assigned anchors"
					icon="/markers/anchor.svg"
					color="#8C8CA1"
					total={allTotalCount?.assigned || 0}
					newBorderRadius="50%"
				/>
				<GlobalPeopleInfo
					isDesktop={true}
					value={plannedAnchorsCount || 0}
					text="Planned anchors"
					icon="/markers/anchor.svg"
					color="#8c8ca1"
					total={allTotalCount?.planned || 0}
					newBorderRadius="4px"
				/>
				<GlobalPeopleInfo
					isDesktop={true}
					value={placedAnchorsCount || 0}
					text="Placed anchors"
					icon="/markers/anchor.svg"
					color="#6CA4FF"
					total={allTotalCount?.placed || 0}
					newBorderRadius="4px"
				/>
				<GlobalPeopleInfo
					isDesktop={true}
					value={approvedAnchorsCount || 0}
					text="Approved anchors"
					icon="/markers/anchor.svg"
					color="#3B7E38"
					total={allTotalCount?.approved || 0}
					newBorderRadius="50%"
				/>
				<GlobalPeopleInfo
					isDesktop={true}
					value={onlineAnchorsCount || 0}
					text="Online anchors"
					icon="/markers/anchor.svg"
					color="#2649AA"
					total={allTotalCount?.online || 0}
					newBorderRadius="50%"
				/>
				<GlobalPeopleInfo
					isDesktop={true}
					value={lostAnchorsCount || 0}
					text="Lost anchors"
					icon="/markers/anchor.svg"
					color="#A43912"
					total={allTotalCount?.lost || 0}
					newBorderRadius="50%"
				/>
				<GlobalPeopleInfo
					isDesktop={true}
					value={removedAnchorsCount || 0}
					text="Removed anchors"
					icon="/markers/anchor.svg"
					color="#ff0000"
					total={allTotalCount?.removed || 0}
					newBorderRadius="50%"
				/>
			</div>

			{/* mobile view */}
			<div className="row" id="top_row_anchors_page_mobile">
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M4.5 22.5H9V21H4.5V3H16.5V10.5H18V3C17.9996 2.6023 17.8414 2.22101 17.5602 1.9398C17.279 1.65859 16.8977 1.50042 16.5 1.5H4.5C4.1023 1.50042 3.72101 1.65859 3.4398 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V21C3.00042 21.3977 3.15859 21.779 3.4398 22.0602C3.72101 22.3414 4.1023 22.4996 4.5 22.5ZM6 4.5H15V6H6V4.5ZM6 7.5H15V9H6V7.5ZM10.5 10.5H6V12H10.5V10.5ZM6 18H9V19.5H6V18ZM22.2803 14.4698L20.0303 12.2198C19.9606 12.1501 19.878 12.0948 19.787 12.0571C19.696 12.0194 19.5985 12 19.5 12C19.4015 12 19.304 12.0194 19.213 12.0571C19.122 12.0948 19.0394 12.1501 18.9697 12.2198L12 19.1895V22.5H15.3106L22.2803 15.5303C22.3499 15.4607 22.4052 15.378 22.4429 15.287C22.4806 15.196 22.5 15.0985 22.5 15C22.5 14.9015 22.4806 14.804 22.4429 14.713C22.4052 14.622 22.3499 14.5394 22.2803 14.4698ZM14.6894 21H13.5V19.8106L17.25 16.0606L18.4394 17.25L14.6894 21ZM19.5 16.1895L18.3106 15L19.5 13.8106L20.6894 15L19.5 16.1895Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Planning</b>
					</h2>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row" style={{ marginBottom: "1.5rem" }}>
				<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--Utilitarian-Neutrals-Grey, #E4E4E8)"
						}}
					></div>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row">
				<GlobalPeopleInfo
					value={assignedAnchorsCount || 0}
					text="Assigned anchors"
					icon="/markers/anchor.svg"
					color="#8C8CA1"
					total={allTotalCount?.assigned || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalPeopleInfo
					value={plannedAnchorsCount || 0}
					text="Planned anchors"
					icon="/markers/anchor.svg"
					color="#8C8CA1"
					total={allTotalCount?.planned || 0}
					newBorderRadius="4px"
					isMobile={true}
				/>
				<GlobalPeopleInfo
					value={placedAnchorsCount || 0}
					text="Placed anchors"
					icon="/markers/anchor.svg"
					color="#6CA4FF"
					total={allTotalCount?.placed || 0}
					newBorderRadius="4px"
				/>
				{/* {isFetching && (
					<ModalProgressBar
						variant="query"
						date-testid="modal_progress_bar"
						style={{ marginLeft: "15px" }}
					/>
				)} */}
			</div>

			<div className="row" id="top_row_anchors_page_mobile" style={{ marginTop: "3rem" }}>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M8.5575 10.5L10.5 12.4425L15.4425 7.5L16.5 8.5575L10.5 14.5575L7.5 11.5575L8.5575 10.5Z"
								fill="#4A4A68"
							/>
							<path
								d="M7.36815 20.0303L12 22.5L16.6319 20.0303C17.9524 19.3278 19.0567 18.279 19.8263 16.9964C20.5959 15.7138 21.0016 14.2458 21 12.75V3C20.9996 2.6023 20.8414 2.22101 20.5602 1.9398C20.279 1.65859 19.8977 1.50042 19.5 1.5H4.5C4.10231 1.50042 3.72102 1.65859 3.43981 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V12.75C2.9984 14.2458 3.40415 15.7138 4.17372 16.9964C4.94328 18.279 6.0476 19.3278 7.36815 20.0303ZM4.5 12.75V3H19.5V12.75C19.5013 13.9739 19.1693 15.175 18.5396 16.2244C17.9099 17.2738 17.0063 18.132 15.9258 18.7067L12 20.8L8.0742 18.7067C6.9937 18.132 6.0901 17.2738 5.46041 16.2244C4.83072 15.175 4.49871 13.9739 4.5 12.75Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Approval</b>
					</h2>
				</div>
				<div
					className="col d-none d-sm-flex"
					style={{ marginRight: "15px", paddingRight: "0" }}
				>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12.75 6H11.25V14.25H12.75V6ZM11.375 16.6896C11.56 16.566 11.7775 16.5 12 16.5C12.2984 16.5 12.5845 16.6185 12.7955 16.8295C13.0065 17.0405 13.125 17.3266 13.125 17.625C13.125 17.8475 13.059 18.065 12.9354 18.25C12.8118 18.435 12.6361 18.5792 12.4305 18.6644C12.225 18.7495 11.9988 18.7718 11.7805 18.7284C11.5623 18.685 11.3618 18.5778 11.2045 18.4205C11.0472 18.2632 10.94 18.0627 10.8966 17.8445C10.8532 17.6262 10.8755 17.4 10.9606 17.1945C11.0458 16.9889 11.19 16.8132 11.375 16.6896Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Others</b>
					</h2>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row" style={{ marginBottom: "1.5rem" }}>
				<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--navigation-blue-blue-dde-9-fd, #DDE9FD)"
						}}
					></div>
				</div>
				<div
					className="col d-none d-sm-flex"
					style={{ marginRight: "15px", paddingRight: "0" }}
				>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--Utilitarian-Red-20, #FBE6DE)"
						}}
					></div>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row " style={{ marginBottom: "3rem" }}>
				<GlobalPeopleInfo
					value={approvedAnchorsCount || 0}
					text="Approved anchors"
					icon="/markers/anchor.svg"
					color="#3B7E38"
					total={allTotalCount?.approved || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalPeopleInfo
					value={onlineAnchorsCount || 0}
					text="Online anchors"
					icon="/markers/anchor.svg"
					color="#2649AA"
					total={allTotalCount?.online || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalPeopleInfo
					value={lostAnchorsCount || 0}
					text="Lost anchors"
					icon="/markers/anchor.svg"
					color="#E65019"
					total={allTotalCount?.lost || 0}
					newBorderRadius="50%"
					isMobile={true}
					className="col d-none d-sm-flex justify-content-between align-items-start"
				/>
				<GlobalPeopleInfo
					value={removedAnchorsCount || 0}
					text="Removed anchors"
					icon="/markers/anchor.svg"
					color="#ff0000"
					total={allTotalCount?.removed || 0}
					newBorderRadius="50%"
					className="col d-none d-sm-flex justify-content-between align-items-start"
				/>
				{/* {isFetching && (
					<ModalProgressBar
						variant="query"
						date-testid="modal_progress_bar"
						style={{ marginLeft: "15px" }}
					/>
				)} */}
			</div>

			<div
				className="row d-flex d-sm-none force-no-padding"
				id="top_row_anchors_page_mobile"
				style={{ marginTop: "3rem" }}
			>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12.75 6H11.25V14.25H12.75V6ZM11.375 16.6896C11.56 16.566 11.7775 16.5 12 16.5C12.2984 16.5 12.5845 16.6185 12.7955 16.8295C13.0065 17.0405 13.125 17.3266 13.125 17.625C13.125 17.8475 13.059 18.065 12.9354 18.25C12.8118 18.435 12.6361 18.5792 12.4305 18.6644C12.225 18.7495 11.9988 18.7718 11.7805 18.7284C11.5623 18.685 11.3618 18.5778 11.2045 18.4205C11.0472 18.2632 10.94 18.0627 10.8966 17.8445C10.8532 17.6262 10.8755 17.4 10.9606 17.1945C11.0458 16.9889 11.19 16.8132 11.375 16.6896Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Others</b>
					</h2>
				</div>
			</div>
			<div
				id="top_row_anchors_page_mobile"
				className="row d-block d-sm-none force-no-padding"
				style={{ marginBottom: "1.5rem" }}
			>
				<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--Utilitarian-Red-20, #FBE6DE)"
						}}
					></div>
				</div>
			</div>
			<div
				id="top_row_anchors_page_mobile"
				className="row d-flex d-sm-none"
				style={{ marginBottom: "3rem" }}
			>
				<GlobalPeopleInfo
					value={lostAnchorsCount || 0}
					text="Lost anchors"
					icon="/markers/anchor.svg"
					color="#E65019"
					total={allTotalCount?.lost || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalPeopleInfo
					value={removedAnchorsCount || 0}
					text="Removed anchors"
					icon="/markers/anchor.svg"
					color="#ff0000"
					total={allTotalCount?.removed || 0}
					newBorderRadius="50%"
				/>
				{/* {isFetching && <ModalProgressBar variant="query" date-testid="modal_progress_bar" />} */}
			</div>

			<div>
				<AnchorsPositioningCard setCollectionChanged={setCollectionChanged} />
			</div>
		</div>
	)
}

export default AnchorsPositioningPage

export function GlobalPeopleInfo({
	value,
	text,
	icon,
	color,
	total,
	newBorderRadius,
	isDesktop,
	isMobile,
	className = "col-12 col-sm d-flex justify-content-between align-items-start"
}) {
	return (
		<div
			className={className}
			style={{
				backgroundColor: "#FFFFFF",
				// backgroundColor: color,
				borderRadius: "5px",
				padding: "15px",
				height: "80px",
				width: "100%",
				boxShadow: "0px 4px 10px 0px rgba(27, 27, 27, 0.10)",
				margin: isDesktop && "0 15px",
				marginRight: isMobile && "15px",
				marginBottom: isMobile && "15px"
			}}
		>
			<div
				className="d-flex flex-column w-100"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					color: "#4A4A68"
				}}
			>
				<div className="d-flex justify-content-between">
					<span
						className="anchors_page_value_total"
						style={{
							// fontSize: "28px",
							lineHeight: "40px",
							fontWeight: "700"
						}}
					>
						{value} <span style={{ fontSize: "14px" }}> / {total}</span>
					</span>
					<div
						className="anchors_page_marker_divs"
						style={{
							boxShadow: "0px 4px 10px 0px rgba(27, 27, 27, 0.10)",
							borderRadius: newBorderRadius,
							backgroundColor: color,
							flexShrink: 0
						}}
					>
						<img
							alt="Person Info Icon"
							src={icon}
							style={{
								width: "55%",
								height: "55%"
							}}
						/>
					</div>
				</div>
				<span
					className="anchors_page_text"
					style={{
						// fontSize: "12px",
						lineHeight: "18px",
						fontWeight: "500",
						whiteSpace: "nowrap",
						alignSelf: "center"
					}}
				>
					{text}
				</span>
			</div>

			{/* <div
					className="anchors_page_marker_divs"
					style={{
						// width: "32px",
						// height: "32px",
						// display: "flex",
						// justifyContent: "center",
						// alignItems: "center",
						boxShadow: "0px 4px 10px 0px rgba(27, 27, 27, 0.10)",
						borderRadius: newBorderRadius,
						backgroundColor: color,
						flexShrink: 0
					}}
				>
					<img
						alt="Person Info Icon"
						src={icon}
						style={{
							width: "55%",
							height: "55%"
						}}
					/>
				</div> */}
		</div>
	)
	// return (
	// 	<div
	// 		className="d-flex justify-content-between align-items-start"
	// 		style={{
	// 			backgroundColor: color,
	// 			borderRadius: "5px",
	// 			paddingTop: "10px",
	// 			height: "80px",
	// 			width: "100%",
	// 			paddingRight: "10px"
	// 		}}
	// 	>
	// 		<div
	// 			className="d-flex flex-column"
	// 			style={{
	// 				fontFamily: "Poppins",
	// 				fontStyle: "normal",
	// 				color: "#FFFFFF",
	// 				// color: "#4A4A68",
	// 				paddingLeft: "10px"
	// 			}}
	// 		>
	// 			<span
	// 				style={{
	// 					fontSize: "22px",
	// 					lineHeight: "40px",
	// 					fontWeight: "700"
	// 				}}
	// 			>
	// 				{value} / {total}
	// 			</span>
	// 			<span
	// 				style={{
	// 					fontSize: "11px",
	// 					lineHeight: "18px",
	// 					fontWeight: "500",
	// 					whiteSpace: "nowrap"
	// 				}}
	// 			>
	// 				{text}
	// 			</span>
	// 		</div>
	// 		<div
	// 			className=""
	// 			style={{
	// 				width: "28px",
	// 				height: "28px"
	// 			}}
	// 		>
	// 			<img
	// 				alt="Person Info Icon"
	// 				src={icon}
	// 				style={{
	// 					width: "100%",
	// 					height: "100%",
	// 					filter:
	// 						"brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(7482%) hue-rotate(180deg) brightness(108%) contrast(115%)"
	// 					// "brightness(0) saturate(100%) invert(31%) sepia(6%) saturate(2139%) hue-rotate(202deg) brightness(89%) contrast(92%)"
	// 				}}
	// 			/>
	// 		</div>
	// 	</div>
	// )
}

// const fetchAnchorsByStatus = async (status) => {
//     const snapshot = await firestoreOld
//         .collection(`Sites/${selectedSite.id}/Nodes`)
//         .where("floorPlanId", "==", currentFloorPlan.id)
//         .where("nodeType", "==", "anchor")
//         .where("status", "==", status)
//         .get();

//     const anchors = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//     }));

//     return anchors;
// };

// const fetchAnchorsCounts = async () => {
//     const plannedAnchors = await fetchAnchorsByStatus("planned");
//     const placedAnchors = await fetchAnchorsByStatus("placed");
//     const createdAnchors = await firestoreOld
//         .collection(`Sites/${selectedSite.id}/AnchorsPosition`)
//         .where("floorPlanId", "==", currentFloorPlan?.id)
//         .where("status", "==", "created")
//         .get()
//         .then(snapshot => snapshot.size);

//     const onlineAnchors = await getCountFromServer(
//         query(
//             collection(firestoreOld, `Sites/${selectedSite.id}/Nodes`),
//             where("nodeType", "==", "anchor"),
//             where("status", "==", "online")
//         )
//     );

//     const lostAnchors = await getCountFromServer(
//         query(
//             collection(firestoreOld, `Sites/${selectedSite.id}/Nodes`),
//             where("nodeType", "==", "anchor"),
//             where("status", "==", "lost")
//         )
//     );

//     const removedAnchors = await getCountFromServer(
//         query(
//             collection(firestoreOld, `Sites/${selectedSite.id}/Nodes`),
//             where("nodeType", "==", "anchor"),
//             where("status", "==", "removed")
//         )
//     );

//     return {
//         planned: plannedAnchors.length,
//         placed: placedAnchors.length,
//         created: createdAnchors,
//         online: onlineAnchors,
//         lost: lostAnchors,
//         removed: removedAnchors
//     };
// };

// useEffect(() => {
//     if (!selectedSite || !currentFloorPlan) return;

//     fetchAnchorsCounts().then(counts => setAllTotalCount(counts));
// }, [currentFloorPlan, selectedSite, collectionChanged]);

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null,
	notificationsGeofencing: false,
	notificationSwitch: true,
	messagingSwitch: true
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState: initialState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		notificationsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.notifications = action.payload
		},
		notificationEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.notifications =
				state.notifications &&
				state.notifications.map(entity => {
					if (entity.id === action.payload.id) {
						return action.payload
					}
					return entity
				})
		},
		notificationDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.notifications = action.payload
		},
		notificationsGeofencingChanged: (state, action) => {
			state.notificationsGeofencing = action.payload
		},
		notificationSwitchChanged: (state, action) => {
			state.notificationSwitch = action.payload
		},
		messagingSwitchChanged: (state, action) => {
			state.messagingSwitch = action.payload
		},
		updateMessagingVolumeState: (state, action) => {
			state.messagingVolume = action.payload
		},
		updateNotificationsVolumeState: (state, action) => {
			state.notificationsVolume = action.payload
		},
		notificationWarningChanged: (state, action) => {
			state.notificationWarning = action.payload
		},
		resetSeeOnMap: (state, action) => {
			state.notificationWarning = state.notificationWarning.map(item => {
				if (item.seeOnMap === true) {
					return { ...item, seeOnMap: false }
				}
				return item
			})
		}
	}
})

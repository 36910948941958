import _ from "lodash"
import React from "react"

export const hideTagsHtmlWhenClustering = params => {
	const { map, tags, updatedCurrentFloorPlan } = params
	if (!map) return

	let layersAdded = false
	const htmlMarkerWithPopup = document.getElementsByClassName("tags_html_markers_dashboard")

	if (tags) {
		const defaultZoom = 18
		const zoomConditionalLevel = updatedCurrentFloorPlan?.mapOrientation?.zoom
			? updatedCurrentFloorPlan?.mapOrientation?.zoom
			: defaultZoom

		const layers = map.getStyle()
		if (layers) {
			const currentZoom = map.getZoom()
			console.log("🚀  currentZoom:", currentZoom)

			if (currentZoom > zoomConditionalLevel) {
				// tags popups
				if (htmlMarkerWithPopup && htmlMarkerWithPopup.length !== 0) {
					setTimeout(() => {
						htmlMarkerWithPopup.forEach(div => {
							div.style.display = "block"
						})
					}, 0)
				}
			}
			if (currentZoom <= zoomConditionalLevel) {
				// tags popups
				if (htmlMarkerWithPopup && htmlMarkerWithPopup.length !== 0) {
					setTimeout(() => {
						htmlMarkerWithPopup.forEach(div => {
							div.style.display = "none"
						})
					}, 0)
				}
			}
		}

		map.on("zoom", () => {
			const mapZoom = map.getZoom()
			console.log("🚀  mapZoom:", mapZoom)

			if (mapZoom > zoomConditionalLevel && !layersAdded) {
				// tags popups
				if (htmlMarkerWithPopup && htmlMarkerWithPopup.length !== 0) {
					htmlMarkerWithPopup.forEach(div => {
						div.style.display = "block"
					})
				}

				layersAdded = true
			}
			if (mapZoom <= zoomConditionalLevel && layersAdded) {
				// tags popups
				if (htmlMarkerWithPopup && htmlMarkerWithPopup.length !== 0) {
					htmlMarkerWithPopup.forEach(div => {
						div.style.display = "none"
					})
				}

				layersAdded = false
			}
		})
	}
}

export const hideMarkersWhenClustering = params => {
	const {
		map,
		// pointsOfInterest,
		// areas,
		// anchors,
		// gateways,
		setShowLayersBesidesTags,
		showPathOnMap,
		zoomConditionalLevel
	} = params

	if (!map) return

	const POISElement = document.getElementsByClassName("markers-pois")
	const areasElement = document.getElementsByClassName("markers-areasInfo")
	const connectorsElement = document.getElementsByClassName("connectorsMarkerDashboard")
	const anchorsElement = document.getElementsByClassName("markers-anchors")
	const gatewaysElement = document.getElementsByClassName("markers-gateways")

	// if (pointsOfInterest || areas || anchors || gateways) {
	const layers = map.getStyle()

	if (layers) {
		const currentZoom = map.getZoom()

		// remove popups if they are sticky on map when zooming out
		map.on("zoomstart", () => {
			const popupSticky = document.getElementsByClassName("popover")

			if (popupSticky && popupSticky.length !== 0) {
				popupSticky.forEach(div => {
					// div.remove()
					div.style.visibility = "hidden"
				})
			}
		})

		if (currentZoom >= zoomConditionalLevel) {
			// POIS markers
			if (POISElement && POISElement.length !== 0) {
				setTimeout(() => {
					POISElement.forEach(div => {
						div.style.visibility = "visible"
					})
					setShowLayersBesidesTags(true)
				}, 0)
			}
			// Areas markers
			if (areasElement && areasElement.length !== 0) {
				setTimeout(() => {
					areasElement.forEach(div => {
						div.style.visibility = "visible"
					})
					setShowLayersBesidesTags(true)
				}, 0)
			}
			// Connectors markers
			if (connectorsElement && connectorsElement.length !== 0) {
				setTimeout(() => {
					connectorsElement.forEach(div => {
						div.style.visibility = "visible"
					})
					setShowLayersBesidesTags(true)
				}, 0)
			}
			// Anchors markers
			if (anchorsElement && anchorsElement.length !== 0) {
				setTimeout(() => {
					anchorsElement.forEach(div => {
						div.style.visibility = "visible"
					})
					setShowLayersBesidesTags(true)
				}, 0)
			}
			// Gateways markers
			if (gatewaysElement && gatewaysElement.length !== 0) {
				setTimeout(() => {
					gatewaysElement.forEach(div => {
						div.style.visibility = "visible"
					})
					setShowLayersBesidesTags(true)
				}, 0)
			}
		}

		// Less than 18
		if (currentZoom < zoomConditionalLevel) {
			// POIS markers disappear when clusters are displayed
			if (POISElement && POISElement.length !== 0 && !showPathOnMap) {
				setTimeout(() => {
					POISElement.forEach(div => {
						div.style.visibility = "hidden"
					})
					setShowLayersBesidesTags(false)
				}, 0)
			}
			// Areas markers disappear when clusters are displayed
			if (areasElement && areasElement.length !== 0 && !showPathOnMap) {
				setTimeout(() => {
					areasElement.forEach(div => {
						div.style.visibility = "hidden"
					})
					setShowLayersBesidesTags(false)
				}, 0)
			}
			// Connectors markers disappear when clusters are displayed
			if (connectorsElement && connectorsElement.length !== 0 && !showPathOnMap) {
				setTimeout(() => {
					connectorsElement.forEach(div => {
						div.style.visibility = "hidden"
					})
					setShowLayersBesidesTags(false)
				}, 0)
			}
			// Anchors markers disappear when clusters are displayed
			if (anchorsElement && anchorsElement.length !== 0 && !showPathOnMap) {
				setTimeout(() => {
					anchorsElement.forEach(div => {
						div.style.visibility = "hidden"
					})
					setShowLayersBesidesTags(false)
				}, 0)
			}
			// Gateways markers disappear when clusters are displayed
			if (gatewaysElement && gatewaysElement.length !== 0 && !showPathOnMap) {
				setTimeout(() => {
					gatewaysElement.forEach(div => {
						div.style.visibility = "hidden"
					})
					setShowLayersBesidesTags(false)
				}, 0)
			}
		}
		// }

		// // On zoom
		// map.on("zoomend", () => {
		// 	const mapZoom = map.getZoom()
		// 	console.log("🚀  mapZoom:", mapZoom)

		// 	if (mapZoom >= zoomConditionalLevel) {
		// 		// POIS markers
		// 		if (POISElement && POISElement.length !== 0 && !showPathOnMap) {
		// 			POISElement.forEach(div => {
		// 				div.style.visibility = "visible"
		// 			})

		// 			setShowLayersBesidesTags(true)
		// 		}
		// 		// Areas markers
		// 		if (areasElement && areasElement.length !== 0 && !showPathOnMap) {
		// 			areasElement.forEach(div => {
		// 				div.style.visibility = "visible"
		// 			})
		// 			setShowLayersBesidesTags(true)
		// 		}
		// 		// Connectors markers
		// 		if (connectorsElement && connectorsElement.length !== 0 && !showPathOnMap) {
		// 			connectorsElement.forEach(div => {
		// 				div.style.visibility = "visible"
		// 			})
		// 			setShowLayersBesidesTags(true)
		// 		}
		// 		// Anchors markers
		// 		if (anchorsElement && anchorsElement.length !== 0 && !showPathOnMap) {
		// 			anchorsElement.forEach(div => {
		// 				div.style.visibility = "visible"
		// 			})
		// 			setShowLayersBesidesTags(true)
		// 		}
		// 		// Gateways markers
		// 		if (gatewaysElement && gatewaysElement.length !== 0 && !showPathOnMap) {
		// 			gatewaysElement.forEach(div => {
		// 				div.style.visibility = "visible"
		// 			})
		// 			setShowLayersBesidesTags(true)
		// 		}
		// 	}

		// 	if (mapZoom < zoomConditionalLevel) {
		// 		// POIS markers disappear when clusters are displayed
		// 		if (POISElement && POISElement.length !== 0) {
		// 			POISElement.forEach(div => {
		// 				div.style.visibility = "hidden"
		// 			})

		// 			setShowLayersBesidesTags(false)
		// 		}
		// 		// Areas markers disappear when clusters are displayed
		// 		if (areasElement && areasElement.length !== 0) {
		// 			areasElement.forEach(div => {
		// 				div.style.visibility = "hidden"
		// 			})
		// 			setShowLayersBesidesTags(false)
		// 		}
		// 		// Connectors markers disappear when clusters are displayed
		// 		if (connectorsElement && connectorsElement.length !== 0) {
		// 			connectorsElement.forEach(div => {
		// 				div.style.visibility = "hidden"
		// 			})
		// 			setShowLayersBesidesTags(false)
		// 		}
		// 		// Anchors markers disappear when clusters are displayed
		// 		if (anchorsElement && anchorsElement.length !== 0) {
		// 			anchorsElement.forEach(div => {
		// 				div.style.visibility = "hidden"
		// 			})
		// 			setShowLayersBesidesTags(false)
		// 		}
		// 		// Gateways markers disappear when clusters are displayed
		// 		if (gatewaysElement && gatewaysElement.length !== 0) {
		// 			gatewaysElement.forEach(div => {
		// 				div.style.visibility = "hidden"
		// 			})
		// 			setShowLayersBesidesTags(false)
		// 		}
		// 	}
		// })
	}
}

export const hideMarkersWhenClusteringOnZoom = (
	map,
	zoomConditionalLevel,
	showPathOnMap,
	setShowLayersBesidesTags
) => {
	const POISElement = document.getElementsByClassName("markers-pois")
	const areasElement = document.getElementsByClassName("markers-areasInfo")
	const connectorsElement = document.getElementsByClassName("connectorsMarkerDashboard")
	const anchorsElement = document.getElementsByClassName("markers-anchors")
	const gatewaysElement = document.getElementsByClassName("markers-gateways")

	// On zoom
	const mapZoom = map.getZoom()
	// console.log("🚀  mapZoom:", mapZoom)

	if (mapZoom >= zoomConditionalLevel) {
		// POIS markers
		if (POISElement && POISElement.length !== 0 && !showPathOnMap) {
			POISElement.forEach(div => {
				div.style.visibility = "visible"
			})

			setShowLayersBesidesTags(true)
		}
		// Areas markers
		if (areasElement && areasElement.length !== 0 && !showPathOnMap) {
			areasElement.forEach(div => {
				div.style.visibility = "visible"
			})
			setShowLayersBesidesTags(true)
		}
		// Connectors markers
		if (connectorsElement && connectorsElement.length !== 0 && !showPathOnMap) {
			connectorsElement.forEach(div => {
				div.style.visibility = "visible"
			})
			setShowLayersBesidesTags(true)
		}
		// Anchors markers
		if (anchorsElement && anchorsElement.length !== 0 && !showPathOnMap) {
			anchorsElement.forEach(div => {
				div.style.visibility = "visible"
			})
			setShowLayersBesidesTags(true)
		}
		// Gateways markers
		if (gatewaysElement && gatewaysElement.length !== 0 && !showPathOnMap) {
			gatewaysElement.forEach(div => {
				div.style.visibility = "visible"
			})
			setShowLayersBesidesTags(true)
		}
	}

	if (mapZoom < zoomConditionalLevel) {
		// POIS markers disappear when clusters are displayed
		if (POISElement && POISElement.length !== 0) {
			POISElement.forEach(div => {
				div.style.visibility = "hidden"
			})

			setShowLayersBesidesTags(false)
		}
		// Areas markers disappear when clusters are displayed
		if (areasElement && areasElement.length !== 0) {
			areasElement.forEach(div => {
				div.style.visibility = "hidden"
			})
			setShowLayersBesidesTags(false)
		}
		// Connectors markers disappear when clusters are displayed
		if (connectorsElement && connectorsElement.length !== 0) {
			connectorsElement.forEach(div => {
				div.style.visibility = "hidden"
			})
			setShowLayersBesidesTags(false)
		}
		// Anchors markers disappear when clusters are displayed
		if (anchorsElement && anchorsElement.length !== 0) {
			anchorsElement.forEach(div => {
				div.style.visibility = "hidden"
			})
			setShowLayersBesidesTags(false)
		}
		// Gateways markers disappear when clusters are displayed
		if (gatewaysElement && gatewaysElement.length !== 0) {
			gatewaysElement.forEach(div => {
				div.style.visibility = "hidden"
			})
			setShowLayersBesidesTags(false)
		}
	}
}

import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import { Carousel } from "react-bootstrap"
import { profileSlice } from "../../../../redux/profile/profileSlice"
import { firestore as db } from "../../../../../firebase"
import { collection, doc, getDoc, getDocs, limit, query, where } from "firebase/firestore"
import PopoverStickOnHover from "../PopoverStickOnHover"

const { actions } = profileSlice

// export const allLocationsInMap = params => {
// 	// console.log("🚀 ~ params", params);

// 	const {
// 		map,
// 		selectedCustomer,
// 		selectedSite,
// 		locationsMarkers,
// 		allSites,
// 		dispatch,
// 		setLocationsMarkers,
// 		floorPlans,
// 		workers,
// 		markersSize
// 	} = params

// 	locationsMarkers &&
// 		locationsMarkers.forEach(m => {
// 			m.remove()
// 		})

// 	let allMarkersLocations = []

// 	// // Remove sensor markers if !selectedLocation
// 	// sensorMarkers && sensorMarkers.forEach((marker) => marker.remove());

// 	// // Stats with summed employees and visitors
// 	// const statsWithSumArray =
// 	//   allLocationsPeopleStats &&
// 	//   allLocationsPeopleStats?.allLocationStats.map((s) => {
// 	//     return {
// 	//       ...s,
// 	//       summedEmployeesAndVisitors: s.current_employees + s.current_visitors,
// 	//     };
// 	//   });

// 	// // console.log("🚀 ~ statsWithSumArray", statsWithSumArray);

// 	// // Find highest sum number to get division number
// 	// const threshold =
// 	//   statsWithSumArray &&
// 	//   statsWithSumArray.reduce(
// 	//     (prev, current) =>
// 	//       prev.summedEmployeesAndVisitors > current.summedEmployeesAndVisitors
// 	//         ? prev
// 	//         : current,
// 	//     0
// 	//   );
// 	// // console.log("🚀 ~ threshold", threshold?.summedEmployeesAndVisitors);

// 	// Find center coords between several locations
// 	const pointsCoords = []
// 	allSites &&
// 		allSites.forEach(loc => {
// 			// console.log("🚀  loc:", loc);

// 			const lng = loc?.gps_position?.longitude // each location lng
// 			const lat = loc?.gps_position?.latitude // each location lat
// 			pointsCoords.push([lng, lat])

// 			// const nameFound = selectedCustomer.merakiNetworks.find(
// 			//   (l) => l?.netId === loc?.netId
// 			// );
// 			// console.log("🚀 ~ nameFound", nameFound);

// 			// const locFound =
// 			//   statsWithSumArray &&
// 			//   statsWithSumArray.find((s) => s.locationId === loc.netId);
// 			// // console.log("🚀 ~ locFound", locFound);

// 			// const merakiDevicesFound =
// 			//   allLocationsMerakiDevices &&
// 			//   allLocationsMerakiDevices.find((s) => s.locationId === loc.netId);
// 			// // console.log("🚀 ~ merakiDevicesFound", merakiDevicesFound);

// 			// const newWidthAndHeight =
// 			//   locFound &&
// 			//   findMinMax(
// 			//     locFound?.summedEmployeesAndVisitors,
// 			//     threshold?.summedEmployeesAndVisitors
// 			//   );
// 			// // console.log("🚀 ~ NEW WIDTH AND HEIGHT", newWidthAndHeight);

// 			// Different markers size / stats
// 			// const minMax =
// 			//   newWidthAndHeight >= 62
// 			//     ? "84px"
// 			//     : newWidthAndHeight >= 52
// 			//     ? "76px"
// 			//     : newWidthAndHeight >= 42
// 			//     ? "68px"
// 			//     : newWidthAndHeight >= 32
// 			//     ? "60px"
// 			//     : newWidthAndHeight >= 22
// 			//     ? "52px"
// 			//     : newWidthAndHeight >= 12
// 			//     ? "44px"
// 			//     : "36px";

// 			// Location markers
// 			let marker = document.createElement("div")
// 			marker.style.cursor = "pointer"
// 			// marker.style.width = minMax;
// 			// marker.style.height = minMax;
// 			// marker.style.width = "40px"
// 			// marker.style.height = "40px"
// 			marker.style.width = `${markersSize}px` || "26px"
// 			marker.style.height = `${markersSize}px` || "26px"
// 			marker.className = "locationMarker slider-markers-size"

// 			const popupSticky = (
// 				<PopoverStickOnHover
// 					component={
// 						<>
// 							<div
// 								id="popupBorderNoLocation"
// 								style={{
// 									background: "#ECF1F4",
// 									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
// 									borderRadius: "8px",
// 									fontFamily: "Poppins",
// 									fontStyle: "normal",
// 									color: "#4A4A68",
// 									paddingBottom: "2px",
// 									width: "276px"
// 								}}
// 							>
// 								{/* <Carousel
// 									indicators={false}
// 									controls={loc.imagesUrls?.length > 1}
// 									interval={1500}
// 								>
// 									{loc.imagesUrls?.length > 0 &&
// 										loc.imagesUrls.map((arrayUrl, i) => (
// 											<Carousel.Item key={i} interval={1000}>
// 												<img
// 													src={arrayUrl}
// 													alt={loc.type}
// 													style={{
// 														borderRadius: "5px 5px 0px 0px",
// 														height: "90px",
// 														width: "276px",
// 														objectFit: "cover",
// 														objectPosition: "center",
// 														cursor: "pointer" // Add this to change the cursor to a pointer
// 													}}
// 												/>
// 											</Carousel.Item>
// 										))}{" "}
// 								</Carousel> */}
// 								<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
// 									<span>{loc.name}</span>
// 								</div>
// 								<p
// 									style={{
// 										fontWeight: 500,
// 										fontSize: "10px",
// 										fontStyle: "italic",
// 										textAlign: "center",
// 										marginTop: "1rem"
// 									}}
// 								>
// 									Press marker to select location.
// 								</p>
// 							</div>
// 						</>
// 					}
// 					placement="top"
// 					onMouseEnter={() => {}}
// 					delay={200}
// 				>
// 					<div
// 						className="d-flex justify-content-center align-items-center"
// 						style={{
// 							cursor: "pointer",
// 							width: "34px",
// 							height: "34px",
// 							position: "relative",
// 							overflow: "hidden",
// 							borderRadius: "50%"
// 						}}
// 					>
// 						<img
// 							style={{
// 								width: "65%",
// 								height: "65%"
// 							}}
// 							alt="location"
// 							src={"/icons/building.svg"}
// 						/>
// 					</div>
// 				</PopoverStickOnHover>
// 			)

// 			ReactDOM.render(popupSticky, marker)
// 			// const randomNumber = Math.round(Math.random() * 120);

// 			// Hide popup
// 			// if (document.getElementById("popupBorderNoLocation")) {
// 			// 	const element = document.getElementById("popupBorderNoLocation")
// 			// 	console.log("🚀  element 1:", element)
// 			// 	// removes the element from the DOM
// 			// 	element.remove()
// 			// }

// 			const locationMarker = new mapboxgl.Marker(marker, {
// 				// offset: [conditionFarfetch, conditionFarfetch], // Este offset é só p se tiver um caso de 2 locations mto perto uma da outra os markers não se sobreporem. (Farfetch Tower&Bower)
// 			})
// 				.setLngLat([lng, lat])
// 				.addTo(map)

// 			allMarkersLocations.push(locationMarker)

// 			// On Click marker
// 			locationMarker.getElement().addEventListener("click", () => {
// 				// console.log(loc, "onClick loc marker ")

// 				// Select new site
// 				// loc?.id && dispatch(actions.siteSelected(loc))
// 				loc?.id &&
// 					firestoreOld
// 						.collection("Sites")
// 						.doc(loc.id)
// 						.get()
// 						.then(doc => {
// 							if (!doc.exists) return
// 							dispatch(actions.siteSelected({ id: doc.id, ...doc.data() }))
// 						})
// 				// .then(() => {

// 				//   console.log(floorPlans, "floorPlans");

// 				// When new site selected, choose a default floorPlan

// 				// Comented this code above because we don't have networkId on each floorplan anymore
// 				//   const foundedFloorPlanInThisSite =
// 				//     floorPlans &&
// 				//     floorPlans.find((f) => {
// 				//       console.log(f, "f");
// 				//       console.log(loc, "loc");
// 				//       return f.networkId === loc.id;
// 				//     });
// 				//   console.log(
// 				//     "🚀  foundedFloorPlanInThisSite:",
// 				//     foundedFloorPlanInThisSite
// 				//   );

// 				//   if (!foundedFloorPlanInThisSite) {
// 				// console.log("PASSOU , loc:", loc);
// 				// console.log("PASSOU , loc.id:", loc.id);

// 				loc?.id &&
// 					firestoreOld
// 						.collection("Sites")
// 						.doc(loc.id)
// 						.collection("FloorPlans")
// 						// .orderBy("creationInfo.createdOn") // orderBy createdOn date (not needed)
// 						.limit(1)
// 						.get()
// 						.then(snapshot => {
// 							if (snapshot.empty) return
// 							const data = snapshot.docs.map(doc => {
// 								return { ...doc.data(), id: doc.id }
// 							})

// 							data && dispatch(actions.floorPlanSelected(data[0]))
// 							// dispatch(
// 							//   actions.floorPlanSelected({ id: doc.id, ...doc.data() })
// 							// );
// 						})
// 				//   } else {
// 				//  dispatch(actions.floorPlanSelected(foundedFloorPlanInThisSite));
// 				//   }
// 				// });

// 				// Hide popup
// 				const element = document.getElementById("popupBorderNoLocation")
// 				if (element) {
// 					// console.log("🚀  element:", element)

// 					// removes the element from the DOM
// 					element.remove()
// 				}

// 				// Remove markers on click
// 				allMarkersLocations &&
// 					allMarkersLocations.forEach(m => {
// 						m.remove()
// 					})

// 				locationsMarkers &&
// 					locationsMarkers.forEach(m => {
// 						m.remove()
// 					})
// 			})

// 			setLocationsMarkers(allMarkersLocations)
// 		})

// 	// Set center of map displaying site markers with zoom fitBounds between first two locations
// 	if (allSites && pointsCoords.length !== 0) {
// 		// console.log("🚀  allSites:", allSites)
// 		// console.log("🚀 ~ pointsCoords ", pointsCoords)

// 		const long1 = pointsCoords?.[0]?.[0]
// 		const lat1 = pointsCoords?.[0]?.[1]
// 		const long2 = pointsCoords?.[1]?.[0]
// 		const lat2 = pointsCoords?.[1]?.[1]

// 		long1 &&
// 			lat1 &&
// 			long2 &&
// 			lat2 &&
// 			map.fitBounds(
// 				[
// 					[long1, lat1],
// 					[long2, lat2]
// 				],
// 				{
// 					duration: 0,
// 					padding: { top: 120, bottom: 120, left: 120, right: 120 }
// 				}
// 			)

// 		// // Old code setCenter
// 		// var features = pointsCoords && turf.points(pointsCoords);
// 		// var center = features && turf.center(features);
// 		// map.setCenter(center.geometry.coordinates);
// 		// map.setZoom(11.5);
// 	}
// }
export const allLocationsInMap = async params => {
	const {
		map,
		selectedCustomer,
		selectedSite,
		locationsMarkers,
		allSites,
		dispatch,
		setLocationsMarkers,
		floorPlans,
		markersSize
	} = params

	if (locationsMarkers) {
		locationsMarkers.forEach(m => m.remove())
	}

	const allMarkersLocations = []
	const pointsCoords = []

	if (allSites) {
		allSites.forEach(loc => {
			const lng = loc?.gps_position?.longitude
			const lat = loc?.gps_position?.latitude
			if (lng && lat) {
				pointsCoords.push([lng, lat])

				const markerDiv = document.createElement("div")
				markerDiv.style.cursor = "pointer"
				markerDiv.style.width = `${markersSize}px` || "26px"
				markerDiv.style.height = `${markersSize}px` || "26px"
				markerDiv.className = "locationMarker slider-markers-size"

				const popupContent = (
					<PopoverStickOnHover
						component={
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									color: "#4A4A68",
									paddingBottom: "2px",
									width: "276px"
								}}
							>
								<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
									<span>{loc.name}</span>
								</div>
								<p
									style={{
										fontWeight: 500,
										fontSize: "10px",
										fontStyle: "italic",
										textAlign: "center",
										marginTop: "1rem"
									}}
								>
									Press marker to select location.
								</p>
							</div>
						}
						placement="top"
						delay={200}
					>
						<div
							className="d-flex justify-content-center align-items-center"
							style={{
								cursor: "pointer",
								width: "34px",
								height: "34px",
								position: "relative",
								overflow: "hidden",
								borderRadius: "50%"
							}}
						>
							<img
								style={{ width: "65%", height: "65%" }}
								alt="location"
								src="/icons/building.svg"
							/>
						</div>
					</PopoverStickOnHover>
				)

				ReactDOM.render(popupContent, markerDiv)

				const locationMarker = new mapboxgl.Marker(markerDiv).setLngLat([lng, lat]).addTo(map)

				allMarkersLocations.push(locationMarker)

				locationMarker.getElement().addEventListener("click", async () => {
					if (loc?.id) {
						const docSnap = await getDoc(doc(db, "Sites", loc.id))
						if (docSnap) {
							dispatch(actions.siteSelected({ id: docSnap.id, ...docSnap.data() }))

							const snapshot = await getDocs(
								query(collection(db, `Sites/${loc.id}/FloorPlans`), limit(1))
							)

							if (!snapshot.empty) {
								const data = snapshot.docs.map(doc => ({
									...doc.data(),
									id: doc.id
								}))
								dispatch(actions.floorPlanSelected(data[0]))
							}
						}
					}

					// Remove all markers
					allMarkersLocations &&
						allMarkersLocations.length > 0 &&
						allMarkersLocations.forEach(m => m.remove())
					locationsMarkers &&
						locationsMarkers.length > 0 &&
						locationsMarkers.forEach(m => m.remove())
				})
			}
		})

		setLocationsMarkers(allMarkersLocations)

		if (pointsCoords.length > 1) {
			const [[long1, lat1], [long2, lat2]] = pointsCoords
			map.fitBounds(
				[
					[long1, lat1],
					[long2, lat2]
				],
				{
					duration: 0,
					padding: { top: 120, bottom: 120, left: 120, right: 120 }
				}
			)
		}
	}
}

export const findMinMax = (value, threshold) => {
	if (threshold >= 1000) return value / 17
	if (threshold >= 850) return value / 16
	if (threshold >= 750) return value / 15
	if (threshold >= 500) return value / 10
	if (threshold >= 250) return value / 7
	if (threshold >= 100) return value / 3
	if (threshold >= 50) return value / 1.5
	return value / 12
}

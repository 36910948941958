import React from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import { TabButton } from "./TabButton"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`
	}
}

const useStyles = makeStyles(theme => ({
	root: {
		filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))",

		// backgroundColor: theme.palette.background.paper,
		// width: 500,
		"& .MuiAppBar-colorDefault": {
			backgroundColor: "#FFFFFF",
			boxShadow: "none"
		},

		// "& .MuiTabs-flexContainer": {
		// 	marginTop: "15px"
		// },

		"& .MuiTabs-indicator": {
			top: "0",
			height: "4px"
		},

		"& .MuiTabs-root": {
			borderRadius: "4px"
		}
	}
}))

export default function TabMenu({ numberOfTabs, tabLabels, tabContents, showTable, setShowTable }) {
	const classes = useStyles()
	const theme = useTheme()
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const handleChangeIndex = index => {
		setValue(index)

		//set all except matching index to false
		const newShowTable = {}

		tabLabels.forEach((label, idx) => {
			newShowTable[idx] = idx === index
		})

		setShowTable(newShowTable)
	}

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
				>
					{Array.from({ length: numberOfTabs }).map((_, index) => (
						<TabButton
							startIcon={tabLabels[index].icon}
							key={index}
							// variant="contained" //remove variant to remove background
							color="primary"
							text={tabLabels[index].text}
							onClick={() => handleChangeIndex(index)}
							contentAlignment={tabLabels[index].contentAlignment}
							activeTab={showTable && showTable[index]}
							{...a11yProps(index)}
						/>
					))}
				</Tabs>
			</AppBar>
			{Array.from({ length: numberOfTabs }).map((_, index) => (
				<TabPanel key={index} value={value} index={index} dir={theme.direction}>
					{tabContents[index]}
				</TabPanel>
			))}
		</div>
	)
}

TabMenu.propTypes = {
	numberOfTabs: PropTypes.number.isRequired,
	tabLabels: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			variant: PropTypes.string,
			color: PropTypes.string
		})
	).isRequired,
	tabContents: PropTypes.arrayOf(PropTypes.node).isRequired
}

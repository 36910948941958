import React, { createContext, useContext, useState, useCallback } from "react"
import { isEqual, isFunction } from "lodash"
import { initialFilter } from "./UIHelpers"

const UIContext = createContext()

const initialColumnsOrder = [
	{ field: "date", sort: null },
	{ field: "area", sort: null },
	{ field: "time", sort: null }
]

export function useUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ UIEvents, children }) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter)
	//for data grid
	const [ids, setIds] = useState([])
	const [columnsOrder, setColumnsOrder] = useState(initialColumnsOrder)
	const [rowsToUse, setRowsToUse] = useState([])
	const [columnVisibilityModel, setColumnVisibilityModel] = useState({}) //visibile columns object for exporting
	const [currentTablePageInfo, setCurrentTablePageInfo] = useState({}) //page and pageSize to export current page only

	const setQueryParams = useCallback(nextQueryParams => {
		setQueryParamsBase(prevQueryParams => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams)
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams
			}

			return nextQueryParams
		})
	}, [])

	const value = {
		queryParams,
		setQueryParams,
		ids,
		setIds,
		columnsOrder,
		setColumnsOrder,
		rowsToUse,
		setRowsToUse,
		columnVisibilityModel,
		setColumnVisibilityModel,
		currentTablePageInfo,
		setCurrentTablePageInfo
	}

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

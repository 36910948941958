import moment from "moment"

export const userMocked = (startDate, endDate, areasData) => {
	// console.log("🚀  areasData", areasData);
	// console.log("🚀  endDate", endDate);
	// console.log("🚀  startDate", startDate);

	const filteredAreas = areasData.filter(area => area.floorPlanId === "IQfM4TlwajQ7JNaMhChb")
	// console.log("🚀  filteredAreas", filteredAreas);

	const startDateParsed = moment(startDate)
	const endDateParsed = moment(endDate)

	const dateList = getDaysBetweenDates(startDateParsed, endDateParsed)
	// console.log(dateList, "dateList");

	const newRowMonth = []

	dateList &&
		dateList.forEach(date => {
			filteredAreas.forEach(a => {
				newRowMonth.push([
					date,
					"0a2e389691b85c2b",
					"IQfM4TlwajQ7JNaMhChb",
					a.name,
					randomIntFromInterval(5, 65)
				])
			})
		})

	// console.log("🚀  newRowMonth", newRowMonth)

	return newRowMonth
}
// export const userMocked = (startDate, endDate, areasData) => {
//   console.log("🚀  areasData", areasData);
//   console.log("🚀  endDate", endDate);
//   console.log("🚀  startDate", startDate);

//   const filteredAreas = areasData.filter(
//     (area) => area.floorPlanId === "IQfM4TlwajQ7JNaMhChb"
//   );
//   console.log("🚀  filteredAreas", filteredAreas);

//   const schedule = getDaysArrayByMonth();

//   let newRowMonth = [];

//   schedule.forEach(function(date, i) {
//     filteredAreas.forEach((a) => {
//       newRowMonth.push([
//         date,
//         "0a2e389691b85c2b",
//         "IQfM4TlwajQ7JNaMhChb",
//         a.name,
//         randomIntFromInterval(5, 65),
//       ]);
//     });
//   });
//   console.log("🚀  newRowMonth", newRowMonth);

//   return newRowMonth;
// };

// Helper function Get Days between dates array
var getDaysBetweenDates = function (startDate, endDate) {
	var now = startDate.clone(),
		dates = []

	while (now.isSameOrBefore(endDate)) {
		dates.push(now.format("ddd MMM D YYYY")) // with year included
		// dates.push(now.format("ddd MMM D"));
		now.add(1, "days")
	}
	return dates
}

// get days of each month
function getDaysArrayByMonth() {
	var daysInMonth = moment().daysInMonth()
	var arrDays = []

	while (daysInMonth) {
		const current = moment().date(daysInMonth)
		// .format("DD-MM-YYYY");
		// console.log("🚀  current", current);

		const dateToday = moment()
		// .subtract(1, "days")
		// .format("DD-MM-YYYY");
		// console.log("🚀  dateToday", dateToday);

		if (current <= dateToday) {
			arrDays.push(current)
		}

		daysInMonth--
	}

	//   console.log(arrDays, "arrDays");

	return arrDays
}

function randomIntFromInterval(min, max) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min)
}

// returned schema from API
// {
//     date: "2021-01-16 10:00:00",
//     macAddress: "0a2e389691b85c2b",
//     floorPlanId: "g_596726950626592201",
//     zoneName: "Stock Room",
//     minutes: 215,
//   },

import React, { useEffect, useState, useMemo } from "react"
import { useUIContext } from "../../UIContext"
import { DataGrid } from "@mui/x-data-grid"
import { makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { shallowEqual, useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		border: "none",
		height: "530px",
		"& .MuiDataGrid-root": {
			height: "610px", // Set a fixed height for the grid
			overflow: "auto" // Enable scrolling
		},

		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},

		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0" // Ensure that minWidth is set to 0 to allow percentage width
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},

		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "-1px !important",
			border: "1px solid #f4f8fe !important"
		},

		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
			minHeight: "90px !important"
		}
	},

	"& .MuiDataGrid-columnSeparator": {
		opacity: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "600 !important",
		fontSize: "1rem",
		color: "#4a4a68"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--textLeft": {
		fontSize: "14px !important",
		fontWeight: "500 !important"
	},

	"& .MuiDataGrid-columnHeaderTitleContainer": {
		paddingLeft: "0 !important",
		paddingRight: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
		paddingRight: "0px !important",
		paddingLeft: "10px !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
		paddingLeft: "2px"
	},

	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		backgroundColor: "inherit !important"
	},

	"& .MuiDataGrid-colCell": {
		minWidth: 0
	},

	"& .actions-header": {
		marginLeft: "10px"
	},

	"& .MuiDataGrid-row": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiPagination-root": {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	"& .MuiPaginationItem-root": {
		borderRadius: "3px !important",
		color: "#3077d3 !important",
		padding: "0 !important",
		margin: "0 !important",
		border: "1px solid #f4f8fe !important"
	},

	"& .MuiPaginationItem-textPrimary.Mui-selected": {
		color: "#fff !important",
		border: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
		{
			color: "#3077d3"
		},

	"& .MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon": {
		color: "#3077d3"
	},

	"& .MuiDataGrid-root .MuiDataGrid-viewport": {
		minHeight: "700px",
		maxHeight: "none"
	},

	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
		minHeight: "90px !important"
	}
}))

const PreviewPeople = () => {
	const classes = useStyles()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			selectedValues: UIContext.selectedValues,
			setSelectedValues: UIContext.setSelectedValues,
			isClosed: UIContext.isClosed,
			setIsClosed: UIContext.setIsClosed,
			sitesValue: UIContext.sitesValue,
			setConfirmTabRows: UIContext.setConfirmTabRows
		}
	}, [UIContext])

	const { people, selectedSite, allVendors, allPeople, allGroups } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			people: state.basePage?.people || [],
			allPeople: state?.manageGroups?.allPeople,
			allGroups: state?.manageGroups?.allGroups,
			allVendors: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	// const [allPeople, setAllPeople] = useState([])
	const [peopleToUse, setPeopleToUse] = useState([])
	const [filteredPeopleToUse, setFilteredPeopleToUse] = useState([])

	const columns = [
		{
			field: "name",
			headerName: "Name",
			hideable: false,
			minWidth: 100,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			renderCell: params => {
				const row = params.row.personalData

				return row ? <span>{row.name}</span> : <span>-</span>
			}
		},
		{
			field: "companyId",
			headerName: "Company",
			type: "string",
			minWidth: 100,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			renderCell: params => {
				const row = params.row

				const vendor = allVendors.find(vendor => vendor.id === row.companyId)
				const companyName = vendor ? vendor.company : null

				return companyName ? <span>{companyName}</span> : <span>{row.companyId}</span>
			}
		},
		{
			field: "uuid",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			headerName: "UUID",
			type: "string",
			flex: 1
		}
	]

	const parsePeopleData = data => {
		if (!data || data.length === 0) return []
		return data
			.filter(
				person =>
					UIProps.sitesValue &&
					UIProps.sitesValue?.length > 0 &&
					UIProps.sitesValue.includes(person.siteId)
			)
			.map(person => ({
				...person,
				companyName:
					(allVendors && allVendors.find(vendor => vendor.id === person.companyId)?.company) ||
					""
			}))
			.sort((a, b) => {
				if (a.personalData?.name && b.personalData?.name) {
					return a.personalData?.name.localeCompare(b.personalData?.name)
				}
				return 0
			})
	}

	const handlePeopleToUse = () => {
		// if (UIProps.sitesValue.toString() === selectedSite.id) {
		// 	const parsedPeople = parsePeopleData(people)
		// 	setPeopleToUse(parsedPeople)
		// 	UIProps.setConfirmTabRows(parsedPeople.map(person => person.id))
		// 	setFilteredPeopleToUse(null)
		// } else {
		// 	const parsedAllPeople = parsePeopleData(allPeople)
		// 	setPeopleToUse(parsedAllPeople)
		// 	UIProps.setConfirmTabRows(parsedAllPeople.map(person => person.id))
		// 	setFilteredPeopleToUse(null)
		// }

		if (!allPeople) return
		const parsedPeople = parsePeopleData(allPeople)

		setPeopleToUse(parsedPeople)
		UIProps.setConfirmTabRows(parsedPeople.map(person => person.id))
		setFilteredPeopleToUse(null)
	}

	// useEffect(() => {
	// 	if (!UIProps.sitesValue || !selectedSite) return

	// 	handlePeopleToUse()
	// }, [UIProps.sitesValue, selectedSite])

	useEffect(() => {
		if (!UIProps.sitesValue || !selectedSite || !allPeople) return

		handlePeopleToUse()
	}, [UIProps.sitesValue, selectedSite, allPeople])

	useEffect(() => {
		if (!UIProps.isClosed) return

		if (!UIProps.selectedValues || Object.keys(UIProps.selectedValues).length === 0) {
			handlePeopleToUse()
			return
		}

		// Extract roles from selectedValues
		const roles = UIProps.selectedValues.Roles || []
		const vendors = UIProps.selectedValues.Vendors || []
		const groups = UIProps.selectedValues.Default || []

		// Filter people based on roles
		const filteredPeople = peopleToUse.filter(person => {
			// Check if person has a role that matches any of the selected roles
			const r = roles.some(role => person.role && person.role.includes(role))
			const v = vendors.some(vendor => person.companyName && person.companyName.includes(vendor))

			if (!r && !v) {
				return false
			}
			if (r && !v) {
				return true
			}
			if (v && !r) {
				return true
			}
			if (r && v) {
				return true
			}
		})

		const matchingGroupMembers = allGroups.filter(
			group => groups?.length > 0 && groups.includes(group.name)
		)

		const memberIds = matchingGroupMembers.flatMap(group => group.members)

		const matchingGroupMembersWithPeople = peopleToUse.filter(person =>
			memberIds.includes(person.id)
		)

		const combinedPeople = [...filteredPeople, ...matchingGroupMembersWithPeople]

		const uniquePeopleMap = new Map()
		combinedPeople.forEach(person => {
			uniquePeopleMap.set(person.id, person)
		})

		const uniquePeople = Array.from(uniquePeopleMap.values())

		// Update the state with filtered people
		const sortedFilteredPeople = uniquePeople.sort((a, b) => {
			if (a.personalData?.name && b.personalData?.name) {
				return a.personalData?.name.localeCompare(b.personalData?.name)
			}
			return 0
		})
		setFilteredPeopleToUse(sortedFilteredPeople)
		UIProps.setConfirmTabRows(sortedFilteredPeople.map(person => person.id))

		// Reset isClosed state
		UIProps.setIsClosed(false)
	}, [UIProps.isClosed, UIProps.selectedValues])

	return (
		<div data-testid="preview-people-container">
			<div style={{ padding: "1rem 0" }}>
				<span
					style={{
						color: "#444",
						fontFamily: "Poppins",
						fontSize: "16px",
						fontStyle: "normal",
						fontWeight: "600",
						lineHeight: "160%"
					}}
				>
					Selected Recipients{" "}
					{filteredPeopleToUse && filteredPeopleToUse.length > 0
						? `(${filteredPeopleToUse.length})`
						: `(${peopleToUse.length})`}
				</span>
			</div>
			<div className={classes.root}>
				<Box sx={{ width: "100%" }}>
					<DataGrid
						rows={filteredPeopleToUse || peopleToUse}
						columns={columns}
						style={{
							fontFamily: "Poppins",
							fontWeight: 400,
							fontSize: "13px",
							border: "none",
							width: "100%",
							color: "#464e5f"
						}}
						className={classes.root}
						density="standard"
						pagination
						disableSelectionOnClick
						disableColumnMenu={true}
						hideFooterPagination
						autoHeight={false}
						sortingOrder={["desc", "asc"]}
					/>
				</Box>
			</div>
		</div>
	)
}

export default PreviewPeople

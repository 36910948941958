import * as requestFromServer from "./peopleCrud"
import { reportingSlice, callTypes } from "../reportingSlice"
// import * as ActionHelpers from "./ActionHelpers"
import * as ActionHelpersNew from "./ActionHelpersNew"

const { actions } = reportingSlice

// Main Promise.all function for People Summary
export const fetchPeopleSummary = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		fetchAllPeople(queryParams, dispatch),
		fetchReportAreas(queryParams, dispatch),
		fetchReportFloorsPeople(queryParams, dispatch),
		fetchLocationReportAreas(queryParams, dispatch),
		fetchReportFloorsLocation(queryParams, dispatch),
		fetchReportBuildingsLocation(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleSummary function : ", error)
		error.clientMessage = "Can't fetch People Summary"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}

// Main Promise.all function for People Timesheets
export const fetchPeopleTimesheetFetchAllPeople = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		fetchAllPeople(queryParams, dispatch)
		// fetchAllPeopleToTimesheets(queryParams, dispatch),
		// fetchSinglePeopleToTimesheets(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleTimesheet function : ", error)
		error.clientMessage = "Can't fetch People By Floor"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}
export const fetchPeopleTimesheetFetchAllPeopleToTimesheets = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		// fetchAllPeople(queryParams, dispatch),
		fetchAllPeopleToTimesheets(queryParams, dispatch)
		// fetchSinglePeopleToTimesheets(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleTimesheet function : ", error)
		error.clientMessage = "Can't fetch People By Floor"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}
export const fetchPeopleTimesheetFetchSinglePeopleToTimesheets = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		// fetchAllPeople(queryParams, dispatch),
		// fetchAllPeopleToTimesheets(queryParams, dispatch),
		fetchSinglePeopleToTimesheets(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleTimesheet function : ", error)
		error.clientMessage = "Can't fetch People By Floor"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}

// Used in charts: Top 6 attendance tracking, Attendance by role
function fetchAllPeople(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.allPeopleDistribution }))
	if (!queryParams) dispatch(actions.allPeopleDistributionFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.allPeopleDistributionFetched(null))

			const data = ActionHelpersNew.handleAllPeopleDistributionResult1({
				result,
				queryParams
			})

			const newData = ActionHelpersNew.parsingAllPeopleDataHelper(data)

			const finalData =
				newData &&
				ActionHelpersNew.handleAttendenceByRole({
					result: newData,
					queryParams
				})

			finalData && dispatch(actions.allPeopleDistributionFetched(finalData))
		})

		.catch(error => {
			console.log("Fetch all People distribution: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// Used in charts: Total time spent per Area
export function fetchReportAreas(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.areaAvgTimeSpent }))

	if (!queryParams) dispatch(actions.areaAvgTimeSpentFetched(null))
	return requestFromServer
		.getAvgTimeSpent(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.areaAvgTimeSpentFetched(null))

			const data = ActionHelpersNew.avgTimeSpentReportAreas({
				result,
				queryParams
			})

			data && dispatch(actions.areaAvgTimeSpentFetched(data))
		})

		.catch(error => {
			console.log("Fetch avg time spent report Areas: ", error)
			error.clientMessage = "Can't fetch avg time spent report Areas"
			dispatch(actions.catchError({ error, callType: callTypes.areaAvgTimeSpent }))
		})
}

// // Used in charts: People Distribution per Area
// export function fetchReportAreas(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.areaAvgTimeSpent }))

// 	if (!queryParams) dispatch(actions.areaAvgTimeSpentFetched(null))
// 	return requestFromServer
// 		.getAvgTimeSpent(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.areaAvgTimeSpentFetched(null))

// 			console.log("🚀  result:", result)

// 			const data = ActionHelpersNew.avgTimeSpentReportAreas({
// 				result,
// 				queryParams
// 			})

// 			data && dispatch(actions.areaAvgTimeSpentFetched(data))
// 		})

// 		.catch(error => {
// 			console.log("Fetch avg time spent report Areas: ", error)
// 			error.clientMessage = "Can't fetch avg time spent report Areas"
// 			dispatch(actions.catchError({ error, callType: callTypes.areaAvgTimeSpent }))
// 		})
// }

// Used in charts: Total time per Floor
export function fetchReportFloorsPeople(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.peopleDistribution }))

	if (!queryParams) dispatch(actions.peopleDistributionFetched(null))
	return requestFromServer
		.getPeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.peopleDistributionFetched(null))

			const data = ActionHelpersNew.parseReportFloors({
				result,
				queryParams
			})

			data && dispatch(actions.peopleDistributionFetched(data))
		})

		.catch(error => {
			console.log("fetchReportFloorsPeople function error: ", error)
			error.clientMessage = "Can't fetch Report Floors People"
			dispatch(actions.catchError({ error, callType: callTypes.peopleDistribution }))
		})
}

// Used in charts: Location Summary Areas
export function fetchLocationReportAreas(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.areaLocationTimeSpent }))

	if (!queryParams) dispatch(actions.fetchLocationReportAreasFetched(null))
	return requestFromServer
		.getAvgTimeSpent(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.fetchLocationReportAreasFetched(null))

			const data = ActionHelpersNew.locationTimeSpentReportAreas({
				result,
				queryParams
			})

			data && dispatch(actions.fetchLocationReportAreasFetched(data))
		})

		.catch(error => {
			console.log("fetchLocationReportAreas: ", error)
			error.clientMessage = "Can't fetch avg time spent report Areas Location"
			dispatch(actions.catchError({ error, callType: callTypes.areaLocationTimeSpent }))
		})
}

// Used in charts: Location Summary Floors
export function fetchReportFloorsLocation(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.peopleDistributionLocation }))

	if (!queryParams) dispatch(actions.peopleDistributionLocationFetched(null))
	return requestFromServer
		.getPeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0)
				dispatch(actions.peopleDistributionLocationFetched(null))

			const data = ActionHelpersNew.parseLocationReportFloors({
				result,
				queryParams
			})

			data && dispatch(actions.peopleDistributionLocationFetched(data))
		})

		.catch(error => {
			console.log("fetch Report Floors Location function error: ", error)
			error.clientMessage = "Can't fetch Report Floors Location"
			dispatch(actions.catchError({ error, callType: callTypes.peopleDistributionLocation }))
		})
}

// Used in charts: Location Summary Buildings
export function fetchReportBuildingsLocation(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.peopleBuildings }))

	if (!queryParams) dispatch(actions.peopleBuildingsFetched(null))
	return requestFromServer
		.getPeopleDistributionBuildings(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.peopleBuildingsFetched(null))

			const data = ActionHelpersNew.parseLocationReportBuildings({
				result,
				queryParams
			})

			data && dispatch(actions.peopleBuildingsFetched(data))
		})

		.catch(error => {
			console.log("fetch Report Buildings Location function error: ", error)
			error.clientMessage = "Can't fetch Report Buildings Location"
			dispatch(actions.catchError({ error, callType: callTypes.peopleBuildings }))
		})
}

// Used in charts: Total contracted workers per role timesheet
function fetchAllPeopleToTimesheets(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.allPeopleTimesheet }))
	if (!queryParams) dispatch(actions.allPeopleToTimesheetsFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result) dispatch(actions.allPeopleToTimesheetsFetched(null))

			const data = ActionHelpersNew.handleAllPeopleDistributionResult1({
				result,
				queryParams
			})

			const newData = ActionHelpersNew.parsingAllPeopleDataHelper(data)

			newData && dispatch(actions.allPeopleToTimesheetsFetched(newData))
		})

		.catch(error => {
			console.log("Fetch all People distribution: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// Used in charts: Daily contracted workers timesheet
function fetchSinglePeopleToTimesheets(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.singlePeopleTimesheet }))
	if (!queryParams) dispatch(actions.singlePeopleToTimesheetsFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			// console.log("🚀 ~ fetchSinglePeopleToTimesheets ~ result:", result)

			if (!result) dispatch(actions.singlePeopleToTimesheetsFetched(null))

			const data = ActionHelpersNew.handleAllPeopleDistributionResult({
				result,
				queryParams
			})
			// console.log("🚀  data:", data)

			data && dispatch(actions.singlePeopleToTimesheetsFetched(data))
		})

		.catch(error => {
			console.log("Fetch all People distribution: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// // Used in charts: Daily contracted workers timesheet
// function fetchSinglePeopleToTimesheets(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.singlePeopleTimesheet }))
// 	if (!queryParams) dispatch(actions.singlePeopleToTimesheetsFetched(null))

// 	return requestFromServer
// 		.getAllThePeopleDistributionOld(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.singlePeopleToTimesheetsFetched(null))
// 			console.log("🚀  result: old 2", result)

// 			const data = ActionHelpersNew.handleAllPeopleDistributionResult({
// 				result,
// 				queryParams
// 			})
// 			console.log("🚀  data:", data)

// 			data && dispatch(actions.singlePeopleToTimesheetsFetched(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch all People distribution: ", error)
// 			error.clientMessage = "Can't fetch all People distribution"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

// function fetchFloorPeopleDistribution(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.floorPeopleDistribution }))
// 	if (!queryParams) dispatch(actions.floorPeopleDistributionFetched(null))
// 	return requestFromServer
// 		.getFloorPeopleDistribution(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.floorPeopleDistributionFetched(null))
// 			const data = ActionHelpers.handlePeopleDistributionResult({
// 				result,
// 				queryParams
// 			})
// 			dispatch(actions.floorPeopleDistributionFetched(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch People Distribution: ", error)
// 			error.clientMessage = "Can't fetch People Distribution"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

// async function fetchFloorOccupancy(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.floorOccupancy }))
// 	if (!queryParams) dispatch(actions.floorOccupancyFetched(null))
// 	const floorOccupancyResult = await requestFromServer
// 		.getFloorOccupancy(queryParams)
// 		.then(response => response && response.json())
// 		.catch(error => {
// 			console.log("Fetch floor occupancy: ", error)
// 			error.clientMessage = "Can't fetch floor occupancy"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})

// 	const totalPeopleResult = await requestFromServer
// 		.getTotalPeople(queryParams)
// 		.then(response => response && response.json())
// 		.catch(error => {
// 			console.log("Fetch Total People: ", error)
// 			error.clientMessage = "Can't fetch Total People"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})

// 	if (floorOccupancyResult && totalPeopleResult) {
// 		const data = ActionHelpers.handleFloorOccupancyResult({
// 			floorOccupancyResult,
// 			totalPeopleResult,
// 			queryParams
// 		})
// 		data && dispatch(actions.floorOccupancyFetched(data))
// 	} else {
// 		dispatch(actions.floorOccupancyFetched(null))
// 	}
// }

// function fetchFloorAverageOccupancy(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.floorAverageOccupancy }))
// 	if (!queryParams) dispatch(actions.floorAverageOccupancyFetched(null))
// 	return requestFromServer
// 		.getFloorAverageOccupancy(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.floorAverageOccupancyFetched(null))

// 			const data = ActionHelpers.handleFloorAverageOccupancyResult({
// 				result,
// 				queryParams
// 			})
// 			dispatch(actions.floorAverageOccupancyFetched(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch average occupancy", error)
// 			error.clientMessage = "Can't fetch average occupancy"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

//★━━━━━━━━━━━━━━━━★ Zone ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

// export const fetchPeopleByZone = queryParams => dispatch => {
// 	dispatch(actions.startCall({ callType: callTypes.list }))

// 	return Promise.all([
// 		fetchZoneOccupancy(queryParams, dispatch),
// 		fetchZoneAverageOccupancy(queryParams, dispatch),
// 		fetchZonePeopleDistribution(queryParams, dispatch),
// 		fetchZoneAvgTimeSpent(queryParams, dispatch),
// 		fetchFloorPeopleForChordDiagram(queryParams, dispatch),
// 		fetchFloorPeopleForSankeyCircularChart(queryParams, dispatch),
// 		fetchFloorPeopleForSankeyChart(queryParams, dispatch),
// 		fetchFloorPeopleForSankeyChartWithDragNodes(queryParams, dispatch)
// 	]).catch(error => {
// 		console.log("Fetch People By Zone: ", error)
// 		error.clientMessage = "Can't fetch People By Zone"
// 		dispatch(actions.catchError({ error, callType: callTypes.list }))
// 	})
// }

// async function fetchZoneOccupancy(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zoneOccupancy }))
// 	if (!queryParams) dispatch(actions.zoneOccupancyFetched(null))
// 	const zoneOccupancyResult = await requestFromServer
// 		.getZoneOccupancy(queryParams)
// 		.then(response => response && response.json())
// 		.catch(error => {
// 			console.log("Fetch zone occupancy: ", error)
// 			error.clientMessage = "Can't fetch zone occupancy"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})

// 	const totalPeopleResult = await requestFromServer
// 		.getTotalPeople(queryParams)
// 		.then(response => response && response.json())
// 		.catch(error => {
// 			console.log("Fetch Total People: ", error)
// 			error.clientMessage = "Can't fetch Total People"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})

// 	if (zoneOccupancyResult && totalPeopleResult) {
// 		const data = ActionHelpers.handleZoneOccupancyResult({
// 			zoneOccupancyResult,
// 			totalPeopleResult,
// 			queryParams
// 		})
// 		dispatch(actions.zoneOccupancyFetched(data))
// 	} else {
// 		dispatch(actions.zoneOccupancyFetched(null))
// 	}
// }

// function fetchZoneAverageOccupancy(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zoneAverageOccupancy }))
// 	if (!queryParams) dispatch(actions.zoneAverageOccupancyFetched(null))
// 	return requestFromServer
// 		.getZoneAverageOccupancy(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.zoneAverageOccupancyFetched(null))
// 			const data = ActionHelpers.handleZoneAverageOccupancy({
// 				result,
// 				queryParams
// 			})

// 			dispatch(actions.zoneAverageOccupancyFetched(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch zone average occupancy: ", error)
// 			error.clientMessage = "Can't fetch zone average occupancy"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

// function fetchZonePeopleDistribution(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zonePeopleDistribution }))
// 	if (!queryParams) dispatch(actions.zonePeopleDistributionFetched(null))
// 	return requestFromServer
// 		.getZonePeopleDistribution(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.zonePeopleDistributionFetched(null))
// 			const data = ActionHelpers.handleZonePeopleDistribution({
// 				result,
// 				queryParams
// 			})
// 			dispatch(actions.zonePeopleDistributionFetched(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch Zone People Distribution: ", error)
// 			error.clientMessage = "Can't fetch Zone People Distribution"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

// function fetchZoneAvgTimeSpent(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zoneAvgTimeSpent }))
// 	if (!queryParams) dispatch(actions.zoneAvgTimeSpentFetched(null))
// 	return requestFromServer
// 		.getAvgTimeSpent(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.zoneAvgTimeSpentFetched(null))
// 			const data = ActionHelpers.avgTimeSpentTopZonesResultToGraphData({
// 				result,
// 				queryParams
// 			})

// 			dispatch(actions.zoneAvgTimeSpentFetched(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch avg time spent: ", error)
// 			error.clientMessage = "Can't fetch avg time spent"
// 			dispatch(actions.catchError({ error, callType: callTypes.zoneAvgTimeSpent }))
// 		})
// }

// function fetchFloorPeopleForChordDiagram(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zoneOccupancyForChord }))
// 	if (!queryParams) dispatch(actions.zoneOccupancyFetchedForChord(null))
// 	return requestFromServer
// 		.getZoneOccupancyForChord(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.zoneOccupancyFetchedForChord(null))

// 			const data = ActionHelpers.handleZoneOccupancyForChord({
// 				result,
// 				queryParams
// 			})

// 			dispatch(actions.zoneOccupancyFetchedForChord(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch people per zone for Chord error: ", error)
// 			error.clientMessage = "Can't fetch People per zone for Chord "
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

// function fetchFloorPeopleForSankeyCircularChart(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zoneOccupancyForSankeyCircular }))
// 	if (!queryParams) dispatch(actions.zoneOccupancyFetchedForSankeyCircular(null))
// 	return requestFromServer
// 		.getZoneOccupancyForSankey(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.zoneOccupancyFetchedForSankeyCircular(null))

// 			const data = ActionHelpers.handleZoneOccupancyForSankeyCircular({
// 				result,
// 				queryParams
// 			})

// 			dispatch(actions.zoneOccupancyFetchedForSankeyCircular(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch people per zone for Sankey Circular error: ", error)
// 			error.clientMessage = "Can't fetch People per zone for Sankey Circular"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

// function fetchFloorPeopleForSankeyChart(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zoneOccupancyForSankey }))
// 	if (!queryParams) dispatch(actions.zoneOccupancyFetchedForSankey(null))
// 	return requestFromServer
// 		.getZoneOccupancyForSankey(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.zoneOccupancyFetchedForSankey(null))

// 			const data = ActionHelpers.handleZoneOccupancyForSankey({
// 				result,
// 				queryParams
// 			})

// 			dispatch(actions.zoneOccupancyFetchedForSankey(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch people per zone for Sankey error: ", error)
// 			error.clientMessage = "Can't fetch People per zone for Sankey "
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

// function fetchFloorPeopleForSankeyChartWithDragNodes(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.zoneOccupancyForSankeyWithDrag }))
// 	if (!queryParams) dispatch(actions.zoneOccupancyFetchedForSankeyWithDrag(null))
// 	return requestFromServer
// 		.getZoneOccupancyForSankey(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.zoneOccupancyFetchedForSankeyWithDrag(null))

// 			const data = ActionHelpers.handleZoneOccupancyForSankeyWithDrag({
// 				result,
// 				queryParams
// 			})

// 			dispatch(actions.zoneOccupancyFetchedForSankeyWithDrag(data))
// 		})
// 		.catch(error => {
// 			console.log("Fetch people per zone for Sankey with drag error: ", error)
// 			error.clientMessage = "Can't fetch People per zone for Sankey with drag."
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

import React, { useMemo, useState, useEffect, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import QRCode from "react-qr-code"
import { useUIContext } from "../UIContext"
import PrintIcon from "@material-ui/icons/Print"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton, TextField, Select, makeStyles } from "@material-ui/core"
import { Button } from "../../../../_partials/Button"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import LabelMultipleNodes from "./LabelMultipleNodes"

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: 2
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	select: {
		"& .MuiSelect-select[multiple]": {
			height: "60px",
			maxHeight: "60px"
		}
	},
	selectSingle: {
		"& .MuiSelect-select[multiple]": {
			height: "15px",
			maxHeight: "15px"
		}
	}
}))

const names = [
	"Oliver Hansen",
	"Van Henry",
	"April Tucker",
	"Ralph Hubbard",
	"Omar Alexander",
	"Carlos Abbott",
	"Miriam Wagner",
	"Bradley Wilkerson",
	"Virginia Andrews",
	"Kelly Snyder"
]

const PrintNodeLabel = ({ show, onHide }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [selectedNodeAddress, setSelectedNodeAddress] = useState([UIProps?.ids?.[0]] || [""])

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryNodes,
			setIds: UIContext.setIdsInventoryNodes,
			technology: UIContext.technology
		}
	}, [UIContext])
	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { inventory, listLoading, customers } = useSelector(
		state => ({
			inventory: state.inventory.nodesInventory,
			listLoading: state.inventory.listLoading,
			customers: state.inventory.customers
		}),

		shallowEqual
	)

	// ━━━━━━━━━━━━━  useEffect  ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!UIProps.ids) return
		setSelectedNodeAddress([UIProps?.ids?.[0]])
	}, [UIProps.ids])

	//  ━━━━━━━━━━━━━ Handlers ━━━━━━━━━━━━━ \\
	const handleChangeMultiple = event => {
		const { options } = event.target
		const value = []
		for (let i = 0, l = options.length; i < l; i += 1) {
			if (options[i].selected) {
				value.push(options[i].value)
			}
		}
		setSelectedNodeAddress(value)
	}

	// ━━━━━━━━━ Print options ━━━━━━━━━
	const { componentRef } = useRef()

	const getCustomPageStyle = () => {
		// return `@page { margin: 2rem 2rem !important; }`
		return `@page { size: portrait; }`
	}

	const componentMultipleLabelsRef = useRef()

	const handlePrintMultipleLabels = useReactToPrint({
		content: () => componentMultipleLabelsRef.current,
		pageStyle: () => getCustomPageStyle()
	})

	return (
		<>
			{show && (
				<div
					className="d-flex flex-column"
					style={{
						position: "absolute",
						top: "36%",
						// right: "61%",
						zIndex: "999",
						width: "312px",
						height: "auto",
						borderRadius: "5px",
						background: "#FFF",
						boxShadow: "0px 4px 14px 0px rgba(28, 28, 59, 0.25)"
					}}
				>
					<div className="drawer_title_container d-flex w-100 p-3 align-items-center justify-content-between">
						<div>
							{/* <SVG
							style={{
								fill: "#1C1C3B"
							}}
							src={toAbsoluteUrl("/media/svg/icons/General/cube.svg")}
						/> */}
							<PrintIcon />
							<span
								style={{
									color: "var(--Utilitarian-Neutrals-Dark, #1C1C3B)",
									fontFamily: "Poppins",
									fontSize: "16px",
									fontStyle: "normal",
									fontWeight: "500",
									lineHeight: "160%",
									marginLeft: "5px",
									verticalAlign: "middle"
								}}
							>
								New Node
							</span>
						</div>
						<div>
							<IconButton
								className="livemap_drawer_filter_close_btn"
								size="small"
								onClick={() => onHide()}
								data-testid="close-button"
							>
								<CloseIcon style={{ fontSize: "18px" }} />
							</IconButton>
						</div>
					</div>
					<div className="drawer_title_container d-flex w-100 pl-3 pt-3 align-items-center justify-content-between">
						Node Address
					</div>
					<div className="drawer_title_container d-flex w-100 pb-3 pl-3 pr-3 pt-1 align-items-center justify-content-between">
						<Select
							multiple
							native
							style={{
								width: "100%"
							}}
							className={UIProps.ids.length > 1 ? classes.select : classes.selectSingle}
							onChange={handleChangeMultiple}
							inputProps={{
								id: "select-multiple-native"
							}}
						>
							{UIProps.ids.map(id => (
								<option key={id} value={id}>
									{id}
								</option>
							))}
						</Select>
					</div>
					<section className="pb-2">
						<div className="pl-3 pt-3">
							<div style={{ marginBottom: "10px" }} className={classes.label}>
								Preview
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<div
								className="d-flex flex-column justify-content-center align-items-center"
								style={{
									height: "auto",
									minHeight: "190px",
									width: "142px",
									border: "2px solid #000",
									borderRadius: "20px"
								}}
							>
								<div className="text-center">
									{/* Can be anything instead of `maxWidth` that limits the width. */}
									<div
										style={{
											height: "auto",
											margin: "0 auto",
											maxWidth: 64,
											width: "100%"
										}}
									>
										{selectedNodeAddress?.[0] && (
											<QRCode
												size={256}
												style={{ height: "auto", maxWidth: "100%", width: "100%" }}
												value={selectedNodeAddress?.[0]}
												viewBox={`0 0 256 256`}
											/>
										)}
									</div>
								</div>
								<div className="text-center pt-4 pb-4">
									<span
										style={{
											color: "var(--primary-sky-gray-palette-100, #1B1B1B)",
											textAlign: "center",
											fontFamily: "Poppins",
											fontSize: "9px",
											fontStyle: "normal",
											fontWeight: "800",
											lineHeight: "120%",
											letterSpacing: "-0.18px"
										}}
									>
										Node Address
									</span>
									<h2
										className="mt-1"
										style={{
											color: "var(--primary-sky-gray-palette-100, #1B1B1B)",
											textAlign: "center",
											fontFamily: "Poppins",
											fontSize: "14px",
											fontStyle: "normal",
											fontWeight: "800",
											lineHeight: "120%",
											letterSpacing: "-0.28px"
										}}
									>
										{selectedNodeAddress ? selectedNodeAddress?.[0] : ""}
									</h2>
								</div>
								<div className="text-center">
									<img
										src="/media/logos/crowdkeep.png"
										alt="company logo"
										style={{
											width: "90%",
											height: "100%",
											objectFit: "contain"
										}}
									/>
								</div>
							</div>
						</div>
					</section>
					<div className="drawer_title_container d-flex w-100 p-3 align-items-center justify-content-end">
						<Button
							color="primary"
							variant="outlined"
							text="Cancel"
							onClick={() => {
								onHide()
							}}
						/>
						<Button
							color="primary"
							variant="contained"
							disabled={!UIProps.ids}
							text="Print"
							style={{
								marginLeft: "12px"
							}}
							onClick={() => {
								// console.log("ACTUAL PRINT")
								handlePrintMultipleLabels()
							}}
							endIcon={<PrintIcon />}
						/>
					</div>
					<div
						style={{
							overflow: "hidden",
							height: "0"
						}}
					>
						<LabelMultipleNodes selected={UIProps.ids} ref={componentMultipleLabelsRef} />
					</div>
				</div>
			)}
		</>
	)
}

export default PrintNodeLabel

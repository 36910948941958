import React, { useState, useEffect } from "react"
import {
	//   Tooltip,
	makeStyles,
	TextField,
	IconButton,
	Tabs,
	Tab,
	SvgIcon
} from "@material-ui/core/"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CloseIcon from "@material-ui/icons/Close"
import * as turf from "@turf/turf"
import PathFinder from "geojson-path-finder"
import RoomIcon from "@material-ui/icons/Room"
import { BiTargetLock } from "react-icons/bi"
import { GiElevator } from "react-icons/gi"
import DirectionsOutlinedIcon from "@material-ui/icons/DirectionsOutlined"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Button } from "../../../../../../_partials/Button"
import { profileSlice } from "../../../../../../redux/profile/profileSlice"
import {
	addPathOnDifferentFloorplanWithEndingPoint,
	addPathOnDifferentFloorplanWithStartingPoint,
	addPathOnDifferentFloorplanWithStartingPointAndEndingPoint,
	findPathOnChangeHandler
} from "./DrawerHelpers"

const { actions } = profileSlice

const useStyles = makeStyles(() => ({
	activeTab: {
		width: "30px",
		height: "30px",
		backgroundColor: "#0277DA",
		opacity: "1",
		margin: "0 0.3rem",
		cursor: "pointer",
		borderRadius: "2px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#FFFFFF",
		"&:hover": {
			backgroundColor: "#016fcc"
		}
	},
	notActiveTab: {
		width: "30px",
		height: "30px",
		backgroundColor: "#FFFFFF",
		opacity: "1",
		margin: "0 0.3rem",
		cursor: "pointer",
		borderRadius: "2px",
		border: "1px solid #DBDEE5",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#B8BCCA",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
	},
	textField: {
		// --- Filled --- \\
		"& .MuiFilledInput-root": {
			backgroundColor: "rgba(236, 241, 244, 1)",
			borderColor: "rgba(0,0,0,0)",
			borderRadius: "5px",
			height: "40px"
		},
		// "& .MuiInputBase-input::placeholder": {
		//   color: "#A4A5B5",
		// },
		"& .MuiFilledInput-input": {
			// Text of placeholder alignment
			padding: "0 1rem"
		}
	},
	autocomplete: {
		"& .MuiFormControl-marginNormal": {
			margin: "0"
		},
		// .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"] .MuiAutocomplete-input
		" & .MuiAutocomplete-input": {
			padding: "0 1rem"
		}
	}
}))

const NewDirectionsControl = ({
	zones,
	getDirections,
	pois,
	floorPlanData,
	floorPlan,
	people,
	assets,
	tags,
	setDrawerOptions,
	selectedSite,
	setGetDirections,
	geoJsonRoutes,
	setPathFound,
	setConnectorAccess,
	iconTab,
	setIconTab,
	typeByDefault,
	setTypeByDefault,
	setCopyOfConnectorCoords,
	setPoiToDoorCoords,
	setPoiToDoorEndPointCoords,
	map,
	dispatch,
	userEmail,
	setShowPathOnMap,
	// pathFound,
	endingPoint,
	setEndingPoint,
	startingPoint,
	setStartingPoint,
	geoJsonRoutesUndefinedSnackBar,
	selectedConnector,
	setSelectedConnector,
	copyOfConnectorCoords,
	setCopyOfStartPointCoords,
	setShowPeople,
	setShowAssets,
	setShowPOIs,
	setShowAreaInfoMarkers,
	setShowConnectors
}) => {
	const classes = useStyles()

	// State
	const [hoveredTab, setHoveredTab] = useState(null)

	// useEffect(() => {
	// 	// console.log(startingPoint, "startingPoint  ->>>>>>>>>")
	// 	// console.log(endingPoint, "endingPoint  ->>>>>>>>>")
	// 	console.log(getDirections, "getDirections ->>>>>>>>>")
	// 	// console.log(floorPlan.id, "floorPlan.id  ->>>>>>>>>");
	// }, [startingPoint, endingPoint, floorPlan, getDirections])

	// not in use, yet.
	// const _buddies = buddies
	//   ? buddies
	//       .map((option) => {
	//         return {
	//           group: "Buddies",
	//           name: option.displayName,
	//           ...option,
	//         };
	//       })
	//       .filter((val) => {
	//         return val.email !== userEmail;
	//       })
	//       .sort((a, b) => {
	//         return -b.group.localeCompare(a.group);
	//       })
	//   : [];

	// zones === areas
	const _zones = zones
		? zones
				.map(option => {
					return {
						group: "Areas",
						...option
					}
				})
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	// Read me: This is the getDirections.poi and need this to get the value of the onChange.
	// .poi was the name chosen when started this feature but it's not related to the points of interest
	// const _poi = getDirections.poi ? getDirections.poi : null;
	const destinationFeature = getDirections.feature ? getDirections.feature : null
	// console.log(destinationFeature, "destinationFeature");

	const _pois = pois
		? pois
				.map(option => {
					return {
						group: "Points of Interest",
						...option
					}
				})
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	// not in use, yet.
	// const  _sensors = sensors
	//   ? sensors
	//       .map((option) => {
	//         return {
	//           group: "Points of Interest",
	//           ...option,
	//         };
	//       })
	//       .sort((a, b) => -b.group.localeCompare(a.group))
	//   : [];

	// Tags / People
	const _people = people
		? people
				.map(option => {
					if (!option.personalData?.name) return null
					const tag = tags && tags.find(val => val.uuid === option.uuid)

					return {
						group: "People",
						name: option.personalData?.name,
						gpsData: tag?.gpsData,
						floorPlanId: tag?.floorPlanId,
						siteId: tag?.siteId, // Added the siteId
						...option
					}
				})
				.filter(val => selectedSite && val?.siteId === selectedSite?.id) // Filter only people of this selectedSite
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	// Tags / Assets
	const _assets = assets
		? assets
				.map(option => {
					if (!option.name) return null
					const tag = tags && tags.find(val => val.uuid === option.uuid)
					return {
						group: "Assets",
						name: option.name,
						gpsData: tag?.gpsData,
						floorPlanId: tag?.floorPlanId,
						siteId: tag?.siteId, // Added the siteId
						...option
					}
				})
				.filter(val => selectedSite && val?.siteId === selectedSite?.id) // Filter only people of this selectedSite
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	let tabIcon = <GiElevator fontSize={20} />

	// Existing connectors on this floor
	const existingTabsOnThisFloor = []

	const allTabsOnThisFloor =
		floorPlanData &&
		floorPlanData.forEach(floor => {
			floor.geoJsonRoutes?.features &&
				floor.geoJsonRoutes.features.forEach(f => {
					if (f.properties.linkToFloors?.includes(floorPlan?.id)) {
						if (f.properties.connectorType === "ELEVATOR") {
							tabIcon = <GiElevator fontSize={20} />
						}
						if (f.properties.connectorType === "STAIRS") {
							tabIcon = (
								<SvgIcon style={{ fontSize: "21px" }}>
									<path
										fill="currentColor"
										d="M15,5V9H11V13H7V17H3V20H10V16H14V12H18V8H22V5H15Z"
									/>
								</SvgIcon>
							)
						}
						if (f.properties.connectorType === "ESCALATOR") {
							tabIcon = (
								<SvgIcon style={{ fontSize: "22px" }}>
									<path
										fill="currentColor"
										d="M20,8H18.95L6.95,20H4A2,2 0 0,1 2,18A2,2 0 0,1 4,16H5.29L7,14.29V10A1,1 0 0,1 8,9H9A1,1 0 0,1 10,10V11.29L17.29,4H20A2,2 0 0,1 22,6A2,2 0 0,1 20,8M8.5,5A1.5,1.5 0 0,1 10,6.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 7,6.5A1.5,1.5 0 0,1 8.5,5Z"
									/>
								</SvgIcon>
							)
						}
						if (f.properties.connectorType === "RAMP") {
							tabIcon = (
								<SvgIcon style={{ fontSize: "19px" }}>
									<svg
										version="1.1"
										id="Layer_1"
										xmlns="http://www.w3.org/2000/svg"
										x="0px"
										y="0px"
										viewBox="0 0 512 512"
										style={{ width: "18px" }}
									>
										<g>
											<path
												fill="currentColor"
												// class='st0'
												d="M505.7,267c-155.4,48.6-310.8,97.2-466.2,145.7c-12.8,4-25.6,7.7-38.5,11.5c0,28.9,0,57.8,0,86.7
		c170,0,340,0,510,0c0-81.6,0-163.3,0-245.3C508.9,266.2,507.3,266.6,505.7,267z"
											/>
											<path
												fill="currentColor"
												// class='st0'
												d="M42,376.2c2.7,2.3,4.9,2.9,8.4,1.8c18.6-6,37.3-11.7,55.9-17.6c41.9-13.1,83.8-26.3,125.7-39.2
		c4-1.2,5.3-3.6,5.1-7.2c-0.3-4.4-3.4-4.3-6.8-4.3c-25.6,0.1-51.1,0-76.7,0c-36.8,0-62.7-25.8-62.8-62.6c0-23.7,0-47.5,0-71.2
		c0-1.9,0-3.8,0-6.3c-3.1,0.8-5.4,1.3-7.5,2C41.9,186,16.4,215,5.2,256.9C3,265,2.4,273.5,1,281.8c0,3.6,0,7.3,0,11
		c0.3,1.4,0.7,2.9,0.9,4.3C4.6,328.9,18,355.2,42,376.2z"
											/>
											<path
												fill="currentColor"
												// class='st0'
												d="M147.2,91.1c-17.2,3.4-26.1,14.2-26.2,31.8c-0.1,41.7-0.1,83.3,0,125c0.1,19.6,12.7,31.9,32.2,31.9
		c32.5,0,65.1,0.1,97.6-0.1c4,0,5.8,1.1,7.2,4.9c3.1,8.5,6.6,16.9,10.1,25.4c17.2-5.4,33.9-10.6,50.9-16
		c-10.5-23.6-20.8-46.6-30.9-69.7c-1.5-3.5-3.4-4.5-7-4.5c-31.2,0.1-62.4,0.1-93.6,0.1c-1.9,0-3.9,0-6,0c0-13.3,0-26,0-39.2
		c30,0,59.6,0,89.3,0c0-10.1,0-19.7,0-29.9c-29.8,0-59.5,0-89.7,0c0-9.1,0-17.7,0-26.3c0-18.6-7.6-28.6-25.4-33.4
		c30.6-9.7,43.8-27.4,39.5-53.2C191.5,15.8,172.8,0.5,150,1c-22.7,0.5-40.9,16.8-43.4,38.9C103.7,65.6,117.7,83.5,147.2,91.1z"
											/>
										</g>
									</svg>
								</SvgIcon>
							)
						}
						existingTabsOnThisFloor.push({
							id: f.properties.connectorType + "_TAB",
							name: f.properties.connectorName,
							type: f.properties.connectorType,
							log: "clicked" + " " + f.properties.connectorType,
							icon: tabIcon
						})
					}
				})
		})

	const sortedExistingTabsOnThisFloor =
		existingTabsOnThisFloor && existingTabsOnThisFloor.sort((a, b) => a.id.localeCompare(b.id))
	// console.log(sortedExistingTabsOnThisFloor, "sortedExistingTabsOnThisFloor");

	const options = [
		// ..._buddies,
		..._zones,
		..._pois,
		//  _sensors,
		..._people,
		..._assets
	]

	return (
		<div className="wrapper_new_path_container">
			<div className="d-flex w-100 align-items-center justify-content-between">
				<div className="mt-4">
					<DirectionsOutlinedIcon
						className="drawer_option_title_icon"
						style={{
							filter:
								"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
						}}
					/>
					<span className="drawer_option_title_span">Indoor Navigation</span>
				</div>
				<div>
					<IconButton
						style={{ margin: "0.5rem" }}
						size="small"
						onClick={() => {
							// console.log("pressed close, clicked false");
							// Close path box on drawer
							setDrawerOptions(prevState => ({
								...prevState,
								openPath: false
							}))

							// ↓ Empty start point on directions box
							setGetDirections({
								type: null,
								poi: null,
								clicked: false
							})

							setStartingPoint() // reset
							setEndingPoint() // reset
						}}
					>
						<CloseIcon style={{ fontSize: "18px" }} />
					</IconButton>
				</div>
			</div>

			<div className="path_drawer_tabs_div">
				{sortedExistingTabsOnThisFloor &&
					sortedExistingTabsOnThisFloor.map((tab, i) => {
						const isActiveTab = iconTab === i
						const showOverlay = hoveredTab === i

						return (
							<OverlayTrigger
								key={tab.name}
								placement="bottom"
								overlay={<Tooltip>{tab.type}</Tooltip>}
								show={showOverlay}
							>
								<div
									onMouseEnter={() => setHoveredTab(i)}
									onMouseLeave={() => setHoveredTab(null)}
									onClick={() => {
										setTypeByDefault(tab.type)
										setIconTab(i)
										setHoveredTab(null) // Reset the hoveredTab state when a new tab is clicked
									}}
									className={isActiveTab ? classes.activeTab : classes.notActiveTab}
								>
									{tab?.icon && tab.icon}
								</div>
							</OverlayTrigger>
						)
					})}
				{/* </Tabs> */}
			</div>

			<div className="path_drawer_inputs_container">
				<div className="path_drawer_dot_1" />
				<div className="path_drawer_dot_2" />
				<div className="path_drawer_dot_3" />

				<div className="path_drawer_inputs_points">
					<div className="path_drawer_inputs_icon_ending">
						<BiTargetLock
							fontSize={16}
							style={{
								filter:
									"invert(18%) sepia(4%) saturate(5855%) hue-rotate(170deg) brightness(91%) contrast(81%)"
							}}
						/>
					</div>
					<div className="path_drawer_input_autocomplete">
						<Autocomplete
							id="livemap_drawer_indoor_start_point"
							className={classes.autocomplete}
							// InputProps={{ disableUnderline: true }}
							fullWidth
							size="small"
							disablePortal
							defaultValue={startingPoint}
							// defaultValue={defaultValue}
							options={options}
							groupBy={option => option.group}
							// getOptionLabel={(option) => option.name}
							getOptionLabel={option => (option ? option?.name : "")}
							getOptionSelected={(option, value) => option?.name === value?.name}
							renderInput={params => (
								<TextField
									className={classes.textField}
									variant="filled"
									{...params}
									InputProps={{
										...params.InputProps,
										disableUnderline: true,
										style: {
											// border: "1px solid red",
											padding: "0 1rem",
											fontSize: 12,
											fontFamily: "Poppins",
											fontWeight: "500",
											width: "100%"
										}
									}}
									margin="normal"
									placeholder="Choose starting point"
								/>
							)}
							onChange={(event, newValue) => {
								// console.log(
								// 	newValue,
								// 	"NEW VALUE starting point (seria o antigo ending point, agora passou a starting point mas é onde finalizamos "
								// )

								// ↓ if no routes drawn display message to user
								if (!geoJsonRoutes) {
									floorPlan && geoJsonRoutesUndefinedSnackBar(floorPlan)
									// Close path box on drawer
									setDrawerOptions(prevState => ({
										...prevState,
										openPath: false
									}))
									return
								}

								if (newValue) {
									// ↓ need to set state of startingPoint
									setStartingPoint(newValue)

									findPathOnChangeHandler({
										dispatch: dispatch,
										// map: map,
										// locations: locations,
										floorPlan: floorPlan,
										endPoint: endingPoint, // new ending point coming from get directions btn click
										startPoint: newValue,
										// startPoint: getDirections,
										// devices: devices,
										// bleDevices: bleDevices,
										// setTravelTo: setTravelTo,
										// startPoint: getDirections,
										geoJsonRoutes: geoJsonRoutes,
										setPathFound: setPathFound,
										zones: zones,
										setConnectorAccess: setConnectorAccess,
										floorPlanData: floorPlanData,
										// floorPlanId: floorPlan.id,
										typeByDefault: typeByDefault,
										// setGetDirections: setGetDirections,
										setCopyOfConnectorCoords: setCopyOfConnectorCoords,
										setPoiToDoorCoords: setPoiToDoorCoords,
										setPoiToDoorEndPointCoords: setPoiToDoorEndPointCoords,
										setSelectedConnector,
										setCopyOfStartPointCoords
									})
								} else {
									// console.log("NO NEW VALUE", newValue);
									setStartingPoint(null) // empty starting point input
								}
							}}
						/>
					</div>
				</div>
				<div className="path_drawer_inputs_points">
					<div className="path_drawer_inputs_icon_starting">
						<RoomIcon fontSize="default" style={{ color: "#2C3E50" }} />
					</div>
					<div className="path_drawer_input_autocomplete">
						<Autocomplete
							id="livemap_drawer_indoor_end_point"
							className={classes.autocomplete}
							fullWidth
							size="small"
							disablePortal
							defaultValue={endingPoint}
							options={options}
							groupBy={option => option.group}
							// getOptionLabel={(option) => option.name}
							getOptionLabel={option => (option ? option?.name : "")}
							getOptionSelected={(option, value) => option?.name === value?.name}
							renderInput={params => (
								<TextField
									className={classes.textField}
									variant="filled"
									{...params}
									InputProps={{
										...params.InputProps,
										disableUnderline: true,
										style: {
											// border: "1px solid red",
											padding: "0 1rem",
											fontSize: 12,
											fontFamily: "Poppins",
											fontWeight: "500",
											width: "100%"
										}
									}}
									margin="normal"
									placeholder="Choose destination"
								/>
							)}
							onChange={(event, newValue) => {
								// console.log(
								// 	newValue,
								// 	"NEW VALUE -> ending point este seria o getDirections"
								// )

								// ↓ if no routes drawn display message to user
								if (!geoJsonRoutes) {
									floorPlan && geoJsonRoutesUndefinedSnackBar(floorPlan)
									// Close path box on drawer
									setDrawerOptions(prevState => ({
										...prevState,
										openPath: false
									}))
									return
								}

								if (newValue) {
									// console.log("🚀 🚀 🚀 🚀 🚀 endPoint  newValue:", newValue)
									// ↓ need to set state of endingPoint
									setEndingPoint(newValue)

									findPathOnChangeHandler({
										dispatch: dispatch,
										// map: map,
										// locations: locations,
										floorPlan: floorPlan,
										endPoint: newValue, // new ending point
										startPoint: startingPoint,
										// startPoint: getDirections,
										// devices: devices,
										// bleDevices: bleDevices,
										// setTravelTo: setTravelTo,
										// startPoint: getDirections,
										geoJsonRoutes: geoJsonRoutes,
										setPathFound: setPathFound,
										zones: zones,
										setConnectorAccess: setConnectorAccess,
										floorPlanData: floorPlanData,
										// floorPlanId: floorPlan.id,
										typeByDefault: typeByDefault,
										// setGetDirections: setGetDirections,
										setCopyOfConnectorCoords: setCopyOfConnectorCoords,
										setPoiToDoorCoords: setPoiToDoorCoords,
										setPoiToDoorEndPointCoords: setPoiToDoorEndPointCoords,
										setSelectedConnector,
										setCopyOfStartPointCoords
									})
								} else {
									// console.log("NO NEW VALUE", newValue);
									setEndingPoint(null) // empty ending point input
									// ↓ Resets the ending point
									setGetDirections({
										type: null,
										feature: null,
										clicked: false
									})
								}
							}}
						/>
					</div>
				</div>
			</div>
			<div
				className="d-flex align-items-center justify-content-end"
				style={{ margin: "0 1rem 0.5rem 0" }}
			>
				<Button
					id="livemap_drawer_indoor_show_on_map_btn"
					disabled={!startingPoint || !endingPoint}
					className="drawer_options_filter_button_select_all"
					style={{
						backgroundColor: "#DDE9FD",
						color: !startingPoint || !endingPoint ? "#B8BCCA" : "#3077D3"
					}}
					variant="contained"
					text="Show on map"
					onClick={() => {
						// console.log("🚀 ~ startingPoint dentro do click", startingPoint)
						// console.log("🚀 ~ endingPoint dentro do click", endingPoint)
						// console.log("🚀 ~ floorPlan.id dentro do click id:", floorPlan.id, floorPlan)

						// Ensure that the markers are displayed on the map, for starting point, when the user activates the indoor navigation.
						if (startingPoint && startingPoint.group) {
							switch (startingPoint.group) {
								case "People":
									setShowPeople(true)
									break
								case "Assets":
									setShowAssets(true)
									break
								case "Points of Interest":
									setShowPOIs(true)
									break
								case "Areas":
									setShowAreaInfoMarkers(true)
									break
								default:
									break
							}
						}

						// Ensure that the markers are displayed on the map, for ending point, when the user activates the indoor navigation.
						if (endingPoint && endingPoint.group) {
							switch (endingPoint.group) {
								case "People":
									setShowPeople(true)
									break
								case "Assets":
									setShowAssets(true)
									break
								case "Points of Interest":
									setShowPOIs(true)
									break
								case "Areas":
									setShowAreaInfoMarkers(true)
									break
								default:
									break
							}
						}

						if (startingPoint.id === endingPoint.id) {
							const paragraphToaster = (
								<p style={{ textAlign: "center" }}>
									Starting and destination points cannot be the same!
									<br />
									<span>Please select a different start or end point.</span>
								</p>
							)

							dispatch({
								type: "SNACKBAR_ERROR",
								payload: paragraphToaster
							})

							return
						}

						// ━━━━━━━━━━━━━━━━━━ ↓ if startingPoint and endingPoint is on a different floorplan than the current floorplan, change the floor
						if (
							startingPoint.floorPlanId !== floorPlan.id &&
							endingPoint.floorPlanId !== floorPlan.id
						) {
							const travelToSelectedFloor =
								startingPoint &&
								floorPlanData &&
								floorPlanData.find(
									f =>
										f.id === startingPoint.floorPlanId && f.id === endingPoint.floorPlanId
								)
							// console.log(
							// 	"🚀 ~ SHOW ON MAP CLICK - travelToSelectedFloor",
							// 	travelToSelectedFloor
							// )

							// ↓ if no routes drawn display message to user
							if (
								!travelToSelectedFloor?.geoJsonRoutes ||
								travelToSelectedFloor?.geoJsonRoutes.features.length === 0
							) {
								geoJsonRoutesUndefinedSnackBar(travelToSelectedFloor)
								return
							}

							// Display the connectors on map
							setShowConnectors(true)

							// ↓ Change to floor of travelTo point
							travelToSelectedFloor &&
								dispatch(actions.floorPlanSelected(travelToSelectedFloor))

							addPathOnDifferentFloorplanWithStartingPointAndEndingPoint({
								dispatch: dispatch,
								floorPlan: travelToSelectedFloor,
								endPoint: endingPoint,
								startPoint: startingPoint,
								geoJsonRoutes: travelToSelectedFloor?.geoJsonRoutes,
								setPathFound: setPathFound,
								zones: zones,
								setPoiToDoorCoords: setPoiToDoorCoords,
								setPoiToDoorEndPointCoords: setPoiToDoorEndPointCoords,
								setCopyOfStartPointCoords
							})
						}

						// ━━━━━━━━━━━━━━━━━━ ↓ if startingPoint is on a different floorplan than the current floorplan, change the floor
						if (
							startingPoint.floorPlanId !== floorPlan.id &&
							endingPoint.floorPlanId === floorPlan.id
						) {
							const travelToSelectedFloor =
								startingPoint &&
								floorPlanData &&
								floorPlanData.find(f => f.id === startingPoint.floorPlanId)
							// console.log(
							// 	"🚀 ~ SHOW ON MAP CLICK - travelToSelectedFloor",
							// 	travelToSelectedFloor
							// )

							// ↓ if no routes drawn display message to user
							if (
								!travelToSelectedFloor?.geoJsonRoutes ||
								travelToSelectedFloor?.geoJsonRoutes.features.length === 0
							) {
								geoJsonRoutesUndefinedSnackBar(travelToSelectedFloor)
								return
							}

							// Display the connectors on map
							setShowConnectors(true)

							// ↓ Change to floor of travelTo point
							travelToSelectedFloor &&
								dispatch(actions.floorPlanSelected(travelToSelectedFloor))

							addPathOnDifferentFloorplanWithStartingPoint({
								selectedConnector,
								startingPoint,
								geoJsonRoutes: travelToSelectedFloor?.geoJsonRoutes,
								dispatch,
								setPathFound,
								setCopyOfStartPointCoords,
								floorPlan: travelToSelectedFloor,
								zones,
								setPoiToDoorEndPointCoords,
								setPoiToDoorCoords
							})
						}

						// ━━━━━━━━━━━━━━━━━━ ↓ if endingPoint is on a different floorplan than the current floorplan, change the floor
						if (
							endingPoint.floorPlanId !== floorPlan.id &&
							startingPoint.floorPlanId === floorPlan.id
						) {
							// ↓ if no routes drawn display message to user
							if (
								!floorPlan?.geoJsonRoutes ||
								floorPlan?.geoJsonRoutes.features.length === 0
							) {
								geoJsonRoutesUndefinedSnackBar(floorPlan)
								return
							}

							// Display the connectors on map
							setShowConnectors(true)

							addPathOnDifferentFloorplanWithEndingPoint({
								selectedConnector,
								startingPoint,
								geoJsonRoutes: floorPlan?.geoJsonRoutes,
								dispatch,
								setPathFound,
								setCopyOfStartPointCoords,
								floorPlan: floorPlan,
								zones,
								setPoiToDoorEndPointCoords,
								setPoiToDoorCoords,
								setCopyOfConnectorCoords
							})
						}

						// ↓ Close Path box
						setDrawerOptions(prevState => ({
							openSearch: false,
							openFilter: false,
							openPath: false,
							openOccupancy: false,
							openSatellite: false
						}))
						// ↓ Show Path on map
						setShowPathOnMap(true)

						// ↓ Resets the ending point
						setGetDirections({
							type: null,
							feature: null,
							clicked: false
						})
					}}
				/>
			</div>
		</div>
	)
}

export default NewDirectionsControl

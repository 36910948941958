import React from "react"

export function Body({ children }) {
	return (
		<span
			style={{
				fontFamily: "Poppins",
				fontWeight: "500",
				fontSize: "16px"
			}}
		>
			{children}
		</span>
	)
}

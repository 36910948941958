import React, { useMemo, useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton, makeStyles, ListItemIcon } from "@material-ui/core"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../UIContext"
import "../Inventory.css"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ViewListIcon from "@material-ui/icons/ViewList"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles(() => ({
	nested: {
		paddingLeft: "4rem",
		cursor: "default"
	}
}))

const DeleteNodes = ({ show, onHide }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryNodes,
			setIds: UIContext.setIdsInventoryNodes,
			technology: UIContext.technology,
			rowsToUse: UIContext.rowsToUseNodesTable
		}
	}, [UIContext])
	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { listLoading, user, currentCustomer, customers } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading,
			user: state?.auth?.user,
			currentCustomer: state?.profile?.currentCustomer,
			customers: state?.inventory?.customers
		}),
		shallowEqual
	)

	const [open, setOpen] = useState(false)
	const [foundAssignedNode, setFoundAssignedNode] = useState(false)
	const [matchingNodesData, setMatchingNodesData] = useState([])

	useEffect(() => {
		if (
			!UIProps.rowsToUse ||
			UIProps.rowsToUse.length <= 0 ||
			!UIProps.ids ||
			UIProps.ids.length <= 0
		)
			return

		const matchedData = UIProps.rowsToUse
			.filter(node => UIProps.ids.includes(node.id))
			.map(node => {
				const customer = node.customerId
					? customers && customers.find(customer => customer.id === node.customerId)
					: null
				const site = customer?.sites?.find(site => site.id === node.siteId) || "none"

				return {
					...node,
					customerName: customer?.name || "none",
					siteName: site !== "none" ? site.name : "none"
				}
			})

		setMatchingNodesData(matchedData)

		const existsAssigned = matchedData.filter(
			node => node.status !== "unassigned" && node.status !== "created"
		)

		if (existsAssigned.length > 0) setFoundAssignedNode(existsAssigned.length)
		else setFoundAssignedNode(false)
	}, [UIProps.ids, UIProps.rowsToUse, customers])

	const handleClick = () => {
		setOpen(!open)
	}

	const deleteNodes = () => {
		if (
			!UIProps.ids ||
			UIProps.ids.length <= 0 ||
			!UIProps.technology ||
			!user ||
			!currentCustomer
		) {
			onHide()
			return
		}

		const idsAsIntegers = UIProps.ids.map(id => parseInt(id, 10))
		if (!idsAsIntegers) return

		const buttonToProgressEl = document.getElementById("buttonToProgress_editPoi")
		buttonToProgressEl &&
			startButtonProgress(buttonToProgressEl, "button__progress_editPoi", "button__text_editPoi")
		dispatch(
			actions.deleteInventoryNodes({
				technology: UIProps.technology,
				nodes: idsAsIntegers,
				callersUserId: user.id,
				callersTenantId: user.tenantId || null,
				onHide
			})
		).then(() => {
			onHide()
		})
	}

	const getSecondaryText = node => {
		return `Assign to: ${node.customerName} (${node.siteName})`
	}

	return (
		<Modal show={show} onHide={onHide} data-testid="DeleteNodes" backdrop="static">
			<Modal.Header style={{ padding: "0.5rem 1rem 0 2rem", border: "none" }}>
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div className="col-auto">
						<div className="row">
							<div
								className="d-flex justify-content-center align-items-end"
								style={{
									width: "16px",
									height: "16px",
									position: "relative",
									overflow: "hidden",
									backgroundColor: "#ffffff",
									outline: "2px solid #4A4A68",
									borderRadius: "50%",
									margin: "auto",
									marginRight: "10px"
								}}
							>
								<img
									style={{
										margin: "auto",
										width: "36%",
										height: "auto",
										display: "block"
									}}
									alt="areasInfo"
									src={"/media/crowdkeep/information_filled.svg"}
								/>
							</div>
							<div>
								<span className="custom-modal-title">Delete Nodes</span>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<IconButton onClick={onHide} style={{ left: "12px" }} data-testid="close-button">
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "0",
					overflow: "hidden"
				}}
			>
				{listLoading && <ModalProgressBar variant="query" />}
				<div style={{ padding: "0 2rem 2rem 2rem" }}>
					{/* ━━★  BODY  ★━━ */}
					{!listLoading && UIProps.ids && UIProps.ids.length >= 0 && foundAssignedNode && (
						<div className="mb-4">
							<Alert
								severity="warning"
								style={{
									color: "rgb(102, 60, 0)",
									backgroundColor: "rgb(255, 244, 229)",
									alignItems: "center"
								}}
							>
								Attention:{" "}
								<b>
									{foundAssignedNode}/{UIProps.ids.length}
								</b>{" "}
								of the selected nodes <b>are assigned.</b> <br />
								Please, unassign the nodes before deleting them.
							</Alert>
						</div>
					)}

					<div className="mb-4">
						{!listLoading && UIProps.ids && UIProps.ids.length >= 0 && !foundAssignedNode && (
							<span className="custom-modal-body">
								You are currently deleting{" "}
								<span style={{ fontWeight: "bold" }}>{UIProps.ids.length}</span> nodes.
							</span>
						)}
						{listLoading && <span>Deleting nodes...</span>}
					</div>

					<div className="mb-4">
						{/* com collapse com span com o nome e o uuid */}
						<List aria-labelledby="nested-list-subheader">
							<ListItem button onClick={handleClick}>
								<ListItemIcon>
									<ViewListIcon />
								</ListItemIcon>
								<ListItemText primary="List of nodes to delete" />
								{open ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse
								in={open}
								timeout="auto"
								unmountOnExit
								style={{
									maxHeight: "190px",
									overflowX: "scroll"
								}}
							>
								<List component="div" disablePadding>
									{matchingNodesData &&
										matchingNodesData.length > 0 &&
										matchingNodesData.map(node => (
											<ListItem
												key={node.id}
												button
												className={classes.nested}
												style={{
													background:
														node.status !== "unassigned" && node.status !== "created"
															? "#FBE6DE"
															: "transparent"
												}}
											>
												<ListItemIcon>
													<SVG
														style={{
															fill: "#1C1C3B"
														}}
														src={toAbsoluteUrl("/media/svg/icons/General/cube.svg")}
													/>
												</ListItemIcon>
												<ListItemText
													primary={node.id}
													secondary={getSecondaryText(node)}
												/>
											</ListItem>
										))}
								</List>
							</Collapse>
						</List>
					</div>

					<div className="row justify-content-end mt-5">
						<div className="col-2" style={{ padding: "0", marginRight: "10px" }}>
							<Button
								variant="outlined"
								color="primary"
								style={{ width: "100%" }}
								disabled={listLoading}
								onClick={onHide}
								text="Cancel"
								data-testid="cancel-button"
							/>
						</div>
						<div className="col-2" style={{ padding: "0", marginRight: "10px" }}>
							<Button
								variant="contained"
								disabled={listLoading}
								data-testid="delete-button"
								style={{
									width: "65px",
									marginRight: "10px",
									fontWeight: "700",
									backgroundColor: "#3077d3",
									color: "#f4f4f4"
								}}
								onClick={() => {
									deleteNodes()
								}}
								className="ml-2"
								id="buttonToProgress_editPoi"
								data-progress-text="Deleting..."
								data-complete-text="Delete complete."
							>
								<div className="button__progress_editPoi"></div>
								<span className="button__text_editPoi">Delete</span>
							</Button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default DeleteNodes

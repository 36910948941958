// React bootstrap table next =>
// https://react-bootstrap-table.github.io/react-bootstrap-table2/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
	/*  getSelectRow, */
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses
} from "../../../_metronic/_helpers"
import { Pagination } from "../../../_metronic/_partials/controls"
import { findCustomers } from "../../../firebase"
import { firestore } from "../../../firebase"
import { doc, getDoc, collection } from "firebase/firestore"

export function PlatformUsersTable() {
	//const userClaims = useSelector((state) => state.auth.claims)

	// Customers UI Context
	/*const customersUIContext = useCustomersUIContext();
    const customersUIProps = useMemo(() => {
        return {
            ids: customersUIContext.ids,
            setIds: customersUIContext.setIds,
            queryParams: customersUIContext.queryParams,
            setQueryParams: customersUIContext.setQueryParams,
            openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
            openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
        };
    }, [customersUIContext]);*/

	// Getting curret state of customers list from store (Redux)
	const { currentState } = useSelector(state => ({ currentState: state.customers }), shallowEqual)
	const { totalCount, entities, listLoading, pageToken: nextPageToken } = currentState

	const columns = [
		{
			dataField: "fullName",
			text: "FULL NAME",
			sort: false,
			sortCaret: sortCaret,
			headerSortingClasses
		},
		{
			dataField: "email",
			text: "EMAIL",
			sort: false,
			sortCaret: sortCaret,
			headerSortingClasses
		},
		{
			dataField: "lastSignIn",
			text: "LAST SIGN-IN",
			sort: false,
			sortCaret: sortCaret,
			headerSortingClasses
		},
		{
			dataField: "customer",
			text: "CUSTOMER",
			sort: false,
			sortCaret: sortCaret,
			headerSortingClasses
		},
		{
			dataField: "profile",
			text: "PROFILE",
			sort: false,
			sortCaret: sortCaret,
			headerSortingClasses
		}
	]
	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		//sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: 2,
		page: 1
	}

	const [entities2, setEntities] = React.useState([])

	const { tenantID } = useSelector(
		state => ({ tenantID: state.auth?.claims?.tenantID }),
		shallowEqual
	)

	//TODO: This makes to many read find a better way
	// useEffect(() => {
	// 	async function fetchName(id) {
	// 		var name
	// 		await firestoreOld
	// 			.collection(`Customers`)
	// 			.doc(id)
	// 			.get()
	// 			.then(
	// 				snapshot => {
	// 					name = snapshot.data().name
	// 				},
	// 				error => console.error(error)
	// 			)
	// 		return name
	// 	}
	// 	async function fetchData() {
	// 		var params = {
	// 			tenantID: tenantID,
	// 			pageSize: 10,
	// 			pageToken: 1,
	// 			pageNumber: 1
	// 		}
	// 		var r = await findCustomers(params)
	// 		//console.log("This: ", r.entities)

	// 		var count = r.entities.length
	// 		var i = 0
	// 		var orgData = []
	// 		while (i < count) {
	// 			orgData.push({
	// 				fullName: r.entities[i].displayName,
	// 				email: r.entities[i].email,
	// 				lastSignIn: r.entities[i].metadata.lastSignInTime,
	// 				customer:
	// 					r.entities[i].customClaims &&
	// 					(await fetchName(r.entities[i].customClaims.customerId)),
	// 				//profile: JSON.stringify(r.entities[i].customClaims),
	// 				profile:
	// 					r.entities[i].customClaims && r.entities[i].customClaims.isUser
	// 						? "User"
	// 						: r.entities[i].customClaims && r.entities[i].customClaims.isAdmin
	// 						? "Admin"
	// 						: r.entities[i].customClaims && r.entities[i].customClaims.isSuper
	// 						? "Super Admin"
	// 						: ""
	// 			})
	// 			i++
	// 		}
	// 		setEntities(orgData)
	// 		console.log("Org Data: ", r)
	// 	}
	// 	fetchData()
	// }, [])
	useEffect(() => {
		async function fetchName(id) {
			try {
				const customerRef = doc(firestore, "Customers", id)
				const snapshot = await getDoc(customerRef)
				return snapshot.data()?.name || "Unknown"
			} catch (error) {
				console.error("Error fetching customer name:", error)
				return "Unknown"
			}
		}

		async function fetchData() {
			try {
				const params = {
					tenantID,
					pageSize: 10,
					pageToken: 1,
					pageNumber: 1
				}

				const r = await findCustomers(params)
				const count = r.entities.length
				const orgData = await Promise.all(
					r.entities.map(async entity => {
						const customerName = entity.customClaims
							? await fetchName(entity.customClaims.customerId)
							: "Unknown"

						return {
							fullName: entity.displayName,
							email: entity.email,
							lastSignIn: entity.metadata.lastSignInTime,
							customer: customerName,
							profile: entity.customClaims
								? entity.customClaims.isUser
									? "User"
									: entity.customClaims.isAdmin
									? "Admin"
									: entity.customClaims.isSuper
									? "Super Admin"
									: ""
								: ""
						}
					})
				)

				setEntities(orgData)
				console.log("Org Data:", orgData)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchData()
	}, [])

	return (
		<>
			<PaginationProvider pagination={paginationFactory(paginationOptions)}>
				{({ paginationProps, paginationTableProps }) => {
					return (
						<Pagination
							isLoading={listLoading}
							paginationProps={paginationProps}
							pageToken={null}
						>
							<BootstrapTable
								wrapperClasses="table-responsive"
								bordered={false}
								classes="table table-head-custom table-vertical-center"
								bootstrap4
								remote
								keyField="customerId"
								data={entities2 === null ? [] : entities2}
								columns={columns}
								//defaultSorted={uiHelpers.defaultSorted}
								/*onTableChange={getHandlerTableChange(
                                  customersUIProps.setQueryParams, nextPageToken
                                )}*/
								/*  selectRow={getSelectRow({
                                  entities,
                                  ids: customersUIProps.ids,
                                  setIds: customersUIProps.setIds,
                                })} */
								{...paginationTableProps}
							>
								<PleaseWaitMessage entities={entities} />
								<NoRecordsFoundMessage entities={entities} />
							</BootstrapTable>
						</Pagination>
					)
				}}
			</PaginationProvider>
		</>
	)
}

import { firestore } from "../../../../firebase"

import { collection, doc, getDocs } from "firebase/firestore"

function callGetGroups(params) {
	const { customerId, userId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	// const raw = JSON.stringify({
	// 	data,
	// })

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		// body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customer/${customerId}/groups/get-groups`
	const url = new URL(baseUrl)
	url.searchParams.append("callersUserId", userId)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

function callUpdateGroup(params) {
	const {
		groupId,
		sitesToAdd,
		sitesToRemove,
		membersToAdd,
		membersToRemove,
		callersUserId,
		customerId
	} = params
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		groupId,
		sitesToAdd,
		sitesToRemove,
		membersToAdd,
		membersToRemove,
		callersUserId
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customer/${customerId}/groups/update-group`
	const url = new URL(baseUrl)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

function callCreateGroup(params) {
	const { callersUserId, customerId, sites, members, name } = params
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		sites,
		members,
		name,
		callersUserId
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customer/${customerId}/groups/create-group`
	const url = new URL(baseUrl)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

function callEnableGroup(params) {
	const { groupId, callersUserId, customerId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		groupId,
		callersUserId
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customer/${customerId}/groups/enable-group`
	const url = new URL(baseUrl)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

function callDisableGroup(params) {
	const { groupId, callersUserId, customerId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		groupId,
		callersUserId
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customer/${customerId}/groups/disable-group`
	const url = new URL(baseUrl)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

function callDeleteGroup(params) {
	const { groupId, callersUserId, customerId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		groupId,
		callersUserId
	})

	const requestOptions = {
		method: "DELETE",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customer/${customerId}/groups/delete-group`
	const url = new URL(baseUrl)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function getAllGroups({ userId, customerId }) {
	if (!userId || !customerId) return

	return callGetGroups({
		customerId: customerId,
		userId: userId
	})
}

export function updateGroup({
	groupId,
	sitesToAdd,
	sitesToRemove,
	membersToAdd,
	membersToRemove,
	callersUserId,
	customerId
}) {
	if (
		!groupId ||
		!sitesToAdd ||
		!sitesToRemove ||
		!membersToAdd ||
		!membersToRemove ||
		!callersUserId ||
		!customerId
	)
		return

	return callUpdateGroup({
		groupId,
		sitesToAdd,
		sitesToRemove,
		membersToAdd,
		membersToRemove,
		callersUserId,
		customerId
	})
}

export function deleteGroups({ groupId, callersUserId, customerId }) {
	if (!groupId || !callersUserId || !customerId) return

	return callDeleteGroup({
		groupId,
		callersUserId,
		customerId
	})
}

export function enableGroup({ groupId, callersUserId, customerId }) {
	if (!groupId || !callersUserId || !customerId) return

	return callEnableGroup({
		groupId,
		callersUserId,
		customerId
	})
}

export function disableGroup({ groupId, callersUserId, customerId }) {
	if (!groupId || !callersUserId || !customerId) return

	return callDisableGroup({
		groupId,
		callersUserId,
		customerId
	})
}

// export function getAllPeople({ customerId }) {
// 	if (!customerId) return Promise.resolve(null)

// 	return customersRef.doc(customerId).collection("People").get()
// }
export async function getAllPeople({ customerId }) {
	if (!customerId) return Promise.resolve(null)

	const peopleCollectionRef = collection(firestore, "Customers", customerId, "People")

	const peopleSnapshot = await getDocs(peopleCollectionRef)

	return peopleSnapshot.docs.map(doc => ({
		id: doc.id,
		...doc.data()
	}))
}

export async function createGroup(queryParams) {
	if (
		!queryParams ||
		!queryParams?.name ||
		!queryParams?.members ||
		!queryParams?.sites ||
		!queryParams?.callersUserId
	)
		return Promise.resolve(null)

	return callCreateGroup(queryParams)
}

import React from "react"
import { ProgressBar } from "react-bootstrap"

export function ModalProgressBar({
	variant = "primary",
	style,
	dataTestid = "modal_progress_bar"
}) {
	return (
		<ProgressBar
			variant={variant}
			animated
			now={100}
			style={{
				width: "100%",
				height: "5px",
				...style
			}}
			data-testid={dataTestid}
		/>
	)
}

export function fetchPersonData(docId) {
	console.log("🚀 . docId:", docId)
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/data-validation`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "getPersonData")
	url.searchParams.append("validationCode", docId)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function acceptTermsAndConditions(validationCode) {
	console.log("🚀 . validationCode:", validationCode)
	if (!validationCode) throw Error("Missing validation code")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		operation: "termsAndConditions",
		termsAndConditionsAccepted: true
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/people-onboarding`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "termsAndConditions")
	url.searchParams.append("validationCode", validationCode)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function refuseTermsAndConditions(validationCode) {
	console.log("🚀 . validationCode:", validationCode)
	if (!validationCode) throw Error("Missing validation code")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		operation: "termsAndConditions",
		termsAndConditionsAccepted: false
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/people-onboarding`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "termsAndConditions")
	url.searchParams.append("validationCode", validationCode)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function getVideoUrl(queryParams) {
	console.log("🚀 . queryParams:", queryParams)
	if (!queryParams) throw Error("Missing queryParams")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/people-onboarding`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "getVideoUrl")
	url.searchParams.append("validationCode", queryParams.validationCode)
	url.searchParams.append("customerId", queryParams.customerId)
	url.searchParams.append("videoId", queryParams.videoId)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function getQuestions(queryParams) {
	console.log("🚀 . questions queryParams:", queryParams)
	if (!queryParams) throw Error("Missing queryParams")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/people-onboarding`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "getQuestions")
	url.searchParams.append("validationCode", queryParams.validationCode)
	url.searchParams.append("questionIds", queryParams.questionIds)
	console.log("🚀 . url:", url)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function defineVideoAsWatched(queryParams) {
	console.log("🚀 . queryParams:", queryParams)
	if (!queryParams.validationCode) throw Error("Missing validationCode")
	if (!queryParams.customerId) throw Error("Missing customerId")
	if (!queryParams.personId) throw Error("Missing personId")
	if (!queryParams.videoId) throw Error("Missing videoId")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		customerId: queryParams.customerId,
		personId: queryParams.personId,
		videoId: queryParams.videoId
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/people-onboarding`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "defineVideoAsWatched")
	url.searchParams.append("validationCode", queryParams.validationCode)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function setQuestionAnswers(queryParams) {
	console.log("🚀 . queryParams:", queryParams)
	if (!queryParams.validationCode) throw Error("Missing validationCode")
	if (!queryParams.customerId) throw Error("Missing customerId")
	if (!queryParams.personId) throw Error("Missing personId")
	if (!queryParams.answers) throw Error("Missing answers")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		customerId: queryParams.customerId,
		personId: queryParams.personId,
		questions: queryParams.answers
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/people-onboarding`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "setQuestionAnswers")
	url.searchParams.append("validationCode", queryParams.validationCode)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function validateData(queryParams) {
	console.log("🚀 . queryParams:", queryParams)
	if (!queryParams.validationCode) throw Error("Missing validationCode")
	if (!queryParams.customerId) throw Error("Missing customerId")
	if (!queryParams.personId) throw Error("Missing personId")
	if (!queryParams.name) throw Error("Missing name")
	if (!queryParams.email) throw Error("Missing email")
	if (!queryParams.phoneNumber) throw Error("Missing phoneNumber")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		customerId: queryParams.customerId,
		personId: queryParams.personId,
		name: queryParams.name,
		email: queryParams.email,
		phoneNumber: queryParams.phoneNumber,
		emergencyName: queryParams.emergencyName,
		emergencyNumber: queryParams.emergencyNumber,
		idCard: queryParams.idCard,
		picture: queryParams.picture,
		pictureExtension: queryParams.pictureExtension
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/people-onboarding`
	const url = new URL(baseUrl)
	url.searchParams.append("operation", "validateData")
	url.searchParams.append("validationCode", queryParams.validationCode)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { Button } from "../../../_partials/Button"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"

export function ScrollTop() {
	return (
		<div id="kt_scrolltop" className="scrolltop">
			{/* <span className="svg-icon">
				<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")}></SVG>
			</span>{" "} */}
			<Button
				variant="contained"
				color="primary"
				startIcon={<ArrowUpwardIcon />}
				style={{ minWidth: "36px", maxWidth: "36px" }}
			/>
		</div>
	)
}

import React, { forwardRef, useState, useEffect } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

const CurrentTableToPrint = forwardRef((props, ref) => {
	const { rowsToUse, columnVisibilityModel, currentTablePageInfo, columns } = props

	const [tableData, setTableData] = useState([])
	const [dynamicColumns, setDynamicColumns] = useState([])

	useEffect(() => {
		if (!rowsToUse || !currentTablePageInfo) return

		setTableData(
			rowsToUse.slice(
				currentTablePageInfo?.page * currentTablePageInfo?.pageSize,
				(currentTablePageInfo?.page + 1) * currentTablePageInfo?.pageSize
			)
		) //data from current page and page size
	}, [rowsToUse, currentTablePageInfo])

	useEffect(() => {
		if (!columnVisibilityModel) return

		const realDynamicColumns =
			columns &&
			columns.filter(column => {
				// If columnVisibilityModel exists and has a corresponding entry
				if (
					columnVisibilityModel &&
					Object.prototype.hasOwnProperty.call(columnVisibilityModel, column?.dataField)
				) {
					// Include the column if the value is not false
					return columnVisibilityModel[column?.dataField] !== false
				}
				// Include the column if it doesn't exist in columnVisibilityModel
				return true
			})

		realDynamicColumns && realDynamicColumns.length > 0 && setDynamicColumns(realDynamicColumns)
	}, [columnVisibilityModel])

	return (
		<ToolkitProvider
			search
			wrapperClasses="table-responsive"
			classes="table table-head-custom table-vertical-center"
			bootstrap4
			keyField="id"
			data={tableData && tableData.length > 0 ? tableData : []}
			columns={dynamicColumns && dynamicColumns.length > 0 ? dynamicColumns : columns}
			style={{ overflow: "hidden" }}
		>
			{props => {
				return (
					<div
						ref={ref}
						data-testid="current-page-people-table-to-print"
						style={{ overflow: "hidden" }}
					>
						<div
							className="d-flex justify-content-between align-items-baseline"
							style={{ marginBottom: "2rem" }}
						>
							<div>
								<h3>
									{/* {currentCustomer && currentCustomer?.customerInfo?.name && (
										<span>{currentCustomer?.customerInfo?.name}</span>
									)}
									&nbsp;- {selectedSite?.siteInfo?.name}&nbsp;people */}
								</h3>
							</div>
							<div style={{ width: "10rem", height: "auto", marginRight: "2rem" }}>
								<img
									src="/media/logos/crowdkeep.png"
									alt="company logo"
									style={{ width: "100%", height: "100%", objectFit: "contain" }}
								/>
							</div>
						</div>
						<div style={{ width: "100%", height: "100%" }}>
							<BootstrapTable bordered={false} {...props.baseProps} />
						</div>
					</div>
				)
			}}
		</ToolkitProvider>
	)
})

export default CurrentTableToPrint

import React from "react"
import {
	makeStyles,
	MenuItem,
	FormHelperText,
	FormControl,
	Select as SelectMUI,
	Checkbox,
	ListItemText,
	ListSubheader,
	Chip
} from "@material-ui/core"
import { typeOf } from "react-is"

const useStyles = makeStyles(theme => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	select: {
		height: "45px",
		width: "100%",
		borderRadius: "4px",
		overflow: "hidden",
		"& .MuiSelect-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiSelect-root": {
			//padding: 0,
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		// Removed underline
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none" // Remove the underline
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none" // Remove the underline after selection
		}
		// "& .MuiFilledInput-input": {
		// 	paddingTop: 0,
		// 	paddingBottom: 0
		// }
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	// inputBox: {
	//   display: "flex",
	//   alignItems: "center",
	//   justifyContent: "center",
	// },
	selectWithSize: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	},
	helperText: {
		margin: 0
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	},
	ellipsis: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	}
}))

export function SelectMultiCheckbox({
	label,
	options = [],
	withFeedbackLabel = true,
	form,
	field,
	size,
	customColor,
	variant = "outlined",
	selectAllEnabled = false,
	isGrouped = false,
	...props
}) {
	const classes = useStyles()
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	// Ensure that value is an array
	if (!Array.isArray(value)) {
		value = []
	}

	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250
			}
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left"
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left"
		},
		getContentAnchorEl: null
	}

	const selectedNames = options
		.filter(option => value.indexOf(option.value) !== -1)
		.map(option => option.name)

	// Initialize selectAll state based on initial values
	const initialSelectedValues = field?.value || []
	const allOptionsSelected = options.every(option => initialSelectedValues.includes(option.value))
	const [selectAll, setSelectAll] = React.useState(allOptionsSelected)

	// Function to handle "Select All" toggle
	const handleSelectAllToggle = () => {
		if (selectAll) {
			// Deselect all options
			setFieldValue(name, [])
		} else {
			// Select all options
			setFieldValue(
				name,
				options.map(option => option.value)
			)
		}
		setSelectAll(!selectAll)
	}

	return (
		<>
			{label && (
				<div style={{ marginBottom: "10px" }}>
					<span className={classes.label}>{label}</span>
				</div>
			)}
			<div>
				<FormControl
					className={classes.formControl}
					variant={variant}
					size={size}
					error={error ? true : false}
				>
					<SelectMUI
						name={name}
						id="select-partial"
						disabled={
							isGrouped
								? !options.some(category => category.items.length > 0)
								: !options.length > 0
						}
						className={size ? classes.selectWithSize : classes.select}
						style={{
							backgroundColor: customColor ? customColor : ""
						}}
						value={value}
						multiple // Enable multiple selection
						MenuProps={MenuProps}
						onClose={() => {
							if (!touched && !setFieldTouched) {
								return
							}
							/*!touched[name] so it doesn't set touched true when it's already true*/
							/*Without this errors will always be one update behind the real value*/
							!touched[name] && setFieldTouched(name, true)
						}}
						onChange={e => {
							if (!touched && !setFieldTouched && !setFieldValue) {
								return
							}
							!touched[name] && setFieldTouched(name, true)
							setFieldValue(name, e.target.value)
						}}
						{...props}
						renderValue={() => selectedNames.join(", ")}
					>
						{/* Conditionally render "Select All" based on selectAllEnabled */}
						{selectAllEnabled && (
							// Conditionally render "Select All" based on selectAllEnabled prop
							<MenuItem
								key="select-all"
								onClick={handleSelectAllToggle}
								value={!selectAll ? "all" : "none"}
							>
								<Checkbox checked={selectAll} color="primary" />
								<ListItemText primary="Select All" />
							</MenuItem>
						)}
						{/* <ListSubheader>Category 1</ListSubheader> */}
						{!isGrouped &&
							options.map((val, i) => (
								<MenuItem key={i} value={val.value} disabled={selectAll}>
									<Checkbox checked={value.indexOf(val.value) > -1} color="primary" />
									<ListItemText primary={val.name} />
								</MenuItem>
							))}
						{isGrouped &&
							options.map(category => (
								<div key={category.floorPlanId}>
									<ListSubheader>{category.categoryName}</ListSubheader>
									{category.items.map(item => (
										<MenuItem key={item.value} value={item.value} disabled={selectAll}>
											<Checkbox
												checked={value.indexOf(item.value) > -1}
												color="primary"
											/>
											<ListItemText primary={item.name} />
										</MenuItem>
									))}
								</div>
							))}
					</SelectMUI>
					{error && (
						<FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>
					)}
					{isGrouped
						? !options.some(category => category.items.length > 0) && (
								<FormHelperText>No Areas With Geofencing Found</FormHelperText>
						  )
						: !options.length > 0 && (
								<FormHelperText>No Areas With Geofencing Found</FormHelperText>
						  )}
				</FormControl>
			</div>
		</>
	)
}

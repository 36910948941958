import { all } from "redux-saga/effects"
import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

import * as auth from "../app/modules/Auth/_redux/authRedux"
import { profileSlice } from "../app/redux/profile/profileSlice"
import { userSlice } from "../app/modules/_redux/user/userSlice"
import { uiReducer } from "../app/globalUI/snackBar.duck"
import { basePageSlice } from "../app/redux/basePage/basePageSlice"
import { liveDataSlice } from "../app/modules/_redux/liveData/liveDataSlice"
import { historicalDataSlice } from "../app/modules/_redux/historicalData/historicalDataSlice"
import { mapZonesSlice } from "../app/modules/_redux/mapZones/mapZonesSlice"
// import { assignResourcesSlice } from "../app/modules/_redux/assignResources/assignResourcesSlice"
import { assignResourcesSlice } from "../app/modules/_redux/admin/designStudio/assignResources/assignResourcesSlice"
import { routesSlice } from "../app/modules/_redux/admin/designStudio/RoutesRedux/routesSlice"
// import { bookingSlice } from "../app/modules/_redux/booking/bookingSlice"
import { reportingSlice } from "../app/modules/_redux/reporting/reportingSlice"
import { persistentReportingSlice } from "../app/modules/_redux/reporting/persistentReportingSlice"
import { manageResourcesSlice } from "../app/modules/_redux/manageResources/manageResourcesSlice"
import { DesignStudioSlice } from "../app/modules/_redux/admin/designStudio/DesignStudioSlice"
import { visualDirectorySlice } from "../app/modules/_redux/visual-directory/visualDirectorySlice"
import { manageUsersSlice } from "../app/modules/_redux/manageUsers/manageUsersSlice"
import { manageBLEsSlice } from "../app/modules/_redux/manageBLEs/manageBLEsSlice"
import { manageThingsSlice } from "../app/modules/_redux/manageThings/manageThingsSlice"
import { manageCustomerSlice } from "../app/modules/_redux/manageCustomer/manageCustomerSlice"
import { contactTracingSlice } from "../app/modules/_redux/contactTracing/contactTracingSlice"
import { createCustomerSlice } from "../app/modules/_redux/create-customer/createCustomerSlice"
import { managePeopleSlice } from "../app/modules/_redux/managePeople/managePeopleSlice"
// import { createFloorplansSlice } from "../app/modules/_redux/create-floorplans/createFloorplansSlice"
import { buildingsSlice } from "../app/modules/_redux/manageBuildings/manageBuildingsSlice"
import { notificationsSlice } from "../app/redux/notifications/notificationsSlice"
import { customerOnboardingSlice } from "../app/modules/_redux/admin/customerOnboarding/customerOnboardingSlice"
import { manageSiteSlice } from "../app/modules/_redux/manageSite/manageSiteSlice"
import { manageAssetsSlice } from "../app/modules/_redux/manageAssets/manageAssetsSlice"
import { manageGroupsSlice } from "../app/modules/_redux/manageGroups/manageGroupsSlice"
import { manageSitesSlice } from "../app/modules/_redux/manageSites/manageSitesSlice"
import { peopleOnboardingSlice } from "../app/modules/_redux/peopleOnboarding/peopleOnboardingSlice"
import { dataValidationSlice } from "../app/modules/_redux/data-validation/dataValidationSlice"
import { inventorySlice } from "../app/modules/_redux/admin/inventory/InventorySlice"

const persistedProfileReducer = persistReducer(
	{
		storage,
		key: "userProfile.crowdkeep",
		whitelist: ["currentCustomer", "currentSite", "currentFloorPlan"]
	},
	profileSlice.reducer
)

const persistedReportingReducer = persistReducer(
	{
		storage,
		key: "reporting.crowdkeep",
		whitelist: ["page"]
		// whitelist: ["page", "group"]
	},
	persistentReportingSlice.reducer
)

const persistedNotificationsReducer = persistReducer(
	{
		storage,
		key: "notifications.crowdkeep",
		whitelist: [
			"notificationsGeofencing",
			"notificationSwitch",
			"messagingSwitch",
			"notificationsVolume",
			"messagingVolume"
		]
	},
	notificationsSlice.reducer
)

const persistedLiveDataReducer = persistReducer(
	{
		storage,
		key: "liveData.crowdkeep",
		whitelist: [
			// "drawerPreferences",
			"mapViewPreferences"
		]
	},
	liveDataSlice.reducer
)

export const rootReducer = combineReducers({
	auth: auth.reducer,
	user: userSlice.reducer,
	profile: persistedProfileReducer,
	basePage: basePageSlice.reducer,
	ui: uiReducer,
	liveData: persistedLiveDataReducer,
	historicalData: historicalDataSlice.reducer,
	// booking: bookingSlice.reducer,
	reporting: reportingSlice.reducer,
	persistentReporting: persistedReportingReducer,
	//* ADMIN *\\
	createCustomer: createCustomerSlice.reducer,
	customerOnboarding: customerOnboardingSlice.reducer,
	manageCustomer: manageCustomerSlice.reducer,
	manageSite: manageSiteSlice.reducer,
	manageSites: manageSitesSlice.reducer,
	manageGroups: manageGroupsSlice.reducer,
	manageResources: manageResourcesSlice.reducer,
	manageUsers: manageUsersSlice.reducer,
	manageBLEs: manageBLEsSlice.reducer,
	manageThings: manageThingsSlice.reducer,
	manageAssets: manageAssetsSlice.reducer,
	manageGroups: manageGroupsSlice.reducer,
	managePeople: managePeopleSlice.reducer,
	contactTracing: contactTracingSlice.reducer,
	routes: routesSlice.reducer,
	designStudio: DesignStudioSlice.reducer,
	visualDirectory: visualDirectorySlice.reducer,
	assignResources: assignResourcesSlice.reducer,
	mapZones: mapZonesSlice.reducer,
	// createFloorplans: createFloorplansSlice.reducer,
	buildings: buildingsSlice.reducer,
	notifications: persistedNotificationsReducer,
	peopleOnboarding: peopleOnboardingSlice.reducer,
	dataValidation: dataValidationSlice.reducer,
	inventory: inventorySlice.reducer
})

export function* rootSaga() {
	yield all([auth.saga()])
}

import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const customerOnboardingSlice = createSlice({
	name: "customerOnboarding",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		customerCreated: (state, action) => {
			state.error = null
			state.actionsLoading = false
		}
	}
})

import React from "react"
import CloseIcon from "@material-ui/icons/Close"
import WarningIcon from "@material-ui/icons/Warning"
import VideocamIcon from "@material-ui/icons/Videocam"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../_metronic/_helpers"
import { Button } from "./Button"

//I commented '...props' from props
export function Alert({ severity, AlertTitle, AlertBody, onClose, seeOnMap, ...props }) {
	const mainColor = severity === 3 ? "#E65019" : severity === 2 ? "#F6B952" : "#2673F0"
	const lightColor = severity === 3 ? "#FBE6DE" : severity === 2 ? "#FDF1DC" : "#DDE9FD"

	const styles = {
		alertModal: {
			borderRadius: "4px",
			boxShadow: "0px 4px 14px 0px rgba(28, 28, 59, 0.25)",
			// backgroundColor: "#fdf1dc",
			backgroundColor: lightColor,
			borderLeft: "8px solid",
			borderColor: mainColor,
			width: "309px",
			height: "143px",
			marginBottom: "10px"
			// right: "30px",
			// top: "70px",
			// padding: "13px 0",
			// position: "absolute"
		},
		alertModalTitle: {
			display: "flex",
			width: "271px",
			height: "29px",
			alignItems: "center",
			flexShrink: "0",
			color: mainColor,
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "160%",
			marginTop: "5px"
		},
		alertModalBody: {
			width: "240px",
			height: "27.133px",
			flexShrink: "0",
			color: "#4a4a68",
			fontFamily: "Poppins",
			fontSize: "13px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "170%"
		}
	}

	return (
		<>
			<div className="overlay-modal-no-technology" style={styles.alertModal}>
				<div className="row" style={{ marginLeft: "10px", marginBottom: "10px" }}>
					<div className="col-6">
						<div className="row" style={styles.alertModalTitle}>
							{severity === 2 && (
								<WarningIcon style={{ marginRight: "5px", color: "#F6B952" }} />
							)}
							{severity === 3 && (
								<SVG
									style={{ marginRight: "5px" }}
									src={toAbsoluteUrl(
										"/media/svg/icons/General/critical-notification-modal-filled.svg"
									)}
								/>
							)}
							{AlertTitle}
						</div>
					</div>
					<div
						className="col-5"
						style={{
							textAlignLast: "end",
							alignSelf: "center",
							cursor: "pointer",
							paddingRight: "0"
						}}
					>
						<CloseIcon onClick={onClose} style={{ color: "#B8BCCA" }} />
					</div>
				</div>
				<div className="row" style={{ marginLeft: "10px" }}>
					<div className="col">
						<div className="row" style={styles.alertModalBody}>
							{AlertBody}
						</div>
					</div>
				</div>
				<div
					className="row"
					style={{
						marginLeft: "10px",
						marginBottom: "10px",
						marginTop: "10px",
						marginRight: "15px",
						textAlign: "end"
					}}
				>
					<div className="col" style={{ padding: "0", marginTop: "15px" }}>
						{/* <Button
							color={severity === 3 ? "error" : severity === 2 ? "warning" : "primary"}
							variant="contained"
							startIcon={<VideocamIcon />}
						/> */}
						<Button
							color={severity === 3 ? "error" : severity === 2 ? "warning" : "primary"}
							variant="contained"
							text="SEE ON MAP"
							style={{ marginLeft: "8px" }}
							onClick={seeOnMap}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

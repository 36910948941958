import * as d3 from "d3"

const formatGenerator = d3.format(",.0f")
const getFormat = units => {
	return units ? d => `${formatGenerator(d)}${units}` : formatGenerator
}

const colorGenerator = d3.scaleOrdinal(d3.schemeTableau10)
const color = d => {
	return colorGenerator(d.category === undefined ? d.name : d.name)
}

export { getFormat, color }

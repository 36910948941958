try {
	var localConfig = require("./local-config").default
} catch (err) {}

export default {
	production: localConfig?.production || "$PRODUCTION",
	firebaseConfig: {
		apiKey: localConfig?.apiKey || "$FIREBASE_API_KEY",
		authDomain: localConfig?.authDomain || "$FIREBASE_AUTH_DOMAIN",
		// databaseURL: localConfig?.databaseURL || "$FIREBASE_DATABASE_URL",
		projectId: localConfig?.projectId || "$FIREBASE_PROJECT_ID",
		storageBucket: localConfig?.storageBucket || "$FIREBASE_STORAGE_BUCKET",
		messagingSenderId: localConfig?.messagingSenderId || "$FIREBASE_MESSAGING_SENDER_ID",
		appId: localConfig?.appId || "$FIREBASE_APP_ID",
		measurementId: localConfig?.measurementId || "$FIREBASE_MEASUREMENT_ID"
	}
}

import React from "react"
import { makeStyles, FormHelperText, FormControl } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"

const useStyles = makeStyles(theme => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},

	autocomplete: {
		height: "45px",
		borderRadius: "5px",
		overflow: "hidden",
		"& .MuiAutocomplete-inputRoot": {
			padding: "6px"
		},
		"& .MuiAutcomplete-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiAutcomplete-root": {
			//padding: 0,
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px",
			height: "50px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		// Removed underline
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none" // Remove the underline
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none" // Remove the underline after selection
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiAutcomplete-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	selectWithSize: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	},
	helperText: {
		margin: 0
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	},
	tooltip: {
		fontSize: "20px"
	}
}))

export function Autocomplete_People({
	name,
	label,
	options = [],
	withFeedbackLabel = true,
	form,
	field,
	size,
	customColor = "#F2F2F2",
	variant = "outlined",
	id,
	placeholder,
	requiredField,
	grouped = false,
	...props
}) {
	const classes = useStyles()
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	return (
		<>
			{label && (
				<div style={{ marginBottom: "8px" }}>
					<span className={classes.label}>
						{label}
						{requiredField ? <sup style={{ color: "red" }}> *</sup> : ""}
					</span>
				</div>
			)}
			<div>
				<FormControl
					className={classes.formControl}
					variant={variant}
					size={size}
					error={error ? true : false}
				>
					{!grouped ? (
						<Autocomplete
							name={name}
							id={id}
							style={{
								backgroundColor: customColor ? customColor : ""
							}}
							className={classes.autocomplete}
							options={options.map(val => (val.name ? val.name : val))}
							renderInput={params => (
								<TextField {...params} placeholder={placeholder} variant={variant} />
							)}
							value={value}
							{...props}
						/>
					) : (
						<Autocomplete
							name={name}
							id={id}
							style={{
								backgroundColor: customColor ? customColor : ""
							}}
							className={classes.autocomplete}
							// options={options.map(val => (val.name ? val.name : val))}
							options={options.sort((a, b) => -b.group.localeCompare(a.group))}
							groupBy={option => option.group}
							getOptionLabel={option => option.name}
							renderOption={(option, { selected }) => (
								<Tooltip
									title={option.tooltip}
									// className={classes.tooltip}
									arrow
									placement="right"
								>
									<div style={{ backgroundColor: selected ? "#E6E6E6" : "inherit" }}>
										{option.name}
									</div>
								</Tooltip>
							)}
							renderInput={params => (
								<TextField {...params} placeholder={placeholder} variant={variant} />
							)}
							value={value}
							{...props}
						/>
					)}
					{error && (
						<FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>
					)}
				</FormControl>
			</div>
		</>
	)
}

import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	departments: null,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const visualDirectorySlice = createSlice({
	name: "visualDirectory",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		departmentsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.departments = action.payload
		},
		usersFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.allUsers = action.payload
		}
	}
})

// import { firestore, storage } from "../../../firebase"

// const customersRef = firestore.collection("Customers")

// export function getPeople(queryParams) {
// 	// Add protection against getPeople being null or undefined
// 	if (!queryParams || !queryParams.customerId) return Promise.resolve() // Return early without performing any actions
// 	// Add protection against customerId being empty strings
// 	if (typeof queryParams.customerId !== "string" || queryParams.customerId === "")
// 		return Promise.reject(new Error("customerId must be a non-empty string"))

// 	return firestore
// 		.collection("Customers")
// 		.doc(queryParams.customerId)
// 		.collection("People")
// 		.get()
// }
import { firestore, storage } from "../../../firebase" // Adjust import based on your Firebase setup
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	getDoc,
	query,
	orderBy,
	where
} from "firebase/firestore"

import { ref, getDownloadURL } from "firebase/storage"

export async function getPeople(queryParams) {
	if (!queryParams || !queryParams.customerId) {
		return Promise.resolve([]) // Return an empty array instead of resolving with nothing
	}

	const customerId = queryParams.customerId

	if (typeof customerId !== "string" || customerId.trim() === "") {
		return Promise.reject(new Error("customerId must be a non-empty string"))
	}

	try {
		const peopleCollectionRef = collection(doc(firestore, "Customers", customerId), "People")
		const snapshot = await getDocs(peopleCollectionRef)

		// Map over the documents and return an array of data
		const people = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return people
	} catch (error) {
		console.error("Error fetching people:", error)
		return Promise.reject(error) // Propagate the error
	}
}

// export function getAssets(queryParams) {
// 	if (!queryParams || !queryParams.customerId) return Promise.resolve()

// 	if (typeof queryParams.customerId !== "string" || queryParams.customerId === "")
// 		return Promise.resolve()

// 	return firestore
// 		.collection("Customers")
// 		.doc(queryParams.customerId)
// 		.collection("Assets")
// 		.get()
// }
export async function getAssets(queryParams) {
	if (!queryParams || !queryParams.customerId) {
		return Promise.resolve([]) // Return an empty array instead of resolving with nothing
	}

	const customerId = queryParams.customerId

	if (typeof customerId !== "string" || customerId.trim() === "") {
		return Promise.reject(new Error("customerId must be a non-empty string"))
	}

	try {
		const assetsCollectionRef = collection(doc(firestore, "Customers", customerId), "Assets")
		const snapshot = await getDocs(assetsCollectionRef)

		// Map over the documents and return an array of data
		const assets = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return assets
	} catch (error) {
		console.error("Error fetching assets:", error)
		return Promise.reject(error) // Propagate the error
	}
}

// export async function getGlobalOptions() {
// 	const generalInfo = await firestore.collection("GlobalOptions").doc("GeneralInfo").get()
// 	const map = await firestore.collection("GlobalOptions").doc("Map").get()
// 	const defaultAssetTypes = await firestore
// 		.collection("GlobalOptions")
// 		.doc("DefaultAssetTypes")
// 		.get()

// 	return {
// 		generalInfo: generalInfo?.data() || null,
// 		map: map?.data() || null,
// 		defaultAssetTypes: defaultAssetTypes.data()?.types || []
// 	}
// }
export async function getGlobalOptions() {
	try {
		// Create document references
		const generalInfoRef = doc(firestore, "GlobalOptions", "GeneralInfo")
		const mapRef = doc(firestore, "GlobalOptions", "Map")
		const defaultAssetTypesRef = doc(firestore, "GlobalOptions", "DefaultAssetTypes")

		// Fetch document data
		const [generalInfoSnapshot, mapSnapshot, defaultAssetTypesSnapshot] = await Promise.all([
			getDoc(generalInfoRef),
			getDoc(mapRef),
			getDoc(defaultAssetTypesRef)
		])

		// Extract data from snapshots
		const generalInfo = generalInfoSnapshot ? generalInfoSnapshot.data() : null
		const map = mapSnapshot ? mapSnapshot.data() : null
		const defaultAssetTypesData = defaultAssetTypesSnapshot
			? defaultAssetTypesSnapshot.data()
			: {}
		const defaultAssetTypes = defaultAssetTypesData.types || []

		return {
			generalInfo,
			map,
			defaultAssetTypes
		}
	} catch (error) {
		console.error("Error fetching global options:", error)
		// Handle errors appropriately
		return {
			generalInfo: null,
			map: null,
			defaultAssetTypes: []
		}
	}
}

// export async function getPeopleWithImages(queryParams) {
// 	if (!queryParams) return Promise.resolve(null)

// 	const images = await Promise.allSettled(
// 		queryParams.people.map(person => {
// 			const personPicturePath = person?.personalData?.picture?.replace("gs://", "")

// 			const index = personPicturePath?.indexOf("/")
// 			const pathWithoutBucket = personPicturePath?.slice(index)
// 			// console.log("🚀 . pathWithoutBucket", pathWithoutBucket)

// 			if (!pathWithoutBucket) return Promise.resolve(null)

// 			return storage
// 				.ref(pathWithoutBucket)
// 				.getDownloadURL()
// 				.catch(err => console.log("Error: ", err))
// 		})
// 	)

// 	return queryParams.people.map((person, index) => {
// 		return {
// 			...person,
// 			picture: images[index]?.status === "fulfilled" ? images[index].value : null
// 		}
// 	})
// }
export async function getPeopleWithImages(queryParams) {
	if (!queryParams || !queryParams.people) return Promise.resolve(null)

	const peopleWithImages = await Promise.all(
		queryParams.people.map(async person => {
			const personPicturePath = person?.personalData?.picture?.replace("gs://", "")

			if (!personPicturePath) {
				return {
					...person,
					picture: null
				}
			}

			const index = personPicturePath.indexOf("/")
			const pathWithoutBucket = personPicturePath.slice(index)

			try {
				const imageRef = ref(storage, pathWithoutBucket)
				const downloadURL = await getDownloadURL(imageRef)
				return {
					...person,
					picture: downloadURL
				}
			} catch (err) {
				console.error("Error fetching image URL: ", err)
				return {
					...person,
					picture: null
				}
			}
		})
	)

	return peopleWithImages
}

// export function getFloorPlans(queryParams) {
// 	if (!queryParams) return Promise.resolve()

// 	if (!queryParams.siteId) return Promise.resolve()

// 	return firestore
// 		.collection("Sites")
// 		.doc(queryParams.siteId)
// 		.collection("FloorPlans")
// 		.orderBy("level", "desc")
// 		.get()
// }
export async function getFloorPlans(queryParams) {
	if (!queryParams || !queryParams.siteId) return Promise.resolve()

	try {
		const floorPlansRef = collection(doc(firestore, "Sites", queryParams.siteId), "FloorPlans")
		const q = query(floorPlansRef, orderBy("level", "desc"))
		const querySnapshot = await getDocs(q)

		const floorPlans = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return floorPlans
	} catch (error) {
		console.error("Error fetching floor plans:", error)
		return []
	}
}

export function createFloorplan(queryParams) {
	const { siteId, floorplanData, selectedCustomer, user } = queryParams

	if (!siteId || !floorplanData) {
		return Promise.resolve(null)
	}

	const reqParams = {
		email: queryParams.user.email,
		userId: queryParams.user.id,
		floorplans: [queryParams?.floorplanData]
	}
	// console.log("🚀  reqParams:", reqParams)
	// console.log("🚀  reqParams:", JSON.stringify(reqParams))

	// Call API function
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify(reqParams)

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageFloorplans/${selectedCustomer.id}/${siteId}`,
		requestOptions
	)
}

// export function getAreas(queryParams) {
// 	if (!queryParams || !queryParams.siteId) return Promise.resolve()

// 	// if (typeof queryParams.siteId !== "string" || queryParams.siteId === "") return Promise.resolve()

// 	return firestore.collection("Sites").doc(queryParams.siteId).collection("Areas").get()
// }
export async function getAreas(queryParams) {
	if (!queryParams || !queryParams.siteId) return Promise.resolve([])

	try {
		const areasRef = collection(doc(firestore, "Sites", queryParams.siteId), "Areas")
		const querySnapshot = await getDocs(areasRef)

		const areas = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return areas
	} catch (error) {
		console.error("Error fetching areas:", error)
		return []
	}
}

// export function getDepartments(queryParams) {
// 	if (!queryParams || !queryParams.customerId || !queryParams.userId) return Promise.resolve()

// 	// if (typeof queryParams.customerId !== "string" || queryParams.customerId === "")
// 	// 	return Promise.resolve()

// 	// if (typeof queryParams.userId !== "string" || queryParams.userId === "") return Promise.resolve()

// 	return Promise.all([
// 		customersRef
// 			.doc(queryParams.customerId)
// 			.collection(`Departments`)
// 			.where("manager", "==", queryParams.userId)
// 			.get(),
// 		customersRef
// 			.doc(queryParams.customerId)
// 			.collection(`Departments`)
// 			.where("employees", "array-contains", queryParams.userId)
// 			.get()
// 	])
// }
export async function getDepartments(queryParams) {
	if (!queryParams || !queryParams.customerId || !queryParams.userId) {
		return Promise.resolve({ managedDepartments: [], employeeDepartments: [] })
	}

	try {
		const departmentsRef = collection(
			doc(firestore, "Customers", queryParams.customerId),
			"Departments"
		)

		const managedQuery = query(departmentsRef, where("manager", "==", queryParams.userId))
		const employeeQuery = query(
			departmentsRef,
			where("employees", "array-contains", queryParams.userId)
		)

		const [managedSnapshot, employeeSnapshot] = await Promise.all([
			getDocs(managedQuery),
			getDocs(employeeQuery)
		])

		const managedDepartments = managedSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		const employeeDepartments = employeeSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return {
			managedDepartments,
			employeeDepartments
		}
	} catch (error) {
		console.error("Error fetching departments:", error)
		return { managedDepartments: [], employeeDepartments: [] }
	}
}

// export function getResources(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return customersRef
// 		.doc(queryParams.customer)
// 		.collection(`Resources`)
// 		.where("networkId", "==", queryParams.location)
// 		.get()
// }
export async function getResources(queryParams) {
	if (!queryParams || !queryParams.customer || !queryParams.location) {
		return Promise.resolve(null)
	}

	try {
		const resourcesRef = collection(
			doc(firestore, "Customers", queryParams.customer),
			"Resources"
		)
		const resourcesQuery = query(resourcesRef, where("networkId", "==", queryParams.location))

		const snapshot = await getDocs(resourcesQuery)

		const resources = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return resources
	} catch (error) {
		console.error("Error fetching resources:", error)
		return null
	}
}

// const sitesRef = firestore.collection("Sites")

// export async function deleteFloorPlan({
// 	siteId,
// 	floorplansToDelete,
// 	areasToDelete,
// 	poisToDelete,
// 	user,
// 	selectedCustomer
// }) {
// 	// console.log("🚀  poisToDelete:", poisToDelete)
// 	// console.log("🚀  areasToDelete:", areasToDelete)
// 	// console.log("🚀  floorplansToDelete:", floorplansToDelete)
// 	if (!siteId || !floorplansToDelete || !user || !selectedCustomer) return Promise.resolve()

// 	const reqParams = {
// 		email: user.email,
// 		userId: user.id,
// 		floorplans: floorplansToDelete
// 	}

// 	// Call API function
// 	var myHeaders = new Headers()
// 	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
// 	myHeaders.append("Content-Type", "application/json")
// 	var raw = JSON.stringify(reqParams)

// 	var requestOptions = {
// 		method: "DELETE",
// 		headers: myHeaders,
// 		body: raw,
// 		redirect: "follow"
// 	}

// 	return Promise.all([
// 		areasToDelete &&
// 			areasToDelete.map(area => {
// 				return sitesRef.doc(`${siteId}/Areas/${area.id}`).delete()
// 			}),
// 		poisToDelete &&
// 			poisToDelete.map(poi => {
// 				return sitesRef.doc(`${siteId}/PointsOfInterest/${poi.id}`).delete()
// 			}),
// 		fetch(
// 			`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageFloorplans/${selectedCustomer.id}/${siteId}`,
// 			requestOptions
// 		)
// 	])

// 	// return Promise.all([
// 	// 	areasToDelete &&
// 	// 		areasToDelete.map(area => {
// 	// 			return sitesRef.doc(`${siteId}/Areas/${area.id}`).delete()
// 	// 		}),
// 	// 	poisToDelete &&
// 	// 		poisToDelete.map(poi => {
// 	// 			return sitesRef.doc(`${siteId}/PointsOfInterest/${poi.id}`).delete()
// 	// 		}),
// 	// 	floorplansToDelete &&
// 	// 		floorplansToDelete.map(floor => {
// 	// 			return sitesRef.doc(`${siteId}/FloorPlans/${floor.id}`).delete()
// 	// 		})
// 	// ])
// }

export async function deleteFloorPlan({
	siteId,
	floorplansToDelete = [],
	areasToDelete = [],
	poisToDelete = [],
	user,
	selectedCustomer
}) {
	if (!siteId || !user || !selectedCustomer) {
		console.error("Missing required parameters.")
		return Promise.resolve()
	}

	const reqParams = {
		email: user.email,
		userId: user.id,
		floorplans: floorplansToDelete
	}

	// Prepare headers and request options for API call
	const myHeaders = new Headers({
		"X-API-Key": process.env.REACT_APP_API_GATEWAY_KEY,
		"Content-Type": "application/json"
	})

	const raw = JSON.stringify(reqParams)

	const requestOptions = {
		method: "DELETE",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	try {
		// Delete areas, points of interest, and floorplans in parallel
		const deletePromises = [
			...areasToDelete.map(area =>
				deleteDoc(doc(firestore, `Sites/${siteId}/Areas/${area.id}`))
			),
			...poisToDelete.map(poi =>
				deleteDoc(doc(firestore, `Sites/${siteId}/PointsOfInterest/${poi.id}`))
			),
			fetch(
				`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageFloorplans/${selectedCustomer.id}/${siteId}`,
				requestOptions
			)
		]

		if (floorplansToDelete.length > 0) {
			deletePromises.push(
				...floorplansToDelete.map(floor =>
					deleteDoc(doc(firestore, `Sites/${siteId}/FloorPlans/${floor.id}`))
				)
			)
		}

		// Execute all delete operations and API call
		await Promise.all(deletePromises)
	} catch (error) {
		console.error("Error deleting floor plans or associated data:", error)
	}
}

export async function updateFloorPan(queryParams) {
	const { siteId, floorplanData, floorPlanToEditId, selectedCustomer, user, POISToDeleteArray } =
		queryParams

	if (!siteId || !floorplanData) {
		return Promise.resolve(null)
	}

	const reqParams = {
		email: queryParams.user.email,
		userId: queryParams.user.id,
		floorplans: [queryParams?.floorplanData]
	}

	// Call API function
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify(reqParams)
	// console.log("🚀  raw:", raw)

	var requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageFloorplans/${selectedCustomer.id}/${siteId}`,
		requestOptions
	)
}

// export function deletePointsOfInterestAfterEditFloorplan({
// 	floorPlanId,
// 	siteId,
// 	POISToDeleteArray
// }) {
// 	if (!siteId || !floorPlanId || !POISToDeleteArray) {
// 		return Promise.resolve(null)
// 	}

// 	POISToDeleteArray.map(val => {
// 		sitesRef.doc(`${siteId}/PointsOfInterest/${val.id}`).delete()
// 	})
// }

// export function getPointsOfInterest(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return sitesRef
// 		.doc(queryParams.siteId)
// 		.collection(`PointsOfInterest`)
// 		.orderBy("created.date")
// 		.get()
// }

export async function deletePointsOfInterestAfterEditFloorplan({
	floorPlanId,
	siteId,
	POISToDeleteArray
}) {
	if (!siteId || !floorPlanId || !POISToDeleteArray || !POISToDeleteArray.length) {
		return Promise.resolve(null)
	}

	try {
		const deletePromises = POISToDeleteArray.map(val => {
			const poiDocRef = doc(firestore, `Sites/${siteId}/PointsOfInterest/${val.id}`)
			return deleteDoc(poiDocRef)
		})

		await Promise.all(deletePromises)

		console.log("Points of Interest successfully deleted.")
	} catch (error) {
		console.error("Error deleting Points of Interest:", error)
	}
}

export async function getPointsOfInterest(queryParams) {
	if (!queryParams || !queryParams.siteId) {
		return Promise.resolve(null)
	}

	try {
		const poiCollectionRef = collection(
			doc(firestore, "Sites", queryParams.siteId),
			"PointsOfInterest"
		)
		const poiQuery = query(poiCollectionRef, orderBy("created.date"))

		const snapshot = await getDocs(poiQuery)

		const pointsOfInterest = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return pointsOfInterest
	} catch (error) {
		console.error("Error fetching Points of Interest:", error)
		return null
	}
}

import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import Chart from "react-apexcharts"
import { withStyles, Badge, Popover, makeStyles } from "@material-ui/core"
import { BsInfoCircle } from "react-icons/bs"
import moment from "moment"

export function PeopleDistribution() {
	const { isLoading, peopleDistribution } = useSelector(
		state => ({
			isLoading: state.reporting?.peopleDistributionLoading,
			peopleDistribution: state.reporting?.peopleDistribution
		}),
		shallowEqual
	)

	// States
	const [graphData, setGraphData] = useState()

	useEffect(() => {
		if (!peopleDistribution) {
			setGraphData(null)
		} else {
			setGraphData(peopleDistribution)
		}
	}, [peopleDistribution])

	return (
		<Card isLoading={isLoading} style={{ height: "480px" }}>
			<CardHeader
				title="Total time per Floor"
				subtitle="Time spent in each floor"
				// title={<Title />}
			/>
			<CardBody>
				<Chart
					options={getChartOptions()}
					series={graphData?.series || []}
					type="treemap"
					height="350"
				/>
			</CardBody>
		</Card>
	)
}

function getChartOptions() {
	return {
		chart: {
			offsetY: 0,
			animations: {
				enabled: false
			},
			toolbar: {
				offsetY: -20,
				offsetX: 0,
				tools: {
					download: true,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				},
				export: {
					csv: {
						filename: "TimeSpentInEachArea",
						columnDelimiter: ",",
						headerCategory: "Area",
						headerValue: "Value",
						dateFormatter(timestamp) {
							return new Date(timestamp).toDateString()
						}
					},
					svg: {
						filename: "TimeSpentInEachArea"
					},
					png: {
						filename: "TimeSpentInEachArea"
					}
				}
			}
		},
		legend: {
			show: false
		},
		noData: {
			text: "No data to display."
		},
		colors: ["#0C48AA"],
		// plotOptions: {
		// 	treemap: {
		// 		distributed: true,
		// 		enableShades: false,
		// 		colorScale: {
		// 			ranges: colors
		// 		}
		// 	}
		// },
		dataLabels: {
			enabled: true,
			style: {
				fontSize: "14px"
			}
			// formatter: function (text, op) {
			// 	return [text, op.value]
			// },
			// offsetY: -4
		},
		tooltip: {
			enabled: true,
			followCursor: true,
			inverseOrder: true,
			fixed: {
				enabled: true,
				position: "topRight",
				offsetX: 150,
				offsetY: 0
			},
			y: {
				formatter: val => {
					if (val > 60) {
						const hours = Math.floor(val / 60)
						const minutes = val % 60
						return `${hours}h and ${minutes.toString().padStart(2, "0")}m`
					}

					return `${val}m`
				}
			}
			// y: {
			// 	formatter: val =>
			// 		moment.utc().startOf("day").add(val, "minutes").format("HH:mm") + " min"
			// 	// val + " %",
			// }
		}
	}
}

const colors = [
	{
		from: 0,
		to: 25,
		color: "rgba(63, 57, 165, 0.40)",
		text: "up to 5min"
	},
	{
		from: 26,
		to: 50,
		color: "rgba(63, 57, 165, 0.50)",
		text: "up to 15min"
	},
	{
		from: 51,
		to: 100,
		color: "rgba(63, 57, 165, 0.60)",
		text: "up to 30min"
	},
	{
		from: 101,
		to: 200,
		color: "rgba(63, 57, 165, 0.70)",
		text: "up to 1h"
	},
	{
		from: 199,
		to: 300,
		color: "rgba(63, 57, 165, 0.80)",
		text: "up to 4h"
	},
	{
		from: 301,
		to: 450,
		color: "rgba(63, 57, 165, 0.90)",
		text: "up to 8h"
	},
	{
		from: 451,
		to: 650,
		color: "#3F39A5",
		text: "up to 12h"
	},
	{
		from: 651,
		to: 99999999,
		color: "#383393",
		text: "more than 12h"
	}
]

const useStyles = makeStyles(theme => ({
	popover: {
		pointerEvents: "none"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2)
	}
}))

const StyledBadge = withStyles(theme => ({
	badge: {
		right: -12,
		top: 6,
		fontSize: "12px",
		padding: "0"
	}
}))(Badge)

// Title with tooltip legend
function Title() {
	const classes = useStyles()

	const [anchorEl, setAnchorEl] = useState(null)

	function handlePopoverOpen(event) {
		setAnchorEl(event.currentTarget)
	}

	function handlePopoverClose() {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	return (
		<StyledBadge
			badgeContent={
				<>
					<span
						className="w-100 text-center"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					>
						<BsInfoCircle style={{ fontSize: "1.3rem" }} />
					</span>
					<Popover
						id="mouse-over-popover"
						className={classes.popover}
						classes={{
							paper: classes.paper
						}}
						open={open}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left"
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "left"
						}}
						onClose={handlePopoverClose}
						disableRestoreFocus
					>
						<div style={{ width: "250px", wordWrap: "break-word" }}>
							<div className="pb-3">
								<b>Average Time Spent: </b>
								<br />
								<p>Represents the average time spent by people on a zone.</p>
								<div
									className="ml-3 mb-n5"
									style={{ width: "150px", wordWrap: "break-word" }}
								>
									{colors.map((val, i) => {
										return (
											<div className="row pb-2 pl-2" key={i}>
												<div
													style={{
														height: "10px",
														width: "10px",
														backgroundColor: val.color
													}}
												></div>
												<span className="ml-2 mt-n1">{val.text}</span>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</Popover>
				</>
			}
		>
			Average Time Spent
		</StyledBadge>
	)
}

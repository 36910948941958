import moment from "moment"

//★━━━━━━━━━━━━━━━★ Crowdkeep new helper functions ★━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

// Function helper to get hours and minutes between two timeframes
const getTimeBetweenTimeframesWithH = (start, end) => {
	// create two timeframes
	const time1 = moment(start)
	const time2 = moment(end)

	// get the difference between the two timeframes in minutes
	const diffMinutes = time2.diff(time1, "minutes")

	// calculate the number of hours and minutes
	const diffHours = Math.floor(diffMinutes / 60)
	let diffMinutesRemainder = diffMinutes % 60

	// format minutes to always have two digits
	diffMinutesRemainder = diffMinutesRemainder.toString().padStart(2, "0")

	// log the result
	let time = `${diffHours}:${diffMinutesRemainder} h`

	return time
}

const getTimeBetweenTimeframes = (start, end) => {
	// create two timeframes
	const time1 = moment(start)
	const time2 = moment(end)

	// get the difference between the two timeframes in minutes
	const diffMinutes = time2.diff(time1, "minutes")

	// calculate the number of hours and minutes
	const diffHours = Math.floor(diffMinutes / 60)
	const diffMinutesRemainder = diffMinutes % 60

	// log the result
	// let time = `${diffHours}:${diffMinutesRemainder} h`
	let newTime = `${diffHours}h and ${diffMinutesRemainder.toString().padStart(2, "0")}min`

	return newTime
}

export const handleAllAssetsDistributionResult = ({ result, queryParams }) => {
	if (!result || !queryParams?.assets || queryParams.assets.length === 0) return

	const data = []

	result.forEach((val, i) => {
		if (!val?.checkIn || !val?.checkOut || !val?.dayTime || !val?.docId) return

		const hours = getTimeBetweenTimeframesWithH(val.checkIn, val.checkOut)

		const asset = queryParams?.assets.find(a => a?.id === val.docId)

		const vendor =
			asset && asset?.companyId && queryParams?.vendors.find(v => v.id === asset?.companyId)

		if (!asset || !asset?.uuid || !asset?.assetDetails?.type) return

		data.push({
			// id: asset.id || null,
			id: i,
			day: moment(val.dayTime).utc().format("ddd MMM D"),
			uuid: asset?.uuid || null,
			asset: asset?.assetDetails?.name || "",
			type: asset?.assetDetails?.type || "",
			company: vendor?.company || "",
			start: moment(val.checkIn).utc().format("hh:mm A") || "",
			end: moment(val.checkOut).utc().format("hh:mm A") || "",
			hours: hours || ""
		})
	})

	// Filter the data to display only the matching assetTypes coming from the dataFilter assetTypes dropdown
	const filteredData =
		data &&
		data.filter(
			asset => queryParams?.assetTypes && queryParams.assetTypes.some(r => r.name === asset.type)
		)

	return filteredData
}

export const handleAllPeopleDistributionResult = ({ result, queryParams }) => {
	if (!result || !queryParams?.people || queryParams.people.length === 0) return

	const data = []

	result.forEach((val, i) => {
		if (!val?.checkIn || !val?.checkOut || !val?.dayTime || !val?.docId) return

		const hours = getTimeBetweenTimeframesWithH(val.checkIn, val.checkOut)

		const person = queryParams?.people.find(p => p?.id === val.docId)

		const vendor =
			person && person?.companyId && queryParams?.vendors.find(v => v.id === person?.companyId)

		if (!person?.personalData?.name) return

		data.push({
			id: i,
			day: moment(val.dayTime).utc().format("ddd MMM D"),
			uuid: person?.uuid || null,
			worker: person?.personalData?.name || "",
			role: person?.role || "",
			company: vendor?.company || "",
			email: person?.personalData?.email || "",
			phone: person?.personalData?.phoneNumber || "",
			start: moment(val.checkIn).utc().format("hh:mm A") || "",
			end: moment(val.checkOut).utc().format("hh:mm A") || "",
			hours: hours || ""
		})
	})

	return data
}

// Helper function to parse correct data to timesheet and sum and count the hours and minutes
export const parsingAllPeopleDataHelper = array => {
	if (!array) return

	// Remove the " h" character and white space from timeframe
	const newArray = array.map(a => {
		let str = a.hours
		let newStr = str.replace(" h", "")
		return { ...a, hours: newStr }
	})

	const result = []

	newArray &&
		newArray.forEach((item, i) => {
			const { day, type, hours } = item
			const index = result.findIndex(obj => obj.day === day && obj.type === type)
			if (index === -1) {
				result.push({
					id: i,
					day,
					type,
					counter: 1,
					hours: parseInt(hours.split(":")[0]),
					minutes: parseInt(hours.split(":")[1])
				})
			} else {
				result[index].counter++
				result[index].hours += parseInt(hours.split(":")[0])
				result[index].minutes += parseInt(hours.split(":")[1])
			}
		})

	result.forEach(item => {
		const totalHours = item.hours + Math.floor(item.minutes / 60)
		const totalMinutes = item.minutes % 60

		item.duration = `${totalHours}h and ${totalMinutes.toString().padStart(2, "0")}min`
		// item.duration = `${totalHours}:${totalMinutes.toString().padStart(2, "0")} h`
		delete item.hours
		delete item.minutes
	})

	// console.log(result, "result")

	return result
}

// Parsing all roles by day to column chart
export function handleAttendenceByRole({ result, queryParams }) {
	if (!result) return

	var data = []
	let mondaysTotal = []
	let tuesdaysTotal = []
	let wednesdaysTotal = []
	let thursdaysTotal = []
	let fridaysTotal = []
	let saturdaysTotal = []
	let sundaysTotal = []

	result.forEach(val => {
		if (val && val.type) {
			data.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Mon")) {
			mondaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Tue")) {
			tuesdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Wed")) {
			wednesdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Thu")) {
			thursdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Fri")) {
			fridaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Sat")) {
			saturdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Sun")) {
			sundaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
	})

	let dates =
		data &&
		data.map(item => item.date).filter((item, index, array) => array.indexOf(item) === index)

	const productTotals = data.reduce((obj, curr) => {
		if (!obj[curr.name]) {
			obj[curr.name] = []
		}
		obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][dates.indexOf(curr.date)]
			? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) && parseInt(curr.count)
			: parseInt(curr.count)
		return obj
	}, {})

	const series = Object.entries(productTotals).map(([name, prodArr]) => {
		return {
			name: `${name}s`,
			data: dates.map((month, monthIndex) => {
				if (!prodArr[monthIndex]) {
					return 0
				} else {
					return prodArr[monthIndex]
				}
			})
		}
	})

	// Colors to set to xAxis
	let colors = []
	dates.forEach(val => {
		if (val.includes("Sun")) {
			colors.push("#F9CE1D")
		} else if (val.includes("Sat")) {
			colors.push("#2ec4b6")
		} else {
			colors.push("#626768")
		}
	})

	// Colors to set to bars or columns
	// const barColors = ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"]
	const barColors = ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]

	return {
		labels: dates,
		series: series,
		colors: colors,
		barColors: barColors
	}

	// If we have no data coming from saturdays and sundays because they're not working days and we want to insert those on the cart use this code
	// const weekStart = queryParams.startDate
	// const weekEnd = queryParams.endDate

	// // Create an array of all dates in the week
	// const allDates = []
	// let currentDate = weekStart.clone()
	// while (currentDate.isSameOrBefore(weekEnd)) {
	// 	allDates.push(currentDate.clone())
	// 	currentDate.add(1, "day")
	// }

	// // Map the moment objects back to formatted strings
	// const formattedDates = allDates.map(date => date.format("ddd MMM D"))
	// console.log(formattedDates, "formattedDates")

	// const productTotals = data.reduce((obj, curr) => {
	// 	if (!obj[curr.name]) {
	// 		obj[curr.name] = []
	// 	}
	// 	obj[curr.name][formattedDates.indexOf(curr.date)] = obj[curr.name][
	// 		formattedDates.indexOf(curr.date)
	// 	]
	// 		? obj[curr.name][formattedDates.indexOf(curr.date)] < parseInt(curr.count) &&
	// 		  parseInt(curr.count)
	// 		: parseInt(curr.count)
	// 	return obj
	// }, {})

	// const series = Object.entries(productTotals).map(([name, prodArr]) => {
	// 	return {
	// 		name: `${name}s`,
	// 		data: formattedDates.map((month, monthIndex) => {
	// 			if (!prodArr[monthIndex]) {
	// 				return 0
	// 			} else {
	// 				return prodArr[monthIndex]
	// 			}
	// 		})
	// 	}
	// })

	// // Colors to set to xAxis
	// let colors = []
	// formattedDates.forEach(val => {
	// 	if (val.includes("Sun")) {
	// 		colors.push("#F9CE1D")
	// 	} else if (val.includes("Sat")) {
	// 		colors.push("#2ec4b6")
	// 	} else {
	// 		colors.push("#626768")
	// 	}
	// })

	// // Colors to set to bars or columns
	// const barColors = ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"]

	// return {
	// 	labels: formattedDates,
	// 	series: series,
	// 	colors: colors,
	// 	barColors: barColors
	// }
}

export function handleAttendenceByAssetType({ result, queryParams }) {
	if (!result) return

	var data = []
	let mondaysTotal = []
	let tuesdaysTotal = []
	let wednesdaysTotal = []
	let thursdaysTotal = []
	let fridaysTotal = []
	let saturdaysTotal = []
	let sundaysTotal = []

	result.forEach(val => {
		if (val && val.type) {
			data.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Mon")) {
			mondaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Tue")) {
			tuesdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Wed")) {
			wednesdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Thu")) {
			thursdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Fri")) {
			fridaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Sat")) {
			saturdaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
		if (val.day.includes("Sun")) {
			sundaysTotal.push({
				name: val.type,
				count: val.counter,
				date: val.day
			})
		}
	})

	let dates =
		data &&
		data.map(item => item.date).filter((item, index, array) => array.indexOf(item) === index)

	const productTotals = data.reduce((obj, curr) => {
		if (!obj[curr.name]) {
			obj[curr.name] = []
		}
		obj[curr.name][dates.indexOf(curr.date)] = obj[curr.name][dates.indexOf(curr.date)]
			? obj[curr.name][dates.indexOf(curr.date)] < parseInt(curr.count) && parseInt(curr.count)
			: parseInt(curr.count)
		return obj
	}, {})

	const series = Object.entries(productTotals).map(([name, prodArr]) => {
		return {
			name: `${name}`,
			data: dates.map((month, monthIndex) => {
				if (!prodArr[monthIndex]) {
					return 0
				} else {
					return prodArr[monthIndex]
				}
			})
		}
	})

	// Colors to set to xAxis
	let colors = []
	dates.forEach(val => {
		if (val.includes("Sun")) {
			colors.push("#F9CE1D")
		} else if (val.includes("Sat")) {
			colors.push("#2ec4b6")
		} else {
			colors.push("#626768")
		}
	})

	// Colors to set to bars or columns
	// const barColors = ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"]
	const barColors = ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]

	return {
		labels: dates,
		series: series,
		colors: colors,
		barColors: barColors
	}
}

export const avgTimeSpentReportAreas = ({ result, queryParams }) => {
	if (
		!result ||
		!queryParams.floorPlans ||
		queryParams?.floorPlans.length === 0 ||
		!queryParams.areas ||
		queryParams?.areas.length === 0
	)
		return

	const data = []

	result.forEach(val => {
		if (val?.area && val?.floorplanId && val?.totalMinutesInArea) {
			data.push({ x: val.area, y: val.totalMinutesInArea, floorPlanId: val.floorplanId })
		}
	})

	// Creating a new array with additional information from the reference array
	const dataArrayUpdated =
		data &&
		data.map(item => {
			const ref = queryParams?.floorPlans.find(refItem => {
				return refItem.id === item.floorPlanId
			})

			if (ref) {
				return {
					...item,
					// buildingName: ref?.buildingName,
					floorPlanName: ref?.name,
					floorPlanId: ref?.id
				}
			} else {
				return item
			}
		})

	if (!dataArrayUpdated.every(item => item.floorPlanName && item.floorPlanId)) {
		console.error("Some items in dataArrayUpdated are missing required properties.")
		return
	}

	// sum all the areas with the same name
	const areasWithSameNamesSummed = Object.values(
		dataArrayUpdated.reduce((value, object) => {
			if (value[object.x]) {
				value[object.x].y += object.y
				// value[object.x].count++
			} else {
				value[object.x] = {
					...object
					// , count: 1
				}
			}
			return value
		}, {})
	)

	// Filter the data to display only the matching areas coming from the dataFilter areas dropdown
	let filteredData =
		areasWithSameNamesSummed &&
		areasWithSameNamesSummed.filter(
			area => queryParams.areas && queryParams.areas.some(r => r.name === area.x)
		)

	// // Match the id of the data with the floorplans in firebase
	// let idToObjectMap = {}

	// queryParams &&
	// 	queryParams?.floorPlans.forEach(obj => {
	// 		idToObjectMap[obj.id] = obj
	// 	})

	// filteredData =
	// 	filteredData &&
	// 	filteredData.filter(obj => {
	// 		if (obj.floorPlanId in idToObjectMap) {
	// 			const matchedObject = idToObjectMap[obj.floorPlanId]
	// 			obj.floorPlanName = matchedObject.name
	// 			return true // Keep the object in array a
	// 		}
	// 		return false // Remove the object from array a
	// 	})

	// console.log("🚀  filteredData: 2 ", filteredData)

	return { series: [{ data: filteredData }] }
}

export const locationTimeSpentReportAreas = ({ result, queryParams }) => {
	if (
		!result ||
		!queryParams.floorPlans ||
		queryParams?.floorPlans.length === 0 ||
		!queryParams.areas ||
		queryParams?.areas.length === 0
	)
		return

	const data = []

	result.forEach(val => {
		if (val?.area && val?.floorplanId && val?.totalMinutesInArea) {
			data.push({ x: val.area, y: val.totalMinutesInArea, floorPlanId: val.floorplanId })
		}
	})

	// Creating a new array with additional information from the reference array
	const dataArrayUpdated =
		data &&
		data.map(item => {
			const ref = queryParams?.floorPlans.find(refItem => {
				return refItem.id === item.floorPlanId
			})

			if (ref) {
				return {
					...item,
					buildingName: ref?.buildingName,
					name: ref?.name,
					floorPlanId: ref?.id
				}
			} else {
				return item
			}
		})

	if (!dataArrayUpdated.every(item => item.buildingName && item.name && item.floorPlanId)) {
		console.error("Some items in dataArrayUpdated are missing required properties.")
		return
	}

	// sum all the areas with the same name
	const areasWithSameNamesSummed = Object.values(
		dataArrayUpdated.reduce((value, object) => {
			if (value[object.x]) {
				value[object.x].y += object.y
				// value[object.x].count++
			} else {
				value[object.x] = {
					...object
					// , count: 1
				}
			}
			return value
		}, {})
	)

	// Filter the data to display only the matching areas coming from the dataFilter areas dropdown
	let filteredData =
		areasWithSameNamesSummed &&
		areasWithSameNamesSummed.filter(
			area => queryParams.areas && queryParams.areas.some(r => r.name === area.x)
		)

	// // Match the id of the data with the floorplans in firebase
	// let idToObjectMap = {}

	// queryParams &&
	// 	queryParams?.floorPlans.forEach(obj => {
	// 		idToObjectMap[obj.id] = obj
	// 	})

	// filteredData =
	// 	filteredData &&
	// 	filteredData.filter(obj => {
	// 		if (obj.floorPlanId in idToObjectMap) {
	// 			const matchedObject = idToObjectMap[obj.floorPlanId]
	// 			obj.name = matchedObject.name
	// 			obj.buildingName = matchedObject.buildingName
	// 			return true // Keep the object in array a
	// 		}
	// 		return false // Remove the object from array a
	// 	})

	// console.log("🚀  filteredData:", filteredData)

	const numDataPoints = filteredData && filteredData.length
	const newColors = generateColors(numDataPoints, "areas") // loop the colors array and generate a color for each element of the array

	const finalResult = {
		labels: filteredData.map(f => f.x || ""),
		series: filteredData.map(f => f.y || ""),
		buildingsName: filteredData.map(f => f.buildingName || ""),
		floors: filteredData.map(f => f.name || ""),
		siteName: queryParams.selectedSite.siteInfo.name || "",
		colors:
			newColors && newColors.length > 0
				? newColors
				: [
						"#99BDF8",
						"#adcaf9",
						"#bbd3fa",
						"#c7dbfb",
						"#d4e3fc",
						"#dfeafc",
						"#eaf1fd",
						"#f1f6fe"
				  ]
	}

	return finalResult
}

function generateColors(numColors, type) {
	const colorPalette =
		type === "buildings"
			? ["#5591F3", "#7aa9f5", "#a0c1f8", "#c5dafa", "#dbe7fc", "#e6eefc"]
			: type === "floors"
			? [
					"#412F8A",
					"#3F39A5",
					"#635AFF",
					"#7670FF",
					"#8680FF",
					"#A791FE",
					"#B7B3FF",
					"#D5CEFF",
					"#E7E5FF"
			  ]
			: type === "areas"
			? [
					"#0F2A5E",
					"#0c48aa",
					"#004ECC",
					"#1065ef",
					"#2673F0",
					"#6CA4FF",
					"#99bdf8"
					// "#DDE9FD"
			  ]
			: ["#0C48AA", "#1065EF", "#5591F3", "#7aa9f5", "#a0c1f8", "#c5dafa", "#dbe7fc", "#e6eefc"]

	const colors = []
	for (let i = 0; i < numColors; i++) {
		colors.push(colorPalette[i % colorPalette.length])
	}
	return colors
}

export const parseReportFloors = ({ result, queryParams }) => {
	if (!result || !queryParams.floorPlans || queryParams?.floorPlans.length === 0) return

	const data = []

	result.forEach(val => {
		if (val?.floorPlanId && val?.totalMinutesInFloor) {
			data.push({ x: val.floorPlanId, y: val.totalMinutesInFloor })
		}
	})

	// Creating a new array with additional information from the reference array
	const dataArrayUpdated =
		data &&
		data.map(item => {
			// Find the corresponding reference data based on the 'x' key
			const ref = queryParams?.floorPlans.find(
				refItem => refItem.wirepasFloorPlanId === item.x || refItem.id === item.x
			)

			if (ref) {
				return {
					...item,

					// buildingName: ref?.buildingName,
					// buildingId: ref?.buildingId,
					floorPlanName: ref?.name
				}
			} else {
				return item
			}
		})

	if (!dataArrayUpdated.every(item => item.x && item.floorPlanName)) {
		console.error("Some items in dataArrayUpdated are missing required properties.")
		return
	}

	// sum all the floorplans with the same id
	let floorsWithSameNamesSummed = Object.values(
		dataArrayUpdated.reduce((value, object) => {
			if (value[object.x]) {
				value[object.x].y += object.y
				// value[object.x].count++
			} else {
				value[object.x] = {
					...object
					// , count: 1
				}
			}
			return value
		}, {})
	)

	const finalArray = floorsWithSameNamesSummed.map(d => {
		return {
			...d,
			x: d.floorPlanName
		}
	})

	// // Match the id of the data with the floorplans in firebase
	// let idToNameMap = {}
	// queryParams &&
	// 	queryParams?.floorPlans.forEach(obj => {
	// 		// idToNameMap[obj.id] = obj.name // match with the floorplan.id
	// 		idToNameMap[obj.wirepasFloorPlanId] = obj.name // match with the wirepasFloorPlanId
	// 	})

	// floorsWithSameNamesSummed =
	// 	floorsWithSameNamesSummed &&
	// 	floorsWithSameNamesSummed.filter(obj => {
	// 		// eslint-disable-next-line no-prototype-builtins
	// 		if (idToNameMap.hasOwnProperty(obj.x)) {
	// 			obj.x = idToNameMap[obj.x]
	// 			return true
	// 		}
	// 		return false
	// 	})

	return { series: [{ data: finalArray }] }
}

export const parseLocationReportFloors = ({ result, queryParams }) => {
	if (!result || !queryParams.floorPlans || queryParams?.floorPlans.length === 0) return

	const data = []

	result.forEach(val => {
		if (val?.floorPlanId && val?.totalMinutesInFloor) {
			data.push({ x: val.floorPlanId, y: val.totalMinutesInFloor })
		}
	})

	// Creating a new array with additional information from the reference array
	const dataArrayUpdated =
		data &&
		data.map(item => {
			const ref = queryParams?.floorPlans.find(
				refItem => refItem.wirepasFloorPlanId === item.x || refItem.id === item.x
			)

			if (ref) {
				return {
					...item,
					buildingName: ref.buildingName,
					name: ref.name
				}
			} else {
				return item
			}
		})

	if (!dataArrayUpdated.every(item => item.buildingName && item.name)) {
		console.error("Some items in dataArrayUpdated are missing required properties.")
		return
	}

	// sum all the floorplans with the same id
	let floorsWithSameNamesSummed = Object.values(
		dataArrayUpdated.reduce((value, object) => {
			if (value[object.x]) {
				value[object.x].y += object.y
				// value[object.x].count++
			} else {
				value[object.x] = {
					...object
					// , count: 1
				}
			}
			return value
		}, {})
	)

	// // Match the id of the data with the floorplans id in firebase
	// let idToObjectMap = {}

	// queryParams.floorPlans.forEach(obj => {
	// 	if (obj.wirepasFloorPlanId) {
	// 		idToObjectMap[obj.wirepasFloorPlanId] = obj
	// 	}
	// })

	// floorsWithSameNamesSummed = floorsWithSameNamesSummed.filter(obj => {
	// 	if (obj.x in idToObjectMap) {
	// 		const matchedObject = idToObjectMap[obj.x]
	// 		obj.name = matchedObject.name
	// 		obj.buildingName = matchedObject.buildingName
	// 		return true // Keep the object in array a
	// 	}
	// 	return false // Remove the object from array a
	// })

	const numDataPoints = floorsWithSameNamesSummed && floorsWithSameNamesSummed.length
	const newColors = generateColors(numDataPoints, "floors") // loop the colors array and generate a color for each element of the array

	const finalResult = {
		labels: floorsWithSameNamesSummed.map(f => f?.name || ""),
		series: floorsWithSameNamesSummed.map(f => f?.y),
		buildingsName: floorsWithSameNamesSummed.map(f => f?.buildingName || ""),
		siteName: queryParams.selectedSite?.siteInfo?.name,
		colors:
			newColors && newColors.length > 0
				? newColors
				: [
						"#635AFF",
						"#8680FF",
						"#B7B3FF",
						"#c5c2ff",
						"#d1ceff",
						"#d8d6ff",
						"#e0deff",
						"#e7e6ff",
						"#ecebff",
						"#f2f1ff"
				  ]
	}

	return finalResult
}

export const parseLocationReportBuildings = ({ result, queryParams }) => {
	if (!result || !queryParams.floorPlans || queryParams?.floorPlans.length === 0) return

	const data = []

	result.forEach(val => {
		if (val?.buildingId && val?.totalMinutesInBulding) {
			data.push({ x: val.buildingId, y: val.totalMinutesInBulding })
		}
	})

	// Creating a new array with additional information from the reference array
	const dataArrayUpdated =
		data &&
		data
			.map(item => {
				// Find the corresponding reference data based on the 'x' key
				const ref = queryParams?.floorPlans.find(
					refItem => refItem.wirepasBuildingId === item.x || refItem.buildingId === item.x
				)

				if (ref) {
					return {
						...item,
						buildingName: ref?.buildingName,
						buildingId: ref?.buildingId,
						id: ref?.name
					}
				}
				return null
				// } else {
				// 	return item
				// }
			})
			.filter(item => item !== null)

	if (!dataArrayUpdated.every(item => item.buildingName && item.id && item.buildingId)) {
		console.error("Some items in dataArrayUpdated are missing required properties.")
		return
	}

	// sum all the floorplans with the same id
	let floorsWithSameNamesSummed = Object.values(
		dataArrayUpdated.reduce((value, object) => {
			if (value[object.x]) {
				value[object.x].y += object.y
				// value[object.x].count++
			} else {
				value[object.x] = {
					...object
					// , count: 1
				}
			}
			return value
		}, {})
	)

	// // Match the id of the data with the floorplans id in firebase
	// let idToObjectMap = {}

	// queryParams &&
	// 	queryParams?.floorPlans.forEach(obj => {
	// 		// idToObjectMap[obj.id] = obj // match with the floorplan.id
	// 		idToObjectMap[obj.wirepasFloorPlanId] = obj // match with the wirepasFloorPlanId
	// 	})

	// floorsWithSameNamesSummed =
	// 	floorsWithSameNamesSummed &&
	// 	floorsWithSameNamesSummed.filter(obj => {
	// 		if (obj.x in idToObjectMap) {
	// 			const matchedObject = idToObjectMap[obj.x]
	// 			obj.id = matchedObject.name
	// 			obj.buildingName = matchedObject.buildingName
	// 			obj.buildingId = matchedObject.buildingId
	// 			return true // Keep the object in array a
	// 		}
	// 		return false // Remove the object from array a
	// 	})

	// console.log(floorsWithSameNamesSummed, "floorsWithSameNamesSummed")

	let buildingsWithSameNamesSummed = Object.values(
		floorsWithSameNamesSummed &&
			floorsWithSameNamesSummed.reduce((value, object) => {
				if (value[object.buildingId]) {
					value[object.buildingId].y += object.y
				} else {
					value[object.buildingId] = {
						// ...object,
						buildingName: object.buildingName,
						buildingId: object.buildingId,
						y: object.y
					}
				}
				return value
			}, {})
	)

	const numDataPoints = buildingsWithSameNamesSummed && buildingsWithSameNamesSummed.length
	const newColors = generateColors(numDataPoints, "buildings") // loop the colors array and generate a color for each element of the array

	const finalResult = buildingsWithSameNamesSummed && {
		labels: buildingsWithSameNamesSummed.map(f => f.buildingName || ""),
		series: buildingsWithSameNamesSummed.map(f => f.y),
		floors: [],
		// floors: floorsWithSameNamesSummed.map(f => f.id),
		siteName: queryParams.selectedSite.siteInfo.name,
		colors:
			newColors && newColors.length > 0
				? newColors
				: [
						"#99BDF8",
						"#adcaf9",
						"#bbd3fa",
						"#c7dbfb",
						"#d4e3fc",
						"#dfeafc",
						"#eaf1fd",
						"#f1f6fe"
				  ]
	}

	return finalResult
}

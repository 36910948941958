import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"
import { useSelector, shallowEqual } from "react-redux"
import { ContentRoute } from "../../layout"
import { InventoryPage } from "./inventory/InventoryPage"
import AnchorsPositioningPage from "./map-infrastructure/anchors-positioning/AnchorsPositioningPage"
import { MapInfrastructurePage } from "./map-infrastructure/MapInfrastructurePage"

export default function OperationsPage() {
	const { userClaims, pageOptions } = useSelector(
		state => ({
			userClaims: state.auth.claims,
			pageOptions: state.profile?.currentSite?.options?.pages
		}),
		shallowEqual
	)

	return (
		<div data-testid="operationsPage">
			<Switch>
				<Redirect exact={true} from="/operations" to="/operations/inventory" />

				{/* Inventory Page */}
				{userClaims?.isSuper && (
					<ContentRoute from="/operations/inventory" component={InventoryPage} />
				)}

				{userClaims?.isSuper && (
					<ContentRoute
						from="/operations/manage-infrastructure"
						component={MapInfrastructurePage}
					/>
				)}
			</Switch>
		</div>
	)
}

import React, { useState, useMemo, useEffect, forwardRef } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useUIContext } from "../UIContext"
import { firestore as db } from "../../../../firebase"
import { collection, doc, getDocs } from "firebase/firestore"

import { Card, CardBody } from "../../../_partials/Card"
import { Input } from "../../../_partials/inputs/Input"
import {
	makeStyles,
	Checkbox,
	TextField,
	Typography,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	MenuItem,
	FormControl,
	Select as SelectMui
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import moment from "moment"
import DatePicker, { getDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { MdOutlineCalendarToday } from "react-icons/md"

const useStyles = makeStyles(() => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	indeterminateColor: {
		color: "#f50057"
	},
	selectAllText: {
		fontWeight: 400
	},
	selectedAll: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	select: {
		height: "38px",
		backgroundColor: "#ECF1F4",
		borderRadius: "5px",
		"& .MuiSelect-select:focus": {
			borderRadius: "0",
			backgroundColor: "transparent"
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		},
		"&:hover": {
			backgroundColor: "#ECF1F4",
			border: "1px solid grey",
			borderRadius: "5px"
		},
		"& .MuiSelect-icon": {
			color: "#4A4A68", // arrow icon color
			marginRight: "0.5rem"
		}
	},
	datePicker: {
		// marginTop: "0.9rem",
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		padding: "0 12px",
		height: "38px",
		"&:hover": {
			cursor: "pointer",
			border: "1px solid grey"
		},
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "38px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		}
	},
	checkboxIcon: {
		"& .MuiSvgIcon-root": {
			color: "#3077D3"
		}
	}
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "center"
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "center"
	},
	variant: "menu"
}

export function DataFilter() {
	const classes = useStyles()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			queryParams: UIContext.queryParams,
			setQueryParams: UIContext.setQueryParams
		}
	}, [UIContext])

	//━━━━━━━━━━━━━━━ Selectors ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
	const { selectedSite, page, roles, assetTypes } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			page: state.persistentReporting.page,
			roles: state.profile?.currentCustomer?.roles,
			assetTypes: state.profile?.currentCustomer?.assetTypes || []
		}),
		shallowEqual
	)

	//━━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
	const [didFirstSetOfParams, setDidFirstSetOfParams] = useState(false)
	const [startDate, setStartDate] = useState(moment(UIProps.queryParams?.startDate).toDate())
	const [endDate, setEndDate] = useState(moment(UIProps.queryParams?.endDate).toDate())
	const [updatedAreas, setUpdatedAreas] = useState([]) // We need this updatedAreas state to fetch if a new area is created, and have the Areas array updated.
	const [selectedAreas, setSelectedAreas] = useState([])
	const [selectedRoles, setSelectedRoles] = useState(roles.map(r => r))
	const [selectedAssetTypes, setSelectedAssetTypes] = useState(assetTypes.map(r => r))

	//━━━━━━━━━━━━━━━ useEffects ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\

	// Wait for default values to load and set params
	useEffect(() => {
		if (didFirstSetOfParams || !selectedSite) return

		handleChangeQueryParams({ start: startDate, end: endDate })
		setDidFirstSetOfParams(true)
	}, [selectedSite, page])

	//━━━━━━━━━━━━━━━ Functions ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\

	function onChangeDatePicker(dates) {
		const [start, end] = dates

		if (!dates[1]) {
			setStartDate(moment(start).toDate())
			setEndDate(null)
		} else {
			setStartDate(moment(start).toDate())
			setEndDate(moment(end).toDate())
			handleChangeQueryParams({ start: moment(start), end: moment(end) }) // set new queryParams to fetch data from API with new dates
		}
	}

	function handleChangeQueryParams(props) {
		const { start, end } = props || {}

		const newQueryParams = {
			startDate: start ? start : moment(startDate),
			endDate: end ? end : moment(endDate),
			areas: selectedAreas ? selectedAreas : updatedAreas,
			roles: selectedRoles ? selectedRoles : roles,
			assetTypes: selectedAssetTypes ? selectedAssetTypes : assetTypes
		}

		if (shallowEqual(UIProps.queryParams, newQueryParams)) return

		UIProps.setQueryParams(newQueryParams)
	}

	useEffect(() => {
		let isMounted = true

		if (!selectedSite) return

		const fetchAndUpdateAreas = async () => {
			try {
				const allFetchedAreas = await fetchAllAreas() // Fetch all updated areas

				if (isMounted && allFetchedAreas) {
					// console.log("🚀  allFetchedAreas:", allFetchedAreas)
					updatedAreasFetched(allFetchedAreas)
				}
			} catch (error) {
				// Handle any errors that occur during the promise resolution
				console.error("Error fetching areas:", error)
			}
		}

		fetchAndUpdateAreas()

		return () => {
			isMounted = false
			// Cleanup code
		}
	}, [selectedSite, page])

	const updatedAreasFetched = async allAreas => {
		allAreas && setUpdatedAreas(allAreas) // Set all updated areas to state (if a new area is created we update the Areas array)
		allAreas && setSelectedAreas(allAreas.map(option => option)) // Populate setSelectedAreas state with all areas options to dropdown box

		// Fetch all updated roles and setSelectedRoles state
		await setSelectedRoles(roles.map(r => r))
		UIProps.setQueryParams({
			...UIProps.queryParams,
			areas: allAreas && allAreas,
			roles: selectedRoles ? selectedRoles : roles,
			assetTypes: selectedAssetTypes ? selectedAssetTypes : assetTypes
		})
	}

	// Fetch all updated areas and set to state
	// async function fetchAllAreas() {
	// 	const _areas = await firestoreOld
	// 		.collection("Sites")
	// 		.doc(selectedSite.id)
	// 		.collection(`Areas`)
	// 		.get()

	// 	const allAreas = _areas.docs.map(doc => {
	// 		return { id: doc.id, ...doc.data() }
	// 	})
	const fetchAllAreas = async () => {
		if (!selectedSite) {
			throw new Error("Selected site ID is not available")
		}
		try {
			// Reference to the "Areas" collection within the selected site
			const areasRef = collection(doc(db, "Sites", selectedSite.id), "Areas")

			// Fetch all documents from the "Areas" collection
			const areasSnapshot = await getDocs(areasRef)

			// Extract area data
			const allAreas = areasSnapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))
			// Update states and UI props
			if (setUpdatedAreas) {
				setUpdatedAreas(allAreas)
			}

			if (setSelectedAreas) {
				setSelectedAreas(allAreas)
			}

			if (setSelectedRoles) {
				setSelectedRoles(roles)
			}

			if (UIProps?.setQueryParams) {
				UIProps.setQueryParams({
					...UIProps.queryParams,
					areas: allAreas,
					roles: roles || UIProps.queryParams.roles
				})
			}

			return allAreas
		} catch (error) {
			console.error("Error fetching areas:", error)
			// Optionally handle errors here
			return []
		}
	}
	// 	// console.log("🚀  allAreas:", allAreas)

	// 	// Note: turned the code below into a async function so that the app doesn't have memory leaks warnings
	// 	// allAreas && setUpdatedAreas(allAreas) // Set all updated areas to state (if a new area is created we update the Areas array)
	// 	// allAreas && setSelectedAreas(allAreas.map(option => option)) // Populate setSelectedAreas state with all areas options to dropdown box
	// 	// // allAreas &&
	// 	// // 	UIProps.setQueryParams({
	// 	// // 		...UIProps.queryParams,
	// 	// // 		areas: allAreas
	// 	// // 	})

	// 	// // Fetch all updated roles and setSelectedRoles state
	// 	// await setSelectedRoles(roles.map(r => r))
	// 	// UIProps.setQueryParams({
	// 	// 	...UIProps.queryParams,
	// 	// 	areas: allAreas && allAreas,
	// 	// 	roles: selectedRoles ? selectedRoles : roles
	// 	// })
	// 	return allAreas
	// }

	const isAllSelectedRoles = roles.length > 0 && selectedRoles.length === roles.length

	const isAllSelectedAssetTypes =
		assetTypes.length > 0 && selectedAssetTypes.length === assetTypes.length

	const handleChangeRoles = event => {
		const value = event.target.value

		const foundedSelectAll = value.some(val => val.id === "all")

		if (foundedSelectAll) {
			// console.log("foundedSelectAll roles ->>>>>>>>>>", foundedSelectAll)
			setSelectedRoles(selectedRoles.length === roles.length ? [] : roles.map(option => option))
			return
		}
		setSelectedRoles(value)
	}

	const handleChangeAssetTypes = event => {
		const value = event.target.value

		const foundedSelectAll = value.some(val => val.id === "all")

		if (foundedSelectAll) {
			// console.log("foundedSelectAll assetTypes ->>>>>>>>>>", foundedSelectAll)
			setSelectedAssetTypes(
				selectedAssetTypes.length === assetTypes.length ? [] : assetTypes.map(option => option)
			)
			return
		}
		setSelectedAssetTypes(value)
	}

	// Areas handlers
	const isAllSelectedAreas =
		updatedAreas.length > 0 && selectedAreas.length === updatedAreas.length

	const handleChangeAreas = event => {
		const value = event.target.value

		const foundedSelectAll = value.some(val => val.id === "all")

		if (foundedSelectAll) {
			// console.log("foundedSelectAll ->>>>>>>>>>", foundedSelectAll)
			setSelectedAreas(
				selectedAreas.length === updatedAreas.length ? [] : updatedAreas.map(option => option)
			)
			return
		}

		setSelectedAreas(value)
	}

	const endDateCondition = endDate && moment(endDate).isAfter(moment()) ? new Date() : null

	const rolesOrTypes =
		page === "peopleTimesheet" || page === "peopleSummary" ? (
			<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-5">
				<div style={{ marginBottom: "10px" }}>
					<b>Roles</b>
				</div>
				<FormControl className={classes.formControl}>
					<SelectMui
						id="reporting_data_filter_roles_dropdown"
						data-testid="data_filter_select_roles_dropdown"
						classes={{
							filled: classes.filled,
							icon: classes.checkboxIcon
						}}
						disableUnderline
						className={classes.select}
						labelId="mutiple-select-label"
						multiple
						value={selectedRoles}
						onChange={handleChangeRoles}
						renderValue={() => {
							if (isAllSelectedRoles) {
								return "Select All"
							} else {
								const selectedRolesNames = selectedRoles.map(role => role.name)
								return selectedRolesNames.join(", ")
							}
						}}
						MenuProps={MenuProps}
						onClose={() => {
							// console.log("onClose ROLES!!!!")
							handleChangeQueryParams()
						}}
					>
						<MenuItem
							id="reporting_roles_dropdown_select_all_value"
							value={{
								id: "all",
								name: "Select All"
							}}
							classes={{
								root: isAllSelectedRoles ? classes.selectedAll : ""
							}}
						>
							<ListItemIcon>
								<Checkbox
									className={classes.checkboxIcon}
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedRoles}
									indeterminate={
										selectedRoles.length > 0 && selectedRoles.length < roles.length
									}
								/>
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.selectAllText }}
								primary="Select All"
							/>
						</MenuItem>
						{roles &&
							roles.map((option, i) => {
								return (
									<MenuItem
										key={i}
										value={option}
										id={"reporting_roles_dropdown_" + option.name + "_" + i}
									>
										<ListItemIcon>
											<Checkbox
												className={classes.checkboxIcon}
												checked={selectedRoles.indexOf(option) > -1}
											/>
										</ListItemIcon>
										<ListItemText
											classes={{ primary: classes.selectAllText }}
											primary={option.name}
										/>
									</MenuItem>
								)
							})}
					</SelectMui>
				</FormControl>
			</div>
		) : (
			<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-5">
				<div style={{ marginBottom: "10px" }}>
					<b>Asset Types</b>
				</div>
				<FormControl className={classes.formControl}>
					<SelectMui
						id="reporting_data_filter_asset_types_dropdown"
						// data-testid="data_filter_select_roles_dropdown"
						classes={{
							filled: classes.filled,
							icon: classes.checkboxIcon
						}}
						disableUnderline
						className={classes.select}
						labelId="mutiple-select-label"
						multiple
						value={selectedAssetTypes}
						onChange={handleChangeAssetTypes}
						renderValue={() => {
							if (isAllSelectedAssetTypes) {
								return "Select All"
							} else {
								const selectedAssetTypesNames = selectedAssetTypes.map(asset => asset.name)
								return selectedAssetTypesNames.join(", ")
							}
						}}
						MenuProps={MenuProps}
						onClose={() => {
							// console.log("onClose ROLES!!!!")
							handleChangeQueryParams()
						}}
					>
						<MenuItem
							id="reporting_roles_dropdown_select_all_value"
							value={{
								id: "all",
								name: "Select All"
							}}
							classes={{
								root: isAllSelectedAssetTypes ? classes.selectedAll : ""
							}}
						>
							<ListItemIcon>
								<Checkbox
									className={classes.checkboxIcon}
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedAssetTypes}
									indeterminate={
										selectedAssetTypes.length > 0 &&
										selectedAssetTypes.length < assetTypes.length
									}
								/>
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.selectAllText }}
								primary="Select All"
							/>
						</MenuItem>
						{assetTypes &&
							assetTypes.map((option, i) => {
								return (
									<MenuItem
										key={i}
										value={option}
										id={"reporting_asset_types_dropdown_" + option.name + "_" + i}
									>
										<ListItemIcon>
											<Checkbox
												className={classes.checkboxIcon}
												checked={selectedAssetTypes.indexOf(option) > -1}
											/>
										</ListItemIcon>
										<ListItemText
											classes={{ primary: classes.selectAllText }}
											primary={option.name}
										/>
									</MenuItem>
								)
							})}
					</SelectMui>
				</FormControl>
			</div>
		)

	return (
		<Card>
			<CardBody>
				<div
					className="row justify-content-center"
					data-testid="dataFilter_main_div"
					id="reporting_data_filter_main_div"
				>
					{/* Roles or Asset Types dropdown */}
					{rolesOrTypes}
					{/* Areas dropdown */}
					<div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-5">
						<div style={{ marginBottom: "10px" }}>
							<b>Areas</b>
						</div>
						<FormControl className={classes.formControl}>
							<SelectMui
								id="reporting_data_filter_areas_dropdown"
								data-testid="data_filter_select_areas_dropdown"
								classes={{
									filled: classes.filled,
									icon: classes.checkboxIcon
								}}
								disableUnderline
								className={classes.select}
								labelId="mutiple-select-label"
								multiple
								value={selectedAreas}
								onChange={handleChangeAreas}
								renderValue={() => {
									if (isAllSelectedAreas) {
										return "Select All"
									} else {
										const selectedAreaNames = selectedAreas.map(area => area.name)
										return selectedAreaNames.join(", ")
									}
								}}
								MenuProps={MenuProps}
								onClose={() => {
									// console.log("onClose AREAS!!!!")
									handleChangeQueryParams()
								}}
							>
								<MenuItem
									id="reporting_areas_dropdown_select_all_value"
									// value="all"
									value={{
										id: "all",
										name: "Select All"
									}}
									classes={{
										root: isAllSelectedAreas ? classes.selectedAll : ""
									}}
								>
									<ListItemIcon>
										<Checkbox
											className={classes.checkboxIcon}
											classes={{ indeterminate: classes.indeterminateColor }}
											checked={isAllSelectedAreas}
											indeterminate={
												selectedAreas.length > 0 &&
												selectedAreas.length < updatedAreas.length
											}
										/>
									</ListItemIcon>
									<ListItemText
										classes={{ primary: classes.selectAllText }}
										primary="Select All"
									/>
								</MenuItem>
								{updatedAreas.map((option, i) => {
									return (
										<MenuItem
											key={option.id}
											// value={option.name}
											value={option}
											id={"reporting_areas_dropdown_" + option.name + "_" + i}
										>
											<ListItemIcon>
												<Checkbox
													className={classes.checkboxIcon}
													// checked={selectedAreas.indexOf(option.name) > -1}
													checked={selectedAreas.indexOf(option) > -1}
												/>
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.selectAllText }}
												primary={option.name}
											/>
										</MenuItem>
									)
								})}
							</SelectMui>
						</FormControl>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-4">
						<div style={{ marginBottom: "10px" }}>
							<b>Date</b>
						</div>
						<DatePicker
							className="custom-datepicker"
							calendarClassName="date_picker_range_reporting"
							selected={startDate}
							onChange={onChangeDatePicker}
							customInput={<ExampleCustomInput />}
							startDate={startDate}
							endDate={endDate}
							dateFormat="P"
							selectsRange
							minDate={
								endDate
									? moment(endDate).subtract(2, "months").toDate()
									: moment(startDate).subtract(2, "months").toDate()
							} // Only allow searching for 31 days before the endDate so the API requests don't get huge
							// maxDate={new Date()}
							maxDate={
								moment(startDate).add(2, "months").endOf("day").isAfter(moment(), "day")
									? moment().toDate()
									: moment(startDate).add(2, "months").endOf("day").toDate()
							}
							todayButton="Reset to today"
							// withPortal
							// portalId="custom-portal"
							wrapperClassName="custom-datepicker-wrapper"
						/>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

// eslint-disable-next-line react/display-name
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
	const classes = useStyles()

	return (
		<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
			<TextField
				className={classes.datePicker}
				fullWidth
				value={value}
				style={{ backgroundColor: "#ECF1F4" }}
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<MdOutlineCalendarToday style={{ fontSize: "1.4rem", color: "#8C8CA1" }} />
						</InputAdornment>
					)
				}}
			/>
		</div>
	)
})

export function CustomAutoComplete(props) {
	const { options, value, setValue, label, name, onClose } = props
	/* if (label === "Zones") {
	console.log("label: ", label)
	console.log("options: ", options)
	console.log("value: ", value)
  } */

	return (
		<Autocomplete
			id={`${label}-autocomplete`}
			data-cy={`${label}-autocomplete`}
			multiple
			disableClearable
			disableListWrap
			disableCloseOnSelect
			options={options}
			getOptionLabel={option => (name ? option[name] : option)}
			value={(value && value) || []}
			onChange={(event, value) => {
				if (value.length === 0) return
				setValue(value)
			}}
			onClose={() => onClose && onClose()}
			renderInput={params => {
				// console.log(params, "p");
				return <Input {...params} label={label} style={{ backgroundColor: "#ECF1F4" }} />
			}}
			renderOption={(option, { selected }) => (
				<React.Fragment>
					<Checkbox
						id="checkboxCustom"
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={<CheckBoxIcon fontSize="small" />}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{name ? option[name] : option}
				</React.Fragment>
			)}
			renderTags={selected => {
				let renderTagsValue = selected.map(elem => (name ? elem?.[name] : elem)).join(", ")
				return (
					<Typography style={{ maxWidth: "80%" }} noWrap={true} color="textPrimary">
						{renderTagsValue}
					</Typography>
				)
			}}
		/>
	)
}

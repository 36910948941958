import React, { useState, useEffect } from "react"
import { Carousel } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"

const BigImageModal = ({ show, handleClose, anchor, selectedImageIndexPosition }) => {
	// const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

	// // Create an Image element to retrieve the actual image dimensions
	// const imgElement = new Image()
	// imgElement.src = imageSrc

	// useEffect(() => {
	// 	// When the image is loaded, set its dimensions
	// 	imgElement.onload = () => {
	// 		setImageSize({ width: imgElement.width, height: imgElement.height })
	// 	}
	// }, [imageSrc])

	// useEffect(() => {
	// 	if (imageSrc) {
	// 		const el = document.getElementsByClassName("big_image_modal_anchors")

	// 		if (el && el[0]) {
	// 			el.forEach(val => {
	// 				val.style.backgroundColor = "transparent"
	// 				val.style.display = "flex"
	// 				val.style.justifyContent = "center"
	// 				val.style.alignItems = "center"
	// 			})
	// 		}
	// 		const el2 = document.getElementsByClassName("big_image_modal_anchors_content")

	// 		if (el2 && el2[0]) {
	// 			el2.forEach(val => {
	// 				val.style.backgroundColor = "transparent"
	// 				val.style.width = imageSize.width < 800 ? imageSize.width + "px" : "500px"
	// 				val.style.height = imageSize.height < 800 ? imageSize.height + "px" : "400px"
	// 			})
	// 		}
	// 	}
	// }, [imageSrc, imgElement, imageSize])

	return (
		<Modal
			show={show}
			onHide={handleClose}
			dialogClassName="big_image_modal_anchors"
			contentClassName="big_image_modal_anchors_content"
		>
			<Modal.Body style={{ padding: 0 }}>
				<Carousel
					indicators={false}
					controls={
						anchor?.imagesUrls &&
						anchor?.imagesUrls?.length > 0 &&
						anchor?.imagesUrls?.length > 1
					}
					interval={1500}
					defaultActiveIndex={selectedImageIndexPosition}
				>
					{anchor &&
						anchor?.imagesUrls?.length > 0 &&
						anchor?.imagesUrls.map((image, index) => (
							<Carousel.Item key={index} interval={1000}>
								<img
									src={image}
									alt="Large Image"
									style={{
										// border: "1px solid red",
										width: "100%",
										height: "43rem",
										maxHeight: "43rem", // You can adjust this value
										objectFit: "cover"
									}}
								/>
							</Carousel.Item>
						))}
				</Carousel>
				<div className="d-flex justify-content-between align-items-center popup_title pt-5 pb-5">
					<span>UUID: {anchor && anchor?.uuid}</span>
				</div>
			</Modal.Body>
			{/* <Modal.Body
				style={{
					padding: "0",
					width: imageSize.width < 800 ? imageSize.width + "px" : "500px",
					height: imageSize.height < 800 ? imageSize.height + "px" : "400px"
				}}
			>
				<div
					style={{
						width: imageSize.width < 800 ? imageSize.width + "px" : "500px",
						height: imageSize.height < 800 ? imageSize.height + "px" : "400px"
					}}
				>
					<img
						src={imageSrc}
						alt="Selected Image"
						style={{ width: "100%", height: "100%", objectFit: "cover" }}
					/>
				</div>
			</Modal.Body> */}
		</Modal>
	)
}

export default BigImageModal

import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual, useDispatch, connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { FormControl, MenuItem, Select } from "@material-ui/core"
import { profileSlice } from "../../../../app/redux/profile/profileSlice"
import { firestore as db, storage, initializeFirestore } from "../../../../firebase"
import { doc, getDoc, collection, getDocs, onSnapshot } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage"
import BusinessIcon from "@material-ui/icons/Business"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import PersonIcon from "@material-ui/icons/Person"
import * as authRedux from "../../../modules/Auth/_redux/authRedux"
import { injectIntl } from "react-intl"

const { actions } = profileSlice

function HeaderSelections(props) {
	const dispatch = useDispatch()
	const location = useLocation()

	const { userClaims, floorPlans, currentCustomer, currentSite, currentFloorPlan } = useSelector(
		state => ({
			userClaims: state.auth.claims,
			floorPlans: state.basePage.floorPlans,
			currentCustomer: state.profile.currentCustomer,
			currentSite: state.profile.currentSite,
			currentFloorPlan: state.profile.currentFloorPlan
		}),
		shallowEqual
	)

	const [customers, setCustomers] = useState()
	const [selectedCustomer, setSelectedCustomer] = useState(currentCustomer?.id || null)
	const [selectedSite, setSelectedSite] = useState(currentSite?.id || null)
	const [selectedFloorPlan, setSelectedFloorPlan] = useState(currentFloorPlan?.id || null)
	const [isReporting, setIsReporting] = useState(false)

	// const useDocument = path => {
	// 	const [data, setData] = useState(null)

	// 	useEffect(() => {
	// 		if (!path) return

	// 		const unsubscribe = firestoreOld.doc(path).onSnapshot(snapshot => {
	// 			if (snapshot.exists) {
	// 				setData({ id: snapshot.id, ...snapshot.data() })
	// 			} else {
	// 				setData(null)
	// 			}
	// 		})

	// 		return () => unsubscribe()
	// 	}, [path])

	// 	return { data }
	// }
	// const useDocument = path => {
	// 	const [data, setData] = useState(null)

	// 	useEffect(() => {
	// 		if (!path) return

	// 		const documentRef = doc(db, path)

	// 		const unsubscribe = onSnapshot(documentRef, snapshot => {
	// 			if (snapshot.exists()) {
	// 				setData({ id: snapshot.id, ...snapshot.data() })
	// 			} else {
	// 				setData(null)
	// 			}
	// 		})

	// 		return () => unsubscribe()
	// 	}, [path])

	// 	return { data }
	// }

	// const { data: customer } = useDocument(selectedCustomer ? `Customers/${selectedCustomer}` : null)

	const useDocument = path => {
		const [data, setData] = useState(null)
		const [loading, setLoading] = useState(true)

		useEffect(() => {
			if (!path) return

			const documentRef = doc(db, path)
			setLoading(true)

			const unsubscribe = onSnapshot(
				documentRef,
				snapshot => {
					if (snapshot) {
						setData({ id: snapshot.id, ...snapshot.data() })
					} else {
						setData(null)
					}
					setLoading(false)
				},
				error => {
					console.error("Error fetching document:", error)
					setData(null)
					setLoading(false)
				}
			)

			return () => unsubscribe()
		}, [path])

		return { data, loading }
	}

	const { data: customer, loading } = useDocument(
		selectedCustomer ? `Customers/${selectedCustomer}` : null
	)

	useEffect(() => {
		if (!customer) {
			dispatch(actions.customerSelected(null))
			return
		}

		const newCustomer = {
			...customer,
			sites: customer?.sites?.filter(val => {
				if (val.disabled) return
				if (userClaims.isSuper) return true
				if (userClaims.sites && !userClaims.sites.includes(val.id)) return
				return true
			})
		}

		dispatch(actions.customerSelected(newCustomer))
	}, [customer])

	// If there is only one site, auto select that one
	useEffect(() => {
		if (
			selectedSite ||
			!currentCustomer?.sites ||
			currentCustomer?.sites.length > 1 ||
			currentCustomer?.sites.length === 0
		)
			return

		setSelectedSite(currentCustomer?.sites[0].id)
	}, [currentCustomer, selectedSite])

	// If there is no floorplan selected, auto select one
	useEffect(() => {
		if (!floorPlans || floorPlans.length === 0) return

		if (selectedFloorPlan && floorPlans.some(val => val.id === selectedFloorPlan)) return

		const selectedId = floorPlans[0]?.id

		if (selectedId !== null) {
			setSelectedFloorPlan(selectedId)
			return
		}

		// console.log("Maybe Did this?")
		setSelectedFloorPlan(null)
	}, [floorPlans, selectedFloorPlan])

	// useEffect(() => {
	// 	if (!selectedSite) {
	// 		dispatch(actions.siteSelected(null))
	// 		return
	// 	}

	// 	firestoreOld
	// 		.collection("Sites")
	// 		.doc(selectedSite)
	// 		.get()
	// 		.then(doc => {
	// 			if (!doc.exists) {
	// 				dispatch(actions.siteSelected(null))
	// 				return
	// 			}

	// 			dispatch(actions.siteSelected({ id: doc?.id, ...doc.data() }))
	// 		})
	// 		.catch(error => {
	// 			console.error("Error fetching data from Firestore:", error)
	// 			dispatch(actions.siteSelected(null))
	// 		})
	// }, [selectedSite])
	useEffect(() => {
		const fetchSiteData = async () => {
			if (!selectedSite) {
				dispatch(actions.siteSelected(null))
				return
			}

			try {
				const siteDoc = doc(db, "Sites", selectedSite)
				const docSnap = await getDoc(siteDoc)

				// if (!docSnap.exists()) {
				if (!docSnap) {
					// console.log("here")
					dispatch(actions.siteSelected(null))
					return
				}

				dispatch(actions.siteSelected({ id: docSnap.id, ...docSnap.data() }))
			} catch (error) {
				console.error("Error fetching data from Firestore:", error)
				dispatch(actions.siteSelected(null))
			}
		}

		fetchSiteData()
	}, [selectedSite])

	// useEffect(() => {
	// 	if (!currentSite || !selectedFloorPlan) {
	// 		dispatch(actions.floorPlanSelected())
	// 		return
	// 	}

	// 	firestoreOld
	// 		.collection("Sites")
	// 		.doc(currentSite?.id)
	// 		.collection("FloorPlans")
	// 		.doc(selectedFloorPlan)
	// 		.get()
	// 		.then(async doc => {
	// 			if (!doc.exists) return
	// 			const floor = doc.data()
	// 			if (floor.imagePath) {
	// 				const url = await storage
	// 					.ref(floor.imagePath)
	// 					.getDownloadURL()
	// 					.catch(error => {
	// 						// Handle error
	// 						console.error(error)
	// 					})
	// 				dispatch(
	// 					actions.floorPlanSelected({
	// 						id: doc.id,
	// 						...floor,
	// 						imageUrl: url
	// 					})
	// 				)
	// 				return
	// 			}
	// 			dispatch(actions.floorPlanSelected({ id: doc.id, ...floor }))
	// 		})
	// }, [currentSite, selectedFloorPlan])
	useEffect(() => {
		const fetchFloorPlanData = async () => {
			if (!currentSite || !selectedFloorPlan) {
				dispatch(actions.floorPlanSelected())
				return
			}

			try {
				const floorPlanDoc = doc(
					collection(db, "Sites", currentSite.id, "FloorPlans"),
					selectedFloorPlan
				)
				const docSnap = await getDoc(floorPlanDoc)

				if (!docSnap) return

				const floor = docSnap.data()
				let imageUrl = ""

				if (floor.imagePath) {
					try {
						imageUrl = await getDownloadURL(ref(storage, floor.imagePath))
					} catch (error) {
						console.error("Error fetching image URL:", error)
					}
				}

				dispatch(
					actions.floorPlanSelected({
						id: docSnap.id,
						...floor,
						imageUrl
					})
				)
			} catch (error) {
				console.error("Error fetching floor plan data:", error)
				dispatch(actions.floorPlanSelected())
			}
		}

		fetchFloorPlanData()
	}, [currentSite, selectedFloorPlan])

	// useEffect(() => {
	// 	if (userClaims.profileLvl < 3) {
	// 		setSelectedCustomer(userClaims.customerId)
	// 		return
	// 	}
	// 	db.collection("Customers")
	// 		.get()
	// 		.then(snapshot => {
	// 			const data = snapshot.docs.map(doc => {
	// 				return { ...doc.data(), id: doc.id }
	// 			})
	// 			setCustomers(data)
	// 		})
	// }, [userClaims, currentCustomer]) // Put currentCustomer has a dependency because if we edit the name of each customer it changes the name in the header selection dropdown when name is edited. If now re-renders this isn't a problem.

	const globalTenantID = global.globalTenantID
	async function getCustomers(db) {
		try {
			const customersCollection = collection(db, "Customers")
			const snapshot = await getDocs(customersCollection)
			const data = snapshot.docs.map(doc => ({
				...doc.data(),
				id: doc.id
			}))

			return data
		} catch (error) {
			console.error("Error validating Firestore database:", error)
			return []
		}
	}

	useEffect(() => {
		// console.log("useEffect triggered")
		const fetchCustomers = async () => {
			try {
				// console.log("db:", db)
				const data = await getCustomers(db)
				// console.log("Fetched customer data:", data)

				if (userClaims.profileLvl < 3) {
					// console.log("Filtering data for userClaims:", userClaims)
					const filteredData = data.filter(customer => customer.id === userClaims.customerId)
					setCustomers(filteredData)
					setSelectedCustomer(userClaims.customerId)
				} else {
					setCustomers(data)
				}
			} catch (error) {
				console.error("Error fetching customers:", error)
			}
		}

		fetchCustomers()
	}, [currentCustomer, userClaims, db])

	useEffect(() => {
		setIsReporting(location.pathname.includes("reporting"))
	}, [location.pathname])

	const onChangeFloorPlanHandler = floorId => {
		const foundedFloorPlan = floorPlans && floorPlans.find(f => f.id === floorId)

		foundedFloorPlan && dispatch(actions.floorPlanSelected(foundedFloorPlan)) // changes floorplan
	}

	return (
		<div className="header-selections">
			{/*\★━━━━━━━━━━━★ Select customer ★━━━━━━━━━━━★\*/}
			{customers && userClaims.profileLvl >= 3 && (
				<div style={{ marginLeft: "10px" }}>
					<BusinessIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							id="select_customer_header_dropdown"
							displayEmpty
							disableUnderline
							value={currentCustomer?.id || ""}
							onChange={e => {
								setSelectedCustomer(e.target.value)
								// Make current customer null so that auto site selection does not select from old customer
								dispatch(actions.customerSelected(null))
								setSelectedSite(null)
								setSelectedFloorPlan()
							}}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 48 * 3 + 8,
										width: 250
									}
								},
								getContentAnchorEl: null,
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left"
								}
							}}
						>
							<MenuItem value="" disabled>
								Select customer
							</MenuItem>
							{customers.map(val => {
								return (
									<MenuItem key={val.id} value={val.id}>
										{val.customerInfo?.name}
									</MenuItem>
								)
							})}
						</Select>
					</FormControl>
				</div>
			)}

			{userClaims.profileLvl >= 1 && (
				<div style={{ marginLeft: "10px" }}>
					{/*\★━━━━━━━━━━━★ Select location ★━━━━━━━━━━━★\*/}
					<LocationOnIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							id="select_site_header_dropdown"
							displayEmpty
							disableUnderline
							value={currentSite?.id || ""}
							onChange={e => {
								// console.log("🚀🚀 ~ HeaderSelections ~ e:", e)
								if (e.target.value && e.target.value === currentSite?.id) return

								if (e.target.value === "") {
									// console.log(
									// 	"🚀🚀🚀 ~ HeaderSelections ~ e.target.value:",
									// 	e.target.value
									// )
									dispatch(actions.siteSelected(null)) // set selectSite to null when clicking on Select Location option
								}

								// console.log("🚀🚀🚀🚀 ~ HeaderSelections ~ e.target.value:", e.target.value)
								setSelectedSite(e.target.value)
								// setSelectedFloorPlan()
							}}
						>
							<MenuItem value="" disabled={currentCustomer?.sites?.length < 2}>
								Select location
							</MenuItem>
							{currentCustomer?.sites &&
								currentCustomer.sites.map(val => {
									return (
										<MenuItem key={val.id} value={val.id}>
											{val.name}
										</MenuItem>
									)
								})}
						</Select>
					</FormControl>
				</div>
			)}

			{userClaims.profileLvl >= 1 && !isReporting && (
				<div style={{ marginLeft: "10px" }}>
					{/*\★━━━━━━━━━━━★ Select level ★━━━━━━━━━━━★\*/}
					<ClearAllIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							id="select_floorplan_header_dropdown"
							displayEmpty
							disableUnderline
							value={
								floorPlans.length > 0 && currentFloorPlan?.id ? currentFloorPlan?.id : ""
							}
							onChange={e => {
								setSelectedFloorPlan(e.target.value)
								onChangeFloorPlanHandler(e.target.value) // need this to match the selectedFloorPlan and currentFloorPlan
							}}
						>
							<MenuItem value="" disabled>
								Select floorplan
							</MenuItem>
							{floorPlans &&
								floorPlans.map((val, i) => {
									return (
										<MenuItem key={i} value={val?.id}>
											{val?.name}
										</MenuItem>
									)
								})}
						</Select>
					</FormControl>
				</div>
			)}

			{userClaims.isSuper && (
				<div style={{ marginLeft: "10px" }}>
					{/*\★━━━━━━━━━━━★ Select level ★━━━━━━━━━━━★\*/}
					<PersonIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							displayEmpty
							disableUnderline
							value={
								userClaims.profileLvl === 3
									? "msp"
									: userClaims.profileLvl === 2
									? "admin"
									: userClaims.profileLvl === 1
									? "user"
									: ""
							}
							onChange={e => {
								if (!userClaims.isSuper) return
								if (e.target.value === "msp") {
									const claims = {
										...userClaims,
										profileLvl: 3
									}
									props.setUserClaims(claims)
								} else if (e.target.value === "admin") {
									const claims = {
										...userClaims,
										profileLvl: 2
									}
									props.setUserClaims(claims)
								} else if (e.target.value === "user") {
									const claims = {
										...userClaims,
										profileLvl: 1
									}
									props.setUserClaims(claims)
								} else {
									const claims = {
										...userClaims,
										profileLvl: authRedux.getProfileLvl(userClaims)
									}
									props.setUserClaims(claims)
								}
							}}
						>
							<MenuItem value="">Original role</MenuItem>
							<MenuItem value="msp">MSP</MenuItem>
							<MenuItem value="admin">Admin</MenuItem>
							<MenuItem value="user">User</MenuItem>
						</Select>
					</FormControl>
				</div>
			)}
		</div>
	)
}

export default injectIntl(connect(null, authRedux.actions)(HeaderSelections))

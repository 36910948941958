import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { UIProvider } from "./UIContext"
import DataUsageIcon from "@material-ui/icons/DataUsage"
import { DataFilter } from "./data-filter/DataFilter"
import { FormControl, Select, MenuItem, makeStyles } from "@material-ui/core"
import * as actions from "../_redux/reporting/reportingActions"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { PeopleTimesheetPage } from "./PeopleTimesheet/PeopleTimesheetPage"
import { PeopleSummaryPage } from "./PeopleSummary/PeopleSummaryPage"
import "./ReportingPage.css"
import { Button } from "../../_partials/Button"
import moment from "moment"
import html2pdf from "html2pdf.js/dist/html2pdf.min.js"
import { AssetsSummaryPage } from "./AssetsSummary/AssetsSummaryPage"
import { AssetsTimesheetPage } from "./AssetsTimesheet/AssetsTimesheetPage"
import TabsReporting from "./TabsReporting/TabsReporting"

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 140
	},
	select: {
		backgroundColor: "#FAFAFA",
		"& .MuiSelect-select": {
			color: "#4A4A68",
			fontFamily: "Poppins",
			fontWeight: "500"
		},
		"& .MuiSelect-select:focus": {
			backgroundColor: "#FAFAFA" // focus background color
		},
		"& .MuiSelect-icon": {
			color: "#4A4A68" // arrow icon color
		}
	}
}))

export const ReportingPage = ({ history }) => {
	const { pathname } = history.location
	const classes = useStyles()
	const dispatch = useDispatch()

	const { page, selectedSite } = useSelector(
		state => ({
			page: state.persistentReporting?.page,
			selectedSite: state.profile?.currentSite
		}),
		shallowEqual
	)

	const pagesOptions = [
		{ name: "People Summary", value: "peopleSummary" },
		{ name: "People Timesheet", value: "peopleTimesheet" },
		{ name: "Asset Summary", value: "assetSummary" },
		{ name: "Asset Timesheet", value: "assetTimesheet" }
	]

	// React.useEffect(() => {
	// 	console.log("page --------->>>>>>>>> ", page)
	// }, [page])

	function downloadPDF() {
		// ↓ Download PDF
		const now = moment().format("YYYY-MM-DD HH:mm")

		let elementId = null
		let fileName = null

		// If page changes it changes the id element to be downloaded
		if (page === "peopleSummary") {
			elementId = "people_summary_main_div"
			fileName = "people_summary"
		}
		if (page === "peopleTimesheet") {
			elementId = "people_timesheet_main_div"
			fileName = "people_timesheet"
		}
		if (page === "assetSummary") {
			elementId = "asset_summary_main_div"
			fileName = "asset_summary"
		}
		if (page === "assetTimesheet") {
			elementId = "asset_timesheet_main_div"
			fileName = "asset_timesheet"
		}

		const element = document.getElementById(elementId)

		// const cutAfterOptions =
		//   group === "people"
		// 	? ["#wifiAdvancedDivToPdf3"]
		// 	: ["wifiAdvancedDivToPdf2", "wifiAdvancedDivToPdf3"];

		// // console.log("🚀 ~ cutAfterOptions", cutAfterOptions);
		// const cutBeforeOptions =
		//   group === "people" ? null : ["wifiAdvancedDivToPdf3"];

		const options = {
			margin: 0,
			filename: `${fileName}_${now}`,
			jsPDF: {
				// unit: "in",
				// format: "letter",
				unit: "pt",
				format: "a4",
				orientation: "l",
				// orientation: "p"
				hotfixes: ["px_scaling"]
				// floatPrecision: "smart",
				// precision: 15,
			},
			image: {
				type: "jpeg",
				quality: 1
				// width: 494,
				// height: 494,
			},
			html2canvas: {
				logging: false,
				scale: 2,
				scrollX: 0,
				scrollY: 0
				// windowWidth: 1224
			},
			// html2canvas: { scale: 2, windowWidth: 1100 },
			// makes pages break to another pdf page after chosen elements id.
			pagebreak: {
				mode: "avoid-all"
				// before: cutBeforeOptions,
				// //   [
				// //     // "#wifiAdvancedDivToPdf2",
				// //     // "#wifiAdvancedDivToPdf3",
				// //     // "#wifiAdvancedDivToPdf3",
				// //     // "#wifiDivToPdf4",
				// //   ],
				// after: cutAfterOptions,
				// //   [
				// //     // "#wifiAdvancedDivToPdf3",
				// //   ],
			}
		}
		// Download PDF
		// html2pdf().set(options).from(element).save()

		// Preview PDF on browser
		html2pdf()
			.set(options)
			.from(element)
			.toPdf()
			.get("pdf")
			.then(function (pdf) {
				window.open(pdf.output("bloburl"), "_blank")
			})
	}

	return (
		<UIProvider>
			<div
				data-testid="reporting_page_main_div"
				className="d-flex align-items-sm-start align-items-center justify-content-between mb-4"
				style={{ width: "100%", minHeight: "4rem" }}
			>
				<div
					className="col-6 d-flex align-items-sm-end align-items-center"
					style={{ minHeight: "5rem" }}
				>
					<div className="d-flex align-items-center">
						<div
							style={{
								width: "30px",
								height: "30px",
								marginRight: "5px",
								marginBottom: "0px"
							}}
						>
							<DataUsageIcon style={{ fontSize: "23px", color: "#2673F0" }} />
						</div>

						<h2 style={{ color: "#4A4A68" }}>
							<b>Reporting</b>
						</h2>
					</div>
				</div>
				<div className="col-6 p-0 d-none d-sm-flex justify-content-end">
					{pathname !== "/reporting/assets" && <TabsReporting pathname={pathname} />}
				</div>
				{/* Mobile dropdown  */}
				{/* <div className="col-6 d-sm-none">
					<div className="d-flex" style={{ justifyContent: "flex-end" }}>
						<FormControl className={classes.formControl}>
							<Select
								id="reporting_page_main_dropdown"
								IconComponent={ExpandMore}
								disableUnderline
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
								className={classes.select}
								value={page}
								onChange={e => {
									dispatch(actions.setPage(e.target.value))
								}}
								// onClose={() => handleChangeQueryParams(selectedTimeframe, page)}
							>
								{pagesOptions &&
									pagesOptions.map((val, i) => {
										return (
											<MenuItem
												key={i}
												value={val.value}
												id={"reporting_page_main_dropdown_" + val.value + "_" + i}
											>
												{val.name}
											</MenuItem>
										)
									})}
							</Select>
						</FormControl>
					</div>
				</div> */}
				{/* <div
					style={{
						border: "2px solid red",
						width: "85px",
						height: "40px",
						paddingRight: "0px"
					}}
					className="col-4"
				>
					<div className="d-flex justify-content-end">
						<Button
							variant="contained"
							color="primary"
							text="Export"
							onClick={() => {
								downloadPDF()
							}}
						/>
					</div>
				</div> */}
			</div>
			{!selectedSite && <Redirect exact={true} to="/dashboard" />}
			{selectedSite && <DataFilter />}
			{/* {selectedSite && renderSwitch(page)} */}

			<Route exact path={["/reporting/people", "/reporting/"]}>
				<Redirect exact={true} to="/dashboard" />
			</Route>

			<Route exact path="/reporting/people/summary">
				<PeopleSummaryPage />
			</Route>
			<Route exact path="/reporting/people/timesheets">
				<PeopleTimesheetPage />
			</Route>
			<Route exact path="/reporting/assets">
				<AssetsSummaryPage />
			</Route>
		</UIProvider>
	)
}

export function renderSwitch(param) {
	switch (param) {
		case "peopleSummary":
			return <PeopleSummaryPage />
		case "peopleTimesheet":
			return <PeopleTimesheetPage />
		case "assetSummary":
			return <AssetsSummaryPage />
		case "assetTimesheet":
			return <AssetsTimesheetPage />

		default:
			return
	}
}

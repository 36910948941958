import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody } from "../../../../_partials/Card"
import { makeStyles } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import { Dropdown } from "react-bootstrap"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import { filterBySearchString } from "../../../../helpersGlobal/DataGridHelpers"
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridOverlay
} from "@mui/x-data-grid"
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid"
import { Pagination } from "@material-ui/lab"
import * as UIHelpers from "../../UIHelpers"

export function TimesheetPerRole() {
	const classes = useStyles()

	const { isLoading, allPeopleTimesheet } = useSelector(
		state => ({
			isLoading: state.reporting.allPeopleTimesheetLoading,
			allPeopleTimesheet: state.reporting.allPeopleTimesheet
		}),
		shallowEqual
	)

	const [dataRows, setDataRows] = useState([])
	const [searchedRows, setSearchedRows] = useState([])
	const [searchString, setSearchString] = useState("")
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)

	// ━━━━━━━━━━━━━ UseEffect ━━━━━━━━━━━━━
	useEffect(() => {
		if (!allPeopleTimesheet) {
			setDataRows([])
		} else {
			setDataRows(allPeopleTimesheet)
		}
	}, [allPeopleTimesheet])

	const columns = [
		// { field: "id", headerName: "ID", width: 90 },
		{
			field: "day",
			headerName: "Day",
			minWidth: 200,
			editable: false,
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "role",
			headerName: "Role",
			minWidth: 200,
			editable: false,
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "counter",
			headerName: "Number of People",
			// type: "number",
			minWidth: 200,
			editable: false,
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "duration",
			headerName: "Total Hours",
			minWidth: 200,
			editable: false,
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		}
	]

	const handleSearch = value => {
		setSearchString(value)
	}

	// Filter the data based on searchText if it is not null or empty string
	useEffect(() => {
		if (!dataRows) return

		if (searchString && searchString.trim() !== "") {
			const fieldsToSearch = ["role", "day"] // Define the fields to search
			const filteredRows = filterBySearchString(dataRows, searchString, fieldsToSearch)

			setSearchedRows(filteredRows)
		} else {
			setSearchedRows(null)
		}
	}, [searchString, dataRows])

	return (
		<Card //  data-testid="manage-asset_types_ManageAssetTypesCard"
		>
			<CardBody
				style={{ minHeight: "450px", height: "100%", width: "100%", background: "white" }}
			>
				<div
					// data-testid="attendance_list_toolkit_provider"
					style={{ minHeight: "450px", height: "100%", width: "100%", background: "white" }}
					data-testid="asset-types-table-datagrid"
				>
					<div className="d-flex flex-column flex-lg-row flex-md-row justify-content-lg-between justify-content-md-between align-items-center mb-5">
						<div className="d-flex flex-column">
							<h3 style={{ color: "#212121", fontSize: 15.3, fontWeight: 600 }}>
								Total contracted workers per role timesheet
							</h3>
							<h5
								style={{
									color: "#b5b5c3",
									fontSize: 11.5,
									fontWeight: 500,
									marginTop: "0px"
								}}
							>
								Timesheet of contracted workers
							</h5>
						</div>
						<div className="mt-2 mt-lg-0 mt-md-0">
							<SearchBarCustom1
								onSearch={handleSearch}
								searchText={searchString}
								disabled={isLoading ? true : false}
								placeholder="Search"
								size="small"
								style={{
									width: "200px",
									background: "#F2F2F2"
								}}
								InputProps={{
									startAdornment: (
										<SearchIcon
											fontSize="small"
											style={{
												color: "#7F8C8D",
												marginRight: "0.7rem"
											}}
										/>
									)
								}}
							/>
						</div>
					</div>
					{isLoading && <ModalProgressBar variant="query" />}
					<DataGrid
						// data-testid="asset-types-table-datagrid"
						rows={searchedRows || dataRows || []}
						columns={columns}
						// loading={isLoading}
						autoHeight
						rowHeight={60}
						pageSize={pageSize}
						columnBuffer={4}
						rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
						page={page}
						onPageChange={newPage => setPage(newPage)}
						onPageSizeChange={e => {
							setPageSize(e)
						}}
						disableSelectionOnClick
						components={{
							Pagination: CustomPagination
						}}
						style={{
							fontFamily: "Poppins",
							fontWeight: 400,
							fontSize: "13px",
							border: "none",
							height: "100%",
							width: "100%",
							color: "#464e5f"
						}}
						className={classes.root}
					/>
				</div>
			</CardBody>
		</Card>
	)
}

function CustomPagination() {
	// const { state, apiRef } = useGridSlotComponentProps()
	const apiRef = useGridApiContext()
	// const [state] = useGridState(apiRef)
	const state = apiRef.current.state
	const rootElement = apiRef.current.rootElementRef
	const rootProps = useGridRootProps()
	const classes = useStyles()
	const options = [5, 10, 20, 30, 50, 100]

	const handlePageSizeChange = pageSize => {
		apiRef.current.setPageSize(pageSize)
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{state.pagination.pageSize}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option}
								as="button"
								onClick={() => handlePageSizeChange(option)}
							>
								{option}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			<Pagination
				className={classes.root}
				id="custom-pagination"
				color="primary"
				count={state.pagination.pageCount}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>
		</>
	)
}

const useStyles = makeStyles(() => UIHelpers.rootStylesTimesheet)

import * as Yup from "yup"

export const manageCustomerDefaultSortedByDisabled = [
	{ dataField: "disabled", order: "asc" },
	{ dataField: "name", order: "asc" }
]
export const defaultSorted = [{ dataField: "name", order: "asc" }]
export const sizePerPageList = [
	{ text: "5", value: 5 },
	{ text: "10", value: 10 },
	{ text: "20", value: 20 },
	{ text: "35", value: 35 },
	{ text: "50", value: 50 },
	{ text: "100", value: 100 }
]
export const initialFilter = {
	filter: {
		searchText: "",
		searchField: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "displayName",
	pageNumber: 1,
	pageSize: 10
}

// create universal unique ID (better than Math.random)
export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		// eslint-disable-next-line no-mixed-operators
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	)
}

// Validation Schema
export const EditSchema = Yup.object().shape({
	// name: Yup.string().required("Name is required"),
	firstName: Yup.string()
		.trim()
		.required("Pleasr insert First Name")
		.min(2, "Please insert more than 1 letter"),
	lastName: Yup.string()
		.trim()
		.required("Please insert Last Name")
		.min(2, "Please insert more than 1 letter"),
	email: Yup.string().email("Email must be valid").required("Email is required"),
	phoneNumber: Yup.string().required("Phone number is required").nullable()
})

import React, { useEffect, useState } from "react"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { GrFilter } from "react-icons/gr"
import { Button } from "../../../../../../_partials/Button"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import * as liveDataActions from "../../../../../_redux/liveData/liveDataActions"
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { firestoreOld } from "../../../../../../../firebase"

const FilterGeneralInfo = ({
	setDrawerOptions,
	showAreaInfoMarkers,
	setShowAreaInfoMarkers,
	showPeople,
	setShowPeople,
	showAssets,
	setShowAssets,
	showUnassignedTags,
	setShowUnassignedTags,
	showPOIs,
	setShowPOIs,
	showConnectors,
	setShowConnectors,
	user,
	dispatch,
	drawerPreferences,
	showAnchors,
	setShowAnchors,
	showGateways,
	setShowGateways,
	roles,
	roleFilterButtonStates,
	setRoleFilterButtonStates,
	assets,
	assetFilterButtonStates,
	setAssetFilterButtonStates,
	differentAssetTypes,
	setDifferentAssetTypes
}) => {
	// dynamicSelectedIcon Roles
	const dynamicSelectedIcon = index => {
		return roleFilterButtonStates[index] ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}

	// ━━━━ Toggle button icons according to the state
	const toggleButtonRoles = index => {
		if (!showPeople) setShowPeople(true)
		setRoleFilterButtonStates(prevState => {
			const newState = [...prevState]
			newState[index] = newState[index] === roles[index].name ? "" : roles[index].name

			// Check if all roles are unselected, then setShowPeople to false
			const allRolesUnselected = newState.every(role => role === "")
			if (allRolesUnselected) {
				setShowPeople(false)
			}

			return newState
		})
	}

	// ━━━━ Select all roleFilterBUttonStates button
	const selectAllRoles = () => {
		setRoleFilterButtonStates(roles.map(rol => rol.name))
	}

	// ━━━━ PerfectScrollbar
	useEffect(() => {
		const containerRoles = document.getElementById("scroll-container-drawer-roles-buttons")

		if (containerRoles) {
			const ps = new PerfectScrollbar(containerRoles, {
				wheelSpeed: 1,
				wheelPropagation: true,
				suppressScrollX: true
			})
			// ps.update()
		}

		const containerAssets = document.getElementById("scroll-container-drawer-assets-buttons")

		if (containerAssets) {
			const ps = new PerfectScrollbar(containerAssets, {
				wheelSpeed: 1,
				wheelPropagation: true,
				suppressScrollX: true
			})
			// ps.update()
		}
	}, [])

	// dynamicSelectedIcon Assets
	const dynamicSelectedIconAssets = index => {
		return assetFilterButtonStates[index] ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}

	// ━━━━ Toggle button icons according to the state
	const toggleButtonAssets = index => {
		if (!showAssets) setShowAssets(true)
		setAssetFilterButtonStates(prevState => {
			const newState = [...prevState]
			newState[index] =
				// newState[index] === differentAssetTypes[index].type
				// 	? ""
				// 	: differentAssetTypes[index].type
				newState[index] === differentAssetTypes[index].assetDetails.type
					? ""
					: differentAssetTypes[index].assetDetails.type

			// Check if all assets are unselected, then setShowAssets to false
			const allAssetsUnselected = newState.every(role => role === "")
			if (allAssetsUnselected) {
				setShowAssets(false)
			}
			return newState
		})
	}

	// ━━━━ Select all assetFilterButtonStates button
	const selectAllAssets = () => {
		// setAssetFilterButtonStates(differentAssetTypes.map(asset => asset.type))
		setAssetFilterButtonStates(differentAssetTypes.map(asset => asset.assetDetails.type))
	}

	const checkedIcon = <CheckIcon style={{ color: "#FFFFFF" }} />
	const addIcon = <AddIcon style={{ color: "#8C8CA2" }} />
	return (
		<div className="d-flex flex-column h-100">
			<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
				<div>
					<GrFilter
						className="drawer_option_title_icon1"
						style={{
							filter:
								"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
						}}
					/>
					<span className="drawer_option_title_span">Filter</span>
				</div>
				<div>
					<IconButton
						className="livemap_drawer_filter_close_btn"
						// style={{ margin: "0.5rem" }}
						size="small"
						onClick={() => {
							if (drawerPreferences && drawerPreferences.length !== 0) {
								const foundedPreferences = drawerPreferences.find(
									val => val.userId === user?.id
								)

								if (foundedPreferences) {
									dispatch(
										liveDataActions.saveUserDrawerPreferences([
											{
												userId: user?.id,
												preferences: {
													...foundedPreferences.preferences,
													connectors: showConnectors,
													pois: showPOIs,
													people: showPeople,
													assets: showAssets,
													unassignedTags: showUnassignedTags,
													areas: showAreaInfoMarkers,
													anchors: showAnchors,
													gateways: showGateways
													// roleFilter: roleFilterButtonStates || [],
													// assetTypeFilter: assetFilterButtonStates || []
												}
											}
										])
									)
								} else {
									dispatch(
										liveDataActions.saveUserDrawerPreferences([
											{
												userId: user?.id,
												preferences: {
													connectors: showConnectors,
													pois: showPOIs,
													people: showPeople,
													assets: showAssets,
													unassignedTags: showUnassignedTags,
													areas: showAreaInfoMarkers,
													anchors: showAnchors,
													gateways: showGateways
													// roleFilter: roleFilterButtonStates || [],
													// assetTypeFilter: assetFilterButtonStates || []
												}
											}
										])
									)
								}
							} else {
								dispatch(
									liveDataActions.saveUserDrawerPreferences([
										{
											userId: user?.id || null,
											preferences: {
												connectors: showConnectors,
												pois: showPOIs,
												people: showPeople,
												assets: showAssets,
												unassignedTags: showUnassignedTags,
												areas: showAreaInfoMarkers,
												anchors: showAnchors,
												gateways: showGateways
												// roleFilter: roleFilterButtonStates || [],
												// assetTypeFilter: assetFilterButtonStates || []
											}
										}
									])
								)
							}
							setDrawerOptions(prevState => ({
								...prevState,
								openFilter: false
							}))
						}}
					>
						<CloseIcon style={{ fontSize: "18px" }} />
					</IconButton>
				</div>
			</div>
			<p className="drawer_option_title_secondary">General Info</p>
			<div>
				<div className="d-flex justify-content-between">
					<Button
						className="drawer_options_filter_button"
						endIcon={showAreaInfoMarkers ? checkedIcon : addIcon}
						style={{
							backgroundColor: showAreaInfoMarkers ? "#3077D3" : "#DBE1E5",
							color: showAreaInfoMarkers ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						// text="Areas info"
						onClick={() => setShowAreaInfoMarkers(prev => !prev)}
					>
						<span>Areas info</span>
					</Button>

					<Button
						className="drawer_options_filter_button"
						endIcon={showPeople ? checkedIcon : addIcon}
						style={{
							backgroundColor: showPeople ? "#3077D3" : "#DBE1E5",
							color: showPeople ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						// text="People"
						onClick={() => {
							setShowPeople(prev => !prev)
							if (roleFilterButtonStates.length <= 0) selectAllRoles()
							else setRoleFilterButtonStates([])
						}}
					>
						<span>People</span>
					</Button>
				</div>
				<div className="d-flex justify-content-between">
					<Button
						className="drawer_options_filter_button"
						endIcon={showAssets ? checkedIcon : addIcon}
						style={{
							backgroundColor: showAssets ? "#3077D3" : "#DBE1E5",
							color: showAssets ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						// text="Assets"
						onClick={() => {
							setShowAssets(prev => !prev)
							if (assetFilterButtonStates.length <= 0) selectAllAssets()
							else setAssetFilterButtonStates([])
						}}
					>
						<span>Assets</span>
					</Button>

					<Button
						className="drawer_options_filter_button"
						endIcon={showUnassignedTags ? checkedIcon : addIcon}
						style={{
							backgroundColor: showUnassignedTags ? "#3077D3" : "#DBE1E5",
							color: showUnassignedTags ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						// text="Unassigned tags"
						onClick={() => setShowUnassignedTags(prev => !prev)}
					>
						<span>Unassigned tags</span>
					</Button>
				</div>

				<div className="d-flex justify-content-between">
					<Button
						className="drawer_options_filter_button"
						endIcon={showAnchors ? checkedIcon : addIcon}
						style={{
							backgroundColor: showAnchors ? "#3077D3" : "#DBE1E5",
							color: showAnchors ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						// text="Anchors"
						onClick={() => setShowAnchors(prev => !prev)}
					>
						<span>Anchors</span>
					</Button>

					<Button
						className="drawer_options_filter_button"
						endIcon={showPOIs ? checkedIcon : addIcon}
						style={{
							backgroundColor: showPOIs ? "#3077D3" : "#DBE1E5",
							color: showPOIs ? "#FFFFFF" : "#8C8CA2",
							whiteSpace: "nowrap"
						}}
						variant="contained"
						// text="Points of Interest"
						onClick={() => setShowPOIs(prev => !prev)}
					>
						<span>Points of Interest</span>
					</Button>
				</div>

				<div className="d-flex justify-content-between">
					<Button
						className="drawer_options_filter_button"
						endIcon={showGateways ? checkedIcon : addIcon}
						style={{
							backgroundColor: showGateways ? "#3077D3" : "#DBE1E5",
							color: showGateways ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						// text="Gateways"
						onClick={() => setShowGateways(prev => !prev)}
					>
						<span>Gateways</span>
					</Button>

					<Button
						className="drawer_options_filter_button"
						endIcon={showConnectors ? checkedIcon : addIcon}
						style={{
							backgroundColor: showConnectors ? "#3077D3" : "#DBE1E5",
							color: showConnectors ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						// text="Connectors"
						onClick={() => setShowConnectors(prev => !prev)}
					>
						<span>Connectors</span>
					</Button>
				</div>
			</div>
			{roles && roles.length > 0 && <p className="drawer_option_title_secondary">Roles</p>}

			<div id="scroll-container-drawer-roles-buttons" className="drawer_options_roles_buttons">
				{roles &&
					roles.length > 0 &&
					roles.map((rol, index) => {
						if (index % 2 === 0) {
							return (
								<div key={index} className="d-flex justify-content-between">
									<Button
										className="drawer_options_filter_button"
										endIcon={dynamicSelectedIcon(index)}
										style={{
											background: roleFilterButtonStates[index] ? "#3077D3" : "#DBE1E5",
											color: roleFilterButtonStates[index] ? "#FFFFFF" : "#8C8CA2"
										}}
										variant="contained"
										onClick={() => {
											toggleButtonRoles(index)
										}}
									>
										<span>{rol?.name}</span>
									</Button>
									{roles[index + 1] && (
										<Button
											className="drawer_options_filter_button"
											endIcon={dynamicSelectedIcon(index + 1)}
											style={{
												background: roleFilterButtonStates[index + 1]
													? "#3077D3"
													: "#DBE1E5",
												color: roleFilterButtonStates[index + 1] ? "#FFFFFF" : "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonRoles(index + 1)
											}}
										>
											<span>{roles[index + 1]?.name}</span>
										</Button>
									)}
								</div>
							)
						} else {
							return null // Render nothing for odd indices
						}
					})}
			</div>

			{differentAssetTypes && differentAssetTypes.length > 0 && (
				<p className="drawer_option_title_secondary">Assets</p>
			)}

			<div id="scroll-container-drawer-assets-buttons" className="drawer_options_roles_buttons">
				{differentAssetTypes &&
					differentAssetTypes.length > 0 &&
					differentAssetTypes.map((asset, index) => {
						if (index % 2 === 0) {
							return (
								<div key={index} className="d-flex justify-content-between">
									<Button
										className="drawer_options_filter_button"
										endIcon={dynamicSelectedIconAssets(index)}
										style={{
											background: assetFilterButtonStates[index] ? "#3077D3" : "#DBE1E5",
											color: assetFilterButtonStates[index] ? "#FFFFFF" : "#8C8CA2"
										}}
										variant="contained"
										onClick={() => {
											toggleButtonAssets(index)
										}}
									>
										{/* <span>{asset?.type}</span> */}
										<span>{asset?.assetDetails?.type}</span>
									</Button>
									{differentAssetTypes[index + 1] && (
										<Button
											className="drawer_options_filter_button"
											endIcon={dynamicSelectedIconAssets(index + 1)}
											style={{
												background: assetFilterButtonStates[index + 1]
													? "#3077D3"
													: "#DBE1E5",
												color: assetFilterButtonStates[index + 1]
													? "#FFFFFF"
													: "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonAssets(index + 1)
											}}
										>
											{/* <span>{differentAssetTypes[index + 1]?.type}</span> */}
											<span>{differentAssetTypes[index + 1]?.assetDetails?.type}</span>
										</Button>
									)}
								</div>
							)
						} else {
							return null // Render nothing for odd indices
						}
					})}
			</div>

			<div className="d-flex align-items-center justify-content-end mt-3">
				<Button
					className="drawer_options_filter_button_select_all_filter_one mr-3"
					style={{
						backgroundColor: "#FFFFFF",
						color: "#3077D3",
						border: "1px solid #3077D3",
						width: "120px",
						height: "31px"
					}}
					variant="contained"
					text="Select All"
					onClick={() => {
						setShowAreaInfoMarkers(true)
						setShowPeople(true)
						setShowAssets(true)
						setShowUnassignedTags(true)
						setShowPOIs(true)
						setShowConnectors(true)
						setShowAnchors(true)
						setShowGateways(true)
						selectAllRoles()
						selectAllAssets()
					}}
				/>
				<Button
					className="drawer_options_filter_button_select_all_filter_one"
					style={{ backgroundColor: "#DDE9FD", color: "#3077D3" }}
					variant="contained"
					text="Save"
					onClick={() => {
						if (drawerPreferences && drawerPreferences.length !== 0) {
							const foundedPreferences = drawerPreferences.find(
								val => val.userId === user?.id
							)

							if (foundedPreferences) {
								dispatch(
									liveDataActions.saveUserDrawerPreferences([
										{
											userId: user?.id,
											preferences: {
												...foundedPreferences.preferences,
												connectors: showConnectors,
												pois: showPOIs,
												people: showPeople,
												assets: showAssets,
												unassignedTags: showUnassignedTags,
												areas: showAreaInfoMarkers,
												anchors: showAnchors,
												gateways: showGateways
												// roleFilter: roleFilterButtonStates || [],
												// assetTypeFilter: assetFilterButtonStates || []
											}
										}
									])
								)
							} else {
								dispatch(
									liveDataActions.saveUserDrawerPreferences([
										{
											userId: user?.id,
											preferences: {
												connectors: showConnectors,
												pois: showPOIs,
												people: showPeople,
												assets: showAssets,
												unassignedTags: showUnassignedTags,
												areas: showAreaInfoMarkers,
												anchors: showAnchors,
												gateways: showGateways
												// roleFilter: roleFilterButtonStates || [],
												// assetTypeFilter: assetFilterButtonStates || []
											}
										}
									])
								)
							}
						} else {
							dispatch(
								liveDataActions.saveUserDrawerPreferences([
									{
										userId: user?.id || null,
										preferences: {
											connectors: showConnectors,
											pois: showPOIs,
											people: showPeople,
											assets: showAssets,
											unassignedTags: showUnassignedTags,
											areas: showAreaInfoMarkers,
											anchors: showAnchors,
											gateways: showGateways
											// roleFilter: roleFilterButtonStates || [],
											// assetTypeFilter: assetFilterButtonStates || []
										}
									}
								])
							)
						}
						setDrawerOptions(prevState => ({
							...prevState,
							openFilter: false
						}))
					}}
				/>
			</div>
		</div>
	)
}

export default FilterGeneralInfo

const perfectScrollbarOptions = {
	wheelSpeed: 2,
	wheelPropagation: false
}

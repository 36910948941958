import React from "react"
import ReactDOM from "react-dom"

export default class Options {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"
		this.threeD = false
		this.container.style.cursor = "pointer"
		this.container.style.height = "31px"
		this.container.style.width = "31px"

		this.content = <OptionsComponent map={map} />

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}
class OptionsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			threeD: true
		}
	}

	handleClick(val) {
		this.setState({ threeD: val })
	}

	render() {
		return (
			<div className="div-3d">
				{this.state.threeD ? (
					<div
						className="button-3d"
						onClick={() => {
							this.handleClick(!this.state.threeD)
							this.props.map.easeTo({ pitch: 55 })
						}}
					>
						3D
					</div>
				) : (
					<div
						className="button-3d"
						onClick={() => {
							this.handleClick(!this.state.threeD)
							this.props.map.easeTo({ pitch: 0 })
						}}
					>
						2D
					</div>
				)}
			</div>
		)
	}
}

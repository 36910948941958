import React, { useMemo } from "react"
import objectPath from "object-path"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { useSelector } from "react-redux"

export function HeaderMobile({ handleHideDrawer }) {
	const uiService = useHtmlClassService()

	const layoutProps = useMemo(() => {
		return {
			headerLogo: uiService.getStickyLogo(),
			asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
			headerMenuSelfDisplay:
				objectPath.get(uiService.config, "header.menu.self.display") === true,
			headerMobileCssClasses: uiService.getClasses("header_mobile", true),
			headerMobileAttributes: uiService.getAttributes("header_mobile")
		}
	}, [uiService])

	const currentCustomer = useSelector(state => state.profile.currentCustomer)

	return (
		<>
			{/*begin::Header Mobile*/}
			<div
				id="kt_header_mobile"
				className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
				{...layoutProps.headerMobileAttributes}
			>
				<div className="d-flex align-items-center mr-auto">
					{/*Aside Mobile Toggle*/}
					<button
						className="btn p-0 burger-icon mr-4"
						id="kt_aside_mobile_toggle"
						onClick={() => {
							handleHideDrawer()
						}}
					>
						<span />
					</button>

					{/*Mobile Customer Name*/}
					<h3 style={{ color: "white", verticalAlign: "baseline" }}>
						{currentCustomer?.name}
					</h3>
				</div>

				<div className="d-flex align-items-center">
					{/**/}
					{/* <button className="btn p-0 burger-icon ml-4" /* id="kt_header_mobile_toggle" />
            <span />
          </button> */}
					{/*Topbar Mobile Toggle*/}
					<button
						className="btn btn-hover-text-primary p-0 ml-2"
						style={{ maxWidth: "30px" }}
						id="kt_header_mobile_topbar_toggle"
					>
						<SVG style={{ fill: "white" }} src="/media/crowdkeep/Person_Placeholder.svg" />
					</button>
				</div>
			</div>
			{/*end::Header Mobile*/}
		</>
	)
}

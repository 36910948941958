// // Helper function - filter for all unique types of assets
// export const uniqValuesTypes = arr => {
// 	const uniq = {}
// 	const arrFiltered = arr.filter(obj => !uniq[obj.type] && (uniq[obj.type] = true))
// 	// console.log("arrFiltered", arrFiltered);
// 	return arrFiltered
// }

// Helper function - filter for one asset per unique assetDetails.type
export const uniqAssetDetailsTypes = arr => {
	const uniq = {}
	const uniqueAssets = arr.reduce((result, obj) => {
		const type = obj?.assetDetails && obj?.assetDetails?.type
		if (type && !uniq[type]) {
			uniq[type] = true
			result.push(obj)
		}
		return result
	}, [])

	return uniqueAssets
}

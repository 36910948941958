import React from "react"
import DateFnsUtils from "@date-io/date-fns"
import {
	makeStyles,
	TextField,
	IconButton,
	InputAdornment,
	FormHelperText
} from "@material-ui/core"
import { MuiPickersUtilsProvider, DatePicker as MUIDatePicker } from "@material-ui/pickers"
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import { BsCalendar4 } from "react-icons/bs"

const useStyles = makeStyles(theme => ({
	datePicker: {
		// marginTop: "0.9rem",
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "38px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		}
	},
	helperText: {
		margin: 0,
		color: theme.palette.error.main
	},
	icon: {
		color: "#B8BCCA"
	}
}))

export function DatePicker({
	field,
	form,
	label,
	withFeedbackLabel = true,
	variant = "inline",
	inputVariant = "outlined",
	textFieldStyle,
	...props
}) {
	const classes = useStyles()
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	return (
		<>
			{label && (
				<div style={{ marginBottom: "10px" }}>
					<b>{label}</b>
				</div>
			)}
			<div>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<MUIDatePicker
						error={error ? true : false}
						helperText={null}
						className={classes.datePicker}
						variant={variant}
						inputVariant={inputVariant}
						format="yyyy/MM/dd"
						value={value}
						TextFieldComponent={params => {
							return (
								<TextField
									{...params}
									style={textFieldStyle}
									InputProps={{
										readOnly: true,
										endAdornment: (
											<InputAdornment position="end">
												<BsCalendar4 className={classes.icon} />
											</InputAdornment>
										)
									}}
								/>
							)
						}}
						onClose={() => {
							if (!touched && !setFieldTouched) {
								return
							}
							/*!touched[name] so ti doesn't set touched true when it's already true*/
							/*Without this errors will always be one update behind the real value*/
							!touched[name] && setFieldTouched(name, true)
						}}
						onChange={date => {
							if (!touched && !setFieldTouched && !setFieldValue) {
								return
							}
							!touched[name] && setFieldTouched(name, true)
							setFieldValue(name, date)
						}}
						{...props}
					/>
					<>
						{error && (
							<FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>
						)}
					</>
				</MuiPickersUtilsProvider>
			</div>
		</>
	)
}

import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const manageSiteSlice = createSlice({
	name: "manageSite",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		siteEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
		}

		// roleCreated: (state, action) => {
		//   state.actionsLoading = false;
		//   state.error = null;
		//   state.roles = state.roles
		//     ? [...state.roles, action.payload]
		//     : state.roles;
		// },
		// roleDeleted: (state, action) => {
		//   state.actionsLoading = false;
		//   state.error = null;
		//   state.roles = state.profile?.currentCustomer?.roles.filter(
		//     (val) => val.id !== action.payload
		//   );
		// },

		// roleEdited: (state, action) => {
		//   state.actionsLoading = false;
		//   state.error = null;
		//   state.roles =
		//     state.roles &&
		//     state.roles.map((entity) => {
		//       if (entity.id === action.payload.id) return action.payload;
		//       return entity;
		//     });
		// },
	}
})

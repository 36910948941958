import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import PopoverStickOnHover from "../PopoverStickOnHover"
import * as turf from "@turf/turf"
import "@turf/angle"
import DirectionsIcon from "@material-ui/icons/Directions"
import areaImage from "../../../../assets/dashboard_markers/newMarkers/areas-markers/area-info.png"
import { IoLocationSharp } from "react-icons/io5"
import { Button } from "../../../../_partials/Button"
import { percentageToSize, pixelToSize } from "./ActionHelpers"

export function addAreasMarkersHasLayers(params) {
	const { map, areasOnThisFloorplan, showLayersBesidesTags, areasSize } = params

	const areasWithoutBaseLayer =
		areasOnThisFloorplan &&
		areasOnThisFloorplan.filter(val => val.properties.type !== "baseLayer")

	const geoJsonArrayAreas = areasWithoutBaseLayer
		? areasWithoutBaseLayer.map(d => {
				return {
					type: "Feature",
					properties: {
						...d
					},
					geometry: {
						type: "Point",
						coordinates: [
							d?.properties?.marker?.longitude,
							d?.properties?.marker?.latitude,
							0.0
						]
					}
				}
		  })
		: []

	geoJsonArrayAreas &&
		geoJsonArrayAreas.forEach(feature => {
			let iconToMarker = areaImage
			let imageId = "area-info-marker"

			map.loadImage(iconToMarker, (error, img) => {
				if (error) {
					// console.error(`Error loading image areas ->`, error)
				}
				if (!img || error) return

				// If map already has the image we need to remove so there aren't any re-renders
				const mapHasImage = map.hasImage(imageId)
				// ↓ So don't get error of loading image with same sourceId
				if (mapHasImage) {
					map.removeImage(imageId)
				}

				// add icon image
				map.addImage(imageId, img)
			})

			// Set the iconImage property in the GeoJSON feature
			feature.properties.iconImage = imageId
		})

	// Remove sources/layers if they already exists before adding new source/layers
	if (typeof map.getLayer("areas-markers-layer") !== "undefined") {
		map.removeLayer("areas-markers-layer")
	}
	if (typeof map.getSource("areas-markers-source") !== "undefined") {
		// map.removeSource("areas-markers-source")
		// Note: instead of removing the source, with the setData() method we update the source with the newData and doesn't flicker all the tags when filtering.
		map.getSource("areas-markers-source").setData({
			type: "FeatureCollection",
			features: geoJsonArrayAreas
		})
	}

	if (!map.getSource("areas-markers-source")) {
		map.addSource("areas-markers-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: geoJsonArrayAreas
			}
		})
	}

	// const correspondentOfOneHundredPercent = 0.8
	const newSize = areasSize || areasSize === 0 ? pixelToSize(areasSize) : 0.3

	if (!map.getLayer("areas-markers-layer")) {
		map.addLayer(
			{
				id: "areas-markers-layer",
				type: "symbol",
				source: "areas-markers-source",
				// filter: ["!", ["has", "point_count"]],
				layout: {
					"icon-image": ["get", "iconImage"],
					"icon-size": newSize ? newSize : 0.4,
					// "icon-size": 0.4,
					// "icon-size": 0.8,
					"symbol-sort-key": ["get", "iconImage"],
					"icon-allow-overlap": true, // Allow icons to overlap if needed (this allows markers to overlap and not disappear)
					"icon-ignore-placement": true, // Ignore placement for better clustering behavior
					visibility: showLayersBesidesTags ? "visible" : "none"
				}
			},
			"state-label"
		)
	}
}

export function addZoneMarkers(params) {
	const {
		map,
		// tags,
		floorPlan,
		areas,
		setGetDirections,
		geoJsonRoutesUndefinedSnackBar,
		// setTravelTo,
		// setCopyOfStartPointCoords,
		setClickedToResetPath,
		containerRef,
		isFullscreen,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		markersSize,
		showAreaInfoMarkers,
		showLayersBesidesTags,
		areasSize,
		peopleInsideEachArea
	} = params

	const areaMarkers = []

	// filter Areas markers if showAreaInfoMarkers filter
	const _floorPlanGeoJsonFiltered = !showAreaInfoMarkers
		? []
		: floorPlan &&
		  floorPlan?.geoJson?.features.map(feat => {
				// Zone in DB with same id as feature
				const fetchZoneWithMarkerCoords =
					areas && areas.find(zone => zone.id === feat.properties.areaId)

				const fetchedLng =
					fetchZoneWithMarkerCoords && fetchZoneWithMarkerCoords?.marker?.longitude
				const fetchedLat =
					fetchZoneWithMarkerCoords && fetchZoneWithMarkerCoords?.marker?.latitude

				const matchArea =
					peopleInsideEachArea &&
					peopleInsideEachArea.find(zone => zone.properties.areaId === feat.properties.areaId)

				const newFeature = {
					...feat,
					properties: {
						...feat.properties,
						marker: {
							longitude: fetchZoneWithMarkerCoords ? fetchedLng : null,
							latitude: fetchZoneWithMarkerCoords ? fetchedLat : null
						},
						peopleInsideCount: matchArea && matchArea?.properties?.peopleInsideCount
					}
				}

				return newFeature
		  })

	floorPlan &&
		_floorPlanGeoJsonFiltered &&
		_floorPlanGeoJsonFiltered.forEach(feat => {
			if (feat.properties.type === "baseLayer") {
				return
			}

			const coordinates = feat.geometry.coordinates.map(coords => {
				return [coords.longitude, coords.latitude]
			})
			const feature = {
				...feat,
				geometry: {
					type: feat.geometry.type,
					coordinates: [coordinates]
				}
			}

			// ↓ Zone markers
			const poly2 = turf.polygon(feature?.geometry?.coordinates) // ← area polygon.

			// // Creating an array of turf points
			// const tagsToPoints = tags.map(tag => {
			// 	return turf.point([tag.gpsData.longitude, tag.gpsData.latitude])
			// })

			// // Check which points are inside the area
			// const pointsInside = tagsToPoints.filter(point => turf.booleanPointInPolygon(point, poly2))

			// // Get the count of points inside the area
			// const pointsInsideCount = pointsInside.length

			const pointOnPolygon = turf.pointOnFeature(poly2) // ← Get point inside each polygon.
			const pointOnPolygonLng = pointOnPolygon.geometry.coordinates[0]
			const pointOnPolygonLat = pointOnPolygon.geometry.coordinates[1]

			// Zone in DB with same id as feature
			const fetchZoneWithMarkerCoords =
				areas && areas.find(zone => zone.id === feat.properties.areaId)

			const fetchedLng = fetchZoneWithMarkerCoords?.marker?.longitude
			const fetchedLat = fetchZoneWithMarkerCoords?.marker?.latitude
			const conditionalLng = fetchedLng ? fetchedLng : pointOnPolygonLng
			const conditionalLat = fetchedLat ? fetchedLat : pointOnPolygonLat

			const isVisible = feat.properties.visible === true ? "block" : "none"

			const noPropertyInDBCondition =
				feature.properties.visible !== undefined ? isVisible : "block"

			const el = document.createElement("div")

			// ↓ Popup in marker
			const popupStick = (
				<PopoverStickOnHover
					component={
						<div
							style={{
								background: "#ECF1F4",
								boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
								borderRadius: "8px",
								fontFamily: "Poppins",
								fontStyle: "normal",
								color: "#4A4A68",
								paddingBottom: "2px",
								width: "276px"
							}}
						>
							<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
								<span>{feat.properties.name}</span>
								<div>
									<IoLocationSharp
										style={{
											height: "18px",
											width: "18px",
											marginRight: "5px"
										}}
									/>
									<span
										style={{
											fontWeight: 300,
											fontSize: "10px",
											lineHeight: "160%"
										}}
									>
										<span>{floorPlan?.name}</span>
									</span>
								</div>
							</div>
							<InfoPOIDashboard
								feat={feat}
								floorPlan={floorPlan}
								geoJsonRoutesUndefinedSnackBar={geoJsonRoutesUndefinedSnackBar}
								setGetDirections={setGetDirections}
								setEndingPoint={setEndingPoint}
								setClickedToResetPath={setClickedToResetPath}
								setOpenDrawerBar={setOpenDrawerBar}
								setDrawerOptions={setDrawerOptions}
								areas={areas}
								pointsInsideArea={feat?.properties?.peopleInsideCount}
								// pointsInsideArea={pointsInsideCount}
							/>
						</div>
					}
					placement="top"
					delay={200}
					containerRef={isFullscreen ? containerRef : null}
				>
					<div
						className="d-flex justify-content-center align-items-center slider-markers-size markers-areasInfo"
						style={{
							display: `${noPropertyInDBCondition}`,
							cursor: "pointer",
							width:
								markersSize && markersSize?.areasInfo
									? `${Math.floor((markersSize?.areasInfo / 100) * (80 - 14 + 1) + 14)}px`
									: "26px",
							height:
								markersSize && markersSize?.areasInfo
									? `${Math.floor((markersSize?.areasInfo / 100) * (80 - 14 + 1) + 14)}px`
									: "26px",
							position: "relative",
							overflow: "hidden",
							// outline: "2px solid #ffffff",
							borderRadius: "50%"
							// outline: "2px solid red"
						}}
					>
						<img
							className="markers-areasInfo"
							style={{
								width: "58",
								// width: "28%",
								height: "auto",
								display: "none"
								// fill: "white"
							}}
							src="/icons/information.svg"
							alt="info icon"
						/>
					</div>
				</PopoverStickOnHover>
			)

			ReactDOM.render(popupStick, el)
			const marker = new mapboxgl.Marker(el)
				.setLngLat([conditionalLng, conditionalLat])
				.addTo(map)
			areaMarkers.push(marker)
		})

	// ━━━━━━━━━ Add markers with Layers
	// console.log("🎃 🎃 🎃 🎃 🎃 map.getStyle()", map.getStyle().layers)

	const layers = map.getStyle()
	if (layers) {
		//  layers markers
		addAreasMarkersHasLayers({
			map,
			areasOnThisFloorplan: _floorPlanGeoJsonFiltered,
			showLayersBesidesTags: showLayersBesidesTags,
			areasSize
		})
	}
	return areaMarkers
}

export function peopleCountInAreas(params) {
	const { tags, floorPlan, areas, showAreaInfoMarkers } = params

	// filter Areas markers if showAreaInfoMarkers filter
	const _floorPlanGeoJsonFiltered = !showAreaInfoMarkers
		? []
		: floorPlan &&
		  floorPlan?.geoJson?.features.map(feat => {
				// Zone in DB with same id as feature
				const fetchZoneWithMarkerCoords =
					areas && areas.find(zone => zone.id === feat.properties.areaId)

				const fetchedLng =
					fetchZoneWithMarkerCoords && fetchZoneWithMarkerCoords?.marker?.longitude
				const fetchedLat =
					fetchZoneWithMarkerCoords && fetchZoneWithMarkerCoords?.marker?.latitude

				const newFeature = {
					...feat,
					properties: {
						...feat.properties,
						marker: {
							longitude: fetchZoneWithMarkerCoords ? fetchedLng : null,
							latitude: fetchZoneWithMarkerCoords ? fetchedLat : null
						}
					}
				}

				return newFeature
		  })

	const areasWithInfoArray =
		floorPlan &&
		_floorPlanGeoJsonFiltered &&
		_floorPlanGeoJsonFiltered.map(feat => {
			const coordinates = feat.geometry.coordinates.map(coords => {
				return [coords.longitude, coords.latitude]
			})
			const feature = {
				...feat,
				geometry: {
					type: feat.geometry.type,
					coordinates: [coordinates]
				}
			}

			// ↓ Zone markers
			const poly2 = turf.polygon(feature?.geometry?.coordinates) // ← area polygon.

			// Creating an array of turf points
			const tagsToPoints = tags.map(tag => {
				return turf.point([tag.gpsData.longitude, tag.gpsData.latitude])
			})

			// Check which points are inside the area
			const pointsInside = tagsToPoints.filter(point => turf.booleanPointInPolygon(point, poly2))

			// Get the count of points inside the area
			const pointsInsideCount = pointsInside.length

			return {
				...feat,
				properties: {
					...feat.properties,
					peopleInsideCount: pointsInsideCount
				}
			}
		})

	const removedBaseLayerFromArray =
		areasWithInfoArray && areasWithInfoArray.filter(feat => feat.properties.type !== "baseLayer")
	return removedBaseLayerFromArray
}

function InfoPOIDashboard(props) {
	const {
		feat,
		floorPlan,
		geoJsonRoutesUndefinedSnackBar,
		setGetDirections,
		setEndingPoint,
		setClickedToResetPath,
		setOpenDrawerBar,
		setDrawerOptions,
		areas,
		pointsInsideArea
	} = props

	return (
		<>
			<div className="d-flex mt-3" style={{ padding: "0px 10px 8px 10px" }}>
				<div
					className="flex-fill mr-3"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px",
						maxWidth: "105px", // Adjust the max-width as needed
						overflow: "hidden",
						maxHeight: "75px",
						overflowY: "auto"
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Type
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{feat.properties.type}
					</div>
				</div>
				<div
					className="flex-fill"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px"
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Capacity
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{pointsInsideArea && pointsInsideArea}
						{"/"}
						{feat?.properties?.capacity}
					</div>
				</div>
				<div
					className="flex-fill ml-3"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px"
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Geofenced area
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{feat.properties.geoFencing ? "Yes" : "No"}
					</div>
				</div>
			</div>
			<div className="d-flex" style={{ padding: "0 10px 8px 10px" }}>
				<div className="flex-fill">
					<Button
						className="w-100"
						variant="contained"
						color="primary"
						onClick={() => {
							// ↓ if no routes drawn display message to user and don't show directions box
							if (
								!floorPlan?.geoJsonRoutes ||
								floorPlan?.geoJsonRoutes.features.length === 0
							) {
								geoJsonRoutesUndefinedSnackBar(floorPlan)
								return
							}

							// Get area / zone in DB with same id as feature
							const fetchZoneWithMarkerCoords =
								areas && areas.find(zone => zone.id === feat.properties.areaId)

							// console.log(
							//   "🚀 ~ fetchZoneWithMarkerCoords",
							//   fetchZoneWithMarkerCoords
							// );

							// ↓ Select Zone has start point in directions box
							setGetDirections({
								type: "zone",
								feature: fetchZoneWithMarkerCoords,
								clicked: true,
								group: "Areas",
								floorPlanId: floorPlan?.id
							})

							setEndingPoint({
								group: "Areas",
								...fetchZoneWithMarkerCoords
							}) // new endingPoint

							// ↓ Read me: Don't need this anymore with new version of Path. Set a Copy of Start Point (that doesn't closes the directions box)
							// // Set a copy of start point (that doesn't close directions box)
							// // Zone in DB with same id as feature
							//	// const fetchZoneWithMarkerCoords =
							//	//   areas &&
							//	//   areas.find(
							//	//     (zone) => zone.id === feat.properties.areaId
							//	//   );

							// // console.log(
							// //   "🚀 ~ fetchZoneWithMarkerCoords",
							// //   fetchZoneWithMarkerCoords
							// // );
							//	// const fetchedLng =
							//	//   fetchZoneWithMarkerCoords?.doorMarker?.longitude;
							//	// const fetchedLat =
							//	//   fetchZoneWithMarkerCoords?.doorMarker?.latitude;
							//	// setCopyOfStartPointCoords([fetchedLng, fetchedLat]);

							// // ↓ Empty go to in directions box
							//	// setTravelTo();

							// ↓ Reset Path
							setClickedToResetPath(true)

							// ↓ opens New Path box on drawer
							setOpenDrawerBar(true)
							setDrawerOptions({
								openSearch: false,
								openFilter: false,
								openPath: true,
								openOccupancy: false,
								openSatellite: false
							})
						}}
					>
						<span
							className="mr-2"
							style={{
								fontSize: "12px",
								letterSpacing: "0.56px",
								fontFamily: "Poppins",
								fontWeight: 700,
								fontStyle: "normal",
								lineHeight: "normal"
							}}
						>
							Directions
						</span>
						<img alt="Directions" src="/icons/directions.svg" />
					</Button>
				</div>
			</div>
		</>
	)
}

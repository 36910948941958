//button has children because without it, I couldn't get buttons with progress animations to work.
//To change startIcon color you have to pass it by style ex. style={{color:#color}}

import React from "react"
import { Button as MUIButton, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { fill } from "lodash"

const useStyles = makeStyles(theme => ({
	button: {
		minWidth: "0px",
		borderRadius: "4px",
		padding: "15px",
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: "4px",
		borderTopRightRadius: "4px",
		boxShadow: "inset 5px -5px 5px -3px rgb(0 0 0 / 10%)",

		// boxShadow: "none",
		// boxShadow: "inset -2px -2px 5px 0px rgb(0 0 0 / 20%)",

		"&:hover": {
			backgroundColor: "#f7f7f7 !important"
		},

		"&.MuiButton-outlined": {
			borderColor: props => theme.palette[props.color]?.main || theme.palette.primary.main
		},
		"& .MuiButton-startIcon": {
			marginLeft: "0 ",
			marginRight: "0 "
		}
	},
	label: {
		fontFamily: "Poppins",
		letterSpacing: "0.04em",
		color: "#4A4A68",
		textTransform: "capitalize",

		fontSize: "15px",
		fontWeight: "600",
		lineHeight: "160%"
	},
	inactiveLabel: {
		color: "#8C8CA1"
	},

	labelContentLeft: {
		"& .MuiButton-label": {
			justifyContent: "left"
		}
	},
	labelContentRight: {
		"& .MuiButton-label": {
			justifyContent: "right"
		}
	},
	// primaryLight: {
	// 	backgroundColor: theme.palette.primary.light,
	// 	color: theme.palette.primary.main,
	// 	"&:hover": {
	// 		backgroundColor: "#acd1fa !important"
	// 	}
	// },
	// warning: {
	// 	backgroundColor: theme.palette.warning.main,
	// 	color: "#fff",
	// 	"&:hover": {
	// 		backgroundColor: "#f4a623 !important"
	// 	}
	// },
	// warningLight: {
	// 	backgroundColor: theme.palette.warning.light,
	// 	color: theme.palette.warning.main,
	// 	"&:hover": {
	// 		backgroundColor: "#f8cd82 !important"
	// 	}
	// },
	// error: {
	// 	backgroundColor: theme.palette.error.main,
	// 	color: "#FFF",
	// 	"&:hover": {
	// 		backgroundColor: "#c34415 !important"
	// 	}
	// },
	// errorLight: {
	// 	backgroundColor: theme.palette.error.light,
	// 	color: "#E65019",
	// 	"&:hover": {
	// 		backgroundColor: "#f5c6b3 !important"
	// 	}
	// },
	// successLight: {
	// 	backgroundColor: theme.palette.success.light,
	// 	color: theme.palette.success.main,
	// 	"&:hover": {
	// 		backgroundColor: "#77db72 !important"
	// 	}
	// },
	// greyLight: {
	// 	backgroundColor: theme.palette.grey.light,
	// 	color: theme.palette.grey.dark,
	// 	"&:hover": {
	// 		backgroundColor: theme.palette.grey.main
	// 	}
	// },

	iconColorGrey: {
		"& .MuiButton-startIcon > svg": {
			fill: "#8C8CA1"
		}
	},
	activeTabButton: {
		// filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))"
		boxShadow: "0px 10px 5px 2px rgba(0,0,0,0.1)"
	}
}))

export function TabButton({ text, className, color, contentAlignment, activeTab, ...props }) {
	const classes = useStyles()

	return (
		<MUIButton
			// color={
			// 	color !== "primaryLight" &&
			// 	color !== "successLight" &&
			// 	color !== "error" &&
			// 	color !== "errorLight" &&
			// 	color !== "warning" &&
			// 	color !== "warningLight" &&
			// 	color !== "greyLight"
			// 		? color
			// 		: "default"
			// }
			className={clsx(
				classes.button,
				contentAlignment === "left" && classes.labelContentLeft,
				contentAlignment === "right" && classes.labelContentRight,
				// To be able to receive extra classes
				className,
				// color === "primaryLight" && classes.primaryLight,
				// color === "successLight" && classes.successLight,
				// color === "error" && classes.error,
				// color === "errorLight" && classes.errorLight,
				// color === "warning" && classes.warning,
				// color === "warningLight" && classes.warningLight,
				// color === "greyLight" && classes.greyLight,

				!activeTab && classes.iconColorGrey,
				activeTab && classes.activeTabButton
			)}
			{...props}
		>
			{props.children}
			{/* With this I can make sure that my label css gets to the buttons inside a ButtonGroup */}
			{/* {props.startIcon ? "" : <span className={classes.label}>{text}</span>} */}
			{text &&
				(props.startIcon ? (
					<span
						style={{ marginLeft: "10px" }}
						className={clsx(classes.label, !activeTab && classes.inactiveLabel)}
					>
						{text}
					</span>
				) : props.endIcon ? (
					<span
						style={{ marginRight: "10px" }}
						className={clsx(classes.label, !activeTab && classes.inactiveLabel)}
					>
						{text}
					</span>
				) : (
					<span className={clsx(classes.label, !activeTab && classes.inactiveLabel)}>
						{text}
					</span>
				))}
		</MUIButton>
	)
}

import React, { useEffect, useState } from "react"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { GrFilter } from "react-icons/gr"
import { Button } from "../../../../_partials/Button"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography"

const ModelsInventoryFilter = ({
	showFilter,
	setShowFilter,
	filterTypeOptions,
	setFilterTypeOptions,
	filterVendorOptions,
	setFilterVendorOptions,
	filterFeaturesOptions,
	setFilterFeaturesOptions,
	filterNodeTypeOptions,
	setFilterNodeTypeOptions,
	filterChipsetOptions,
	setFilterChipsetOptions,
	filterBatteryOptions,
	setFilterBatteryOptions
}) => {
	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [allSelected, setAllSelected] = useState(false)

	// dynamicSelectedIconTypes Roles
	const dynamicSelectedIconTypes = index => {
		return filterTypeOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconFeatures = index => {
		return filterFeaturesOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconVendor = index => {
		return filterVendorOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconNodeTypes = index => {
		return filterNodeTypeOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconChipset = index => {
		return filterChipsetOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconBattery = index => {
		return filterBatteryOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}

	// ━━━━ Toggle button icons according to the state
	const toggleButtonTypes = index => {
		setFilterTypeOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonFeatures = index => {
		setFilterFeaturesOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonVendor = index => {
		setFilterVendorOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonNodeTypes = index => {
		setFilterNodeTypeOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonChipset = index => {
		setFilterChipsetOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonBattery = index => {
		setFilterBatteryOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}

	// ━━━━ Select all filterTypeOptions button
	const selectAllOptions = () => {
		setFilterTypeOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterFeaturesOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterVendorOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterNodeTypeOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterChipsetOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterBatteryOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
	}

	// ━━━━ PerfectScrollbar
	useEffect(() => {
		const containerTypes = document.getElementById("scroll-container-drawer-types-buttons")

		if (containerTypes) {
			const ps = new PerfectScrollbar(containerTypes, {
				wheelSpeed: 1,
				wheelPropagation: true,
				suppressScrollX: true
			})
			// ps.update()
		}
	}, [])

	useEffect(() => {
		const allSelect =
			filterTypeOptions.every(filter => filter.selected) &&
			filterFeaturesOptions.every(filter => filter.selected) &&
			filterVendorOptions.every(filter => filter.selected) &&
			filterNodeTypeOptions.every(filter => filter.selected) &&
			filterChipsetOptions.every(filter => filter.selected) &&
			filterBatteryOptions.every(filter => filter.selected)
		setAllSelected(allSelect)
	}, [
		filterTypeOptions,
		filterFeaturesOptions,
		filterVendorOptions,
		filterNodeTypeOptions,
		filterChipsetOptions,
		filterBatteryOptions
	])

	const checkedIcon = <CheckIcon style={{ color: "#FFFFFF" }} />
	const addIcon = <AddIcon style={{ color: "#8C8CA2" }} />

	const onHide = () => {
		setShowFilter(false)
	}
	return (
		<Modal
			show={showFilter}
			onHide={onHide}
			size="sm"
			data-testid="assignInventoryModal"
			backdrop="static"
		>
			<Modal.Header style={{ padding: "0.5rem 1rem 0 2rem", border: "none" }}>
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div className="col-auto">
						<div className="row align-items-center">
							<GrFilter
								className="drawer_option_title_icon1"
								style={{
									filter:
										"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
								}}
							/>
							<div style={{ marginLeft: "10px" }}>
								{/* <span className="custom-modal-title">Filter</span> */}
								<Bold>Filter</Bold>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<IconButton onClick={onHide} style={{ left: "12px" }} data-testid="close-button">
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "1rem",
					overflow: "hidden"
				}}
			>
				<div>
					{filterBatteryOptions && filterBatteryOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Battery</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterBatteryOptions &&
							filterBatteryOptions.length > 0 &&
							filterBatteryOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconBattery(index)}
												style={{
													background: filterBatteryOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterBatteryOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonBattery(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterBatteryOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconBattery(index + 1)}
													style={{
														background: filterBatteryOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterBatteryOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonBattery(index + 1)
													}}
												>
													<span>{filterBatteryOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterTypeOptions && filterTypeOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Types</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterTypeOptions &&
							filterTypeOptions.length > 0 &&
							filterTypeOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconTypes(index)}
												style={{
													background: filterTypeOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterTypeOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonTypes(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterTypeOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconTypes(index + 1)}
													style={{
														background: filterTypeOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterTypeOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonTypes(index + 1)
													}}
												>
													<span>{filterTypeOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterFeaturesOptions && filterFeaturesOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Status</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterFeaturesOptions &&
							filterFeaturesOptions.length > 0 &&
							filterFeaturesOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconFeatures(index)}
												style={{
													background: filterFeaturesOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterFeaturesOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonFeatures(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterFeaturesOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconFeatures(index + 1)}
													style={{
														background: filterFeaturesOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterFeaturesOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonFeatures(index + 1)
													}}
												>
													<span>{filterFeaturesOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterVendorOptions && filterVendorOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Vendor</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterVendorOptions &&
							filterVendorOptions.length > 0 &&
							filterVendorOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconVendor(index)}
												style={{
													background: filterVendorOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterVendorOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonVendor(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterVendorOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconVendor(index + 1)}
													style={{
														background: filterVendorOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterVendorOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonVendor(index + 1)
													}}
												>
													<span>{filterVendorOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterNodeTypeOptions && filterNodeTypeOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Model</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterNodeTypeOptions &&
							filterNodeTypeOptions.length > 0 &&
							filterNodeTypeOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconNodeTypes(index)}
												style={{
													background: filterNodeTypeOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterNodeTypeOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonNodeTypes(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterNodeTypeOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconNodeTypes(index + 1)}
													style={{
														background: filterNodeTypeOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterNodeTypeOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonNodeTypes(index + 1)
													}}
												>
													<span>{filterNodeTypeOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterChipsetOptions && filterChipsetOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Firmware</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterChipsetOptions &&
							filterChipsetOptions.length > 0 &&
							filterChipsetOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconChipset(index)}
												style={{
													background: filterChipsetOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterChipsetOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonChipset(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterChipsetOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconChipset(index + 1)}
													style={{
														background: filterChipsetOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterChipsetOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonChipset(index + 1)
													}}
												>
													<span>{filterChipsetOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					<div className="d-flex align-items-center justify-content-end mt-5">
						<Button
							className="inventory_options_filter_button_select_all_filter_one "
							style={{
								backgroundColor: "#FFFFFF",
								color: "#3077D3",
								border: "1px solid #3077D3",
								width: !allSelected ? "120px" : "130px",
								height: "31px"
							}}
							variant="contained"
							text={!allSelected ? "Select All" : "Unselect All"}
							onClick={() => {
								selectAllOptions()
							}}
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModelsInventoryFilter

const perfectScrollbarOptions = {
	wheelSpeed: 2,
	wheelPropagation: false
}

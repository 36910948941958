import { collection, doc, getDocs, updateDoc, query, where, or } from "firebase/firestore"
import { firestore } from "../../../../firebase"

function callManagePeopleAndAssets(params) {
	const { operation, dataType, customerId, siteId, data, user } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		operation,
		dataType,
		siteId,
		data,
		user: {
			userId: user.id,
			email: user.email
		}
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	// console.log("🚀 ~ callManagePeopleAndAssets ~ requestOptions:", requestOptions)

	return fetch(
		`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manage-people-and-assets/${customerId}`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

function callManagePeople(params) {
	const { operation, dataType, customerId, siteId, data, user } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		dataType,
		siteId,
		data,
		user: {
			callersUserId: user.id,
			callersTenantId: user.tenantID
		}
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	// console.log("🚀 ~ callManagePeopleAndAssets ~ requestOptions:", requestOptions)

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customer/${customerId}/provisioning/manage-people-and-assets-healthcare/${operation}`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

// export function getPeople(queryParams) {
// 	const customerRef = doc(firestore, "Customers", queryParams.customerId)
// 	const peopleRef = collection(customerRef, "People")
// 	const orderedQuery = query(peopleRef)
// 	return getDocs(orderedQuery)
// }

// export async function getPeopleBySite(queryParams) {
// 	if (!queryParams?.customerId || !queryParams?.siteId) return

// 	const customerRef = doc(firestore, "Customers", queryParams.customerId)
// 	const sitesRef = doc(firestore, "Sites", queryParams.siteId)

// 	const peopleCollection = collection(customerRef, "People")
// 	const nodesCollection = collection(sitesRef, "Nodes")

// 	const nodesArray = []

// 	// Create a query to get all documents from the Nodes collection
// 	const querySnapshot = await getDocs(nodesCollection)

// 	// Iterate through the documents and extract the 'uuid' field
// 	querySnapshot.forEach(doc => {
// 		const data = doc.data()
// 		// Assuming 'uuid' is a field in each document
// 		if (data.uuid) {
// 			nodesArray.push(data.uuid)
// 		}
// 	})

// 	// Create an array to store the results
// 	const results = []

// 	// Split the nodesArray into chunks of 30 UUIDs or fewer
// 	const chunkedArrays = []
// 	while (nodesArray.length > 0) {
// 		chunkedArrays.push(nodesArray.splice(0, 30))
// 	}

// 	// Create a Set to store unique document IDs
// 	const uniqueIds = new Set()

// 	//create an 'OR' codition for null and empty uuid
// 	const orUUID = or(where("uuid", "==", null), where("uuid", "==", ""))

// 	// Iterate through each chunk and create a query
// 	for (const chunk of chunkedArrays) {
// 		const emptyUUIDQuery = query(peopleCollection, orUUID)
// 		const matchingUUIDQuery = query(peopleCollection, where("uuid", "in", chunk))

// 		const emptyUUIDResults = await getDocs(emptyUUIDQuery)
// 		const matchingUUIDResults = await getDocs(matchingUUIDQuery)

// 		// Inside the for loop, add the unique document IDs to the Set
// 		emptyUUIDResults.forEach(resultDoc => {
// 			const id = resultDoc.id
// 			if (!uniqueIds.has(id)) {
// 				const data = resultDoc.data()
// 				// Add the 'id' and 'startDate' fields to each result
// 				results.push({
// 					id: id,
// 					...data,
// 					startDate: data.startDate ? data.startDate.toDate() : null
// 				})
// 				uniqueIds.add(id)
// 			}
// 		})

// 		matchingUUIDResults.forEach(resultDoc => {
// 			const id = resultDoc.id
// 			if (!uniqueIds.has(id)) {
// 				const data = resultDoc.data()
// 				// Add the 'id' and 'startDate' fields to each result
// 				results.push({
// 					id: id,
// 					...data,
// 					startDate: data.startDate ? data.startDate.toDate() : null
// 				})
// 				uniqueIds.add(id)
// 			}
// 		})
// 	}
// 	return results
// }

export function createPerson(queryParams) {
	if (!queryParams?.customerId || !queryParams?.person) return
	console.log("queryParams2", queryParams)
	return callManagePeople({
		operation: "create",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: [queryParams.person],
		user: queryParams.user
	})
}

export function editPerson(queryParams) {
	console.log("🚀 ~ editPerson ~ queryParams:", queryParams)
	if (!queryParams?.customerId || !queryParams?.id || !queryParams?.person || !queryParams?.user)
		return

	return callManagePeople({
		operation: "update",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: [{ docId: queryParams.id, ...queryParams.person }],
		user: queryParams.user
	})
}

export function editPeopleGeofencing(queryParams) {
	if (
		!queryParams?.customerId ||
		!queryParams?.siteId ||
		!queryParams?.peopleToEdit ||
		!queryParams?.user
	)
		return

	// console.log("🚀 ~ editPeopleGeofencing ~ queryParams?.peopleToEdit:", queryParams?.peopleToEdit)

	return callManagePeople({
		operation: "update",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.peopleToEdit,
		user: queryParams.user
	})
}

export function editPeopleUuid(queryParams) {
	if (
		!queryParams?.customerId ||
		!queryParams?.siteId ||
		!queryParams?.peopleToEdit ||
		!queryParams?.user
	)
		return

	return callManagePeople({
		operation: "update",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.peopleToEdit,
		user: queryParams.user
	})
}

export async function uploadPeople(queryParams) {
	if (
		!queryParams?.customerId ||
		!queryParams?.siteId ||
		!queryParams?.people ||
		!queryParams?.user
	)
		return Promise.resolve(null)

	var promises = []

	const customerRef = doc(firestore, "Customers", queryParams.customerId)
	if (queryParams.newVendorsArray || queryParams.newRolesArray) {
		promises.push(
			updateDoc(customerRef, {
				vendors: queryParams.newVendorsArray,
				roles: queryParams.newRolesArray
			})
		)
	}

	return callManagePeople({
		operation: "create",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.people,
		user: queryParams.user
	})
}

export function disablePeople(queryParams) {
	if (
		!queryParams?.customerId ||
		!queryParams?.siteId ||
		!queryParams?.people ||
		!queryParams?.user
	)
		return

	return callManagePeople({
		operation: "disable",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.people,
		user: queryParams.user
	})
}

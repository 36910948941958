import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import Chart from "react-apexcharts"
import { FormControl, makeStyles, Select, MenuItem, InputLabel } from "@material-ui/core"

const useStyles = makeStyles({
	select: {
		backgroundColor: "#ecf1f4",
		"& .MuiSelect-root": {
			minWidth: "10rem"
		},

		"& .MuiSelect-outlined": {
			borderColor: "#ecf1f4",
			"&&&&:hover": {
				borderColor: "#ecf1f4"
			}
		},

		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ecf1f4",
			"&&&&:hover": {
				borderColor: "#ecf1f4"
			}
		}
	}
})

export function AttendanceByAssetType() {
	const classes = useStyles()

	const { isLoading, allAssetsDistribution } = useSelector(
		state => ({
			isLoading: state.reporting.allAssetsDistributionLoading,
			allAssetsDistribution: state.reporting.allAssetsDistribution
		}),
		shallowEqual
	)

	// States
	const [graphData, setGraphData] = useState({
		labels: [],
		series: [],
		colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"],
		barColors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
	})

	const [types, setTypes] = useState("allTypes")
	const [dropdownTypes, setDropdownTypes] = useState([])

	const handleChange = event => {
		// console.log(event.target.value, "event.target.value");
		setTypes(event.target.value)
	}

	// Parsing data and setting to graphData state
	useEffect(() => {
		if (!allAssetsDistribution) {
			setGraphData({
				series: [],
				labels: [],
				colors: [],
				barColors: []
			})
		} else {
			// Set graphData
			allAssetsDistribution &&
				setGraphData({
					series: allAssetsDistribution?.series,
					labels: allAssetsDistribution?.labels,
					colors: allAssetsDistribution?.colors,
					barColors: allAssetsDistribution?.barColors
				})

			// Set all types to state for dropdown
			allAssetsDistribution && setDropdownTypes(allAssetsDistribution?.series)

			const filteredData =
				allAssetsDistribution && allAssetsDistribution?.series.filter(val => val.name === types)

			if (allAssetsDistribution && filteredData[0] && types === filteredData[0]?.name) {
				// Find and pass the bar color equal for each role
				const foundedIndexForColorMatch = allAssetsDistribution?.series.findIndex(
					val => val.name === filteredData[0]?.name
				)

				const individualColorMatched = allAssetsDistribution?.barColors.find(
					(val, i) => i === foundedIndexForColorMatch
				)

				setGraphData({
					series: filteredData,
					labels: allAssetsDistribution.labels,
					colors: allAssetsDistribution.colors,
					barColors: individualColorMatched
						? [individualColorMatched]
						: allAssetsDistribution?.barColors
				})
			}
		}
	}, [allAssetsDistribution, types])

	return (
		<Card isLoading={isLoading}>
			<CardHeader
				title="Attendance by Type"
				subtitle="Total number of assets per day"
				toolbar={
					<>
						<FormControl variant="outlined">
							<InputLabel
								shrink={false} // hides label to border
							></InputLabel>
							<Select
								id="attendance_by_type_chart_types_dropdown"
								className={classes.select}
								value={types}
								onChange={handleChange}
								inputProps={{
									classes: {
										root: classes.root
									}
								}}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
							>
								<MenuItem value="allTypes">All Types</MenuItem>
								{dropdownTypes &&
									dropdownTypes.map((data, i) => {
										return (
											<MenuItem value={data.name} key={i}>
												{data.name}
											</MenuItem>
										)
									})}
							</Select>
						</FormControl>
					</>
				}
			/>
			<CardBody style={{ height: "380px" }}>
				<Chart
					options={graphData && getChartOptions(graphData)}
					series={graphData?.series || []}
					type="bar"
					height="100%"
				/>
			</CardBody>
		</Card>
	)
}

function getChartOptions(graphData) {
	return {
		chart: {
			type: "bar",
			stacked: false,
			toolbar: {
				tools: {
					download: true,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				},
				export: {
					csv: {
						filename: "AttendanceTrackingByType",
						columnDelimiter: ",",
						headerCategory: "Date",
						headerValue: "Value",
						dateFormatter(timestamp) {
							return new Date(timestamp).toDateString()
						}
					},
					svg: {
						filename: "AttendanceTrackingByType"
					},
					png: {
						filename: "AttendanceTrackingByType"
					}
				}
			},
			zoom: {
				enabled: false
			}
		},
		noData: {
			text: "No data to display."
		},
		colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"],
		// colors:  graphData?.barColors || []
		fill: {
			colors: graphData?.barColors || []
		},
		// colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"],
		plotOptions: {
			bar: {
				columnWidth: "55%",
				endingShape: "rounded"
			}
		},
		stroke: {
			width: 0
		},
		dataLabels: {
			enabled: false
			// ↓ enabledOnSeries: shows only the line chart series total
			//   enabledOnSeries: mockDataTracking && [mockDataTracking.series.length - 1],
			//   style: {
			//     colors: ["#546E7A"],
			//   },
		},

		// labels: occupancy?.labels || [],
		labels: graphData?.labels || [],
		xaxis: {
			//Default xaxis so it doesnt break when new options arrive
			tickPlacement: "between",
			labels: {
				show: true,
				// sets colors of the xAxis (if saturdy or sunday it changes colors)
				style: {
					colors: graphData?.colors || []
				}
			}
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			markers: {
				fillColors: graphData?.barColors || []
				// useSeriesColors: true,
			}
		},
		tooltip: {
			enabled: true,
			y: {
				formatter: val => val.toFixed(0)
			}
		},
		// responsive: [
		//   {
		//     breakpoint: 480,
		//   },
		// ],
		yaxis: {
			title: {
				text: "Asset Count"
			}
		}
	}
}

import React from "react"
import { Route } from "react-router-dom"
import { UIProvider } from "./UIContext"
import InventoryMainCard from "./InventoryMainCard"
import EditInventoryModal from "./EditInventory/EditInventoryModal"
import UnassignInventoryModal from "./UnassignInventory/UnassignInventoryModal"
import AssignInventoryModal from "./AssignInventory/AssignInventoryModal"
import PrintNodeLabel from "./PrintLabel/PrintNodeLabel"
import EditInventoryModelsModal from "./ModelsInventory/EditInventoryModelsModal"
import DeleteInventoryModelsModal from "./ModelsInventory/DeleteInventoryModelsModal"
import DeleteInventoryFirmwaresModal from "./FirmwareInventory/DeleteInventoryFirmwaresModal"
import DeleteNodes from "./DeleteNodes/DeleteNodes"

const mainRoute = "/operations/inventory"

export const InventoryPage = ({ history }) => {
	const handleLocationChange = () => {
		if (!history?.location?.pathname) return

		const path = history?.location?.pathname
		const headerControls = document.querySelector("#kt_header > div > div:nth-child(2)")
		if (path.includes("inventory")) {
			if (headerControls) {
				headerControls.style.pointerEvents = "none"
				headerControls.style.opacity = "0.5"
			}
		} else {
			// Reset styles if the path does not include "inventory"
			if (headerControls) {
				headerControls.style.pointerEvents = ""
				headerControls.style.opacity = ""
			}
		}
	}

	React.useEffect(() => {
		// Call the function once initially
		handleLocationChange()

		// Listen for location changes
		const unlisten = history && history.listen(handleLocationChange)

		// Clean up function
		return () => {
			unlisten && unlisten()
		}
	}, [history])

	// ━━━━━━━━━ UIEvents  ━━━━━━━━━
	const UIEvents = {
		openEditInventoryNodesModal: () => {
			history.push(`${mainRoute}/editInventoryNodes`)
		},
		openUnassignInventoryNodesModal: () => {
			history.push(`${mainRoute}/openUnassignInventoryNodes`)
		},
		openDeleteInventoryNodesModal: () => {
			history.push(`${mainRoute}/openDeleteInventoryNodesModal`)
		},
		openAssignInventoryNodesModal: () => {
			history.push(`${mainRoute}/openAssignInventoryNodes`)
		},
		openPrintNodeLabelModal: () => {
			history.push(`${mainRoute}/openPrintNodeLabelModal`)
		},
		openEditInventoryModelsModal: () => {
			history.push(`${mainRoute}/openEditInventoryModelsModal`)
		},
		openDeleteInventoryModelsModal: () => {
			history.push(`${mainRoute}/openDeleteInventoryModelsModal`)
		},
		openDeleteInventoryFirmwaresModal: () => {
			history.push(`${mainRoute}/openDeleteInventoryFirmwaresModal`)
		}
	}

	return (
		<UIProvider UIEvents={UIEvents}>
			<>
				<Route path={`${mainRoute}/editInventoryNodes`} data-testid="editInventoryModal">
					{({ history, match }) => (
						<EditInventoryModal
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>

				<Route path={`${mainRoute}/openUnassignInventoryNodes`}>
					{({ history, match }) => (
						<UnassignInventoryModal
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>

				<Route path={`${mainRoute}/openDeleteInventoryNodesModal`}>
					{({ history, match }) => (
						<DeleteNodes
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>

				<Route path={`${mainRoute}/openAssignInventoryNodes`}>
					{({ history, match }) => (
						<AssignInventoryModal
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>

				<Route path={`${mainRoute}/openPrintNodeLabelModal`}>
					{({ history, match }) => (
						<PrintNodeLabel
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>

				<Route path={`${mainRoute}/openEditInventoryModelsModal`}>
					{({ history, match }) => (
						<EditInventoryModelsModal
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>

				<Route path={`${mainRoute}/openDeleteInventoryModelsModal`}>
					{({ history, match }) => (
						<DeleteInventoryModelsModal
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>

				<Route path={`${mainRoute}/openDeleteInventoryFirmwaresModal`}>
					{({ history, match }) => (
						<DeleteInventoryFirmwaresModal
							show={match != null}
							onHide={() => {
								history.push(mainRoute)
							}}
						/>
					)}
				</Route>
				<InventoryMainCard />
			</>
		</UIProvider>
	)
}

import React from "react"

import FilterPeople from "./FilterPeople"
import PreviewPeople from "./PreviewPeople"

const SelectPeopleTab = ({ handleChange, allGroups, allPeople }) => {
	return (
		<div className="row pl-4">
			<div
				className="col"
				style={{ borderRight: "1px solid #E2E6EA", padding: "0", background: "#FFFFFF" }}
			>
				<FilterPeople handleChange={handleChange} allGroups={allGroups} allPeople={allPeople} />
			</div>
			<div className="col" style={{ background: "#FFFFFF" }}>
				<PreviewPeople />
			</div>
		</div>
	)
}

export default SelectPeopleTab

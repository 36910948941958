import React, { forwardRef, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/managePeople/managePeopleActions"
import { Input } from "../../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { SelectMultiCheckbox } from "../../../../_partials/inputs/SelectMultiCheckbox"
import { Button } from "../../../../_partials/Button"
import { AutocompleteWithLazyLoading } from "../../../Admin/manage-customer/Assets/manage-assets/AutocompleteWithLazyLoading"
import DatePicker from "react-datepicker"
import { InputAdornment, TextField, makeStyles } from "@material-ui/core"
import { MdOutlineCalendarToday } from "react-icons/md"
import { checkIfTagsAreAssigned } from "../../../_redux/_helpers/ActionHelpers"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Collapse, IconButton } from "@material-ui/core/"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
// import "../ManagePeople.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import ImageUploading from "react-images-uploading"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { fetchDownloadURL } from "../../../_redux/managePeople/managePeopleHelpers"
import { uuidv4 } from "../../UIHelpers"
import PatientForm from "./PatientForm"
const phoneNumberRegex = /^\+\d+\s\d+$/

const EditSchema = Yup.object().shape({
	// name: Yup.string().trim().required("Name is required").min(1, "Name must not be empty"),
	firstName: Yup.string()
		.matches(/^[^\d]*$/, "First name cannot contain numbers") // Disallow numbers
		.trim()
		.required("Please insert First Name")
		.min(2, "Please insert more than 1 letter"),
	lastName: Yup.string()
		.matches(/^[^\d]*$/, "Last name cannot contain numbers") // Disallow numbers
		.trim()
		.required("Please insert Last Name")
		.min(2, "Please insert more than 1 letter"),
	company: Yup.string().nullable().required("Company is required"),
	role: Yup.string().nullable().required("Role is required"),
	email: Yup.string().email().nullable(),
	phoneNumber: Yup.string()
		//.matches(phoneNumberRegex, "Invalid phone number format. Example: +1 5551234567")
		.nullable(),
	/* phone: Yup.string()
		.test("phones", "Invalid phone number", value => {
			if (!value) {
				return true
			}
			if (isMobilePhone(value)) {
				return true
			}
			return
		})
		.nullable(), */
	idCard: Yup.string().nullable().trim().min(1, "Id Number must not be empty"),
	passport: Yup.string().nullable().trim().min(1, "Passport must not be empty"),
	driverLicense: Yup.string().nullable().trim().min(1, "Drivers License must not be empty"),
	helmetId: Yup.string()
		.nullable()
		.trim()
		.matches(/^[0-9]+$/, "Helmet ID must contain only numbers"),
	emergencyName: Yup.string().nullable().trim().min(1, "Emergency Name must not be empty"),
	emergencyNumber: Yup.string()
		.matches(phoneNumberRegex, "Invalid emergency phone number format. Example: +1 5551234567")
		.nullable(),
	supervisorName: Yup.string().nullable().trim().min(1, "Supervisor Name must not be empty"),
	supervisorNumber: Yup.string()
		.matches(phoneNumberRegex, "Invalid supervisor phone number format. Example: +1 5551234567")
		.nullable()
})

export function PersonDetailedPageForm({ onHide, initialValues, personId, personToEdit }) {
	const dispatch = useDispatch()
	const classes = useStyles()

	// Selectors
	const {
		isLoading,
		customer,
		customerId,
		siteId,
		roles,
		areas,
		vendors,
		user,
		floorPlans,
		isSuper,
		people
	} = useSelector(
		state => ({
			isLoading: state.basePage?.actionsLoading,
			customer: state.profile?.currentCustomer,
			customerId: state.profile?.currentCustomer?.id,
			siteId: state.profile?.currentSite?.id,
			roles: state.profile?.currentCustomer?.roles || [],
			areas: state.basePage?.areas
				? state.basePage?.areas.filter(val => val.geoFencing === true)
				: [],
			floorPlans: state.basePage?.floorPlans,
			vendors: state.profile?.currentCustomer?.vendors || [],
			user: state.auth?.user,
			isSuper: state.auth?.claims?.isSuper,
			people: state.basePage?.people || []
		}),
		shallowEqual
	)

	// States
	const [selectedCompany, setSelectedCompany] = useState(initialValues.company || "")
	const [selectedRole, setSelectedRole] = useState(initialValues.role || "")
	const [selectedNationality, setSelectedNationality] = useState(initialValues.nationality || "")
	const [generalInfoOpen, setGeneralInfoOpen] = useState(true)
	const [documentationInfoOpen, setDocumentationInfoOpen] = useState(true)
	const [onboardingOpen, setOnboardingOpen] = useState(false)
	const [otherContacts, setOtherContacts] = useState(false)
	const [geofencingSelectedAll, setGeofencingSelectedAll] = useState()
	const [groupedGeofencingOptions, setGroupedGeofencingOptions] = useState()
	const [countryCode, setCountryCode] = useState("")
	const [personImageFileName, setPersonImageFileName] = useState(null)
	const [newImage, setNewImage] = useState(null)

	useEffect(() => {
		if (!areas || !floorPlans) return

		// Create options array based on the association
		const groupedOptions = floorPlans.map(floorplan => {
			const associatedAreas = areas.filter(area => area.floorPlanId === floorplan.id)

			return {
				floorPlanId: floorplan.id,
				categoryName: floorplan.name, // Use floorplan name as the category name
				items: associatedAreas.map(area => ({
					value: area.id,
					name: area.name
				}))
			}
		})

		setGroupedGeofencingOptions(groupedOptions)
	}, [])

	// Set uploaded image to state to be displayed on edit person
	useEffect(() => {
		if (personToEdit && personToEdit?.pictureUrl && personToEdit?.personalData.picture) {
			setNewImage(personToEdit?.pictureUrl)

			const splitStr = personToEdit?.personalData.picture.split("PeopleImages/")
			const nameOfPicInStorage = splitStr && splitStr[1]
			nameOfPicInStorage && setPersonImageFileName(nameOfPicInStorage) // if there's an image uploaded keep the file name to allow to delete from storage
		}
		if (personToEdit && !personToEdit?.pictureUrl && personToEdit?.personalData.picture) {
			// If !personToEdit?.pictureUrl fetch the url.
			fetchDownloadURL(personToEdit?.personalData.picture).then(newUrl => {
				setNewImage(newUrl)

				const splitStr = personToEdit?.personalData.picture.split("PeopleImages/")
				const nameOfPicInStorage = splitStr && splitStr[1]
				nameOfPicInStorage && setPersonImageFileName(nameOfPicInStorage) // if there's an image uploaded keep the file name to allow to delete from storage
			})
		}
	}, [personToEdit])

	const handleGetPictureName = path => {
		if (typeof path !== "string") {
			return null // Return null if path is not a string
		}

		const splitStr = path.split("PeopleImages/")
		const nameOfPicInStorage = splitStr[1]
		return nameOfPicInStorage
	}

	// Submit handler to save form
	const onSubmit = async values => {
		console.log("site", siteId)
		const vendorFounded = vendors && vendors.find(v => v.company === values.company)
		let uuid = null

		if (values?.uuid) {
			if (initialValues?.uuid === values?.uuid) {
				uuid = values?.uuid
			} else {
				const tagsChecked = await checkIfTagsAreAssigned({ customerId, tags: values.uuid })
				if (tagsChecked.some(val => val.uuid === values?.uuid && val?.assigned)) {
					console.error("Error: Tag as been assigned by someone else.")
					dispatch({
						type: "SNACKBAR_ERROR",
						payload: "Error creating Person, please try again!"
					})
					onHide()
					return
				}
			}
			uuid = values?.uuid
		}

		// Input phone number and state code
		const phoneNumber = values.phoneNumber
		const stateCode = countryCode

		let formattedPhoneNumber

		// Check if the string starts with the state code
		if (stateCode && stateCode.length > 0 && phoneNumber.startsWith(stateCode)) {
			const phoneNumberWithoutCode = phoneNumber.slice(stateCode.length)

			formattedPhoneNumber = "+" + Number(stateCode) + " " + Number(phoneNumberWithoutCode)
		} else {
			formattedPhoneNumber = null
		}

		const randomNumberUnique = uuidv4() // create unique id for picture upload

		if (values.picture?.file) {
			var pictureToUpload = values.picture
			const name = values.picture.file?.name
			var nameWithId = name + "_" + randomNumberUnique
			var imagePath = `Customers/${customerId}/PeopleImages/${nameWithId}`
		}

		const deleteOldPic =
			handleGetPictureName(values.picture) === personImageFileName
				? false
				: nameWithId !== personImageFileName
				? true
				: !nameWithId
				? true
				: false // if user wants to upload new pic, make sure to delete old pic

		if (personId) {
			// Note: Improvement to be done if we have time. We get a Firebase get() error when uploading a new image because, the basePage.js has a function to get the image path and get the image url and add that url with the prop pictureUrl. Because the backend function is receiving and writing this path on firestore doc, and only after the response is success we upload to storage the error is because the image doesn't exist yet on storage. The way to build this correctly with more time is to upload the image before writing the path with the backend function. If response not successfull, delete uploaded image.
			dispatch(
				actions.editPerson({
					...values,
					id: personId,
					customerId,
					siteId,
					uuid: uuid,
					user,
					companyId: vendorFounded ? vendorFounded.id : null,
					company: vendorFounded ? vendorFounded?.company : null,
					phoneNumber: formattedPhoneNumber ? formattedPhoneNumber : values.phoneNumber,
					name: `${values.firstName} ${values.lastName}`,
					picture: imagePath
						? imagePath
						: personToEdit?.personalData?.picture && !personImageFileName
						? personToEdit?.personalData.picture
						: personToEdit?.personalData?.picture && !deleteOldPic
						? personToEdit?.personalData.picture
						: personToEdit?.personalData?.picture && !deleteOldPic && !values.picture
						? null
						: personToEdit?.personalData?.picture && deleteOldPic && values.picture
						? personToEdit?.personalData.picture
						: null,
					pictureToUpload: pictureToUpload || null,
					randomNumberUnique: pictureToUpload ? randomNumberUnique : null,
					personImageFileName: !pictureToUpload
						? personImageFileName
						: deleteOldPic
						? personImageFileName
						: null,
					deleteOldPic: deleteOldPic
				})
			)
				.then(() => {
					onHide() // close modal
				})

				.catch(() => {
					dispatch({
						type: "SNACKBAR_ERROR",
						payload: "Error editing Person, please try again!"
					})
				})
			return
		}
	}

	const header = (title, isOpen, setOpen) => (
		<div className="d-flex align-items-center">
			<div className="manage_header_spacing">
				<IconButton
					size="small"
					onClick={() => {
						setOpen(!isOpen)
					}}
				>
					{isOpen ? (
						<ExpandLessIcon fontSize="large" style={{ color: "#4A4A68" }} />
					) : (
						<ExpandMoreIcon fontSize="large" style={{ color: "#4A4A68" }} />
					)}
				</IconButton>
			</div>
			<span>{title}</span>
		</div>
	)

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={EditSchema}
			onSubmit={values => {
				onSubmit(values)
			}}
		>
			{({ dirty, values, setFieldValue, handleSubmit }) => {
				{
					/* const person = people.find(p => p.id === personId)
				console.log("🚀  person:", person) */
				}

				const picture = values.picture

				if (
					values.geofencing &&
					Array.isArray(values.geofencing) &&
					// values.geofencing.length === 1 &&
					values.geofencing.some(val => val === "all")
				) {
					const allValues = areas.map(val => val.id)
					setFieldValue("geofencing", allValues)
				}
				if (
					values.geofencing &&
					Array.isArray(values.geofencing) &&
					values.geofencing.some(val => val === "none")
				) {
					setFieldValue("geofencing", [])
				}

				if (values.email && values.email.includes(" ")) {
					setFieldValue("email", values.email.replace(" ", ""))
				}

				const onImageChange = imageList => {
					if (!imageList || !imageList?.[0]) return

					setNewImage(imageList?.[0]?.data_url)
					setFieldValue("picture", imageList[0])
				}

				const getPicture = () => {
					if (!newImage) {
						return `url(${toAbsoluteUrl("/svg/communication/person-blue.svg")}`
					}
					if (newImage && !values.picture) {
						return `url(${toAbsoluteUrl("/svg/communication/person-blue.svg")}`
					}

					return `url(${newImage})`
				}

				return (
					<PatientForm
						header={header}
						generalInfoOpen={generalInfoOpen}
						setGeneralInfoOpen={setGeneralInfoOpen}
						classes={classes}
						onImageChange={onImageChange}
						getPicture={getPicture}
						picture={picture}
						values={values}
						isLoading={isLoading}
						newImage={newImage}
						isSuper={isSuper}
						personId={personId}
						setCountryCode={setCountryCode}
						handleSubmit={handleSubmit}
						dirty={dirty}
						documentationInfoOpen={documentationInfoOpen}
						setDocumentationInfoOpen={setDocumentationInfoOpen}
						nationalities={nationalities}
						selectedNationality={selectedNationality}
						setSelectedNationality={setSelectedNationality}
						setFieldValue={setFieldValue}
						onboardingOpen={onboardingOpen}
						setOnboardingOpen={setOnboardingOpen}
						vendors={vendors}
						selectedCompany={selectedCompany}
						setSelectedCompany={setSelectedCompany}
						initialValues={initialValues}
						roles={roles}
						selectedRole={selectedRole}
						setSelectedRole={setSelectedRole}
						areas={areas}
						otherContacts={otherContacts}
						setOtherContacts={setOtherContacts}
						setNewImage={setNewImage}
					/>
				)
			}}
		</Formik>
	)
}

const useStyles = makeStyles(() => ({
	datePicker: {
		// marginTop: "2px",
		// backgroundColor: "#FFFFFF",
		background: "#F2F2F2",
		borderRadius: "5px",
		margin: 0,
		padding: "0 12px",
		height: "44px",
		"&:hover": {
			cursor: "pointer",
			border: "1px solid grey"
		},
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "38px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		}
	},
	datePicker2: {
		// marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		padding: "0 12px",
		height: "44px",
		"&:hover": {
			cursor: "pointer",
			border: "1px solid grey"
		},
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "44px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		}
	},
	collapse: {
		borderBottom: "1px solid #E2E6EA",
		visibility: "visible",
		"& .MuiCollapse-hidden": {
			visibility: "visible"
		}
	}
}))

export const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
	const classes = useStyles()

	return (
		<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
			<TextField
				className={classes.datePicker}
				fullWidth
				value={value}
				// style={{ backgroundColor: "#F2F2F2" }}
				// style={{ backgroundColor: "#F2F2F2",  }}
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<MdOutlineCalendarToday style={{ fontSize: "1.4rem", color: "#8C8CA1" }} />
						</InputAdornment>
					)
				}}
			/>
		</div>
	)
})
ExampleCustomInput.displayName = "ExampleCustomInput"

export const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => {
	const classes = useStyles()

	return (
		<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
			<TextField
				className={classes.datePicker2}
				fullWidth
				value={value}
				style={{ backgroundColor: "#F2F2F2" }}
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<MdOutlineCalendarToday style={{ fontSize: "1.4rem", color: "#8C8CA1" }} />
						</InputAdornment>
					)
				}}
			/>
		</div>
	)
})
ExampleCustomInput2.displayName = "ExampleCustomInput"

const nationalities = [
	"Afghan",
	"Albanian",
	"Algerian",
	"American",
	"Andorran",
	"Angolan",
	"Antiguans",
	"Argentinean",
	"Armenian",
	"Australian",
	"Austrian",
	"Azerbaijani",
	"Bahamian",
	"Bahraini",
	"Bangladeshi",
	"Barbadian",
	"Barbudans",
	"Batswana",
	"Belarusian",
	"Belgian",
	"Belizean",
	"Beninese",
	"Bhutanese",
	"Bolivian",
	"Bosnian",
	"Brazilian",
	"British",
	"Bruneian",
	"Bulgarian",
	"Burkinabe",
	"Burmese",
	"Burundian",
	"Cambodian",
	"Cameroonian",
	"Canadian",
	"Cape Verdean",
	"Central African",
	"Chadian",
	"Chilean",
	"Chinese",
	"Colombian",
	"Comoran",
	"Congolese",
	"Costa Rican",
	"Croatian",
	"Cuban",
	"Cypriot",
	"Czech",
	"Danish",
	"Djibouti",
	"Dominican",
	"Dutch",
	"East Timorese",
	"Ecuadorean",
	"Egyptian",
	"Emirian",
	"Equatorial Guinean",
	"Eritrean",
	"Estonian",
	"Ethiopian",
	"Fijian",
	"Filipino",
	"Finnish",
	"French",
	"Gabonese",
	"Gambian",
	"Georgian",
	"German",
	"Ghanaian",
	"Greek",
	"Grenadian",
	"Guatemalan",
	"Guinea-Bissauan",
	"Guinean",
	"Guyanese",
	"Haitian",
	"Herzegovinian",
	"Honduran",
	"Hungarian",
	"I-Kiribati",
	"Icelander",
	"Indian",
	"Indonesian",
	"Iranian",
	"Iraqi",
	"Irish",
	"Israeli",
	"Italian",
	"Ivorian",
	"Jamaican",
	"Japanese",
	"Jordanian",
	"Kazakhstani",
	"Kenyan",
	"Kittian and Nevisian",
	"Kuwaiti",
	"Kyrgyz",
	"Laotian",
	"Latvian",
	"Lebanese",
	"Liberian",
	"Libyan",
	"Liechtensteiner",
	"Lithuanian",
	"Luxembourger",
	"Macedonian",
	"Malagasy",
	"Malawian",
	"Malaysian",
	"Maldivan",
	"Malian",
	"Maltese",
	"Marshallese",
	"Mauritanian",
	"Mauritian",
	"Mexican",
	"Micronesian",
	"Moldovan",
	"Monacan",
	"Mongolian",
	"Moroccan",
	"Mosotho",
	"Motswana",
	"Mozambican",
	"Namibian",
	"Nauruan",
	"Nepali",
	"New Zealander",
	"Nicaraguan",
	"Nigerian",
	"Nigerien",
	"North Korean",
	"Northern Irish",
	"Norwegian",
	"Omani",
	"Pakistani",
	"Palauan",
	"Panamanian",
	"Papua New Guinean",
	"Paraguayan",
	"Peruvian",
	"Polish",
	"Portuguese",
	"Qatari",
	"Romanian",
	"Russian",
	"Rwandan",
	"Saint Lucian",
	"Salvadoran",
	"Samoan",
	"San Marinese",
	"Sao Tomean",
	"Saudi",
	"Scottish",
	"Senegalese",
	"Serbian",
	"Seychellois",
	"Sierra Leonean",
	"Singaporean",
	"Slovakian",
	"Slovenian",
	"Solomon Islander",
	"Somali",
	"South African",
	"South Korean",
	"Spanish",
	"Sri Lankan",
	"Sudanese",
	"Surinamer",
	"Swazi",
	"Swedish",
	"Swiss",
	"Syrian",
	"Taiwanese",
	"Tajik",
	"Tanzanian",
	"Thai",
	"Togolese",
	"Tongan",
	"Trinidadian/Tobagonian",
	"Tunisian",
	"Turkish",
	"Tuvaluan",
	"Ugandan",
	"Ukrainian",
	"Uruguayan",
	"Uzbekistani",
	"Venezuelan",
	"Vietnamese",
	"Welsh",
	"Yemenite",
	"Zambian",
	"Zimbabwean"
]

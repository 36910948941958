import gatewayImg from "../../../../assets/dashboard_markers/newMarkers/gateways/gateway.png"
import { percentageToSize, pixelToSize } from "./ActionHelpers"

export function addGatewaysHasLayers(params) {
	const {
		map,
		gateways,
		// showLayersBesidesTags,
		gatewaysSize
	} = params

	const geoJsonArrayGateways = gateways
		? gateways.map(d => {
				return {
					type: "Feature",
					properties: {
						...d
					},
					geometry: {
						type: "Point",
						coordinates: [d?.gpsData.longitude, d?.gpsData.latitude, 0.0]
					}
				}
		  })
		: []

	geoJsonArrayGateways &&
		geoJsonArrayGateways.forEach(feature => {
			// Reset iconToMarker and iconVisible for each feature
			let iconToMarker = gatewayImg
			let imageId = "default-gateway"

			map.loadImage(iconToMarker, (error, img) => {
				// console.log("🚀  iconToMarker:", iconToMarker)
				// console.log("🚀  img:", img)
				if (error) {
					// console.log(`Error loading image ->`, error)
				}
				if (!img || error) return

				// If map already has the image we need to remove so there aren't any re-renders
				const mapHasImage = map.hasImage(imageId)
				// ↓ So don't get error of loading image with same sourceId
				if (mapHasImage) {
					map.removeImage(imageId)
				}

				// add icon image
				map.addImage(imageId, img)
			})

			// Set the iconImage property in the GeoJSON feature
			feature.properties.iconImage = imageId
		})

	// Remove sources/layers if they already exists before adding new source/layers
	if (typeof map.getLayer("gateways-layer") !== "undefined") {
		map.removeLayer("gateways-layer")
	}
	if (typeof map.getSource("gateways-source") !== "undefined") {
		map.removeSource("gateways-source")
		// Note: instead of removing the source, with the setData() method we update the source with the newData and doesn't flicker all the tags when filtering.
		map.getSource("gateways-source").setData({
			type: "FeatureCollection",
			features: geoJsonArrayGateways
		})
	}

	if (!map.getSource("gateways-source")) {
		map.addSource("gateways-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: geoJsonArrayGateways
			}
		})
	}

	// const correspondentOfOneHundredPercent = 0.8
	const newSize = gatewaysSize || gatewaysSize === 0 ? pixelToSize(gatewaysSize) : 0.5

	if (!map.getLayer("gateways-layer")) {
		map.addLayer(
			{
				id: "gateways-layer",
				type: "symbol",
				source: "gateways-source",
				// filter: ["!", ["has", "point_count"]],
				layout: {
					"icon-image": ["get", "iconImage"],
					"icon-size": newSize ? newSize : 0.4,
					// "icon-size": 0.4,
					// "icon-size": 0.8,
					"symbol-sort-key": ["get", "iconImage"],
					"icon-allow-overlap": true, // Allow icons to overlap if needed (this allows markers to overlap and not disappear)
					"icon-ignore-placement": true // Ignore placement for better clustering behavior
					// visibility: showLayersBesidesTags ? "visible" : "none"
				}
			},
			"state-label"
		)
	}
}

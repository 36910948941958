import React, { useEffect } from "react"
import { useUIContext } from "./UIContext"
import { NewGroupModalTabs } from "./NewGroup/NewGroupModalTabs"
import { useSelector, shallowEqual, useDispatch } from "react-redux"

import * as actions from "../_redux/manageGroups/manageGroupsActions"
import * as manageSitesActions from "../_redux/manageSites/manageSitesActions"

export function CommunicationCard({ id, show, onHide }) {
	const dispatch = useDispatch()

	const { currentCustomer, selectedSite, isLoading, allGroups, allPeople, allVendors, user } =
		useSelector(
			state => ({
				currentCustomer: state?.profile?.currentCustomer,
				selectedSite: state?.profile?.currentSite,
				isLoading: state.manageGroups.actionsLoading,
				allGroups: state.manageGroups.allGroups,
				allPeople: state.manageGroups.allPeople,
				allVendors: state.profile?.currentCustomer?.vendors || [],
				user: state.auth?.user
			}),
			shallowEqual
		)

	//━━━━━━━━━━━━━━━ Effects ━━━━━━━━━━━━━━━\\
	useEffect(() => {
		if (!selectedSite || !currentCustomer) {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: `No location selected. Please select a location on top.`
			})
			return
		}

		dispatch(
			manageSitesActions.fetchSites({
				customerId: currentCustomer.id
			})
		)

		dispatch(
			actions.fetchAllGroups({
				customerId: currentCustomer.id,
				userId: user.id
			})
		)

		if (allPeople && allPeople.length > 0) return
		dispatch(
			actions.fetchCustomerPeople({
				customerId: currentCustomer.id
			})
		)
	}, [currentCustomer, selectedSite, user])

	//━━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━ Handlers ━━━━━━━━━━━━━━━\\
	function hide() {
		onHide()
	}

	return (
		<div data-testid="communication-card">
			<NewGroupModalTabs
				onHide={hide}
				allGroups={allGroups}
				allVendors={allVendors}
				allPeople={allPeople}
			/>
		</div>
	)
}

export default CommunicationCard

import React, { useMemo, useState, useEffect } from "react"
import objectPath from "object-path"
import SVG from "react-inlinesvg"
import { SvgIcon } from "@material-ui/core"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { shallowEqual, useSelector } from "react-redux"
import { storage } from "../../../../firebase"
import "./Brand.css"

export function Brand({ handleOpenDrawer, handleHideDrawer, mainDrawerOpened, matches }) {
	const uiService = useHtmlClassService()

	const layoutProps = useMemo(() => {
		return {
			brandClasses: uiService.getClasses("brand", true),
			asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
			headerLogo: uiService.getLogo(),
			headerStickyLogo: uiService.getStickyLogo()
		}
	}, [uiService])

	const { currentCustomer, currentSite } = useSelector(
		state => ({
			currentSite: state.profile?.currentSite,
			currentCustomer: state.profile?.currentCustomer
		}),
		shallowEqual
	)

	const [companyNameValue, setCompanyNameValue] = useState("")
	const [companyImagePath, setCompanyImagePath] = useState("")
	const [companyImageUrl, setCompanyImageUrl] = useState()

	useEffect(() => {
		if (!currentCustomer) return

		// Customer company name
		setCompanyNameValue(currentCustomer?.customerInfo?.name)

		//Customer Current Image
		setCompanyImagePath(currentCustomer?.customerInfo?.logoPath)
	}, [currentCustomer])

	useEffect(() => {
		// Call the fetchDownloadURL function when companyImagePath changes
		if (companyImagePath) {
			fetchDownloadURL(companyImagePath)
				.then(url => {
					setCompanyImageUrl(url)
				})
				.catch(error => {
					// Handle the error here if needed
				})
		}
	}, [companyImagePath, companyImageUrl])

	const storageRef = storage.ref()

	// Define a function to fetch the download URL
	async function fetchDownloadURL(path) {
		try {
			const url = await storage.ref(path).getDownloadURL()
			return url
		} catch (error) {
			console.error(error)
			throw error // Rethrow the error to handle it in the component
		}
	}

	const getUserPic = () => {
		if (!currentCustomer?.[0]?.data_url) {
			// return `url(${companyImageUrl}`
			return `${companyImageUrl}`
		}
		// return `url(${imagesCustomer[0].data_url})`
	}

	const getSitePic = () => {
		if (!currentSite?.siteInfo?.imageUrls?.[0]) {
			// return `url(${companyImageUrl}`
			return `${companyImageUrl}`
		}
		return `${currentSite?.siteInfo?.imageUrls?.[0]}`
	}

	return (
		<>
			{/* begin::Brand */}
			<div
				style={{
					padding: "0 16px",
					marginTop: "1rem",
					alignSelf: !matches && "center",
					flexDirection: !mainDrawerOpened ? "column" : "row"
				}}
				className={`brand ${layoutProps.brandClasses}`}
				id="kt_brand"
			>
				{/* begin::Logo */}
				{/*<Link to="" className="brand-logo">
          <img alt="logo" src={layoutProps.headerLogo}/>
        </Link>*/}

				{matches && mainDrawerOpened && (
					<div className="brand-text">
						{currentSite ? (
							<img style={{ width: "16%" }} src={`${getSitePic()}`} alt="logo" />
						) : companyImageUrl ? (
							<img style={{ width: "16%" }} src={`${getUserPic()}`} alt="logo" />
						) : (
							<span className="svg-icon svg-icon-xl mr-4">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Crowdkeep-logo.svg")} />
							</span>
						)}
						<span
							style={{
								fontSize: "16px",
								fontStyle: "normal",
								fontWeight: "700",
								lineHeight: "160%",
								marginLeft: companyImageUrl && "20px",
								alignContent: companyImageUrl && "center"
							}}
							className="to-hide-text"
						>
							{currentSite
								? currentSite?.siteInfo?.name
								: currentCustomer?.customerInfo?.name}
						</span>
					</div>
				)}
				{/* end::Logo */}
				{layoutProps.asideSelfMinimizeToggle && (
					<>
						{/* begin::Toggle */}
						<button
							className="brand-toggle btn btn-sm px-0"
							id="kt_aside_toggle"
							onClick={() => {
								if (!matches) {
									handleHideDrawer()
									return
								}
								handleOpenDrawer()
							}}
						>
							<span className="svg-icon svg-icon-xl">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}
								/>
							</span>
						</button>
						{!mainDrawerOpened && (
							<div
								style={{
									marginTop: "1rem",
									textAlign: "center"
								}}
							>
								{currentSite ? (
									<img style={{ width: "40%" }} src={`${getSitePic()}`} alt="logo" />
								) : companyImageUrl ? (
									<img style={{ width: "40%" }} src={`${getUserPic()}`} alt="logo" />
								) : (
									<span className="svg-icon svg-icon-xl mr-4">
										<SVG
											src={toAbsoluteUrl("/media/svg/icons/Files/Crowdkeep-logo.svg")}
										/>
									</span>
								)}
							</div>
						)}
						{/* end::Toolbar */}
					</>
				)}
			</div>
			{/* end::Brand */}
		</>
	)
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link, Switch, Redirect } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ContentRoute } from "../../../layout"
import Login from "./Login"
import ForgotPassword from "./ForgotPassword"
import "../../../_assets/sass/pages/login/classic/login-1.scss"
import ckLogo from "../../../assets/logos/crowdkeep branco (high res).png"

export function AuthPage() {
	const today = new Date().getFullYear()
	return (
		<>
			<div className="d-flex flex-column flex-root">
				{/*begin::Login*/}
				<div
					className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
					id="kt_login"
					style={{
						background: "linear-gradient(270deg, #0A1B3D 0%, #0A1B3D 38.02%, #1065EF 100%)"
					}}
				>
					<div
						className="container d-flex flex-column flex-lg-row flex-row-fluid"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							backgroundImage: "url('../media/bg/bg-auth.png')",
							backgroundSize: "72% 100%",
							maxWidth: "100%",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "right"
						}}
					>
						{/*begin::Content*/}
						<div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
							{/*begin::Content header*/}
							{/*<div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
              <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
            </div>*/}
							{/*end::Content header*/}

							{/* begin::Content body */}
							<div className="d-flex flex-column-fluid flex-center mt-40 mt-lg-0">
								<Switch>
									<ContentRoute path="/auth/login" component={Login} />
									<ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
									<Redirect from="/auth" exact={true} to="/auth/login" />
									<Redirect to="/auth/login" />
								</Switch>
							</div>
							{/*end::Content body*/}

							{/* begin:: Desktop footer */}
							<div className="d-none flex-column-auto d-lg-flex justify-content-between">
								<div
									className="opacity-70 font-weight-bold	text-white"
									style={{ position: "fixed", bottom: "2rem", left: "2rem" }}
								>
									&copy; {today.toString()} Crowdkeep
								</div>
							</div>
							{/* end:: Desktop footer */}

							{/* begin::Mobile footer */}
							<div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
								<div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
									&copy; {today.toString()} Crowdkeep
								</div>
							</div>
							{/* end::Mobile footer */}
						</div>
						{/*end::Content*/}
					</div>
					{/*end::Login*/}
				</div>
			</div>
		</>
	)
}

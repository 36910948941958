import moment from "moment"

function filterSSIDs(queryParams) {
	if (!queryParams.peopleType) return []
	let SSIDs = []
	if (queryParams.peopleType.some(val => val.value === "visitors")) {
		SSIDs.push("")
	}
	if (queryParams.peopleType.some(val => val.value === "employees")) {
		queryParams.locations.forEach(l => {
			if (!l.employeeSSID) return
			SSIDs = SSIDs.concat(l.employeeSSID)
		})
	}
	if (queryParams.peopleType.includes("guests")) {
		queryParams.locations.forEach(l => {
			if (!l.guestSSID) return
			SSIDs = SSIDs.concat(l.guestSSID)
		})
	}
	return SSIDs
}

function fetchAPI(props) {
	// console.log("🚀  props:", props)

	const {
		typechart,
		networkId,
		// SSIDs,
		// floors,
		// zones,
		areas,
		dateStart,
		dateEnd,
		url,
		floorPlanId,
		uuid,
		siteId,
		timeZone
	} = props
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")

	// Set dates to correct times and then change those times to match UTC
	const startDate = moment(dateStart).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
	const endDate = moment(dateEnd).endOf("day").format("YYYY-MM-DDTHH:mm:ss")

	const raw = JSON.stringify({
		// ssid: SSIDs,
		// floorPlanId: floors || [],
		// zones: zones || [],
		// dateStart: moment(startDate).format("YYYY-MM-DDT00:00:00+01:00"),
		// dateEnd: moment(endDate).format("YYYY-MM-DDT23:59:59+01:00")
		// dateEnd: moment(endDate).format("YYYY-MM-DD[T23:59:59]"), //TODO: Lembrar o Rui para receber o time no dateEnd e dateStart tb no timesheet API.
		siteId: siteId || "",
		typechart: typechart,
		networkId: networkId,
		timeZone: timeZone,
		// dateStart: moment(dateStart).format("YYYY-MM-DD"),
		// dateEnd: moment(dateEnd).format("YYYY-MM-DD"),
		dateStart: startDate,
		dateEnd: endDate,
		floorPlanId: floorPlanId || "",
		uuid: uuid || "",
		areas: areas || []
	})

	// console.log(raw, "raw")

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}${url}`, requestOptions).catch(
		err => {
			console.log("Error: ", err)
		}
	)
}

function fetchAPIVersion2(props) {
	const {
		networkId,
		areas,
		dateStart,
		dateEnd,
		url,
		floorPlanId,
		uuid,
		siteId,
		timeZone,
		nodeType
	} = props
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")

	// Set dates to correct times and then change those times to match UTC
	const startDate = moment(dateStart).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
	const endDate = moment(dateEnd).endOf("day").format("YYYY-MM-DDTHH:mm:ss")

	// Construct base URL and endpoint
	const baseUrl = process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2
	const endpoint = `/sites/${siteId}/reporting/${url}`

	// Construct query parameters
	const queryParams = new URLSearchParams({
		dateStart: startDate,
		dateEnd: endDate
	})

	if (nodeType) queryParams.append("nodeType", nodeType)
	if (networkId) queryParams.append("networkId", networkId)
	if (timeZone) queryParams.append("timeZone", timeZone)

	const finalUrl = `${baseUrl}${endpoint}?${queryParams.toString()}`

	// console.log("🚀  finalUrl:", finalUrl)

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	return fetch(finalUrl, requestOptions).catch(error =>
		console.error("Error fetching data: ", error)
	)
}

//★━━━━━━━━━━━━━━━━★ Floor ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getAllThePeopleDistribution(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPIVersion2({
		siteId: queryParams.siteId,
		networkId: queryParams.selectedSite.netWirepas,
		timeZone: queryParams.selectedSite.timeZone,
		// typechart: "timesheet",
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		url: `peopleday`,
		nodeType: queryParams?.nodeType
	})
}

export function getAllThePeopleDistributionOld(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPI({
		siteId: queryParams.siteId,
		networkId: queryParams.selectedSite.netWirepas,
		timeZone: queryParams.selectedSite.timeZone,
		typechart: "timesheet",
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		url: `/timesheets/${queryParams.customerId}`
	})
}

export function getAvgTimeSpent(queryParams) {
	if (
		!queryParams.customerId ||
		!queryParams?.startDate ||
		!queryParams?.endDate ||
		!queryParams?.areas ||
		!Array.isArray(queryParams.areas) ||
		queryParams.areas.length === 0
	)
		return Promise.resolve(null)

	const allAreasNames = queryParams.areas.map(val => val.name)

	return fetchAPIVersion2({
		siteId: queryParams.siteId || "",
		networkId: queryParams.selectedSite.netWirepas,
		// typechart: "dailyInfo",
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		// floorPlanId: queryParams.floorPlanId,
		// uuid: queryParams.uuid || [],
		areas: allAreasNames,
		url: `areas/day`,
		nodeType: queryParams?.nodeType
		// url: `/report-areas/${queryParams.customerId}`
	})
}

// export function getAvgTimeSpent(queryParams) {
// 	if (
// 		!queryParams.customerId ||
// 		!queryParams?.startDate ||
// 		!queryParams?.endDate ||
// 		!queryParams?.areas
// 	)
// 		return Promise.resolve(null)

// 	const allAreasNames = queryParams.areas.map(val => val.name)

// 	return fetchAPI({
// 		siteId: queryParams.siteId || "",
// 		networkId: queryParams.selectedSite.netWirepas,
// 		typechart: "dailyInfo",
// 		dateStart: queryParams.startDate,
// 		dateEnd: queryParams.endDate,
// 		// floorPlanId: queryParams.floorPlanId,
// 		// uuid: queryParams.uuid || [],
// 		areas: allAreasNames,
// 		url: `/report-areas/${queryParams.customerId}`
// 	})
// }

export function getPeopleDistributionBuildings(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPIVersion2({
		siteId: queryParams.siteId || "",
		networkId: queryParams.selectedSite.netWirepas,
		// typechart: "dailyInfo",
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		// uuid: queryParams.uuid || [],
		url: `buildings/day`,
		nodeType: queryParams?.nodeType
	})
}

export function getPeopleDistribution(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPIVersion2({
		siteId: queryParams.siteId || "",
		networkId: queryParams.selectedSite.netWirepas,
		// typechart: "dailyInfo",
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		// uuid: queryParams.uuid || [],
		url: `floors/day`,
		nodeType: queryParams?.nodeType
	})
}
// export function getPeopleDistribution(queryParams) {
// 	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
// 		return Promise.resolve(null)

// 	return fetchAPI({
// 		siteId: queryParams.siteId || "",
// 		networkId: queryParams.selectedSite.netWirepas,
// 		typechart: "dailyInfo",
// 		dateStart: queryParams.startDate,
// 		dateEnd: queryParams.endDate,
// 		// uuid: queryParams.uuid || [],
// 		url: `/report-floors/${queryParams.customerId}`
// 	})
// }

export function getFloorPeopleDistribution(queryParams) {
	if (!queryParams?.locations || !queryParams?.date) return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => location.netId)
	const floors = queryParams.floors.map(floor => floor.floorId)

	const SSIDs = filterSSIDs(queryParams)
	if (SSIDs === null || SSIDs.length === 0) {
		return Promise.resolve(null)
	}

	return fetchAPI({
		typechart: "dailyInfo",
		locations,
		SSIDs,
		floors,
		startDate,
		endDate: queryParams.date,
		url: `/bigquery/reporting/floors/${queryParams.customerId}`
	})
}

export function getFloorOccupancy(queryParams) {
	if (!queryParams?.locations || !queryParams?.date) return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => location.netId)
	const floors = queryParams.floors.map(floor => floor.floorId)

	const SSIDs = filterSSIDs(queryParams)
	if (SSIDs === null || SSIDs.length === 0) {
		return Promise.resolve(null)
	}

	return fetchAPI({
		typechart: "dailyInfo",
		locations,
		SSIDs,
		floors,
		startDate,
		endDate: queryParams.date,
		url: `/bigquery/reporting/floors/${queryParams.customerId}`
	})
}

export function getTotalPeople(queryParams) {
	if (!queryParams?.locations || !queryParams?.date) return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => location.netId)
	const floors = queryParams.floors.map(floor => floor.floorId)

	const SSIDs = filterSSIDs(queryParams)
	if (SSIDs === null || SSIDs.length === 0) {
		return Promise.resolve(null)
	}

	return fetchAPI({
		typechart: "globalInfo",
		locations,
		SSIDs,
		floors,
		startDate,
		endDate: queryParams.date,
		url: `/bigquery/reporting/floors/${queryParams.customerId}`
	})
}

export function getFloorAverageOccupancy(queryParams) {
	if (!queryParams?.locations || !queryParams?.date) return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => location.netId)
	const floors = queryParams.floors.map(floor => floor.floorId)

	const SSIDs = filterSSIDs(queryParams)
	if (SSIDs === null || SSIDs.length === 0) {
		return Promise.resolve(null)
	}
	return fetchAPI({
		typechart: "globalHour",
		locations,
		SSIDs,
		floors,
		startDate,
		endDate: queryParams.date,
		url: `/bigquery/reporting/floors/${queryParams.customerId}`
	})
}

//★━━━━━━━━━━━━━━━━★ Zone ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getZoneOccupancy(queryParams) {
	if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
		return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => location.netId)
	const zones = queryParams.zones.map(zone => zone.name)

	const SSIDs = filterSSIDs(queryParams)
	if (SSIDs === null || SSIDs.length === 0) {
		return Promise.resolve(null)
	}

	return fetchAPI({
		typechart: "dailyInfo",
		locations,
		SSIDs,
		zones,
		startDate,
		endDate: queryParams.date,
		url: `/bigquery/reporting/zones/${queryParams.customerId}`
	})
}

export function getZoneAverageOccupancy(queryParams) {
	if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
		return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => location.netId)
	const zones = queryParams.zones.map(zone => zone.name)

	const SSIDs = filterSSIDs(queryParams)
	if (SSIDs === null || SSIDs.length === 0) {
		return Promise.resolve(null)
	}

	return fetchAPI({
		typechart: "hourlyInfo",
		locations,
		SSIDs,
		zones,
		startDate,
		endDate: queryParams.date,
		url: `/bigquery/reporting/zones/${queryParams.customerId}`
	})
}

export function getZonePeopleDistribution(queryParams) {
	if (!queryParams?.locations || !queryParams?.zones || !queryParams?.date)
		return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => location.netId)
	const zones = queryParams.zones.map(zone => zone.name)

	const SSIDs = filterSSIDs(queryParams)
	if (SSIDs === null || SSIDs.length === 0) {
		return Promise.resolve(null)
	}

	return fetchAPI({
		typechart: "dailyInfo",
		locations,
		SSIDs,
		zones,
		startDate,
		endDate: queryParams.date,
		url: `/bigquery/reporting/zones/${queryParams.customerId}`
	})
}

export function getZoneOccupancyForChord(queryParams) {
	if (!queryParams?.locations || !queryParams?.date) return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => {
		return location.netId
	})
	const floors = queryParams.floors.map(floor => {
		return floor.floorId
	})
	const zones = queryParams.zones.map(zone => {
		return zone.name
	})

	const SSIDs = filterSSIDs(queryParams)
	// if (SSIDs === null || SSIDs.length === 0) {
	//   return Promise.resolve(null);
	// }

	return fetchAPI({
		typechart: "chordDependencyDiagram",
		locations,
		floors,
		zones,
		startDate,
		endDate: queryParams.date,
		url: `/reporting/customGraphs/${queryParams.customerId}`
	})
}

export function getZoneOccupancyForSankey(queryParams) {
	if (!queryParams?.locations || !queryParams?.date) return Promise.resolve(null)

	var startDate = moment(queryParams.date)
	if (queryParams.timeframe === "week") startDate.subtract(6, "days")
	if (queryParams.timeframe === "month") startDate.subtract(1, "months")

	const locations = queryParams.locations.map(location => {
		return location.netId
	})
	const floors = queryParams.floors.map(floor => {
		return floor.floorId
	})
	const zones = queryParams.zones.map(zone => {
		return zone.name
	})

	const SSIDs = filterSSIDs(queryParams)
	// if (SSIDs === null || SSIDs.length === 0) {
	//   return Promise.resolve(null);
	// }

	return fetchAPI({
		typechart: "fromTo",
		locations,
		floors,
		zones,
		startDate,
		endDate: queryParams.date,
		url: `/reporting/peopleFlow/${queryParams.customerId}`
	})
}

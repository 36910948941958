import React, { forwardRef, useState, useEffect } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { shallowEqual, useSelector } from "react-redux"
import { sortCaret } from "../../../../../../_metronic/_helpers"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

const CurrentTimesheetDailyToPrintChart = forwardRef((props, ref) => {
	//changed for current page to print
	const { rowsToUse, columnVisibilityModel, currentTablePageInfo } = props

	const { currentCustomer, companies } = useSelector(
		state => ({
			currentCustomer: state.profile?.currentCustomer,
			companies: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	const [tableData, setTableData] = useState([])
	const [dynamicColumns, setDynamicColumns] = useState([])

	//changed for current page to print
	//? dont really need this for timesheeet but it doenst hurt
	useEffect(() => {
		if (!rowsToUse || !currentTablePageInfo) return
		const filteredDisabled = rowsToUse
			.filter(val => !val.disabled)
			.filter(val => val?.assetDetails?.name.trim() !== "")
		setTableData(
			filteredDisabled.slice(
				currentTablePageInfo?.page * currentTablePageInfo?.pageSize,
				(currentTablePageInfo?.page + 1) * currentTablePageInfo?.pageSize
			)
		) //data from current page and page size
	}, [rowsToUse, currentTablePageInfo])

	useEffect(() => {
		if (!columnVisibilityModel) return

		const realDynamicColumns =
			columns &&
			columns.filter(column => {
				// If columnVisibilityModel exists and has a corresponding entry
				if (
					columnVisibilityModel &&
					Object.prototype.hasOwnProperty.call(columnVisibilityModel, column?.dataField)
				) {
					// Include the column if the value is not false
					return columnVisibilityModel[column?.dataField] !== false
				}
				// Include the column if it doesn't exist in columnVisibilityModel
				return true
			})

		realDynamicColumns && realDynamicColumns.length > 0 && setDynamicColumns(realDynamicColumns)
	}, [columnVisibilityModel])

	const columns = [
		{
			dataField: "day",
			text: "Day",
			sort: true,
			sortCaret: sortCaret,
			style: {
				verticalAlign: "middle",
				minWidth: "80px"
			}
		},
		{
			dataField: "worker",
			text: "Worker",
			sort: true,
			sortCaret: sortCaret,
			style: {
				verticalAlign: "middle",
				minWidth: "80px"
			}
		},
		{
			dataField: "role",
			text: "Role",
			style: {
				verticalAlign: "middle",
				minWidth: "80px"
			}
		},
		{
			dataField: "company",
			text: "Company",
			style: {
				verticalAlign: "middle",
				minWidth: "80px"
			}
		},
		{
			dataField: "start",
			text: "Check-in",
			style: {
				verticalAlign: "middle",
				minWidth: "80px"
			}
		},
		{
			dataField: "end",
			text: "Check-out",
			style: {
				verticalAlign: "middle",
				minWidth: "80px"
			}
		},
		{
			dataField: "hours",
			text: "Total Hours",
			style: {
				verticalAlign: "middle",
				minWidth: "80px"
			}
		}
	]

	return (
		<>
			<ToolkitProvider
				search
				wrapperClasses="table-responsive"
				classes="table table-head-custom table-vertical-center"
				bootstrap4
				keyField="id"
				//changed and added for data grid
				data={tableData && tableData.length > 0 ? tableData : []}
				columns={dynamicColumns && dynamicColumns.length > 0 ? dynamicColumns : columns}
				style={{ overflow: "hidden" }}
			>
				{props => (
					<div ref={ref}>
						<div
							className="d-flex justify-content-between align-items-baseline"
							style={{ marginBottom: "2rem" }}
						>
							<div>
								<h3>
									{currentCustomer && currentCustomer?.customerInfo?.name && (
										<span>{currentCustomer?.customerInfo?.name}</span>
									)}
								</h3>
								<h3>Daily contracted workers timesheet</h3>
								<p>Timesheet of contracted workers</p>
							</div>
							<div style={{ width: "10rem", height: "auto", marginRight: "2rem" }}>
								<img
									src="/media/logos/crowdkeep.png"
									alt="company logo"
									style={{ width: "100%", height: "100%", objectFit: "contain" }}
								/>
							</div>
						</div>
						<div style={{ width: "100%", height: "100%" }}>
							<BootstrapTable bordered={false} {...props.baseProps}></BootstrapTable>
						</div>
					</div>
				)}
			</ToolkitProvider>
		</>
	)
})

export default CurrentTimesheetDailyToPrintChart

import React from "react"
import { UIProvider } from "./UIContext"
import CommunicationCard from "./CommunicationCard"
import { SvgIcon } from "@material-ui/core"
import { Button } from "../../_partials/Button"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"

export const CommunicationPage = () => {
	const UIEvents = {}

	return (
		<UIProvider UIEvents={UIEvents}>
			{/* <AuditToolTitle data-testid="audit-tool-title" /> */}
			<div
				className="row align-items-sm-end align-items-center p-0"
				style={{ minHeight: "5rem", marginBottom: "1rem" }}
				data-testid="communication-page"
			>
				<div className="d-flex align-items-center col">
					<CommunicationIcon
						style={{ width: "1.4em", height: "1.4em", marginRight: "10px" }}
					/>

					<h2 style={{ color: "#4A4A68" }}>
						<b>Communication</b>
					</h2>
				</div>
				{/* Communication PHASE 3 - Message History */}
				{/* <div
					className="d-flex align-items-center col"
					style={{
						placeContent: "flex-end"
					}}
				>
					<Button color="primaryLight" startIcon={<EmailOutlinedIcon />} />
				</div> */}
			</div>
			<CommunicationCard data-testid="communication-card" />
		</UIProvider>
	)
}

function CommunicationIcon(props) {
	return (
		<SvgIcon {...props} width="20" height="20">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="40"
				height="41"
				viewBox="0 0 40 41"
				fill="none"
			>
				<g id="license--third-party">
					<path
						d="M11.0875 19.211L10 18.586L12.5 14.211H16.25C16.5815 14.211 16.8995 14.0793 17.1339 13.8449C17.3683 13.6105 17.5 13.2925 17.5 12.961V5.461C17.5 5.12948 17.3683 4.81154 17.1339 4.57712C16.8995 4.34269 16.5815 4.211 16.25 4.211H3.75C3.41848 4.211 3.10054 4.34269 2.86612 4.57712C2.6317 4.81154 2.5 5.12948 2.5 5.461V12.961C2.5 13.2925 2.6317 13.6105 2.86612 13.8449C3.10054 14.0793 3.41848 14.211 3.75 14.211H9.375V15.461H3.75C3.08696 15.461 2.45107 15.1976 1.98223 14.7288C1.51339 14.2599 1.25 13.624 1.25 12.961V5.461C1.25 4.79796 1.51339 4.16207 1.98223 3.69323C2.45107 3.22439 3.08696 2.961 3.75 2.961H16.25C16.913 2.961 17.5489 3.22439 18.0178 3.69323C18.4866 4.16207 18.75 4.79796 18.75 5.461V12.961C18.75 13.624 18.4866 14.2599 18.0178 14.7288C17.5489 15.1976 16.913 15.461 16.25 15.461H13.225L11.0875 19.211ZM15 6.711H5V7.961H15V6.711ZM5 10.461H11.25V11.711H5V10.461Z"
						fill="#2673F0"
					/>
				</g>
			</svg>
		</SvgIcon>
	)
}

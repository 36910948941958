import React, { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton, ListItemIcon } from "@material-ui/core"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../UIContext"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ViewListIcon from "@material-ui/icons/ViewList"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import "../Inventory.css"

const DeleteInventoryModelsModal = ({
	showDeleteModel,
	setShowDeleteModal,
	technology,
	setSelectionModel
}) => {
	const dispatch = useDispatch()

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryModels,
			setIds: UIContext.setIdsInventoryModels
		}
	}, [UIContext])
	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { listLoading, user, modelsInventory } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading,
			user: state.auth.user,
			modelsInventory: state.inventory.modelsInventory
		}),

		shallowEqual
	)

	const [open, setOpen] = useState(false)
	const [saving, setSaving] = useState(false)

	function onHide() {
		// setEditedId(null)
		UIProps.setIds([])
		setSelectionModel([])
		setShowDeleteModal(false)
	}

	const handleClick = () => {
		setOpen(!open)
	}

	const deleteModels = () => {
		if (!UIProps.ids || UIProps.ids.length <= 0 || !user || !user.id) {
			onHide()
			return
		}

		setSaving(true)

		dispatch(
			actions.deleteModels({
				modelId: UIProps.ids,
				callersUserId: user.id,
				technology: technology,
				onHide
			})
		)
			.then(() => {
				setSaving(false)
				onHide()
			})
			.catch(error => {
				console.error("Error saving:", error) // Handle error
				setSaving(false) // Reset saving state on error
			})
	}

	return (
		<Modal
			show={showDeleteModel}
			onHide={onHide}
			backdrop="static"
			data-testid="Models-DeleteInventoryModelsModal"
		>
			<Modal.Header style={{ padding: "0.5rem 1rem 0 2rem", border: "none" }}>
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div className="col-auto">
						<div className="row">
							<div
								className="d-flex justify-content-center align-items-end"
								style={{
									width: "16px",
									height: "16px",
									position: "relative",
									overflow: "hidden",
									backgroundColor: "#ffffff",
									outline: "2px solid #4A4A68",
									borderRadius: "50%",
									margin: "auto",
									marginRight: "10px"
								}}
							>
								<img
									style={{
										margin: "auto",
										width: "36%",
										height: "auto",
										display: "block"
									}}
									alt="areasInfo"
									src={"/media/crowdkeep/information_filled.svg"}
								/>
							</div>
							<div>
								<span className="custom-modal-title">Delete Models</span>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<IconButton
							disabled={saving}
							onClick={onHide}
							style={{ left: "12px" }}
							data-testid="close-button"
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "0",
					overflow: "hidden"
				}}
			>
				{listLoading && <ModalProgressBar variant="query" />}
				<div style={{ padding: "0 2rem 2rem 2rem" }}>
					{/* ━━★  BODY  ★━━ */}
					<div className="mb-4">
						{!listLoading && UIProps.ids && UIProps.ids.length > 0 && (
							<span className="custom-modal-body">
								You are currently deleting{" "}
								<span style={{ fontWeight: "bold" }}>{UIProps?.ids?.length}</span> models.
							</span>
						)}
						{listLoading && <span>Deleting nodes...</span>}
					</div>
					<div className="mb-4">
						{/* com collapse com span com o nome e o uuid */}
						<List
							aria-labelledby="nested-list-subheader"
							// className={classes.root}
						>
							<ListItem button onClick={handleClick}>
								<ListItemIcon>
									<ViewListIcon />
								</ListItemIcon>
								<ListItemText primary="List of models to delete" />
								{open ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse
								in={open}
								timeout="auto"
								unmountOnExit
								style={{
									maxHeight: "190px",
									overflowX: "scroll"
								}}
							>
								<List component="div" disablePadding>
									{modelsInventory &&
										modelsInventory.length > 0 &&
										modelsInventory.map(model => {
											if (
												UIProps.ids &&
												UIProps.ids.length > 0 &&
												UIProps.ids.includes(model?.id)
											) {
												return (
													<ListItem
														key={model?.id}
														button
														style={{ paddingLeft: "4rem", cursor: "default" }}
													>
														<ListItemText
															primary={model?.name}
															secondary={model?.id}
														/>
													</ListItem>
												)
											}
											return null // If the id doesn't match, return null
										})}
								</List>
							</Collapse>
						</List>
					</div>
					<div className="row justify-content-end mt-5">
						<div className="col-2" style={{ padding: "0", marginRight: "10px" }}>
							<Button
								variant="outlined"
								color="primary"
								style={{ width: "100%" }}
								disabled={listLoading || saving}
								onClick={onHide}
								text="Cancel"
								data-testid="cancel-button"
							/>
						</div>
						<div className="col-1" style={{ padding: "0", marginRight: "10px" }}>
							<Button
								id="inventory_unassign_btn"
								variant="contained"
								color="primary"
								style={{ width: "100%" }}
								disabled={listLoading}
								onClick={deleteModels}
								text="Ok"
								data-testid="delete-button"
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default DeleteInventoryModelsModal

import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import Chart from "react-apexcharts"
import { withStyles, Badge, Popover, makeStyles, Typography } from "@material-ui/core"
import { BsInfoCircle } from "react-icons/bs"
import moment from "moment"
import { Link } from "react-router-dom"

export function AverageTimeSpentAssets() {
	const classes = useStyles()

	const { isLoading, areaAvgTimeSpentAssets, areasData } = useSelector(
		state => ({
			isLoading: state.reporting.areaAvgTimeSpentLoadingAssets,
			areaAvgTimeSpentAssets: state.reporting.areaAvgTimeSpentAssets,
			areasData: state.basePage?.areas || []
		}),
		shallowEqual
	)

	// States
	const [graphData, setGraphData] = useState()
	const [noDataMessage, setNoDataMessage] = useState("")

	useEffect(() => {
		if (areasData && areasData.length === 0) {
			setNoDataMessage("No areas created. ")
		} else {
			setNoDataMessage("No data to display.")
		}
	}, [areasData])

	useEffect(() => {
		if (!areaAvgTimeSpentAssets) {
			setGraphData(null)
		} else {
			setGraphData(areaAvgTimeSpentAssets)
		}
	}, [areaAvgTimeSpentAssets])

	const [anchorEl, setAnchorEl] = React.useState(null)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	return (
		<Card isLoading={isLoading} style={{ height: "480px" }}>
			<CardHeader
				title="Total time spent per Area"
				subtitle="Time spent in each area"
				// title={<Title />}
				toolbar={
					graphData && graphData?.series?.[0] ? (
						<div
							style={{
								padding: "0.3rem 1.5rem",
								borderRadius: "0.42rem",
								background: "#ecf1f4"
							}}
						>
							<Typography
								aria-owns={open ? "mouse-over-popover" : undefined}
								aria-haspopup="true"
								onMouseEnter={handlePopoverOpen}
								onMouseLeave={handlePopoverClose}
								style={{
									cursor: "pointer",
									fontFamily: "Poppins",
									fontSize: "13px",
									fontWeight: "500",
									lineHeight: "170%",
									textTransform: "none",
									color: "#9EA2A4"
								}}
							>
								Floors
							</Typography>
						</div>
					) : null
				}
			/>
			<CardBody>
				{/* <Chart
					options={getChartOptions(graphData)}
					series={graphData?.series || []}
					type="treemap"
					height="350"
				/> */}
				{graphData?.series && graphData?.series.length !== 0 ? (
					<Chart
						options={getChartOptions(graphData)}
						series={graphData?.series || []}
						type="treemap"
						height="350"
					/>
				) : (
					<div
						style={{ textAlign: "center" }}
						className="d-flex justify-content-center align-items-center"
					>
						{areasData && areasData.length !== 0 && !graphData && (
							<div className="d-flex align-items-center" style={{ minHeight: "280px" }}>
								<div>
									<p className="mb-0 mt-4" style={{ fontSize: "14px" }}>
										No data to display.
									</p>
								</div>{" "}
							</div>
						)}
						{areasData && areasData.length === 0 && (
							<div className="d-flex align-items-center" style={{ minHeight: "280px" }}>
								<div>
									<p>{noDataMessage}</p>
									<p>
										{" "}
										<Link to="/admin/design-studio">Click here to create.</Link>
									</p>
								</div>{" "}
							</div>
						)}
					</div>
				)}
				<Popover
					id="mouse-over-popover"
					className={classes.popover}
					classes={{
						paper: classes.paper
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<div
						style={{
							display: "flex",
							padding: "1rem"
						}}
					>
						<div
							style={{
								marginRight: "2rem",
								fontWeight: "500"
							}}
						>
							{graphData && graphData?.series?.[0]?.data.map((f, i) => <p key={i}>{f.x}</p>)}
						</div>
						<div style={{ marginRight: "0.5rem", fontWeight: "400" }}>
							{graphData &&
								graphData?.series?.[0]?.data.map((f, i) => (
									<p key={i}>- &nbsp;&nbsp;{f.floorPlanName}</p>
								))}
						</div>
					</div>
				</Popover>
			</CardBody>
		</Card>
	)
}

function getChartOptions(graphData) {
	return {
		chart: {
			offsetY: 0,
			animations: {
				enabled: false
			},
			toolbar: {
				offsetY: -20,
				offsetX: 0,
				tools: {
					download: true,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				},
				export: {
					csv: {
						filename: "TimeSpentInEachArea",
						columnDelimiter: ",",
						headerCategory: "Area",
						headerValue: "Value",
						dateFormatter(timestamp) {
							return new Date(timestamp).toDateString()
						}
					},
					svg: {
						filename: "TimeSpentInEachArea"
					},
					png: {
						filename: "TimeSpentInEachArea"
					}
				}
			}
		},
		legend: {
			show: false
		},
		noData: {
			text: "No data to display."
		},
		colors: ["#3F39A5"],
		// plotOptions: {
		// 	treemap: {
		// 		distributed: true,
		// 		enableShades: false,
		// 		colorScale: {
		// 			ranges: colors
		// 		}
		// 	}
		// },
		dataLabels: {
			enabled: true,
			style: {
				fontSize: "14px"
			}
			// formatter: function (text, op) {
			// 	return [text, op.value]
			// },
			// offsetY: -4
		},
		tooltip: {
			enabled: true,
			followCursor: true,
			inverseOrder: true,
			fixed: {
				enabled: true,
				position: "topRight",
				offsetX: 150,
				offsetY: 0
			},
			y: {
				formatter: val => {
					if (val > 60) {
						const hours = Math.floor(val / 60)
						const minutes = val % 60
						return `${hours}h ${minutes.toString().padStart(2, "0")}m`
					}

					return `${val}m`
				}
			}
			// y: {
			// 	formatter: val =>
			// 		moment.utc().startOf("day").add(val, "minutes").format("HH:mm") + " min"
			// 	// val + " %",
			// }
		}
	}
}

const colors = [
	{
		from: 0,
		to: 25,
		color: "rgba(63, 57, 165, 0.40)",
		text: "up to 5min"
	},
	{
		from: 26,
		to: 50,
		color: "rgba(63, 57, 165, 0.50)",
		text: "up to 15min"
	},
	{
		from: 51,
		to: 100,
		color: "rgba(63, 57, 165, 0.60)",
		text: "up to 30min"
	},
	{
		from: 101,
		to: 200,
		color: "rgba(63, 57, 165, 0.70)",
		text: "up to 1h"
	},
	{
		from: 199,
		to: 300,
		color: "rgba(63, 57, 165, 0.80)",
		text: "up to 4h"
	},
	{
		from: 301,
		to: 450,
		color: "rgba(63, 57, 165, 0.90)",
		text: "up to 8h"
	},
	{
		from: 451,
		to: 650,
		color: "#3F39A5",
		text: "up to 12h"
	},
	{
		from: 651,
		to: 99999999,
		color: "#383393",
		text: "more than 12h"
	}
]

const useStyles = makeStyles(theme => ({
	popover: {
		pointerEvents: "none"
	},
	paper: {
		background: "#F5F5F5",
		padding: theme.spacing(1),
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "12px",
		fontFamily: "Poppins",
		boxShadow: "0px 10px 19px 16px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)"
	}
}))

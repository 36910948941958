import React, { useState, useEffect, useRef, useMemo } from "react"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { useSelector, shallowEqual } from "react-redux"
import { Collapse, TextField, makeStyles } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Button } from "../../../../_partials/Button"
import FirmwaresInventoryTable from "./FirmwaresInventoryTable"
import FirmwaresInventoryFilter from "./FirmwaresInventoryFilter"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import SearchIcon from "@material-ui/icons/Search"
import FilterListIcon from "@material-ui/icons/FilterList"
import AddInventoryFirmwaresModal from "./AddInventoryFirmwaresModal"

import { useUIContext } from "../UIContext"
import moment from "moment"
import * as XLSX from "xlsx"
import { useReactToPrint } from "react-to-print"
import { ExportModal } from "../Export/ExportModal"
import TableToPrint from "../TableToPrint/TableToPrint"
import CurrentTableToPrint from "../TableToPrint/CurrentTableToPrint"

const useStyles = makeStyles(() => ({
	autocomplete: {
		width: "170px"
	},
	select: {
		borderColor: "red",
		height: "36px",
		backgroundColor: "#F2F2F2",
		borderRadius: "5px",

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		},
		"& .MuiInputBase-input": {
			fontWeight: "500"
		}
	}
}))

export const FirmwaresInventoryCard = ({
	firmwaresInventory,
	technology,
	setTechnology,
	handleTechnology,
	technologyList
}) => {
	const classes = useStyles()

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { listLoading } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading
		}),

		shallowEqual
	)
	//* ━━━━━━━━━━━━━━━━━━━━━━━━━ UIContext
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			rowsToUseFirmwaresTable: UIContext.rowsToUseFirmwaresTable,
			columnVisibilityModelFirmwaresTable: UIContext.columnVisibilityModelFirmwaresTable,
			setColumnVisibilityModelFirmwaresTable: UIContext.setColumnVisibilityModelFirmwaresTable,
			currentFirmwaresTablePageInfo: UIContext.currentFirmwaresTablePageInfo
		}
	}, [UIContext])

	// ━━━━━━━━━ useStates  ━━━━━━━━━
	const [showFilter, setShowFilter] = useState(false)
	//export
	const [showExportModal, setShowExportModal] = useState(false)
	const [checked, setChecked] = useState(false)
	const [optionsOfExport, setOptionsOfExport] = useState(null)
	const [columnVisibilityModelBeforePrint, setColumnVisibilityModelBeforePrint] = useState({})
	// const [technologyList, setTechnologyList] = useState(null)
	//for both components have acess to table rows
	const [rows, setRows] = useState([])
	const [searchText, setSearchText] = React.useState("")
	//fill this options with inventory state
	const [filterVersionOptions, setFilterVersionOptions] = useState([])
	const [filterSeverityOptions, setFilterSeverityOptions] = useState([])
	const [filterNameOptions, setFilterNameOptions] = useState([])
	const [filterVendorOptions, setFilterVendorOptions] = useState([])
	const [showNewModelModal, setShowNewModelModal] = useState(false)

	// ━━━━━━━━━ useEffects  ━━━━━━━━━
	useEffect(() => {
		if (!firmwaresInventory || !firmwaresInventory.length > 0 || !technology) return

		//*For Version
		const uniqueVersion = Array.from(
			new Set(
				(firmwaresInventory || [])
					.filter(
						model =>
							model.version !== "" && model.version !== null && model.version !== undefined
					) // Filter out empty strings
					.map(model => model.version)
			)
		)

		const versionOptions = uniqueVersion.map(type => ({ node: type, selected: false }))

		//*For Severity
		const uniqueSeverity = Array.from(
			new Set(
				(firmwaresInventory || [])
					.filter(
						model =>
							model.severity !== "" &&
							model.severity !== null &&
							model.severity !== undefined
					) // Filter out empty strings
					.map(model => model.severity)
			)
		)

		const severityOptions = uniqueSeverity.map(type => ({ node: type, selected: false }))

		//*For Name
		const uniqueName = Array.from(
			new Set(
				(firmwaresInventory || [])
					.filter(
						model => model.name !== "" && model.name !== null && model.name !== undefined
					) // Filter out empty strings
					.map(model => model.name)
			)
		)

		const nameOptions = uniqueName.map(type => ({ node: type, selected: false }))

		//*For Vendor
		const uniqueVendor = Array.from(
			new Set(
				(firmwaresInventory || [])
					.filter(
						model =>
							model.vendor !== "" && model.vendor !== null && model.vendor !== undefined
					) // Filter out empty strings
					.map(model => model.vendor)
			)
		)

		const vendorOptions = uniqueVendor.map(type => ({ node: type, selected: false }))

		setFilterVersionOptions(versionOptions)
		setFilterSeverityOptions(severityOptions)
		setFilterVendorOptions(vendorOptions)
		setFilterNameOptions(nameOptions)
	}, [firmwaresInventory, technology])

	useEffect(() => {
		if (!firmwaresInventory || !technology) return
		if (optionsOfExport === "print_all_pages") {
			handlePrint()
		}
		if (optionsOfExport === "print_current_page") {
			handleCurrentPagePrint()
		}
		if (optionsOfExport === "excel_all_pages") {
			exportToExcel()
		}
		if (optionsOfExport === "excel_current_page") {
			exportToExcel(UIProps.currentFirmwaresTablePageInfo)
		}
	}, [optionsOfExport])
	useEffect(() => {
		setOptionsOfExport(null)
	}, [optionsOfExport])

	//  ━━━━━━━ useRef ━━━━━━━
	const componentRef = useRef()
	const currentPageComponentRef = useRef()

	//  ━━━━━━━ Header Collapse ━━━━━━━

	const header = (
		<div className="d-flex align-items-center">
			<span>Firmwares</span>
		</div>
	)

	//  ━━━━━━━ Handlers ━━━━━━━
	//update the search text state variable.
	const handleSearch = value => {
		setSearchText(value)
	}
	const exportToExcel = (currentFirmwaresTablePageInfo = null) => {
		if (UIProps.rowsToUseFirmwaresTable) {
			const filteredDataAfterSort =
				UIProps.rowsToUseFirmwaresTable &&
				UIProps.rowsToUseFirmwaresTable.map(firmware => ({
					name: firmware.name || "",
					version: firmware.version || "",
					severity: firmware.severity || "",
					vendor: firmware.vendor || "",
					modelId: firmware.modelId || "",
					notes: firmware.notes || ""
				}))

			const filteredDataAfterColumnsChange =
				filteredDataAfterSort &&
				filteredDataAfterSort.map(personData => {
					const filteredData = Object.entries(personData)
						.filter(([key]) => {
							const isVisible = UIProps.columnVisibilityModelFirmwaresTable[key]
							if (isVisible === undefined) {
								console.warn(`Visibility setting for ${key} is undefined.`)
							}
							return isVisible !== false && isVisible !== undefined
						})
						.reduce((acc, [key, value]) => {
							acc[key] = value
							return acc
						}, {})
					return filteredData
				})

			let finalData = []
			if (currentFirmwaresTablePageInfo) {
				// console.log(currentFirmwaresTablePageInfo?.page && currentFirmwaresTablePageInfo?.pageSize)
				finalData =
					filteredDataAfterColumnsChange &&
					filteredDataAfterColumnsChange.slice(
						currentFirmwaresTablePageInfo?.page * currentFirmwaresTablePageInfo?.pageSize,
						(currentFirmwaresTablePageInfo?.page + 1) *
							currentFirmwaresTablePageInfo?.pageSize
					)
			} else {
				finalData = filteredDataAfterColumnsChange && filteredDataAfterColumnsChange
			}

			const workbook = XLSX.utils.book_new()
			const worksheet = XLSX.utils.json_to_sheet(finalData) // send Data to Excel

			// Set column widths manually (in pixels)
			const columnWidths = [
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 }
			]

			const dateToday = moment().format("ddd DD MMM")

			worksheet["!cols"] = columnWidths

			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

			const excelBuffer = XLSX.write(workbook, { type: "buffer" })
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			})
			const url = URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.href = url
			a.download = `Inventory_Firmwares_Table_${dateToday}.xlsx`
			a.click()
			URL.revokeObjectURL(url)
		}
	}
	const getPageMargins = () => {
		return `@page { margin: 2rem 2rem !important; }`
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: () => getPageMargins()
	})
	const handleCurrentPagePrint = useReactToPrint({
		content: () => currentPageComponentRef.current,
		pageStyle: () => getPageMargins()
	})

	return (
		<Card
			data-testid="FirmwaresInventoryCard"
			style={{
				display: "flex",
				flexDirection: "column"
			}}
		>
			<CardHeader
				title={header}
				toolbar={
					<div className="d-flex align-items-center">
						<SearchBarCustom1
							onSearch={handleSearch}
							searchText={searchText}
							disabled={listLoading || !technology ? true : false}
							placeholder="Search"
							size="small"
							style={{
								width: "200px",
								background: "#F2F2F2",
								marginRight: "10px"
							}}
							InputProps={{
								startAdornment: (
									<SearchIcon
										fontSize="small"
										style={{
											color: "#7F8C8D",
											marginRight: "0.7rem"
										}}
									/>
								)
							}}
						/>
						<Autocomplete
							id="audit_tool_users_autocomplete"
							selectOnFocus
							clearOnBlur
							disableClearable
							handleHomeEndKeys
							className={classes.autocomplete}
							options={technologyList || []}
							size="small"
							getOptionLabel={option => {
								return option || ""
							}}
							value={technology}
							onChange={handleTechnology}
							renderInput={params => (
								<TextField
									className={classes.select}
									{...params}
									placeholder="Technology"
									variant="outlined"
								/>
							)}
							popupIcon={<ExpandMoreIcon style={{ color: "#8C8CA2" }} />} // Your custom arrow icon
						/>
						<Button
							id="manage_site_new_building_btn"
							className="ml-2"
							color="primary"
							variant="contained"
							text="FILTER"
							style={{
								height: "34.44px"
							}}
							disabled={listLoading || !technology ? true : false}
							onClick={() => {
								setShowFilter(prev => !prev)
							}}
							endIcon={<FilterListIcon />}
							data-testid="filter"
						/>
						<Button
							id="manage_site_new_building_btn"
							className="ml-2 mr-3"
							color="primary"
							variant="contained"
							text="NEW FIRMWARE"
							style={{
								height: "34.44px"
							}}
							disabled={listLoading || !technology ? true : false}
							onClick={() => {
								setShowNewModelModal(prev => !prev)
							}}
							data-testid="CREATE"
						/>
						<Button
							color="primary"
							variant="contained"
							disabled={listLoading || !technology ? true : false}
							text="Export"
							style={{
								height: "34.44px"
							}}
							onClick={() => {
								setShowExportModal(prev => !prev)
							}}
						/>
					</div>
				}
				// line={false}
			/>
			<Collapse in={true}>
				<CardBody style={{ padding: "10px" }}>
					<FirmwaresInventoryTable
						filterVersionOptions={filterVersionOptions}
						filterSeverityOptions={filterSeverityOptions}
						filterNameOptions={filterNameOptions}
						filterVendorOptions={filterVendorOptions}
						technology={technology}
						rows={rows}
						setRows={setRows}
						searchText={searchText}
						setSearchText={setSearchText}
					/>
					{showFilter && (
						<FirmwaresInventoryFilter
							showFilter={showFilter}
							setShowFilter={setShowFilter}
							filterVersionOptions={filterVersionOptions}
							setFilterVersionOptions={setFilterVersionOptions}
							filterSeverityOptions={filterSeverityOptions}
							setFilterSeverityOptions={setFilterSeverityOptions}
							filterNameOptions={filterNameOptions}
							setFilterNameOptions={setFilterNameOptions}
							filterVendorOptions={filterVendorOptions}
							setFilterVendorOptions={setFilterVendorOptions}
						/>
					)}
					{showNewModelModal && (
						<AddInventoryFirmwaresModal
							setShowNewModelModal={setShowNewModelModal}
							technology={technology}
						/>
					)}

					<div style={{ overflow: "hidden", height: "0" }}>
						<TableToPrint
							rowsToUse={UIProps.rowsToUseFirmwaresTable}
							columnVisibilityModel={UIProps.columnVisibilityModelFirmwaresTable}
							columns={[
								{
									dataField: "name",
									text: "Name",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "version",
									text: "Version",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "severity",
									text: "Severity",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "vendor",
									text: "Vendor",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "modelId",
									text: "Model",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "notes",
									text: "Notes",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								}
							]}
							ref={componentRef}
						/>
						<CurrentTableToPrint
							rowsToUse={UIProps.rowsToUseFirmwaresTable}
							currentTablePageInfo={UIProps.currentFirmwaresTablePageInfo}
							columnVisibilityModel={UIProps.columnVisibilityModelFirmwaresTable}
							columns={[
								{
									dataField: "name",
									text: "Name",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "version",
									text: "Version",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "severity",
									text: "Severity",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "vendor",
									text: "Vendor",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "modelId",
									text: "Model",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "notes",
									text: "Notes",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								}
							]}
							ref={currentPageComponentRef}
						/>

						<ExportModal
							contentClassName="bg-transparent"
							show={showExportModal}
							onHide={() => setShowExportModal(false)}
							columnsForExport={[
								"name",
								"version",
								"severity",
								"vendor",
								"modelId",
								"notes"
							]}
							setOptionsOfExport={setOptionsOfExport}
							columnVisibilityModelBeforePrint={columnVisibilityModelBeforePrint}
							rowsToUse={UIProps.rowsToUseFirmwaresTable}
							setColumnVisibilityModel={UIProps.setColumnVisibilityModelFirmwaresTable}
						/>
					</div>
				</CardBody>
			</Collapse>
		</Card>
	)
}

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}

import React from "react"

// Utility function to calculate marker size
export const calculateSize = size => {
	return `${Math.abs(Math.floor((size / 100) * (80 - 14 + 1) + 14))}px`
}

// Component for rendering marker image
export const MarkerImage = ({ src, alt, size }) => (
	<img
		style={{
			marginTop: "0",
			marginBottom: "2px",
			width: "75%",
			height: "auto"
		}}
		alt={alt}
		src={src}
	/>
)

// Component for rendering marker content
export const MarkerContent = ({ tag, size, customClaims }) => {
	if (tag.person) {
		const imgSrc = customClaims.isSuper ? "/icons/Star.svg" : "/media/crowdkeep/Person_white.svg"
		return <MarkerImage src={imgSrc} alt="person" size={size} />
	}

	if (tag.asset) {
		return <MarkerImage src="/media/crowdkeep/Asset_Cube_Filled.svg" alt="asset" size={size} />
	}

	return null // Unassigned tag case
}

import React from "react"
import { makeStyles, Checkbox as MUICheckbox, FormHelperText, Button } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
	textField: {
		marginRight: "5px",
		// backgroundColor: "#FFFFFF",
		// borderRadius: "5px",
		margin: 0,
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		// -------------- \\
		// --- Filled --- \\
		"& .MuiFilledInput-root": {
			backgroundColor: "rgba(236, 241, 244, 1)",
			borderColor: "rgba(0,0,0,0)",
			borderRadius: "5px"
		},
		"& .MuiFilledInput-inputMarginDense": {
			padding: "0 15px",
			height: "40px",
			verticalAlign: "middle"
		}
	},
	textFieldError: {
		border: "1px solid #F64E60"
	},
	textFieldDefaultSize: {
		"& .MuiInputBase-root": {
			height: "45px",
			padding: "0px 12px",
			display: "flex",
			alignItems: "center"
		},
		"& .MuiInputBase-input": {
			padding: "0"
		}
	},
	helperText: {
		margin: 0,
		color: theme.palette.error.main
	},
	textFieldWithAppend: {
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0
	},
	button: {
		marginTop: "2px",
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		textTransform: "none",
		float: "right",
		backgroundColor: "#C3C4DD",
		color: "#323389",
		padding: "5px",
		"&:hover": {
			backgroundColor: "#CECEE3",
			color: "#323389"
		},
		"&:active": {
			backgroundColor: "#ADADD0",
			color: "#323389"
		}
	}
}))

export function Checkbox({
	field,
	form,
	label,
	withFeedbackLabel = true,
	appendButton,
	size,
	...props
}) {
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const classes = useStyles()
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	return (
		<div className="d-flex align-items-center">
			<div style={{ display: "flex", flexDirection: "row" }}>
				<MUICheckbox
					//   error={error ? true : false}
					color="primary"
					size={size}
					className={clsx(
						classes.textField,
						error && classes.textFieldError,
						!size && classes.textFieldDefaultSize,
						appendButton && classes.textFieldWithAppend
					)}
					// InputProps={{ disableUnderline: true }}
					name={name}
					checked={value || false}
					onBlur={() => {
						if (!touched && !setFieldTouched) {
							return
						}
						/*!touched[name] so it doesn't set touched true when it's already true*/
						/*Without this errors will always be one update behind the real value*/
						!touched[name] && setFieldTouched(name, true)
					}}
					onChange={e => {
						if (!touched && !setFieldTouched && !setFieldValue) {
							return
						}
						!touched[name] && setFieldTouched(name, true)
						setFieldValue(name, e.target.checked)
					}}
					{...props}
				/>
				{appendButton && (
					<Button
						disableElevation
						variant="contained"
						className={classes.button}
						onClick={appendButton.onClick || null}
					>
						<b
							style={{
								width: "100%",
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis"
							}}
						>
							{appendButton.text}
						</b>
					</Button>
				)}
			</div>
			{label && (
				<div>
					<b>{label}</b>
				</div>
			)}
			{error && <FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>}
		</div>
	)
}

import React, { forwardRef, useEffect, useState } from "react"
import clsx from "clsx"
import { isFragment } from "react-is"
import { ModalProgressBar } from "./ModalProgressBar"

export function Card({ className, isLoading, children, margin = true, ...props }) {
	return (
		<>
			{isLoading && (
				<ModalProgressBar
					style={{
						marginBottom: "-6px",
						height: "6px",
						position: "relative",
						zIndex: 999,
						borderRadius: "0.42rem 0.42rem 0 0"
					}}
				/>
			)}
			<div className={clsx("card card-custom", margin && "mb-8", className)} {...props}>
				{children}
			</div>
		</>
	)
}

export function CardBody({ className, padding, style, ...props }) {
	return (
		<div className={className} style={{ padding: padding || "30px 40px", ...style }} {...props} />
	)
}

export const CardHeaderIcon = forwardRef(({ className }, ref) => (
	<span ref={ref} className={clsx("card-head-icon", className)} />
))

export const CardHeaderTitle = forwardRef(({ className, ...props }, ref) => (
	// eslint-disable-next-line jsx-a11y/heading-has-content
	<h3
		{...props}
		ref={ref}
		className={clsx("card-title align-items-start flex-column mt-0 mb-0", className)}
	/>
))

export const CardHeaderToolbar = forwardRef(({ children, className, id, ...props }, ref) => (
	<div {...props} ref={ref} className={clsx("card-toolbar", className)} id={id}>
		{children}
	</div>
))

export const CardHeader = forwardRef(
	(
		{
			style,
			children,
			icon,
			title,
			subtitle,
			toolbar,
			className,
			sticky = false,
			labelRef,
			line = true,
			padding = true,
			headerForMessaging = false,
			headerForMessagingIcon,
			read = false,
			severity = severity,
			onClick,
			id,
			hasTabTitle = false,
			...props
		},
		ref
	) => {
		const [top, setTop] = useState(0)
		const [windowHeight, setWindowHeight] = useState(0)

		useEffect(() => {
			handleResize()

			function handleResize() {
				setWindowHeight(window.innerWidth)
			}

			window.addEventListener("resize", handleResize)

			return () => {
				window.removeEventListener("resize", handleResize)
			}
		})

		useEffect(() => {
			// Skip if sticky is disabled or on initial render when we don't know about window height.
			if (!sticky || windowHeight === 0) {
				return
			}

			const headerElement = document.querySelector(".header")
			const subheaderElement = document.querySelector(".subheader")
			const headerMobileElement = document.querySelector(".header-mobile")

			let nextMarginTop = 0

			// mobile header
			if (window.getComputedStyle(headerElement).height === "0px") {
				nextMarginTop = headerMobileElement.offsetHeight
			} else {
				// desktop header
				if (document.body.classList.contains("header-minimize-topbar")) {
					// hardcoded minimized header height
					nextMarginTop = 60
				} else {
					// normal fixed header
					if (document.body.classList.contains("header-fixed")) {
						nextMarginTop += headerElement.offsetHeight
					}

					if (document.body.classList.contains("subheader-fixed")) {
						nextMarginTop += subheaderElement.offsetHeight
					}
				}
			}

			setTop(nextMarginTop)
		}, [sticky, windowHeight])

		if (headerForMessaging) {
			return (
				<div
					style={{
						display: "flex"
					}}
				>
					<div
						style={{
							display: "grid",
							justifyItems: "center",
							alignContent: "center",
							width: "20%",
							minHeight: 55,
							// backgroundColor: read ? "#D1D1D1" : "#2673F0",
							backgroundColor: read
								? "#D1D1D1"
								: severity === 3
								? "#E65019"
								: severity === 2
								? "#F6B952"
								: "#2673F0",
							borderTopLeftRadius: "5px",
							borderBottomLeftRadius: "5px"
						}}
						onClick={onClick}
					>
						{headerForMessagingIcon}
					</div>
					<div
						ref={ref}
						className="card-header"
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "80%",
							minHeight: 55,
							padding: "5px 10px",
							borderBottom: !line && 0,
							borderTopRightRadius: "5px",
							borderBottomRightRadius: "5px",
							...style
						}}
					>
						{title != null && (
							<div ref={labelRef} className={clsx("card-title m-0", className)}>
								{icon}

								{
									/* Wrap string and fragments in CardHeaderTitle */
									typeof title === "string" || isFragment(title) ? (
										<CardHeaderTitle>
											<span className="font-weight-bolder text-dark">{title}</span>
											{subtitle && (
												<span className="text-muted mt-1 font-weight-bold font-size-sm">
													{subtitle}
												</span>
											)}
										</CardHeaderTitle>
									) : (
										title
									)
								}
							</div>
						)}

						<CardHeaderToolbar
							style={{ alignSelf: "center", display: hasTabTitle ? "block" : "flex" }}
						>
							{toolbar}
						</CardHeaderToolbar>
						{children}
					</div>
				</div>
			)
		} else {
			return (
				<div
					ref={ref}
					className="card-header"
					style={{
						padding: padding ? "14px" : 0,
						paddingLeft: padding ? "24px" : 0,
						borderBottom: !line && 0,
						...style
					}}
				>
					{title != null && (
						<div ref={labelRef} className={clsx("card-title m-0", className)}>
							{icon}

							{
								/* Wrap string and fragments in CardHeaderTitle */
								typeof title === "string" || isFragment(title) ? (
									<CardHeaderTitle>
										<span className="font-weight-bolder text-dark">{title}</span>
										{subtitle && (
											<span className="text-muted mt-1 font-weight-bold font-size-sm">
												{subtitle}
											</span>
										)}
									</CardHeaderTitle>
								) : (
									title
								)
							}
						</div>
					)}
					<CardHeaderToolbar style={{ display: hasTabTitle ? "block" : "flex" }} id={id}>
						{toolbar}
					</CardHeaderToolbar>
					{children}
				</div>
			)
		}
	}
)

export default class MapControlsButtons {
	constructor({ className = "", title = "", id = "", eventHandler = "", eventHandler2 = "" }) {
		this._className = className
		this._title = title
		this._id = id
		this._eventHandler = eventHandler
		this._eventHandler2 = eventHandler2
	}

	onAdd(map) {
		this._btn = document.createElement("button")
		this._btn.className = "mapboxgl-ctrl-icon" + " " + this._className
		this._btn.type = "button"
		this._btn.title = this._title
		this._btn.id = this._id
		this._btn.onclick = this._eventHandler
		this._btn.ondblclick = this._eventHandler2

		this._container = document.createElement("div")
		this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl"
		this._container.appendChild(this._btn)

		return this._container
	}

	onRemove() {
		// this._container.parentNode.removeChild(this._container);
		if (this._container.parentNode) {
			this._container.parentNode.removeChild(this._container)
		}
		this._map = undefined
	}
}

/* Event Handlers */
// function one(event) {
//   alert("Event handler when clicking on \r\n" + event.target.className);
//   console.log("event number 1", event);
// }

// function two(event) {
//   alert("Event handler when clicking on \r\n" + event.target.className);
//   console.log("event number 2", event);
// }

// function three(event) {
//   alert("Event handler when clicking on \r\n" + event.target.className);
//   console.log("event number 3", event);
// }

/* Instantiate new controls with custom event handlers */
// const ctrlPoint = new MapControlsButtons({
//   className: "mapbox-gl-draw_point",
//   title: "Draw Point",
//   eventHandler: one,
// });

// const ctrlLine = new MapControlsButtons({
//   className: "mapbox-gl-draw_line",
//   title: "Draw Line",
//   eventHandler: two,
// });

// const ctrlPolygon = new MapControlsButtons({
//   className: "mapbox-gl-draw_polygon",
//   title: "Draw Polygon",
//   eventHandler: three,
// });

/* Add Controls to the Map */
//   map.addControl(new mapboxgl.NavigationControl(), "top-left");
//   map.addControl(new PitchToggle({ minpitchzoom: 11 }), "top-left");
//   map.addControl(ctrlPoint, "bottom-left");
//   map.addControl(ctrlLine, "bottom-right");
//   map.addControl(ctrlPolygon, "top-right");

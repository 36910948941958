import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import PopoverStickOnHover from "../MapControls/PopoverStickOnHover"
import "@turf/angle"
import { profileSlice } from "../../../../redux/profile/profileSlice"

const { actions } = profileSlice

export const addImageLayerToMap = params => {
	//━━━ Parameters ━━━\\
	const { map, floorPlan } = params

	if (!map || !floorPlan) return

	const layers = map.getStyle()

	if (layers) {
		//━━━ Remove sources and layers ━━━\\
		typeof map.getLayer("imageLayer") !== "undefined" && map.removeLayer("imageLayer")
		typeof map.getSource("imageSource") !== "undefined" && map.removeSource("imageSource")
		typeof map.getLayer("geoJsonLayer") !== "undefined" && map.removeLayer("geoJsonLayer")
		typeof map.getSource("geoJsonSource") !== "undefined" && map.removeSource("geoJsonSource")
		typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
			map.removeLayer("geoJsonLinesLayer")
		typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
			map.removeSource("geoJsonLinesSource")

		//━━━ Add image ━━━\\
		map.addSource("imageSource", {
			type: "image",
			url: floorPlan?.imageUrl,
			coordinates: [
				[
					floorPlan?.coords?.topLeftCorner?.coords.longitude,
					floorPlan?.coords?.topLeftCorner?.coords.latitude
				],
				[
					floorPlan?.coords?.topRightCorner?.coords.longitude,
					floorPlan?.coords?.topRightCorner?.coords.latitude
				],
				[
					floorPlan?.coords?.bottomRightCorner?.coords.longitude,
					floorPlan?.coords?.bottomRightCorner?.coords.latitude
				],
				[
					floorPlan?.coords?.bottomLeftCorner?.coords.longitude,
					floorPlan?.coords?.bottomLeftCorner?.coords.latitude
				]
			]
		})
		typeof map.getSource("geoJsonLayer-0") !== "undefined"
			? map.addLayer(
					{
						id: "imageLayer",
						source: "imageSource",
						type: "raster",
						paint: {
							"raster-opacity": 0.8
						}
					},
					// "geoJsonLayer-0"
					"state-label"
			  )
			: map.addLayer(
					{
						id: "imageLayer",
						source: "imageSource",
						type: "raster",
						paint: {
							"raster-opacity": 0.8
						}
					},
					"state-label"
			  )

		// ↓ If exists is this floorPlan mapOrientation it displays orientation from db
		if (Boolean(floorPlan.mapOrientation) === true) {
			const lat = floorPlan.mapOrientation.center.latitude
			const lng = floorPlan.mapOrientation.center.longitude
			map.easeTo({
				center: [lng, lat],
				bearing: floorPlan.mapOrientation.bearing,
				pitch: floorPlan.mapOrientation.pitch,
				zoom: floorPlan.mapOrientation.zoom,
				duration: 0
			})
		} else {
			// Onde é centrado o mapa por default
			map.fitBounds(
				[
					[
						floorPlan?.coords?.topLeftCorner.coords.longitude,
						floorPlan?.coords?.topLeftCorner.coords.latitude
					],
					[
						floorPlan?.coords?.bottomRightCorner.coords.longitude,
						floorPlan?.coords?.bottomRightCorner.coords.latitude
					]
				],
				// ↓ Duração da animação a iniciar o mapa e o zoom inicial
				{
					padding: { top: 10, bottom: 10, left: 10, right: 10 },
					// animationOptions,
					duration: 0
				}
			)
		}
	}
}

export const addGeoJsonLayerAndMarkersToMap = params => {
	const { map, geoJson, layerTypes, areas } = params

	const layers = map.getStyle()

	if (layers) {
		typeof map.getLayer("geoJsonLayer") !== "undefined" && map.removeLayer("geoJsonLayer")
		typeof map.getSource("geoJsonSource") !== "undefined" && map.removeSource("geoJsonSource")
		typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
			map.removeLayer("geoJsonLinesLayer")
		typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
			map.removeSource("geoJsonLinesSource")

		var iterateWhile = true
		var index = 0

		while (iterateWhile === true) {
			typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLayer-${index}`)
			typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonSource-${index}`)
			typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLinesLayer-${index}`)
			typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonLinesSource-${index}`)
			index++
			if (
				typeof map.getLayer(`geoJsonLinesLayer-${index}`) === "undefined" &&
				typeof map.getSource(`geoJsonLinesSource-${index}`) === "undefined" &&
				typeof map.getLayer(`geoJsonLayer-${index}`) === "undefined" &&
				typeof map.getSource(`geoJsonSource-${index}`) === "undefined"
			) {
				iterateWhile = false
			}
		}

		if (!geoJson) return

		//━━━ Tranform data for mapbox ━━━\\
		geoJson.features.forEach((feature, index) => {
			typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLayer-${index}`)
			typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonSource-${index}`)
			typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLinesLayer-${index}`)
			typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonLinesSource-${index}`)

			var currentType = layerTypes?.find(type => type.id === feature.properties.type)

			var coordinates = []
			feature.geometry.coordinates.forEach(coords => {
				coordinates.push([coords.longitude, coords.latitude])
			})

			const data = {
				type: geoJson.features[index].type,
				properties: {
					...geoJson.features[index].properties,
					color: currentType?.color || "#e9ecef",
					outline: currentType ? "#ced4da" : "#ced4da",
					lineWidth: feature.properties.type !== "baseLayer" ? (currentType ? 2 : 2) : 4
				},
				geometry: {
					type: geoJson.features[index].geometry.type,
					coordinates: [coordinates]
				}
			}

			const isVisible = feature.properties.visible === true ? "visible" : "none"

			const noPropertyInDBCondition =
				feature.properties.visible !== undefined ? isVisible : "visible"

			//━━━ Add to floorplan ━━━\\
			map.addSource(`geoJsonSource-${index}`, {
				type: "geojson",
				data: data
			})

			map.addLayer(
				{
					id: `geoJsonLayer-${index}`,
					source: `geoJsonSource-${index}`,
					type: "fill",
					layout: {
						visibility: `${noPropertyInDBCondition}`
					},
					paint: {
						"fill-color": ["get", "color"],
						"fill-outline-color": ["get", "outline"],
						"fill-opacity": 0.4
					}
				},
				"country-label"
			)

			//━━━ Add lines ━━━\\
			map.addSource(`geoJsonLinesSource-${index}`, {
				type: "geojson",
				data: data
			})
			map.addLayer(
				{
					id: `geoJsonLinesLayer-${index}`,
					source: `geoJsonLinesSource-${index}`,
					type: "line",
					layout: {
						visibility: `${noPropertyInDBCondition}`
						// visibility: "visible",
					},
					paint: {
						"line-color": ["get", "outline"],
						"line-width": ["get", "lineWidth"]
					}
				},
				"country-label"
			)

			// Create the Info Marker
			const el = document.createElement("div")
			// el.className = "zonesMarkers"

			// ↓ Popup in info marker
			const popupStick = feature && (
				<PopoverStickOnHover
					component={
						<div className="popoverCardDashboard">
							<div className="popupMainDivDashboard">
								<div className="popup2Dashboard">
									{/* {feat.properties?.zoneImagesUrls &&
                      feat.properties.zoneImagesUrls.length !== 0 && (
                        <Carousel indicators={false} interval={1500}>
                          {feat.properties.zoneImagesUrls.map((arrayUrl, i) => (
                            <Carousel.Item key={i} interval={1500}>
                              <img
                                className='d-block w-100'
                                src={arrayUrl}
                                alt={feat.type}
                                style={{
                                  borderRadius: "8px 8px 0px 0px",
                                  height: "12vh",
                                  objectFit: "cover",
                                  objectPosition: "50% 50%",
                                }}
                              />
                            </Carousel.Item>
                          ))}{" "}
                        </Carousel>
                      )} */}
									<div className="popupTextDashboard">
										<div className="d-flex justify-content-between align-items-baseline mb-3">
											<h6
												style={{
													paddingBottom: "1px",
													paddingTop: "1px",
													fontSize: "14px",
													marginRight: "1rem",
													fontWeight: "700"
												}}
											>
												<b>{feature.properties.name}</b>
											</h6>
										</div>
										<h6
											style={{
												paddingBottom: "1px",
												paddingTop: "1px",
												fontSize: "10px",
												fontWeight: "400"
											}}
										>
											Type:{" "}
											<span>
												<b>{feature.properties.type}</b>
											</span>
										</h6>
										<h6
											style={{
												paddingBottom: "1px",
												paddingTop: "1px",
												fontSize: "10px",
												fontWeight: "400",
												marginBottom: "1rem"
											}}
										>
											Capacity:{" "}
											<span>
												<b>{feature.properties.capacity}</b>
											</span>
										</h6>
									</div>
								</div>
							</div>
						</div>
					}
					placement="top"
					delay={200}
				>
					<div
						className="d-flex justify-content-center align-items-center markerZoneDivDashboardSmall zonesMarkers"
						style={{
							width: "26px",
							height: "26px",
							position: "relative",
							overflow: "hidden"
						}}
					>
						<img
							style={{
								width: "20%",
								height: "auto",
								display: "block"
							}}
							src="/icons/information.svg"
							alt="info icon"
						/>
					</div>
				</PopoverStickOnHover>
			)

			// Area in DB with same id as feature
			const fetchZoneWithMarkerCoords =
				areas && areas.find(area => area?.id === feature?.properties?.areaId)

			if (feature.properties.type !== "baseLayer" && fetchZoneWithMarkerCoords) {
				// Info Marker
				ReactDOM.render(popupStick, el)
				new mapboxgl.Marker(el, {
					draggable: false
				})
					.setLngLat([
						fetchZoneWithMarkerCoords?.marker?.longitude,
						fetchZoneWithMarkerCoords?.marker?.latitude
					])
					.addTo(map)
			}
		})
	}
}

// fake data
export const auditDataSmall = [
	{
		id: 0,
		date: "Tue, 12 Sep 2023 05:03:00 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28489155232981,
		longitude: -76.55157688342477,

		area: "First Top Right North",
		time: "05:03:00",
		uuid: 135018198290337
	},
	{
		id: 1,
		date: "Tue, 12 Sep 2023 05:19:01 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28489341397915,
		longitude: -76.55193969381577,
		area: "First Top Right North",
		time: "05:19:01",
		uuid: 135018198290337
	},
	{
		id: 2,
		date: "Tue, 12 Sep 2023 05:44:00 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.284860015246636,
		longitude: -76.55240997953089,
		area: "First Top Right North",
		time: "05:44:00",
		uuid: 135018198290337
	},
	{
		id: 3,
		date: "Tue, 12 Sep 2023 05:56:01 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28441572582784,
		longitude: -76.5523819057622,
		area: "First Top Right North",
		time: "05:56:01"
	},
	{
		id: 4,
		date: "Tue, 12 Sep 2023 06:00:01 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.284149581127735,
		longitude: -76.55236284984652,

		area: "Second Bottom Right South",
		time: "06:00:01",
		uuid: 135018198290337
	},
	{
		id: 5,
		date: "Tue, 12 Sep 2023 06:02:01 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28391480694418,
		longitude: -76.55233905968278,
		area: "First Top Right North",
		time: "06:02:01",
		uuid: 135018198290337
	},
	{
		id: 6,
		date: "Tue, 12 Sep 2023 06:22:01 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28380506690871,
		longitude: -76.55217092872329,

		area: "First Top Right North",
		time: "06:22:01",
		uuid: 135018198290337
	},
	{
		id: 7,
		date: "Tue, 12 Sep 2023 06:30:01 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28381933794671,
		longitude: -76.55197705102776,

		area: "First Top Right North",
		time: "06:30:01",
		uuid: 135018198290337
	},
	{
		id: 8,
		date: "Tue, 12 Sep 2023 06:47:00 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28383095014766,
		longitude: -76.55180763261325,

		area: "First Top Right North",
		time: "06:47:00",
		uuid: 135018198290337
	},
	{
		id: 9,
		date: "Tue, 12 Sep 2023 06:54:00 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28385500195523,
		longitude: -76.55163004484785,

		area: "First Top Right North",
		time: "06:54:00",
		uuid: 135018198290337
	},
	{
		id: 10,
		date: "Tue, 12 Sep 2023 07:16:01 GMT",
		floorPlanId: "IQfM4TlwajQ7JNaMhChb",

		latitude: 39.28431047525024,
		longitude: -76.55159165974986,

		area: "First Top Right North",
		time: "07:16:01",
		uuid: 135018198290337
	}
]

export function addPersonMarkers(params) {
	const { map, data, updatedCurrentFloorPlan, floorPlans, containerRef, isFullscreen, dispatch } =
		params

	if (!data || !map || !updatedCurrentFloorPlan) return

	const layers = map.getStyle()

	if (layers) {
		var personMarkers = []
		data.forEach(val => {
			// Find floorplan of each marker
			const travelToSelectedFloor =
				val &&
				floorPlans &&
				floorPlans.find(
					f => f.id === val?.floorPlanId || f.wirepasFloorPlanId === val?.floorPlanId
				)

			const el2 = document.createElement("div")
			// el2.className = "poiMarkersDashboard"
			// el2.id = poi.id
			// el2.style.cursor = "pointer"

			const newPopupStick3 = val && (
				<PopoverStickOnHover
					component={
						<>
							<div className="popoverCardDashboard">
								<div className="popupMainDiv">
									<div className="popup2">
										{/* <Carousel
										indicators={false}
										controls={poi.imagesUrls?.length > 1}
										interval={1500}
									>
										{poi.imagesUrls?.length > 0 &&
											poi.imagesUrls.map((arrayUrl, i) => (
												<Carousel.Item key={i} interval={1000}>
													<img
														className="d-block w-100"
														src={arrayUrl}
														alt={poi.type}
														style={{
															// borderRadius: "2px 2px 0px 0px",
															height: "12vh",
															objectFit: "cover",
															objectPosition: "50% 50%"
														}}
													/>
												</Carousel.Item>
											))}{" "}
									</Carousel> */}
										{/* <div className="popupTextDashboard">
										<div className="d-flex justify-content-between align-items-baseline mb-3">
											<h6
												style={{
													paddingBottom: "1px",
													paddingTop: "1px",
													fontSize: "14px",
													marginRight: "1rem",
													fontWeight: "700"
												}}
											>
												<b>{poi.name}</b>
											</h6>
										</div>

										<h6
											style={{
												paddingBottom: "1px",
												paddingTop: "1px",
												fontSize: "11px",
												fontWeight: "500"
											}}
										>
											Type: <b>{poi.type}</b>
										</h6>
										<h6
											style={{
												paddingBottom: "1px",
												paddingTop: "1px",
												fontSize: "11px",
												fontWeight: "500",
												marginBottom: "1rem"
											}}
										>
											Description: <b>{poi.description}</b>
										</h6>
									
									</div> */}
									</div>
								</div>
							</div>
						</>
					}
					placement="top"
					delay={200}
					containerRef={isFullscreen && containerRef}
				>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{
							cursor: "pointer",
							width: "26px",
							height: "26px",
							position: "relative",
							overflow: "hidden",
							margin: "auto",
							backgroundColor: "#53B04F",
							outline: "2px solid #ffffff",
							borderRadius: "50%"
						}}
					>
						<img
							style={{
								marginTop: "1px",
								// width: "96%",
								// height: "96%",
								width: "100%",
								height: "auto",
								display: "block"
							}}
							alt="person"
							src="/media/crowdkeep/Person_white.svg"
						/>
					</div>
				</PopoverStickOnHover>
			)

			// Same floorplan
			if (
				val?.floorPlanId === updatedCurrentFloorPlan?.id ||
				val?.floorPlanId === updatedCurrentFloorPlan?.wirepasFloorPlanId
			) {
				// Render the marker with popup sticky
				ReactDOM.render(newPopupStick3, el2)

				// Create the marker
				const markerWithImage = new mapboxgl.Marker(el2, {
					draggable: false
				}).setLngLat([val.longitude, val.latitude])

				// Add marker to map
				markerWithImage && markerWithImage.addTo(map)

				personMarkers.push(el2)
			} else {
				// console.log("🚀  travelToSelectedFloor:", travelToSelectedFloor)

				// Change floor if person marker was seen on another floor besides the current.
				travelToSelectedFloor && dispatch(actions.floorPlanSelected(travelToSelectedFloor))
			}
		})
	}
	return personMarkers
}

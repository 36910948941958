import React, { useEffect, useMemo, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useUIContext } from "../../UIContext"
import { FormControlLabel, Switch } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import SwitchBase from "@material-ui/core/internal/SwitchBase"
import { FormControl } from "react-bootstrap"

const LiveMapFalse = () => {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.3"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.13964 13.4081L4.23488 14.4982C2.46266 13.0274 1.4165 10.8483 1.4165 8.49994C1.4165 6.15481 2.45975 3.97848 4.22777 2.50757L5.13382 3.59662C3.68596 4.80117 2.83317 6.58018 2.83317 8.49994C2.83317 10.4224 3.68834 12.2036 5.13964 13.4081ZM11.86 13.4081L12.7648 14.4982C14.537 13.0274 15.5832 10.8483 15.5832 8.49994C15.5832 6.15481 14.5399 3.97848 12.7719 2.50757L11.8659 3.59662C13.3137 4.80117 14.1665 6.58018 14.1665 8.49994C14.1665 10.4224 13.3113 12.2036 11.86 13.4081ZM10.0429 11.234L10.9442 12.327C12.0789 11.3913 12.7498 9.99974 12.7498 8.49994C12.7498 7.00507 12.0834 5.61755 10.955 4.68176L10.0507 5.77222C10.8578 6.44166 11.3332 7.43121 11.3332 8.49994C11.3332 9.57218 10.8547 10.5647 10.0429 11.234ZM6.05545 12.327L6.95673 11.234C6.14498 10.5647 5.6665 9.57218 5.6665 8.49994C5.6665 7.43121 6.14183 6.44166 6.94902 5.77222L6.04467 4.68176C4.91631 5.61755 4.24984 7.00507 4.24984 8.49994C4.24984 9.99974 4.92073 11.3913 6.05545 12.327Z"
				fill="#4A4A68"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.50016 9.91659C9.28257 9.91659 9.91683 9.28232 9.91683 8.49992C9.91683 7.71752 9.28257 7.08325 8.50016 7.08325C7.71776 7.08325 7.0835 7.71752 7.0835 8.49992C7.0835 9.28232 7.71776 9.91659 8.50016 9.91659Z"
				fill="#4A4A68"
			/>
		</svg>
	)
}

const LiveMapTrue = () => {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.7"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.13964 13.4081L4.23488 14.4982C2.46266 13.0274 1.4165 10.8483 1.4165 8.49994C1.4165 6.15481 2.45975 3.97848 4.22777 2.50757L5.13382 3.59662C3.68596 4.80117 2.83317 6.58018 2.83317 8.49994C2.83317 10.4224 3.68834 12.2036 5.13964 13.4081ZM11.86 13.4081L12.7648 14.4982C14.537 13.0274 15.5832 10.8483 15.5832 8.49994C15.5832 6.15481 14.5399 3.97848 12.7719 2.50757L11.8659 3.59662C13.3137 4.80117 14.1665 6.58018 14.1665 8.49994C14.1665 10.4224 13.3113 12.2036 11.86 13.4081ZM10.0429 11.234L10.9442 12.327C12.0789 11.3913 12.7498 9.99974 12.7498 8.49994C12.7498 7.00507 12.0834 5.61755 10.955 4.68176L10.0507 5.77222C10.8578 6.44166 11.3332 7.43121 11.3332 8.49994C11.3332 9.57218 10.8547 10.5647 10.0429 11.234ZM6.05545 12.327L6.95673 11.234C6.14498 10.5647 5.6665 9.57218 5.6665 8.49994C5.6665 7.43121 6.14183 6.44166 6.94902 5.77222L6.04467 4.68176C4.91631 5.61755 4.24984 7.00507 4.24984 8.49994C4.24984 9.99974 4.92073 11.3913 6.05545 12.327Z"
				fill="#53B04F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.50016 9.91659C9.28257 9.91659 9.91683 9.28232 9.91683 8.49992C9.91683 7.71752 9.28257 7.08325 8.50016 7.08325C7.71776 7.08325 7.0835 7.71752 7.0835 8.49992C7.0835 9.28232 7.71776 9.91659 8.50016 9.91659Z"
				fill="#53B04F"
			/>
		</svg>
	)
}

const SwitchStyle = withStyles(theme => ({
	root: {
		width: "70px",
		height: "27px",
		margin: 0,
		padding: 0,
		fontSize: "12px !important",
		"& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
			backgroundColor: "#53B04F !important",
			opacity: 1
		},
		"& .MuiSwitch-colorSecondary.Mui-checked:hover": {
			backgroundColor: "white !important"
		},
		"& .MuiIconButton-root:hover": {
			backgroundColor: "white !important"
		}
	},
	switchBase: {
		padding: 3.7,
		backgroundColor: "white",
		color: "#53B04F",
		top: 1.2,
		left: 1.7,
		"&$checked": {
			color: "white",
			opacity: 1,
			"& + $track": {
				backgroundColor: "#53B04F"
			}
		}
	},
	thumb: {
		width: "24px",
		height: "24px",
		backgroundColor: "#FFFFFF"
	},
	track: {
		borderRadius: 20,
		backgroundColor: "#D6D9E1",
		opacity: 1,
		position: "relative",
		"&:before, &:after": {
			display: "inline-block",
			position: "absolute",
			top: "50%",
			width: "50%",
			transform: "translateY(-50%)",
			color: "#fff",
			textAlign: "center"
		},
		"&:before": {
			content: '"LIVE"',
			left: 4,
			opacity: 0
		},
		"&:after": {
			content: "",
			right: 4
		}
	},
	checked: {
		"&$switchBase": {
			transform: "translateX(175%)",
			margin: "0px -0.2px"
		},
		"& $thumb": {
			backgroundColor: "#fff"
		}
	}
}))(({ classes, ...props }) => {
	return (
		<Switch
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked
			}}
			{...props}
		/>
	)
})

const ToggleLiveMapButton = () => {
	// ━━━ User based information ━━━\\
	const { selectedCustomer, selectedSite } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite
		}),
		shallowEqual
	)

	// ━━━ Data from UIContext ━━━\\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			liveData: UIContext.liveData,
			setLiveData: UIContext.setLiveData,
			resetSnapshot: UIContext.resetSnapshot,
			setResetSnapshot: UIContext.setResetSnapshot
		}
	}, [UIContext])

	const [isChecked, setIsChecked] = useState(true)

	const handleLiveButtonChange = () => {
		!isChecked && UIProps.setResetSnapshot(!UIProps.resetSnapshot)
	}

	useEffect(() => {
		if (UIProps.liveData) {
			setIsChecked(true)
		} else {
			setIsChecked(false)
		}
	}, [UIProps.liveData])

	// If not live, and user moves or scrolls mouse resetSnapshot to fetch liveData
	useEffect(() => {
		if (UIProps.liveData) return

		let mouseMoved = false
		let mouseScroll = false

		function handleMouseMove() {
			mouseMoved = true
			if (!isChecked && mouseMoved) {
				// console.log("User moved mouse, resetting snapshot")
				handleLiveButtonChange()
			}
		}

		function handleScroll() {
			mouseScroll = true
			if (!isChecked && mouseScroll) {
				// console.log("User scrolled, resetting snapshot")
				handleLiveButtonChange()
			}
		}

		if (!isChecked) {
			document.addEventListener("mousemove", handleMouseMove)
			document.addEventListener("scroll", handleScroll)
		}

		// Cleanup function
		return () => {
			document.removeEventListener("mousemove", handleMouseMove)
			document.removeEventListener("scroll", handleScroll)
		}
	}, [isChecked, UIProps.liveData])

	return (
		<div className="d-flex align-items-center">
			{selectedCustomer ? (
				<div className="col-0">
					{selectedSite && (
						<div
							className="toolbar"
							style={{
								margin: "0",
								textAlign: "center",
								color: "white",
								marginLeft: "0 !important"
							}}
						>
							<span
								className="sw"
								style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
							>
								<SwitchStyle
									id="switch"
									checked={isChecked}
									onChange={handleLiveButtonChange}
									icon={<LiveMapFalse />}
									checkedIcon={<LiveMapTrue />}
								/>
								{isChecked ? (
									<label
										htmlFor="switch"
										style={{
											position: "absolute",
											cursor: "pointer",
											color: "white",
											fontWeight: "bold",
											width: "50px",
											height: "12px",
											textAlign: "left"
										}}
									>
										LIVE
									</label>
								) : (
									<label
										htmlFor="switch"
										style={{
											position: "absolute",
											cursor: "pointer",
											color: "#8C8CA1",
											fontWeight: "bold",
											width: "50px",
											height: "12px",
											textAlign: "right"
										}}
									>
										LIVE
									</label>
								)}
							</span>
						</div>
					)}
				</div>
			) : null}
		</div>
	)
}

export default ToggleLiveMapButton

import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	floorPlans: null,
	lastError: null
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const assignResourcesSlice = createSlice({
	name: "assignResources",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		floorPlansFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.floorPlans = entities
		},
		resourcesFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.resources = entities
		},
		resourceDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.resources = state.resources.filter(el => el.plate !== action.payload.id)
		}
	}
})

import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const manageAssetsSlice = createSlice({
	name: "manageAssets",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		assetsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.assets = action.payload
		},
		assetsFetchedBySite: (state, action) => {
			state.listLoading = false
			state.error = null
			state.assetsBySite = action.payload
		},
		assetCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.assets = state.assets ? [...state.assets, action.payload] : [action.payload.user]
		},
		assetEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.assets =
				state.assets &&
				state.assets.map(entity => {
					if (entity.id === action.payload.id) {
						return action.payload
					}
					return entity
				})
		},
		assetDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.assets = state.assets.filter(val => val.id !== action.payload)
		},
		assetsDisabled: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.assets =
				state.assets &&
				state.assets.map(entity => {
					const findAssetsToDisable = action.payload.assetsToDisable.find(
						element => element.id === entity.id
					)
					return {
						...entity,
						disabled: findAssetsToDisable ? findAssetsToDisable.disabled : entity.disabled
					}
				})
			state.assetsBySite =
				state.assetsBySite &&
				state.assetsBySite.map(entity => {
					const findAssetsToDisable = action.payload.assetsToDisable.find(
						element => element.id === entity.id
					)
					return {
						...entity,
						disabled: findAssetsToDisable ? findAssetsToDisable.disabled : entity.disabled
					}
				})
		}
	}
})

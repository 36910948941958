import { auth, initializeFirestore } from "../../../../firebase/index"
import { getFirestore, collection, query, where, getDocs, getDoc, doc } from "firebase/firestore"
import { fetchSignInMethodsForEmail, signInWithEmailAndPassword } from "firebase/auth"
import _ from "lodash"

export async function login(email, password, _tenantID = null) {
	if (!email || !password) return

	const domain = email.substring(email.lastIndexOf("@") + 1)

	let tenantID = _tenantID

	const loginDataRef = collection(getFirestore(), "LoginData")
	// If tenant comes from customer selection dont fetch login data
	// Witouth login data the tenantID used will be the one coming from customer selection
	const querySnapshot =
		!tenantID && (await getDocs(query(loginDataRef, where("domain", "==", domain))))

	if (querySnapshot?.docs?.length > 1) {
		const promises = querySnapshot.docs.map(val => {
			auth.tenantId = val.data().tenantID

			return fetchSignInMethodsForEmail(auth, email)
				.then(result => {
					if (result.length > 0)
						return { tenantID: val.data().tenantID, customerName: val.data().customerName }
				})
				.catch(error => {
					console.log("An error occurred:", error)
				})
		})
		var results = await Promise.all(promises)
		results = results.filter(val => val)

		if (results.length > 1) {
			return {
				multipleTenants: true,
				customers: results
			}
		}

		tenantID = results[0].tenantID
	}

	if (querySnapshot?.docs?.length === 1) {
		tenantID = querySnapshot.docs[0].data().tenantID
	}

	auth.tenantId = tenantID === "superAdmin" ? null : tenantID

	// console.log("🚀 ~ login ~ auth.tenantId:", auth.tenantId)
	// global.globalTenantID = auth.tenantId
	initializeFirestore(auth.tenantId)

	return signInWithEmailAndPassword(auth, email, password).catch(error => {
		console.log(error)

		const errorCode = error.code
		let errorMessage = ""

		switch (errorCode) {
			case "auth/user-not-found":
				errorMessage = "User not found."
				break
			case "auth/wrong-password":
				errorMessage = "Incorrect password."
				break
			default:
				errorMessage = "An error occurred. Please try again."
				break
		}

		throw errorMessage
	})
}

export async function requestPassword(email) {
	const tenantID = await getTenantID(email)

	if (tenantID?.multipleTenants) {
		return Promise.any(
			tenantID.customers.map(val => {
				return sendResetPasswordEmail(email, val.tenantID, val.customerName)
			})
		)
	} else {
		return sendResetPasswordEmail(email, tenantID, null)
	}
}

function sendResetPasswordEmail(email, tenantID, customerName) {
	const baseUrl = window.location.protocol + "//" + window.location.host + "/" // Get the base URL (protocol and host)

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			email: email,
			tenantId: tenantID,
			customerName: customerName,
			redirectUrl: baseUrl
		}),
		redirect: "follow"
	}

	return fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/forgot-password`, requestOptions)
}

export const getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged(userAuth => {
			unsubscribe()
			resolve(userAuth)
		}, reject)
	})
}

export const getUserClaims = async user => {
	let userClaims
	try {
		var idToken = await user.getIdTokenResult()
		userClaims = _.omit(idToken.claims, [
			"aud",
			"auth_time",
			"email",
			"email_verified",
			"exp",
			"firebase",
			"iat",
			"iss",
			"sub",
			"user_id"
		])
	} catch (err) {
		console.log("Error while fetching custom claims: ", err)
	}
	return userClaims
}

export const getUserPermissions = async userClaims => {
	const fetchRole = async () => {
		try {
			// Reference to the document in the 'DefaultRoles' collection by its ID
			const roleDocRef = doc(getFirestore(), "DefaultRoles", userClaims.userRole)

			// Fetch the document
			const roleDocSnapshot = await getDoc(roleDocRef)

			// Check if the document exists and return the data
			if (roleDocSnapshot.exists()) {
				return roleDocSnapshot.data()
			} else {
				console.error("No matching document found!")
				return null
			}
		} catch (error) {
			console.error("Error fetching role:", error)
			return null
		}
	}

	// Fetch the role and return the result
	const data = await fetchRole()
	return data
}

async function getTenantID(email, _tenantID = null) {
	const domain = email.substring(email.lastIndexOf("@") + 1)

	let tenantID = _tenantID

	const loginDataRef = collection(getFirestore(), "LoginData")

	// If tenant comes from customer selection dont fetch login data
	// Witouth login data the tenantID used will be the one coming from customer selection
	const querySnapshot =
		!tenantID && (await getDocs(query(loginDataRef, where("domain", "==", domain))))

	if (querySnapshot?.docs?.length > 1) {
		const promises = querySnapshot.docs.map(val => {
			auth.tenantId = val.data().tenantID

			return fetchSignInMethodsForEmail(auth, email)
				.then(result => {
					if (result.length > 0)
						return { tenantID: val.data().tenantID, customerName: val.data().customerName }
				})
				.catch(error => {
					console.log("An error occurred:", error)
				})
		})
		var results = await Promise.all(promises)
		results = results.filter(val => val)

		if (results.length > 1) {
			return {
				multipleTenants: true,
				customers: results
			}
		}

		tenantID = results[0].tenantID
	}

	if (querySnapshot?.docs?.length === 1) {
		tenantID = querySnapshot.docs[0].data().tenantID
	}

	tenantID = tenantID === "superAdmin" ? null : tenantID
	return tenantID
}

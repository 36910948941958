import React from "react"
import { Card, CardBody } from "../../../../_partials/Card"
import { Logo } from "../_partials"
import { shallowEqual, useSelector } from "react-redux"
import SVG from "react-inlinesvg"

const DataValidationFinalized = () => {
	const { failedFinalQuiz } = useSelector(
		state => ({
			failedFinalQuiz: state.dataValidation?.personData?.failedFinalQuiz
		}),
		shallowEqual
	)

	const icon = failedFinalQuiz
		? "/svg/general/dislike-filled.svg"
		: "/svg/general/trophy-filled.svg"
	const title = failedFinalQuiz ? "Training Failed!" : "Congratulations!"
	const text = failedFinalQuiz
		? "You have failed your training, now you have to wait until someone contacts you again. Good luck next time!"
		: "You have completed your training and have met our expectations, you are now ready to work with us.	Good job!"

	if (failedFinalQuiz !== false && failedFinalQuiz != true) return <div />
	return (
		<div
			style={{
				maxWidth: "375px"
			}}
		>
			<Logo />
			<Card data-testid="data-validation_DataValidationFinalized">
				<CardBody padding="20px">
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "25px"
						}}
					>
						<SVG
							src={icon}
							style={{ marginBottom: "0.5rem" }}
							data-testid="data-validation-icon"
						/>
						<div
							style={{
								color: "#0A1B3D",
								textAlign: "center",
								fontFamily: "Poppins",
								fontSize: "28px",
								fontStyle: "normal",
								fontWeight: 500,
								lineHeight: "40px",
								letterSpacing: "-0.28px"
							}}
						>
							{title}
						</div>
					</div>
					<div
						style={{
							color: "#8C8CA1",
							textAlign: "center",
							fontFamily: "Poppins",
							fontSize: "14px",
							fontStyle: "normal",
							fontWeight: 500,
							lineHeight: "22px"
						}}
					>
						{text}
					</div>
				</CardBody>
			</Card>
		</div>
	)
}

export default DataValidationFinalized

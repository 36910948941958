import React from "react"
import { FieldFeedbackLabel } from "./FieldFeedbackLabel"

const getFieldCSSClasses = (touched, errors, inputStyle) => {
	const classes = ["form-control"]
	if (touched && errors) {
		classes.push("is-invalid")
	}

	if (touched && !errors) {
		classes.push("is-valid")
	}

	if (inputStyle?.includes("solid")) {
		classes.push("form-control-solid")
	}

	if (inputStyle?.includes("large")) {
		classes.push("form-control-lg")
	}

	return classes.join(" ")
}

export function Input({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	label,
	withFeedbackLabel = true,
	customFeedbackLabel,
	type = "text",
	inputStyle,
	...props
}) {
	return (
		<>
			{label && (
				<b>
					{/*Enter*/} {label}:
				</b>
			)}
			<input
				type={type}
				className={getFieldCSSClasses(touched[field.name], errors[field.name], inputStyle)}
				{...field}
				{...props}
			/>
			{withFeedbackLabel && (
				<FieldFeedbackLabel
					error={errors[field.name]}
					touched={touched[field.name]}
					label={label}
					type={type}
					customFeedbackLabel={customFeedbackLabel}
				/>
			)}
		</>
	)
}

import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import Chart from "react-apexcharts"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { Link } from "react-router-dom"

export function LocationSummary() {
	const {
		isLoading,
		areaLocationTimeSpent,
		isLoadingFloors,
		peopleDistributionLocation,
		isLoadingBuildings,
		peopleBuildings,
		areasData
	} = useSelector(
		state => ({
			isLoading: state.reporting?.areaLocationTimeSpentLoading,
			areaLocationTimeSpent: state.reporting?.areaLocationTimeSpent,
			isLoadingFloors: state.reporting?.peopleDistributionLocationLoading,
			peopleDistributionLocation: state.reporting?.peopleDistributionLocation,
			isLoadingBuildings: state.reporting?.peopleBuildingsLoading,
			peopleBuildings: state.reporting?.peopleBuildings,
			areasData: state.basePage?.areas || []
		}),
		shallowEqual
	)

	// States
	const [graphDataAreas, setGraphDataAreas] = useState({
		labels: [],
		series: []
	})
	const [graphDataFloors, setGraphDataFloors] = useState({
		labels: [],
		series: []
	})
	const [graphDataBuildings, setGraphDataBuildings] = useState({
		labels: [],
		series: []
	})
	const [noDataMessage, setNoDataMessage] = useState("")

	useEffect(() => {
		if (areasData && areasData.length === 0) {
			setNoDataMessage("No areas created. ")
		} else {
			setNoDataMessage("No data to display.")
		}
	}, [areasData])

	// Areas
	useEffect(() => {
		if (!areaLocationTimeSpent || !areaLocationTimeSpent.series) {
			setGraphDataAreas({ series: [], labels: [], colors: [] })
		} else {
			setGraphDataAreas({
				series: areaLocationTimeSpent?.series,
				labels: areaLocationTimeSpent?.labels,
				colors: areaLocationTimeSpent?.colors
			})
		}
	}, [areaLocationTimeSpent])

	// Floors
	useEffect(() => {
		if (
			!peopleDistributionLocation ||
			!peopleDistributionLocation?.series ||
			peopleDistributionLocation?.series.length === 0
		) {
			setGraphDataFloors({ series: [], labels: [], colors: [] })
		} else {
			setGraphDataFloors({
				series: peopleDistributionLocation?.series,
				labels: peopleDistributionLocation?.labels,
				colors: peopleDistributionLocation?.colors
			})
		}
	}, [peopleDistributionLocation])

	// Buildings
	useEffect(() => {
		if (!peopleBuildings) {
			setGraphDataBuildings({ series: [], labels: [], colors: [] })
		} else {
			setGraphDataBuildings({
				series: peopleBuildings?.series,
				labels: peopleBuildings?.labels,
				colors: peopleBuildings?.colors
			})
		}
	}, [peopleBuildings])

	return (
		<Card isLoading={isLoading || isLoadingFloors || isLoadingBuildings}>
			<CardHeader title="Location Summary" subtitle="Time spent in each location" />
			<CardBody style={{ height: "450px" }}>
				<div className="main-container">
					<div className="scrollable-container" style={{ height: "420px" }}>
						<div className="chart-container">
							<div style={{ textAlign: "center" }}>
								<h6 className="location_title">IN BUILDINGS</h6>
							</div>
							<div className="p-6 p-md-0 p-lg-0" style={{ height: "275px" }}>
								<Chart
									options={getChartOptions(graphDataBuildings)}
									series={graphDataBuildings?.series || []}
									type="donut"
									height="100%"
								/>
							</div>

							<div
								className="d-flex justify-content-center align-items-center"
								style={{ height: "100px" }}
							>
								{graphDataBuildings?.series && graphDataBuildings?.series.length !== 0 && (
									<CustomLegend data={peopleBuildings || {}} />
								)}
							</div>
						</div>
						<div className="chart-container">
							<div style={{ textAlign: "center" }}>
								<h6 className="location_title">IN FLOORS</h6>
							</div>
							<div className="p-6 p-md-0 p-lg-0" style={{ height: "275px" }}>
								<Chart
									options={getChartOptions(graphDataFloors)}
									series={graphDataFloors?.series || []}
									type="donut"
									height="100%"
								/>
							</div>

							<div
								className="d-flex justify-content-center align-items-center"
								style={{ height: "100px" }}
							>
								{graphDataFloors?.series && graphDataFloors?.series.length !== 0 && (
									<CustomLegend data={peopleDistributionLocation || {}} />
								)}
							</div>
						</div>
						<div className="chart-container">
							<div style={{ textAlign: "center" }}>
								<h6 className="location_title">IN AREAS</h6>
							</div>
							<div
								className="p-6 p-md-0 p-lg-0"
								style={{ height: "275px", minWidth: "250px" }}
							>
								{graphDataAreas?.series && graphDataAreas?.series.length !== 0 ? (
									<Chart
										options={getChartOptions(graphDataAreas)}
										series={graphDataAreas?.series || []}
										type="donut"
										height="100%"
									/>
								) : (
									<div
										style={{ textAlign: "center" }}
										className="d-flex justify-content-center align-items-center"
									>
										{areasData && areasData.length === 0 && (
											<div
												className="d-flex align-items-center"
												style={{ minHeight: "280px" }}
											>
												<div>
													<p>{noDataMessage}</p>
													<p>
														{" "}
														<Link to="/admin/design-studio">
															Click here to create.
														</Link>
													</p>
												</div>{" "}
											</div>
										)}
									</div>
								)}
							</div>
							{/* <div
								className="p-6 p-md-0 p-lg-0"
								style={{ height: "275px", border: "1px solid red" }}
							>
								<Chart
									options={getChartOptions(graphDataAreas)}
									series={graphDataAreas?.series || []}
									type="donut"
									height="100%"
								/>
							</div> */}
							<div
								className="d-flex justify-content-center align-items-center"
								style={{ height: "100px" }}
							>
								{graphDataAreas?.series && graphDataAreas?.series.length !== 0 && (
									<CustomLegend data={areaLocationTimeSpent || {}} />
								)}
							</div>
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

function getChartOptions(props) {
	// console.log("🚀  props:", props)

	return {
		chart: {
			type: "donut",
			toolbar: {
				show: false
				// tools: {
				// 	download: true,
				// 	selection: false,
				// 	zoom: false,
				// 	zoomin: false,
				// 	zoomout: false,
				// 	pan: false,
				// 	reset: false,
				// 	customIcons: []
				// },
				// export: {
				// 	csv: {
				// 		filename: "LocationSummary",
				// 		columnDelimiter: ",",
				// 		headerCategory: "Floor",
				// 		headerValue: "Value",
				// 		dateFormatter(timestamp) {
				// 			return new Date(timestamp).toDateString()
				// 		}
				// 	},
				// 	svg: {
				// 		filename: "LocationSummary"
				// 	},
				// 	png: {
				// 		filename: "LocationSummary"
				// 	}
				// }
			},
			zoom: {
				enabled: false
			}
		},
		noData: {
			text: "No data to display."
		},
		labels: props?.labels,
		// labels: graphDataAreas?.labels,
		legend: {
			show: false,
			position: "bottom"
		},
		// colors: props?.colors || ["#1A73E8", "#B32824"],
		fill: {
			// colors: ["#1A73E8", "#B32824"]
			colors: props?.colors || []
		},
		colors: props?.colors || [],
		// colors: [
		// 	"#0C48AA",
		// 	"#1065EF",
		// 	"#5591F3",
		// 	"#7aa9f5",
		// 	"#a0c1f8",
		// 	"#c5dafa",
		// 	"#dbe7fc",
		// 	"#e6eefc"
		// ],
		// colors: [
		// 	"#635AFF",
		// 	"#8680FF",
		// 	"#B7B3FF",
		// 	"#c5c2ff",
		// 	"#d1ceff",
		// 	"#d8d6ff",
		// 	"#e0deff",
		// 	"#e7e6ff",
		// 	"#ecebff",
		// 	"#f2f1ff"
		// ],
		// colors: [
		// 	"#99BDF8",
		// 	"#adcaf9",
		// 	"#bbd3fa",
		// 	"#c7dbfb",
		// 	"#d4e3fc",
		// 	"#dfeafc",
		// 	"#eaf1fd",
		// 	"#f1f6fe"
		// ],
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						value: {
							fontSize: "12px",
							fontWeight: 500,
							color: "black",
							offsetY: 2
						},
						total: {
							show: true,
							label: "Total",
							color: "black",
							fontSize: "11px",
							fontWeight: 500,
							// formatter: w => {
							// 	const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
							// 	return `${total}`
							// 	// return `${total} min`
							// 	// const average = total / w.globals.seriesTotals.length;
							// 	// return Math.floor(average);
							// }
							formatter: w => {
								const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0)

								if (total > 60) {
									const hours = Math.floor(total / 60)
									const minutes = total % 60
									return `${hours}h ${minutes.toString().padStart(2, "0")}m`
								}

								return `${total}m`
							}
						}
					}
				}
			}
		},
		tooltip: {
			y: {
				formatter: value => {
					if (value > 60) {
						const hours = Math.floor(value / 60)
						const minutes = value % 60
						return `${hours}h ${minutes.toString().padStart(2, "0")}m`
					}
					return `${value}m`
				}
			}
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: "bottom"
					}
				}
			}
		]
	}
}

function CustomLegend({ data }) {
	let options = data
	// console.log("🚀  options:", options)

	const [displayedOptions, setDisplayedOptions] = useState([])

	useEffect(() => {
		if (!options) return

		setDisplayedOptions(options?.labels?.slice(0, 2))
	}, [options])

	const showNextOptions = () => {
		const currentIndex = options.labels.indexOf(displayedOptions[1])
		let nextIndex = currentIndex + 1

		if (nextIndex >= options.labels.length) {
			nextIndex = 0
		}

		const nextOptions = options.labels.slice(nextIndex, nextIndex + 2)
		setDisplayedOptions(nextOptions)
	}

	const showPreviousOptions = () => {
		let currentIndex = options.labels.indexOf(displayedOptions[0])
		let previousIndex = currentIndex - 2

		if (previousIndex < 0) {
			previousIndex = options.labels.length + previousIndex
		}

		const previousOptions = options.labels.slice(previousIndex, previousIndex + 2)
		setDisplayedOptions(previousOptions)
	}

	return (
		<div className="d-flex justify-content-center align-items-center w-100 h-100">
			{displayedOptions && displayedOptions.length > 0 && (
				<div
					className="row d-flex align-items-center w-100 h-100"
					style={{ justifyContent: displayedOptions.length <= 1 ? "center" : "" }}
				>
					{displayedOptions.length > 0 && (
						<div className="col-2 w-100 h-100 d-flex justify-content-center align-items-center">
							<div
								className="d-flex flex-column justify-content-between"
								style={{
									border: "1px solid #DDE9FD",
									borderRadius: "4px",
									width: "25px",
									height: "60px"
								}}
							>
								<div
									onClick={showPreviousOptions}
									className="d-flex custom_legend_arrow_div_top"
								>
									<ExpandLessIcon style={{ fontSize: "23px", color: "#3077D3" }} />
								</div>
								<div
									onClick={showNextOptions}
									className="d-flex custom_legend_arrow_div_bottom"
								>
									<ExpandMoreIcon style={{ fontSize: "23px", color: "#3077D3" }} />
								</div>
							</div>
						</div>
					)}

					<div className="col-10 pl-1">
						{displayedOptions &&
							displayedOptions.length > 0 &&
							displayedOptions.map((option, index) => (
								<div
									key={index}
									className="d-flex align-items-center mb-5 mt-5"
									// style={{ margin: "12px 0" }}
								>
									<div
										className="mr-3"
										style={{
											backgroundColor:
												options?.colors?.[options.labels?.indexOf(option)],
											width: "20px",
											height: "20px",
											borderRadius: "50%"
										}}
									/>
									<p style={{ fontSize: "11px", margin: "0" }}>
										{/* {options?.buildingsName?.[options.labels?.indexOf(option)]} -{" "} */}
										{options?.floors
											? options?.floors?.[options.labels?.indexOf(option)]
											: options?.buildingsName?.[options.labels?.indexOf(option)]}{" "}
										{options.labels?.length > 1 ? " - " : " "}
										<span style={{ fontWeight: "500" }}>{option}</span>
									</p>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	)
}

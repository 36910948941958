import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	page: "peopleSummary",
	group: "floor",
	lastError: null
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const persistentReportingSlice = createSlice({
	name: "persistentReporting",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		pageSet: (state, action) => {
			state.listLoading = false
			state.error = null
			state.page = action.payload
		},
		groupSet: (state, action) => {
			state.listLoading = false
			state.error = null
			state.group = action.payload
		}
	}
})

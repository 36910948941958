import React, { useEffect, useState, useMemo } from "react"
import { makeStyles, FormControl } from "@material-ui/core"
import SVG from "react-inlinesvg"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import NestedOptionsDropdown from "../../../../../_partials/NestedOptionsDropdown"
import { useUIContext } from "../../../UIContext"

const useStyles = makeStyles(theme => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	menuProps: {
		"& .MuiPaper-root": {
			display: "none !important"
			// border: "1px solid red",
			// maxHeight: "370px !important"
		}
	},
	select: {
		height: "45px",
		width: "100%",
		maxWidth: "400px", // Set the max-width for the Select component
		borderRadius: "4px",
		overflow: "hidden",

		"& .MuiPaper-root": {
			display: "none !important"
			// border: "1px solid red",
			// maxHeight: "370px !important"
		},

		"& .MuiSelect-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	selectWithSize: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	},
	helperText: {
		margin: 0
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	},
	ellipsis: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	stickyListSubheader: {
		backgroundColor: theme.palette.background.paper,
		position: "sticky",
		top: 0,
		zIndex: 1,
		cursor: "default", // Disable cursor for non-interactive appearance
		pointerEvents: "none" // Disable pointer events to make it non-clickable
	}
}))

const GroupsFilter = () => {
	// * ━━★  useUIContext ★━━ */}
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			groupId: UIContext.groupId,
			selectedValues: UIContext.selectedValues,
			setSelectedValues: UIContext.setSelectedValues,
			setIsClosed: UIContext.setIsClosed
		}
	}, [UIContext])

	// * ━━★  useSelector ★━━ */}
	const { allGroups, allRoles, allVendors, allPeople } = useSelector(
		state => ({
			allGroups: state?.manageGroups?.allGroups || [],
			allRoles: state.profile?.currentCustomer?.roles || [],
			allVendors: state.profile?.currentCustomer?.vendors || [],
			allPeople: state?.manageGroups?.allPeople || []
		}),
		shallowEqual
	)

	// * ━━★  const declarations / handlers / functions ★━━ */}

	const [options, setOptions] = useState([])

	const classes = useStyles()
	const variant = "outlined"

	// * ━━★  useEffect ★━━ */}
	useEffect(() => {
		if (!UIProps.selectedValues || UIProps.selectedValues.length === 0) return

		UIProps.setIsClosed(true)
	}, [])

	useEffect(() => {
		if (
			(!allRoles || allRoles.length === 0) &&
			(!allVendors || allVendors.length === 0) &&
			(!allGroups || allGroups.length === 0)
		) {
			return
		}

		const auxOptions = []

		if (allRoles && allRoles.length > 0) {
			const allOptionsRoles =
				options && options.length > 0
					? options
							.find(op => op.label === "Roles")
							?.subOptions.map(sub => sub.name)
							.sort((a, b) => a.localeCompare(b))
					: []

			const allRolesNames =
				allRoles && allRoles.length > 0
					? allRoles.map(op => op.name).sort((a, b) => a.localeCompare(b))
					: []

			if (JSON.stringify(allRolesNames) === JSON.stringify(allOptionsRoles)) return

			const roles = {
				label: "Roles",
				subOptions: allRoles
					.filter(role => role.id)
					.map(role => ({
						name: role.name,
						value: role.id
					}))
			}
			auxOptions.push(roles)
		}

		if (allVendors && allVendors.length > 0) {
			const vendors = {
				label: "Vendors",
				subOptions: allVendors
					.filter(vendor => vendor.id)
					.map(vendor => ({
						name: vendor.company,
						value: vendor.id
					}))
			}
			auxOptions.push(vendors)
		}

		if (allGroups && allGroups.length > 0) {
			const defaultGroups = {
				label: "Default",
				subOptions: allGroups
					.filter(group => group.id)
					.map(group => ({
						name: group.name,
						value: group.id
					}))
			}
			auxOptions.push(defaultGroups)
		}

		setOptions(auxOptions)
	}, [allRoles, allVendors, allGroups])

	return (
		<div data-testid="groups-filter">
			<FormControl className={classes.formControl} variant={variant}>
				<NestedOptionsDropdown
					selectedValues={UIProps.selectedValues}
					setSelectedValues={UIProps.setSelectedValues}
					options={options}
					hasSearchBox={false}
					setIsClosed={UIProps.setIsClosed}
				/>
			</FormControl>

			{UIProps.selectedValues && Object.keys(UIProps.selectedValues).length > 0 && (
				<div style={{ marginBottom: "10px" }} className="row">
					<div className="col-8">
						<div className="row" style={{ paddingLeft: "12.5px" }}>
							<div
								className="col-4 d-flex"
								style={{ position: "relative", overflow: "auto", height: "31px" }}
							>
								{Object.values(UIProps.selectedValues)
									.flat()
									.slice(0, 6)
									.map((data, index) => (
										<div
											key={index}
											className="icon-preview"
											style={{
												position: "absolute",
												left: `${index * 10}px`
											}}
										>
											<SVG src={"/media/crowdkeep/person.svg"} />
										</div>
									))}
							</div>
							<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
								{Object.values(UIProps.selectedValues).flat().length}
							</div>
							<div className="col select-counter">Groups Selected</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default GroupsFilter

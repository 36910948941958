import React from "react"
import { Button as MUIButton, LinearProgress, makeStyles } from "@material-ui/core"
import Box from "@mui/material/Box"
import { green, red } from "@mui/material/colors"
import clsx from "clsx"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
	button: {
		minWidth: "0px",
		borderRadius: "4px",
		boxShadow: "none",
		lineHeight: "21px",
		position: "relative",
		"&.MuiButton-outlined": {
			borderColor: props => theme.palette[props.color]?.main || theme.palette.primary.main
		},
		"& .MuiButton-startIcon": {
			marginLeft: "0",
			// marginRight: "5px"
			marginRight: "0"
		}
	},
	label: {
		fontFamily: "Poppins",
		fontWeight: "700",
		lineHeight: "21px",
		letterSpacing: "0.04em"
	},
	primaryLight: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: "#acd1fa !important"
		}
	},
	warning: {
		backgroundColor: theme.palette.warning.main,
		color: "#fff",
		"&:hover": {
			backgroundColor: "#f4a623 !important"
		}
	},
	warningLight: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.main,
		"&:hover": {
			backgroundColor: "#f8cd82 !important"
		}
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: "#FFF",
		"&:hover": {
			backgroundColor: "#c34415 !important"
		}
	},
	errorLight: {
		backgroundColor: theme.palette.error.light,
		color: "#E65019",
		"&:hover": {
			backgroundColor: "#f5c6b3 !important"
		}
	},
	successLight: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.main,
		"&:hover": {
			backgroundColor: "#77db72 !important"
		}
	},
	greyLight: {
		backgroundColor: theme.palette.grey.light,
		color: theme.palette.grey.dark,
		"&:hover": {
			backgroundColor: theme.palette.grey.main
		}
	},
	failure: {
		backgroundColor: red[500],
		color: "#FFF",
		"&:hover": {
			backgroundColor: red[700] + " !important"
		}
	},
	loadingBar: {
		color: green[500],
		position: "absolute",
		bottom: -6,
		left: 0,
		right: 0,
		zIndex: 1
	}
}))

export const Button = ({
	text,
	className,
	color,
	needsLoadingBar = false,
	onClick,
	success,
	failure,
	setSuccess,
	setFailure,
	loading,
	...props
}) => {
	const classes = useStyles()

	React.useEffect(() => {
		let resetTimer
		if (success) {
			resetTimer = setTimeout(() => {
				setSuccess(false)
			}, 2000) // Reset success state after 2 seconds
		}
		return () => clearTimeout(resetTimer)
	}, [success, setSuccess])

	React.useEffect(() => {
		let resetTimer
		if (failure) {
			resetTimer = setTimeout(() => {
				setFailure(false)
			}, 2000) // Reset failure state after 2 seconds
		}
		return () => clearTimeout(resetTimer)
	}, [failure, setFailure])

	const buttonElement = (
		<MUIButton
			color={
				color !== "primaryLight" &&
				color !== "successLight" &&
				color !== "error" &&
				color !== "errorLight" &&
				color !== "warning" &&
				color !== "warningLight" &&
				color !== "greyLight"
					? color
					: "default"
			}
			className={clsx(
				classes.button,
				className,
				color === "primaryLight" && classes.primaryLight,
				color === "successLight" && classes.successLight,
				color === "error" && classes.error,
				color === "errorLight" && classes.errorLight,
				color === "warning" && classes.warning,
				color === "warningLight" && classes.warningLight,
				color === "greyLight" && classes.greyLight,
				success && classes.successLight, // Apply success styling
				failure && classes.failure // Apply failure styling
			)}
			onClick={onClick}
			{...props}
		>
			{props.children}
			{props.startIcon ? "" : <span className={classes.label}>{text}</span>}
		</MUIButton>
	)

	if (needsLoadingBar) {
		return (
			<Box sx={{ position: "relative" }}>
				{buttonElement}
				{loading && <LinearProgress className={classes.loadingBar} />}
			</Box>
		)
	}

	return buttonElement
}

Button.propTypes = {
	text: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		"primary",
		"secondary",
		"success",
		"primaryLight",
		"successLight",
		"error",
		"errorLight",
		"warning",
		"warningLight",
		"greyLight",
		"default"
	]),
	needsLoadingBar: PropTypes.bool,
	onClick: PropTypes.func,
	children: PropTypes.node,
	success: PropTypes.bool, // Prop to control success state
	failure: PropTypes.bool, // Prop to control failure state
	setSuccess: PropTypes.func, // Function to set the success state
	setFailure: PropTypes.func, // Function to set the failure state
	loading: PropTypes.bool // Prop to control loading state
}

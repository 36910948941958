import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import * as turf from "@turf/turf"
import "@turf/angle"
import { profileSlice } from "../../../../redux/profile/profileSlice"

const { actions } = profileSlice

export function resetZoomOnMap(props) {
	const { map, updatedCurrentFloorPlan, setGoTo } = props

	if (!map) return

	const layers = map.getStyle()

	if (layers) {
		typeof map.getLayer("goToThingMarker") !== "undefined" && map.removeLayer("goToThingMarker")
		typeof map.getSource("goToThingMarker") !== "undefined" && map.removeSource("goToThingMarker")

		if (updatedCurrentFloorPlan && updatedCurrentFloorPlan.mapOrientation) {
			const lat = updatedCurrentFloorPlan?.mapOrientation?.center?.latitude
			const lng = updatedCurrentFloorPlan?.mapOrientation?.center?.longitude
			lng &&
				lat &&
				map.flyTo({
					center: [lng, lat],
					zoom: updatedCurrentFloorPlan.mapOrientation.zoom,
					bearing: updatedCurrentFloorPlan.mapOrientation.bearing,
					pitch: updatedCurrentFloorPlan.mapOrientation.pitch
				})
		} else {
			updatedCurrentFloorPlan &&
				updatedCurrentFloorPlan?.coords?.topLeftCorner?.coords?.longitude &&
				map.fitBounds(
					[
						[
							updatedCurrentFloorPlan.coords.topLeftCorner.coords.longitude,
							updatedCurrentFloorPlan.coords.topLeftCorner.coords.latitude
						],
						[
							updatedCurrentFloorPlan.coords.bottomRightCorner.coords.longitude,
							updatedCurrentFloorPlan.coords.bottomRightCorner.coords.latitude
						]
					],
					{ padding: { top: 10, bottom: 10, left: 10, right: 10 } }
				)
		}
		setGoTo()
	}
}

export function handleSearchChange(props) {
	const {
		dispatch,
		map,
		floorPlans,
		floorPlan,
		value,
		setGoTo,
		setShowPeople,
		setShowAssets,
		setShowPOIs,
		setShowAreaInfoMarkers,
		showRoleAndTypeFunction,
		markersSize,
		tags
		// devices,
		// bleDevices,
		// bleThings,
	} = props

	// console.log("🚀  value:", value)

	if (!value) {
		searchHandleMap({ map, floorPlan, setGoTo })
		return
	}

	let searchedTag = null

	if (value && value.group === "People") {
		showRoleAndTypeFunction("People")

		searchedTag = tags && tags.find(tag => tag?.person?.id === value.id)
	}
	if (value && value.group === "Assets") {
		showRoleAndTypeFunction("Assets")
		searchedTag = tags && tags.find(tag => tag?.asset?.id === value.id)
	}

	// console.log("🚀  searchedTag:", searchedTag)

	var coords
	var polygon
	let lastSeen

	switch (value?.group) {
		// case "Buddies": // not in use, yet.
		//   const device =
		//     devices &&
		//     devices.find((val) => value.devicesArray?.includes(val.clientMac));
		//   if (device) {
		//     coords = [device.gpsData.longitude, device.gpsData.latitude];
		//     break;
		//   }
		//   if (!device) {
		//     // console.log("nao device!");
		//     // console.log(value, "value");

		//     // const dateParsed = value.lastSeen.toDate().toDateString();
		//     const lastSeenDataWithTime = value.lastSeen.toDate();
		//     const time = value.lastSeen.toDate().toLocaleTimeString();
		//     // const finalDate = dateParsed + " at " + time;

		//     let lastExecutionTime = moment(lastSeenDataWithTime).format(); //your last seen time
		//     // console.log("🚀 ~ lastExecutionTime", lastExecutionTime);

		//     let currentTime = moment().format(); //current time

		//     let previousDay = moment(currentTime)
		//       .subtract(24, "hour")
		//       .format(); // calculate 24 hours previous to current time

		//     let canExecuteNow = moment(lastExecutionTime).isBetween(
		//       previousDay,
		//       currentTime
		//     );
		//     // console.log("🚀 ~ canExecuteNow", canExecuteNow);

		//     if (canExecuteNow) {
		//       // User lastSeen coords
		//       coords = [value.gpsData.longitude, value.gpsData.latitude];

		//       // User has been seen in the last 24h of current time
		//       lastSeen = {
		//         lastSeen: true,
		//         date: time,
		//       };
		//       // console.log("🚀 ~ lastSeen", lastSeen);
		//     }
		//   }
		//   const bleDevice =
		//     bleDevices &&
		//     bleDevices.find((val) => value.blesArray?.includes(val.macAddress));
		//   if (bleDevice) {
		//     coords = [bleDevice.gpsData.longitude, bleDevice.gpsData.latitude];
		//     break;
		//   }
		//   break;
		case "Areas":
			// if user searches for another value.group besides "Buddies" removes popup and marker
			coords = null
			lastSeen = null
			/* if (!value.marker) { break }
      coords = [value.marker.longitude, value.marker.latitude] */
			polygon = value.polygon.map(val => turf.point([val.longitude, val.latitude]))
			break
		case "Points of Interest":
			if (!value?.coords) break
			lastSeen = null
			coords = [value.coords.longitude, value.coords.latitude]
			break
		case "People":
			if (!searchedTag?.gpsData) break
			lastSeen = null
			coords = [searchedTag?.gpsData?.longitude, searchedTag?.gpsData?.latitude]
			break
		case "Assets":
			if (!searchedTag?.gpsData) break
			lastSeen = null
			coords = [searchedTag?.gpsData?.longitude, searchedTag?.gpsData?.latitude]
			// coords = [value.gpsData.longitude, value.gpsData.latitude]
			break
		default:
			break
	}

	searchHandleMap({
		floorPlans,
		map,
		floorPlan,
		goTo: value,
		setGoTo,
		coords,
		polygon,
		dispatch,
		lastSeen,
		setShowPeople,
		setShowAssets,
		setShowPOIs,
		setShowAreaInfoMarkers,
		markersSize
	})
}

export function searchHandleMap(params) {
	const {
		floorPlans,
		map,
		floorPlan,
		goTo,
		setGoTo,
		coords,
		polygon,
		dispatch,
		lastSeen,
		setShowPeople,
		setShowAssets,
		setShowPOIs,
		setShowAreaInfoMarkers,
		markersSize
	} = params
	const padding = { padding: { top: 10, bottom: 10, left: 10, right: 10 } }

	// console.log(goTo, "goTo in searchHandleMap")
	// console.log(goTo?.group, "goTo.group")
	// console.log(coords, "coords")
	// console.log(polygon, "polygon")
	// console.log(lastSeen, "lastSeen")
	// console.log("🚀 . floorPlan", floorPlan)

	// Ensure that the markers are displayed on the map when the user makes a search.
	if (goTo && goTo.group) {
		switch (goTo.group) {
			case "People":
				setShowPeople(true)
				break
			case "Assets":
				setShowAssets(true)
				break
			case "Points of Interest":
				setShowPOIs(true)
				break
			case "Areas":
				setShowAreaInfoMarkers(true)
				break
			default:
				break
		}
	}

	const layers = map.getStyle()

	if (layers) {
		typeof map.getLayer("goToThingMarker") !== "undefined" && map.removeLayer("goToThingMarker")
		typeof map.getSource("goToThingMarker") !== "undefined" && map.removeSource("goToThingMarker")

		if (!goTo) {
			// if value is different close popup
			if (!lastSeen) {
				const popup = document.getElementsByClassName("mapboxgl-popup")
				if (popup.length) {
					popup[0].remove()
				}
			}
			if (floorPlan.mapOrientation) {
				const lat = floorPlan?.mapOrientation?.center?.latitude
				const lng = floorPlan?.mapOrientation?.center?.longitude
				map.flyTo({
					center: [lng, lat],
					zoom: floorPlan.mapOrientation.zoom,
					bearing: floorPlan.mapOrientation.bearing,
					pitch: floorPlan.mapOrientation.pitch
				})
			} else {
				floorPlan?.coords?.topLeftCorner?.coords?.longitude &&
					map.fitBounds(
						[
							[
								floorPlan.coords.topLeftCorner.coords.longitude,
								floorPlan.coords.topLeftCorner.coords.latitude
							],
							[
								floorPlan.coords.bottomRightCorner.coords.longitude,
								floorPlan.coords.bottomRightCorner.coords.latitude
							]
						],
						{ padding: { top: 10, bottom: 10, left: 10, right: 10 } }
					)
			}
			setGoTo()
			return
		}

		if (!coords && !polygon) {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: "No location found"
			})
			// if value is different close popup
			if (!lastSeen) {
				const popup = document.getElementsByClassName("mapboxgl-popup")
				if (popup.length) {
					popup[0].remove()
				}
			}
			if (floorPlan.mapOrientation) {
				const lat = floorPlan.mapOrientation.center.latitude
				const lng = floorPlan.mapOrientation.center.longitude
				map.flyTo({
					center: [lng, lat],
					zoom: floorPlan.mapOrientation.zoom,
					bearing: floorPlan.mapOrientation.bearing,
					pitch: floorPlan.mapOrientation.pitch
				})
			} else {
				floorPlan?.topLeftCorner?.lng &&
					map.fitBounds(
						[
							[floorPlan.topLeftCorner.lng, floorPlan.topLeftCorner.lat],
							[floorPlan.bottomRightCorner.lng, floorPlan.bottomRightCorner.lat]
						],
						{ padding: { top: 10, bottom: 10, left: 10, right: 10 } }
					)
			}
			return
		}

		const popup = new mapboxgl.Popup()

		// if value is different close popup
		if (!lastSeen) {
			const popup = document.getElementsByClassName("mapboxgl-popup")
			if (popup.length) {
				popup[0].remove()
			}
		}

		// ━━━━━━━━━━━ If same floorPlan search ━━━━━━━━━━━

		if (floorPlan.id === goTo.floorPlanId) {
			if (polygon) {
				// console.log("🚀 . polygon", polygon)

				var features = turf.featureCollection(polygon)
				var enveloped = turf.envelope(features)
				if (floorPlan.mapOrientation) {
					map.setPitch(floorPlan.mapOrientation.pitch)
					map.fitBounds(enveloped.bbox, {
						bearing: floorPlan.mapOrientation.bearing
					})
				} else {
					map.fitBounds(enveloped.bbox, padding)
				}
				setGoTo(goTo)
				return
			}
			// make sure that layer doesn't exist, so we can add a new layer
			typeof map.getLayer("goToThingMarker") !== "undefined" &&
				map.removeLayer("goToThingMarker")
			typeof map.getSource("goToThingMarker") !== "undefined" &&
				map.removeSource("goToThingMarker")

			//Add thing to map while it is being searched
			map.addSource("goToThingMarker", {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: [
						{
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: coords
							}
						}
					]
				}
			})

			map.addLayer(
				{
					id: "goToThingMarker",
					type: "circle",
					source: "goToThingMarker",
					paint: {
						"circle-color": "#4EECC8",
						"circle-radius": !markersSize?.people ? 20 : markersSize?.people * 0.9 || 20,
						"circle-stroke-width": 0, // ← circle border
						"circle-stroke-color": "#ffffff"
					}
				},
				"settlement-label"
			)

			if (floorPlan.mapOrientation) {
				map.flyTo({
					center: coords,
					zoom: 23,
					bearing: floorPlan.mapOrientation.bearing,
					pitch: floorPlan.mapOrientation.pitch
				})
			} else {
				map.flyTo({
					center: coords,
					zoom: 23
				})
			}

			const el2 = document.createElement("div")
			if (el2) {
				el2.style.width = "auto"
				el2.style.padding = "1.5rem"
				el2.style.cursor = "pointer"
			}

			const popupStick2 = lastSeen && (
				<div>
					<h6 style={{ fontSize: "13px" }}>
						<b>Last seen: </b>
						{lastSeen.date}
					</h6>
				</div>
			)

			// If lastSeen buddy
			lastSeen && el2 && popupStick2 && ReactDOM.render(popupStick2, el2)
			lastSeen && popup.setLngLat(coords).addTo(map).setDOMContent(el2)

			// If thing exists
			const popupStickThings = goTo.group === "Things" && (
				<div>
					<h6 style={{ fontSize: "13px" }}>
						<b>Name: </b>
						{goTo.name}
					</h6>
				</div>
			)

			goTo.group === "Things" &&
				el2 &&
				popupStickThings &&
				ReactDOM.render(popupStickThings, el2)
			goTo.group === "Things" && popup.setLngLat(coords).addTo(map).setDOMContent(el2)

			// on click marker layer opens popup
			map.on("click", "goToThingMarker", e => {
				lastSeen && el2 && popupStick2 && ReactDOM.render(popupStick2, el2)
				// const popup =
				//   lastSeen &&
				//   new mapboxgl.Popup()
				//     .setLngLat(coords)
				//     .addTo(map)
				//     .setDOMContent(el2)

				goTo.group === "Things" &&
					el2 &&
					popupStickThings &&
					ReactDOM.render(popupStickThings, el2)
				// const popupThings =
				//   goTo.group === "Things" &&
				//   new mapboxgl.Popup()
				//     .setLngLat(coords)
				//     .addTo(map)
				//     .setDOMContent(el2)
			})

			// Change the cursor to a pointer when the mouse is over the places layer.
			map.on("mouseenter", "goToThingMarker", () => {
				map.getCanvas().style.cursor = "pointer"
			})

			// Change it back to a pointer when it leaves.
			map.on("mouseleave", "goToThingMarker", () => {
				map.getCanvas().style.cursor = "default"
			})

			setGoTo(goTo)
			return
		}

		// console.log("🚀 . floorPlan", floorPlan)

		// ━━━━━━━━━━━ If different floorPlan ━━━━━━━━━━━
		if (floorPlan.mapOrientation) {
			const lat = floorPlan.mapOrientation.center.latitude
			const lng = floorPlan.mapOrientation.center.longitude
			map.flyTo({
				center: [lng, lat],
				zoom: floorPlan.mapOrientation.zoom,
				bearing: floorPlan.mapOrientation.bearing,
				pitch: floorPlan.mapOrientation.pitch
			})
		} else {
			floorPlan?.coords?.topLeftCorner?.coords?.longitude &&
				map.fitBounds(
					[
						[
							floorPlan.coords.topLeftCorner.coords.longitude,
							floorPlan.coords.topLeftCorner.coords.latitude
						],
						[
							floorPlan.coords.bottomRightCorner.coords.longitude,
							floorPlan.coords.bottomRightCorner.coords.latitude
						]
					],
					{ padding: { top: 10, bottom: 10, left: 10, right: 10 } }
				)
		}

		map.once("moveend", () => {
			const f = floorPlans?.find(val => val.id === goTo.floorPlanId)

			if (!f) {
				dispatch({
					type: "SNACKBAR_WARNING",
					payload: "No location found"
				})
				return
			}

			f && dispatch(actions.floorPlanSelected(f))
			setGoTo(goTo)
			setTimeout(() => {
				if (polygon) {
					var features = turf.featureCollection(polygon)
					var enveloped = turf.envelope(features)
					if (floorPlan.mapOrientation) {
						map.setPitch(floorPlan.mapOrientation.pitch)
						map.fitBounds(enveloped.bbox, {
							bearing: floorPlan.mapOrientation.bearing
						})
					} else {
						map.fitBounds(enveloped.bbox, padding)
					}
					return
				}

				typeof map.getLayer("goToThingMarker") !== "undefined" &&
					map.removeLayer("goToThingMarker")
				typeof map.getSource("goToThingMarker") !== "undefined" &&
					map.removeSource("goToThingMarker")

				//Add thing to map while it is being searched
				map.addSource("goToThingMarker", {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: [
							{
								type: "Feature",
								geometry: {
									type: "Point",
									coordinates: coords
								}
							}
						]
					}
				})

				map.addLayer(
					{
						id: "goToThingMarker",
						type: "circle",
						source: "goToThingMarker",
						paint: {
							"circle-color": "#4EECC8",
							"circle-radius": !markersSize?.people ? 20 : markersSize?.people * 0.9 || 20,
							"circle-stroke-width": 0, // ← circle border
							"circle-stroke-color": "#ffffff"
						}
					},
					"settlement-label"
				)

				const el2 = document.createElement("div")
				if (el2) {
					el2.style.width = "auto"
					el2.style.padding = "1.5rem"
					el2.style.cursor = "pointer"
				}

				// If thing exists
				const popupStickThings = goTo.group === "Things" && (
					<div>
						<h6 style={{ fontSize: "13px" }}>
							<b>Name: </b>
							{goTo.name}
						</h6>
					</div>
				)

				goTo.group === "Things" &&
					el2 &&
					popupStickThings &&
					ReactDOM.render(popupStickThings, el2)
				goTo.group === "Things" && popup.setLngLat(coords).addTo(map).setDOMContent(el2)

				// on click marker layer opens popup
				map.on("click", "goToThingMarker", e => {
					goTo.group === "Things" &&
						el2 &&
						popupStickThings &&
						ReactDOM.render(popupStickThings, el2)
					// const popupThings =
					//   goTo.group === "Things" &&
					//   new mapboxgl.Popup()
					//     .setLngLat(coords)
					//     .addTo(map)
					//     .setDOMContent(el2)
				})

				if (floorPlan.mapOrientation) {
					map.flyTo({
						center: coords,
						zoom: 23,
						bearing: floorPlan.mapOrientation.bearing,
						pitch: floorPlan.mapOrientation.pitch
					})
				} else {
					map.flyTo({
						center: coords,
						zoom: 23
					})
				}
			}, 1000)
		})
	}
}

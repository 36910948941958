import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	page: null,
	group: null,
	lastError: null
}
export const callTypes = {
	list: "list",
	action: "action",
	floorPeopleDistribution: "floorPeopleDistribution",
	allPeopleDistribution: "allPeopleDistribution",
	allPeopleTimesheet: "allPeopleTimesheet",
	singlePeopleTimesheet: "singlePeopleTimesheet",
	areaAvgTimeSpent: "areaAvgTimeSpent",
	peopleDistribution: "peopleDistribution",
	areaLocationTimeSpent: "areaLocationTimeSpent",
	peopleDistributionLocation: "peopleDistributionLocation",
	peopleBuildings: "peopleBuildings",

	allAssetsDistribution: "allAssetsDistribution",
	areaLocationTimeSpentAssets: "areaLocationTimeSpentAssets",
	assetsDistributionLocation: "assetsDistributionLocation",
	assetsBuildings: "assetsBuildings",
	areaAvgTimeSpentAssets: "areaAvgTimeSpentAssets",
	assetsDistribution: "assetsDistribution",
	allAssetsTimesheet: "allAssetsTimesheet",
	singleAssetTimesheet: "singleAssetTimesheet"
}

export const reportingSlice = createSlice({
	name: "reporting",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			switch (action.payload.callType) {
				case callTypes.list:
					state.listLoading = true
					break
				case callTypes.action:
					state.actionsLoading = true
					break

				// People Floor \\
				// ↓ New fetch all people
				case callTypes.floorPeopleDistribution:
					state.floorPeopleDistributionLoading = true
					break

				case callTypes.allPeopleDistribution:
					state.allPeopleDistributionLoading = true
					break
				case callTypes.allPeopleTimesheet:
					state.allPeopleTimesheetLoading = true
					break
				case callTypes.singlePeopleTimesheet:
					state.singlePeopleTimesheetLoading = true
					break
				case callTypes.areaAvgTimeSpent:
					state.areaAvgTimeSpentLoading = true
					break
				case callTypes.peopleDistribution:
					state.peopleDistributionLoading = true
					break
				case callTypes.areaLocationTimeSpent:
					state.areaLocationTimeSpentLoading = true
					break
				case callTypes.peopleDistributionLocation:
					state.peopleDistributionLocationLoading = true
					break
				case callTypes.peopleBuildings:
					state.peopleBuildingsLoading = true
					break
				// Assets
				case callTypes.allAssetsDistribution:
					state.allAssetsDistributionLoading = true
					break
				case callTypes.areaLocationTimeSpentAssets:
					state.areaLocationTimeSpentAssetsLoading = true
					break
				case callTypes.assetsDistributionLocation:
					state.assetsDistributionLocationLoading = true
					break
				case callTypes.assetsBuildings:
					state.assetsBuildingsLoading = true
					break
				case callTypes.areaAvgTimeSpentAssets:
					state.areaAvgTimeSpentLoadingAssets = true
					break
				case callTypes.assetsDistribution:
					state.assetsDistributionLoading = true
					break
				case callTypes.allAssetsTimesheet:
					state.allAssetsTimesheetLoading = true
					break
				case callTypes.singleAssetTimesheet:
					state.singleAssetTimesheetLoading = true
					break
				default:
					break
			}
		},
		// People Floor \\
		// ↓ New fetch all people fetch
		allPeopleDistributionFetched: (state, action) => {
			state.allPeopleDistributionLoading = false
			state.allPeopleDistribution = action.payload
		},
		allPeopleToTimesheetsFetched: (state, action) => {
			state.allPeopleTimesheetLoading = false
			state.allPeopleTimesheet = action.payload
		},
		singlePeopleToTimesheetsFetched: (state, action) => {
			state.singlePeopleTimesheetLoading = false
			state.singlePeopleTimesheet = action.payload
		},
		areaAvgTimeSpentFetched: (state, action) => {
			state.areaAvgTimeSpentLoading = false
			state.areaAvgTimeSpent = action.payload
		},
		peopleDistributionFetched: (state, action) => {
			state.peopleDistributionLoading = false
			state.peopleDistribution = action.payload
		},
		fetchLocationReportAreasFetched: (state, action) => {
			state.areaLocationTimeSpentLoading = false
			state.areaLocationTimeSpent = action.payload
		},
		peopleDistributionLocationFetched: (state, action) => {
			state.peopleDistributionLocationLoading = false
			state.peopleDistributionLocation = action.payload
		},
		peopleBuildingsFetched: (state, action) => {
			state.peopleBuildingsLoading = false
			state.peopleBuildings = action.payload
		},

		// Assets
		allAssetsDistributionFetched: (state, action) => {
			state.allAssetsDistributionLoading = false
			state.allAssetsDistribution = action.payload
		},
		fetchLocationReportAreasFetchedAssets: (state, action) => {
			state.areaLocationTimeSpentAssetsLoading = false
			state.areaLocationTimeSpentAssets = action.payload
		},
		assetsDistributionLocationFetched: (state, action) => {
			state.assetsDistributionLocationLoading = false
			state.assetsDistributionLocation = action.payload
		},
		assetsBuildingsFetched: (state, action) => {
			state.assetsBuildingsLoading = false
			state.assetsBuildings = action.payload
		},
		areaAvgTimeSpentFetchedAssets: (state, action) => {
			state.areaAvgTimeSpentLoadingAssets = false
			state.areaAvgTimeSpentAssets = action.payload
		},
		assetsDistributionFetched: (state, action) => {
			state.assetsDistributionLoading = false
			state.assetsDistribution = action.payload
		},
		allAssetsToTimesheetsFetched: (state, action) => {
			state.allAssetsTimesheetLoading = false
			state.allAssetsTimesheet = action.payload
		},
		singleAssetToTimesheetsFetched: (state, action) => {
			state.singleAssetTimesheetLoading = false
			state.singleAssetTimesheet = action.payload
		}
	}
})

import { firestore } from "../../../../../firebase"
import { collection, doc, getDocs, writeBatch } from "firebase/firestore"

async function callGetNodes(params) {
	const { technology, customerId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/get-nodes`
	const url = new URL(baseUrl)
	customerId && url.searchParams.append("customerId", customerId)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}
function callGetModels(params) {
	const { technology, customerId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		customerId
	})

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		// body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/get-models`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callGetFirmwares(params) {
	const { technology, customerId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		customerId
	})

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		// body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/get-firmwares`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callGetCustomersAndSites(params) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/get-customers-and-sites`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callCreateNodes(params) {
	const { technology, data } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...data
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/create-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callUpdateNodes(params) {
	// console.log("🚀 ~ callUpdateNodes ~ params:", params)
	const { technology, data } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...data
	})
	// console.log("🚀 ~ callUpdateNodes ~ raw:", raw)

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	// console.log(
	// 	`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/update-nodes`
	// )

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/update-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callCreateModel(params) {
	// console.log("🪲 ~ callCreateModel ~ params:", params)
	const { technology, data } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...data
		// technology: technology
	})
	// console.log("🪲 ~ callCreateModel ~ raw:", raw)

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/create-model`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callUpdateModel(params) {
	const { technology, data } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...data
		// technology: technology
	})
	// console.log("🪲 ~ callUpdateModel ~ raw:", raw)

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/update-model`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callCreateFirmware(params) {
	const { technology, data } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...data
		// technology: technology
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/create-firmware`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callDeleteModels(params) {
	const { technology, callersUserId, modelId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		callersUserId,
		modelId: modelId[0]
	})

	const requestOptions = {
		method: "DELETE",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/delete-model`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callDeleteFirmwares(params) {
	const { technology, callersUserId, firmwareId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		callersUserId,
		firmwareId: firmwareId[0]
	})

	const requestOptions = {
		method: "DELETE",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/delete-firmware`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callAssignInventoryNodes(params) {
	// console.log("🪲 ~ callAssignInventoryNodes ~ params:", params)
	const { technology, data } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...data
	})
	// console.log("🪲 ~ callAssignInventoryNodes ~ raw:", raw)

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	// console.log("🪲 ~ callAssignInventoryNodes ~ raw:", raw)

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/assign-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callUnassignInventoryNodes(params) {
	const { technology, data } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...data
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/unassign-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}
function callDeleteInventoryNodes(params) {
	const { technology, callersUserId, nodes } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		callersUserId,
		nodes: nodes
	})

	const requestOptions = {
		method: "DELETE",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/${technology}/delete-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

//#1 get tehcnology list
export async function getTechnologyList() {
	const infrastructureCollection = collection(firestore, "Infrastructure")
	const infrastructureSnapshot = await getDocs(infrastructureCollection)

	let techList = []
	infrastructureSnapshot.docs.map(async techDoc => {
		techList.push(techDoc.id)
	})

	return techList
}

//#2 get all data for table //r's from cRud
export function getNodes(queryParams) {
	if (!queryParams?.technology) return

	return callGetNodes({
		customerId: queryParams.customerId || null,
		technology: queryParams.technology
	})
}
export async function getModels(queryParams) {
	if (!queryParams?.technology) return

	return callGetModels({
		customerId: queryParams.customerId || "",
		technology: queryParams.technology
	})
}
export function getFirmwares(queryParams) {
	if (!queryParams?.technology) return

	return callGetFirmwares({
		customerId: queryParams.customerId || "",
		technology: queryParams.technology
	})
}
export function getCustomersAndSites() {
	return callGetCustomersAndSites()
}

/*** NODES ***/
export function createNodes(queryParams) {
	if (!queryParams?.data || !queryParams?.technology) return

	return callCreateNodes({
		data: queryParams.data,
		technology: queryParams.technology
	})
}
export function updateNodes(queryParams) {
	if (!queryParams?.data || !queryParams?.technology) return

	return callUpdateNodes({
		...queryParams
	})
}
export function assignInventoryNodes(queryParams) {
	if (!queryParams || !queryParams.data || !queryParams.technology) return

	return callAssignInventoryNodes(queryParams)
}
export function unassignInventoryNodes(queryParams) {
	if (!queryParams || !queryParams.data || !queryParams.technology) return

	return callUnassignInventoryNodes(queryParams)
}
export function deleteInventoryNodes(queryParams) {
	if (!queryParams || !queryParams.nodes || !queryParams.technology) return

	return callDeleteInventoryNodes(queryParams)
}

/*** MODELS ***/
export function createModel(queryParams) {
	if (!queryParams?.data || !queryParams?.technology) return

	return callCreateModel({
		data: queryParams.data,
		technology: queryParams.technology
	})
}
export function updateModel(queryParams) {
	if (!queryParams?.data || !queryParams?.technology) return

	return callUpdateModel({
		data: queryParams.data,
		technology: queryParams.technology
	})
}
export function deleteModels(queryParams) {
	if (!queryParams?.modelId || !queryParams?.technology || !queryParams.callersUserId) return

	return callDeleteModels({
		modelId: queryParams.modelId,
		technology: queryParams.technology,
		callersUserId: queryParams.callersUserId
	})
}

/*** FIRMWARES ***/
export function createFirmware(queryParams) {
	if (!queryParams?.data || !queryParams?.technology) return

	return callCreateFirmware({
		data: queryParams.data,
		technology: queryParams.technology
	})
}
export function deleteFirmwares(queryParams) {
	if (!queryParams?.firmwareId || !queryParams?.technology || !queryParams.callersUserId) return

	return callDeleteFirmwares({
		firmwareId: queryParams.firmwareId,
		technology: queryParams.technology,
		callersUserId: queryParams.callersUserId
	})
}

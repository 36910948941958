import React, { useEffect, useState } from "react"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { GrFilter } from "react-icons/gr"
import { Button } from "../../../../_partials/Button"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography"

const FirmwaresInventoryFilter = ({
	showFilter,
	setShowFilter,
	filterVersionOptions,
	setFilterVersionOptions,
	filterSeverityOptions,
	setFilterSeverityOptions,
	filterNameOptions,
	setFilterNameOptions,
	filterVendorOptions,
	setFilterVendorOptions
}) => {
	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [allSelected, setAllSelected] = useState(false)
	const [saving, setSaving] = useState(false)

	// dynamicSelectedIconVersion Roles
	const dynamicSelectedIconVersion = index => {
		return filterVersionOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconSeverity = index => {
		return filterSeverityOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconVendor = index => {
		return filterVendorOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconName = index => {
		return filterNameOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}

	// ━━━━ Toggle button icons according to the state
	const toggleButtonVersion = index => {
		setFilterVersionOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonSeverity = index => {
		setFilterSeverityOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonVendor = index => {
		setFilterVendorOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonName = index => {
		setFilterNameOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}

	// ━━━━ Select all filterVersionOptions button
	const selectAllOptions = () => {
		setFilterVersionOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterSeverityOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterVendorOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterNameOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
	}

	// ━━━━ PerfectScrollbar
	useEffect(() => {
		const containerTypes = document.getElementById("scroll-container-drawer-types-buttons")

		if (containerTypes) {
			const ps = new PerfectScrollbar(containerTypes, {
				wheelSpeed: 1,
				wheelPropagation: true,
				suppressScrollX: true
			})
			// ps.update()
		}
	}, [])

	useEffect(() => {
		const allSelect =
			filterVersionOptions.every(filter => filter.selected) &&
			filterSeverityOptions.every(filter => filter.selected) &&
			filterVendorOptions.every(filter => filter.selected) &&
			filterNameOptions.every(filter => filter.selected) &&
			setAllSelected(allSelect)
	}, [filterVersionOptions, filterSeverityOptions, filterVendorOptions, filterNameOptions])

	const checkedIcon = <CheckIcon style={{ color: "#FFFFFF" }} />
	const addIcon = <AddIcon style={{ color: "#8C8CA2" }} />

	const onHide = () => {
		setShowFilter(false)
	}

	return (
		<Modal
			show={showFilter}
			onHide={onHide}
			size="sm"
			data-testid="assignInventoryModal"
			backdrop="static"
		>
			<Modal.Header style={{ padding: "0.5rem 1rem 0 2rem", border: "none" }}>
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div className="col-auto">
						<div className="row align-items-center">
							<GrFilter
								className="drawer_option_title_icon1"
								style={{
									filter:
										"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
								}}
							/>
							<div style={{ marginLeft: "10px" }}>
								{/* <span className="custom-modal-title">Filter</span> */}
								<Bold>Filter</Bold>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<IconButton onClick={onHide} style={{ left: "12px" }} data-testid="close-button">
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "1rem",
					overflow: "hidden"
				}}
			>
				<div>
					{filterVersionOptions && filterVersionOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Version</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterVersionOptions &&
							filterVersionOptions.length > 0 &&
							filterVersionOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconVersion(index)}
												style={{
													background: filterVersionOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterVersionOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonVersion(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterVersionOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconVersion(index + 1)}
													style={{
														background: filterVersionOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterVersionOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonVersion(index + 1)
													}}
												>
													<span>{filterVersionOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterSeverityOptions && filterSeverityOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Severity</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterSeverityOptions &&
							filterSeverityOptions.length > 0 &&
							filterSeverityOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconSeverity(index)}
												style={{
													background: filterSeverityOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterSeverityOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonSeverity(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterSeverityOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconSeverity(index + 1)}
													style={{
														background: filterSeverityOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterSeverityOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonSeverity(index + 1)
													}}
												>
													<span>{filterSeverityOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterVendorOptions && filterVendorOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Vendor</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterVendorOptions &&
							filterVendorOptions.length > 0 &&
							filterVendorOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconVendor(index)}
												style={{
													background: filterVendorOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterVendorOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonVendor(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterVendorOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconVendor(index + 1)}
													style={{
														background: filterVendorOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterVendorOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonVendor(index + 1)
													}}
												>
													<span>{filterVendorOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					{filterNameOptions && filterNameOptions.length > 0 && (
						<p className="drawer_option_title_secondary">Name</p>
					)}

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
					>
						{filterNameOptions &&
							filterNameOptions.length > 0 &&
							filterNameOptions.map((type, index) => {
								if (index % 2 === 0) {
									return (
										<div key={index} className="d-flex justify-content-between mb-2">
											<Button
												className="inventory_options_filter_button"
												endIcon={dynamicSelectedIconName(index)}
												style={{
													background: filterNameOptions[index].selected
														? "#3077D3"
														: "#DBE1E5",
													color: filterNameOptions[index].selected
														? "#FFFFFF"
														: "#8C8CA2",
													marginRight: "10px"
												}}
												variant="contained"
												onClick={() => {
													toggleButtonName(index)
												}}
											>
												<span>{type?.node}</span>
											</Button>
											{filterNameOptions[index + 1] && (
												<Button
													className="inventory_options_filter_button"
													endIcon={dynamicSelectedIconName(index + 1)}
													style={{
														background: filterNameOptions[index + 1].selected
															? "#3077D3"
															: "#DBE1E5",
														color: filterNameOptions[index + 1].selected
															? "#FFFFFF"
															: "#8C8CA2"
													}}
													variant="contained"
													onClick={() => {
														toggleButtonName(index + 1)
													}}
												>
													<span>{filterNameOptions[index + 1]?.node}</span>
												</Button>
											)}
										</div>
									)
								} else {
									return null // Render nothing for odd indices
								}
							})}
					</div>

					<div className="d-flex align-items-center justify-content-end mt-5">
						<Button
							className="inventory_options_filter_button_select_all_filter_one "
							style={{
								backgroundColor: "#FFFFFF",
								color: "#3077D3",
								border: "1px solid #3077D3",
								width: !allSelected ? "120px" : "130px",
								height: "31px"
							}}
							variant="contained"
							text={!allSelected ? "Select All" : "Unselect All"}
							onClick={() => {
								selectAllOptions()
							}}
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default FirmwaresInventoryFilter

const perfectScrollbarOptions = {
	wheelSpeed: 2,
	wheelPropagation: false
}

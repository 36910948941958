import React from "react"

const Title = ({ text }) => {
	return (
		<div
			style={{
				color: "#4A4A68",
				fontFamily: "Poppins",
				fontSize: "18px",
				fontWeight: "600",
				lineHeight: "160%",
				marginBottom: "15px"
			}}
		>
			{text}
		</div>
	)
}

export default Title

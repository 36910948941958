import React, { createContext, useContext, useState, useCallback } from "react"
import { isEqual, isFunction } from "lodash"
// import { initialFilter } from "./UIHelpers"

const UIContext = createContext()

const initialFilter = {
	filter: {
		searchText: "",
		searchField: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "displayName",
	pageNumber: 1,
	pageSize: 10
}

const initialColumnsOrderGroups = [
	{
		field: "pic",
		sort: null
	},
	{
		field: "name",
		sort: null
	},
	{
		field: "members",
		sort: null
	}
]

export function useUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ UIEvents, children }) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter)
	const [idsGroups, setIdsGroups] = useState([])

	//for groups table
	const [showDisabledGroups, setShowDisabledGroups] = useState(false)
	const [columnsOrderGroups, setColumnsOrderGroups] = useState(initialColumnsOrderGroups)
	const [rowsToUseGroups, setRowsToUseGroups] = useState([])
	const [columnVisibilityModelGroups, setColumnVisibilityModelGroups] = useState({}) //visibile columns object for exporting
	const [currentTablePageInfoGroups, setCurrentTablePageInfoGroups] = useState({}) //page and pageSize to export current page only

	//for groups table
	const [selectedValues, setSelectedValues] = useState([])
	const [isClosed, setIsClosed] = useState([])
	const [sitesValue, setSitesValue] = useState([])
	const [confirmTabRows, setConfirmTabRows] = useState([])
	const [groupName, setGroupName] = useState("")
	const [groupId, setGroupId] = useState(null)
	const [members, setMembers] = useState([])

	//for communication
	const [membersToSendMessage, setMembersToSendMessage] = useState([])

	const setQueryParams = useCallback(nextQueryParams => {
		setQueryParamsBase(prevQueryParams => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams)
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams
			}

			return nextQueryParams
		})
	}, [])

	const value = {
		queryParams,
		setQueryParamsBase,
		idsGroups,
		setIdsGroups,

		//Groups
		showDisabledGroups,
		setShowDisabledGroups,
		columnsOrderGroups,
		setColumnsOrderGroups,
		rowsToUseGroups,
		setRowsToUseGroups,
		columnVisibilityModelGroups,
		setColumnVisibilityModelGroups,
		currentTablePageInfoGroups,
		setCurrentTablePageInfoGroups,

		//create Groups
		selectedValues,
		setSelectedValues,
		isClosed,
		setIsClosed,
		sitesValue,
		setSitesValue,
		confirmTabRows,
		setConfirmTabRows,
		groupName,
		setGroupName,
		groupId,
		setGroupId,
		members,
		setMembers,

		//communication
		membersToSendMessage,
		setMembersToSendMessage
	}

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

import React, { useState } from "react"
import { Button } from "../../../_partials/Button"
import { Modal } from "@material-ui/core"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import MassTextAlertsModalBody from "./MassTextAlertsModalBody"
import SVG from "react-inlinesvg"

const MassTextAlerts = () => {
	//━━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━━\\
	const [buttonColor, setbuttonColor] = useState()
	const [open, setOpen] = useState(false)
	const [buttonCoordinates, setButtonCoordinates] = useState({ top: 0, left: 0 })

	// ━━━━━━━━━━━━━━━ Functions ━━━━━━━━━━━━━━━\\
	const handleOpen = event => {
		setbuttonColor("primaryLight")
		setOpen(true)
		const buttonRect = event.currentTarget.getBoundingClientRect()
		setButtonCoordinates({ top: buttonRect.bottom + 10, left: buttonRect.left - 505 })
	}

	const handleClose = () => {
		setbuttonColor()
		setOpen(false)
	}

	return (
		<>
			<OverlayTrigger
				style={{}}
				placement="bottom"
				overlay={
					<Tooltip
						id="firebase-notification-tooltip"
						style={{ visibility: open ? "hidden" : "visible" }}
					>
						Mass Messaging
					</Tooltip>
				}
			>
				<Button
					color={buttonColor}
					startIcon={
						<SVG
							// style={{ marginLeft: "2px" }}
							src={"/svg/communication/mass-communication-send.svg"}
						/>
					}
					style={{
						width: "36px",
						height: "36px",
						borderRadius: "25px",
						minWidth: "0",
						// marginRight: "15px"
						marginRight: "4px"
					}}
					onClick={handleOpen}
				/>
			</OverlayTrigger>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				style={{
					top: buttonCoordinates.top,
					left: buttonCoordinates.left,
					position: "absolute"
				}}
			>
				<div>
					<MassTextAlertsModalBody onHide={handleClose} />
				</div>
			</Modal>
		</>
	)
}

export default MassTextAlerts

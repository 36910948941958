import React, { useEffect, useMemo, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import { shallowEqual, useSelector } from "react-redux"
import * as UIHelpers from "../UIHelpers"
import { sortCaret } from "../../../../../_metronic/_helpers"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Toolbar } from "./toolbar/Toolbar"
import moment from "moment"
import { Dropdown } from "react-bootstrap"

//for data grid
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import SearchIcon from "@material-ui/icons/Search"
import { useUIContext } from "../UIContext"
import WarningIcon from "@material-ui/icons/Warning"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { BiEditAlt } from "react-icons/bi"
import { Button } from "../../../../_partials/Button"
import { Pagination } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core"
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridOverlay
} from "@mui/x-data-grid"
import Box from "@mui/material/Box"
import {
	filterBySearchString,
	timeStringComparator,
	dayMonthYearComparator
} from "../../../../helpersGlobal/DataGridHelpers"
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid"

//for data grid
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		border: "none",
		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},

		"& .MuiDataGrid-main": {
			padding: "30px 40px"
		},

		"& .MuiDataGrid-columnHeaders": {
			padding: "30px 40px"
		},

		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0" // Ensure that minWidth is set to 0 to allow percentage width
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},

		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "-1px !important",
			border: "1px solid #f4f8fe !important"
		},

		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
			minHeight: "90px !important"
		}
	},

	"& .MuiDataGrid-columnSeparator": {
		opacity: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "600 !important",
		fontSize: "1rem",
		color: "#4a4a68"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--textLeft": {
		fontSize: "14px !important",
		fontWeight: "500 !important"
	},

	"& .MuiDataGrid-columnHeaderTitleContainer": {
		paddingLeft: "0 !important",
		paddingRight: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
		paddingRight: "0px !important",
		paddingLeft: "10px !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
		paddingLeft: "2px"
	},

	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		backgroundColor: "inherit !important"
	},

	"& .MuiDataGrid-colCell": {
		minWidth: 0
	},

	"& .actions-header": {
		marginLeft: "10px"
	},

	"& .MuiDataGrid-row": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiPagination-root": {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	"& .MuiPaginationItem-root": {
		borderRadius: "3px !important",
		color: "#3077d3 !important",
		padding: "0 !important",
		margin: "0 !important",
		border: "1px solid #f4f8fe !important"
	},

	"& .MuiPaginationItem-textPrimary.Mui-selected": {
		color: "#fff !important",
		border: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
		{
			color: "#3077d3"
		},

	"& .MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon": {
		color: "#3077d3"
	},

	"& .MuiDataGrid-root .MuiDataGrid-viewport": {
		minHeight: "700px",
		maxHeight: "none"
	},

	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
		minHeight: "90px !important"
	}
}))

//for data grid
function CustomPagination() {
	// const { state, apiRef } = useGridSlotComponentProps()
	const apiRef = useGridApiContext()
	// const [state] = useGridState(apiRef)
	const state = apiRef.current.state
	const rootElement = apiRef.current.rootElementRef
	const rootProps = useGridRootProps()
	const classes = useStyles()
	const options = [5, 10, 20, 30, 50, 100]

	const handlePageSizeChange = pageSize => {
		apiRef.current.setPageSize(pageSize)
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{state.pagination.pageSize}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option}
								as="button"
								onClick={() => handlePageSizeChange(option)}
							>
								{option}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			<Pagination
				className={classes.root}
				id="custom-pagination"
				color="primary"
				count={state.pagination.pageCount}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>
		</>
	)
}

export function ContactTracingTable({
	contactTracingData,
	rows,
	setRows,
	isLoading,
	setShowExporModal
}) {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			//for data grid
			ids: UIContext.ids,
			setIds: UIContext.setIds,
			columnsOrder: UIContext.columnsOrder,
			setColumnsOrder: UIContext.setColumnsOrder,
			setRowsToUse: UIContext.setRowsToUse,
			showDisabled: UIContext.showDisabled,
			columnVisibilityModel: UIContext.columnVisibilityModel,
			setColumnVisibilityModel: UIContext.setColumnVisibilityModel,
			setCurrentTablePageInfo: UIContext.setCurrentTablePageInfo
		}
	}, [UIContext])

	//added for data grid
	const [searchText, setSearchText] = useState("")
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [searchedRows, setSearchedRows] = useState(null)
	const [selectionModel, setSelectionModel] = useState([])
	const [rowsToUse, setRowsToUse] = useState([])
	const [currentRows, setCurrentRows] = useState([])
	const [rowCounter, setRowCounter] = useState(0)
	const [rowCountState, setRowCountState] = useState(rowCounter)
	const [sortModel, setSortModel] = useState([
		{
			field: "area",
			sort: "asc"
		}
	])

	//for data grid
	const classes = useStyles()

	//changed for data grid
	const columns = [
		{
			field: "date",
			headerName: "Date",
			width: 500,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			sortable: false,
			valueFormatter: params => moment(params.value).format("ddd MMM D YYYY"),
			sortComparator: dayMonthYearComparator
		},
		{
			field: "area",
			headerName: "Area",
			width: 500,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "time",
			headerName: "Time",
			width: 500,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			valueFormatter: params => {
				const cell = params.value
				return cell
					? `0${Math.floor(cell / 60)}`.slice(-2) + "h " + ("0" + (cell % 60)).slice(-2) + "m"
					: ""
			},
			sortComparator: timeStringComparator
		}
	]

	// const columns = [
	// 	{
	// 		dataField: "date",
	// 		text: "Date",
	// 		sort: true,
	// 		sortCaret: sortCaret,
	// 		formatter: cell => moment(cell).format("ddd MMM D YYYY")
	// 	},
	// 	// {
	// 	// 	dataField: "floorPlan",
	// 	// 	text: "Floor",
	// 	// 	sort: true,
	// 	// 	sortCaret: sortCaret,
	// 	// 	formatter: cell => {
	// 	// 		if (!floorPlans) {
	// 	// 			return "Unknown floor"
	// 	// 		}
	// 	// 		return floorPlans.find(val => val.id === cell)?.name
	// 	// 	}
	// 	// },
	// 	{
	// 		dataField: "area",
	// 		text: "Area",
	// 		sort: true,
	// 		sortCaret: sortCaret
	// 	},
	// 	{
	// 		dataField: "time",
	// 		text: "Time",
	// 		sort: true,
	// 		sortCaret: sortCaret,
	// formatter: cell => {
	// 	// return cell ? `0${(cell / 60) ^ 0}`.slice(-2) + ":" + ("0" + (cell % 60)).slice(-2) + " h" : ""
	// 	return cell
	// 		? `0${(cell / 60) ^ 0}`.slice(-2) + "h " + ("0" + (cell % 60)).slice(-2) + "m"
	// 		: ""
	// }
	// 	}
	// ]

	// const paginationOptions = paginationFactory({
	// 	sizePerPageList: UIHelpers.sizePerPageList,
	// 	sizePerPage: UIHelpers.initialFilter.pageSize,
	// 	sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
	// 		<Dropdown>
	// 			<Dropdown.Toggle variant="light" id="dropdown-basic">
	// 				{currSizePerPage}
	// 			</Dropdown.Toggle>
	// 			<Dropdown.Menu>
	// 				{options.map(option => {
	// 					return (
	// 						<Dropdown.Item
	// 							key={option.text}
	// 							as="button"
	// 							onClick={() => onSizePerPageChange(option.page)}
	// 						>
	// 							{option.text}
	// 						</Dropdown.Item>
	// 					)
	// 				})}
	// 			</Dropdown.Menu>
	// 		</Dropdown>
	// 	)
	// })

	// Give marginTop to the pagination at bottom of table
	useEffect(() => {
		if (!contactTracingData) return

		// Give marginTop on paginationFactory div
		const htmlCollection = document.getElementsByClassName("react-bootstrap-table-pagination")

		if (htmlCollection) {
			Array.from(htmlCollection).forEach(element => {
				element.style.marginTop = "2rem"
			})
		}
	}, [contactTracingData])

	//added for data grid
	const handleSearch = value => {
		// Reset column sorting when performing a search
		setSortModel([
			{
				field: "area",
				sort: "asc"
			}
		])

		setSearchText(value)
	}

	//added for data grid
	// Update the handleSortModelChange function in your PeopleTable component
	const handleSortModelChange = newSortModel => {
		// Sort the full dataset (rowsToUse) based on the sort model
		const sortedRows = [...rowsToUse].sort((a, b) => {
			// Iterate over the sort model
			for (const sortItem of newSortModel) {
				const field = sortItem.field
				const sortOrder = sortItem.sort === "asc" ? 1 : -1 // Only consider asc and desc
				if (sortItem.sort !== null) {
					if (field === "disabled") {
						// Sort based on boolean value for the "disabled" field
						if (a[field] && !b[field]) return sortOrder
						if (!a[field] && b[field]) return -sortOrder
						continue // Move to the next sort item
					}
					// For other fields, handle sorting based on their type
					if (a[field] === null && b[field] === null) continue // If both values are null, maintain current order
					if (a[field] === null) return -sortOrder // If 'a' is null, place it before 'b'
					if (b[field] === null) return sortOrder // If 'b' is null, place it before 'a'
					// Sort non-null values based on column type
					if (typeof a[field] === "string") {
						const comparison = a[field].localeCompare(b[field], undefined, { numeric: true })
						if (comparison !== 0) return comparison * sortOrder
					} else if (typeof a[field] === "number") {
						return (a[field] - b[field]) * sortOrder
					}
				}
			}
			return 0 // If no column has a defined sort order, maintain current order
		})

		// Update the sorted data and currentRows
		setRowsToUse(sortedRows) //all data
		setCurrentRows(sortedRows.slice(page * pageSize, (page + 1) * pageSize))
		setSortModel(newSortModel)
	}

	//added for data grid
	useEffect(() => {
		if (!contactTracingData) return

		const testContactTracingData = [...contactTracingData]

		testContactTracingData.sort((a, b) => {
			const areaA = a.area.toLowerCase()
			const areaB = b.area.toLowerCase()

			if (areaA < areaB) {
				return -1
			}
			if (areaA > areaB) {
				return 1
			}
			return 0
		})

		setRows(testContactTracingData)
	}, [contactTracingData])

	//added for data grid
	useEffect(() => {
		if (!rows) return

		if (!searchText && searchText.trim() == "") setSearchedRows(filteredRows)
		// return

		const fieldsToSearch = ["date", "area", "time"] // Define the fields to search
		const filteredRows = filterBySearchString(rows, searchText, fieldsToSearch)

		if (filteredRows && filteredRows.length > 0) {
			setSearchedRows(filteredRows)
		} else {
			setSearchedRows([])
		}
	}, [rows, searchText])

	//added for data grid
	useEffect(() => {
		if (!rows && !searchedRows) return

		if (searchedRows) {
			setRowsToUse(searchedRows)
		} else if (rows && rows.length > 0) {
			setRowsToUse(rows)
		}
	}, [rows, searchedRows])

	//added for data grid
	// Update current rows when rows or current page changes
	useEffect(() => {
		if (!rowsToUse) return
		updateCurrentRows()
		UIProps?.setCurrentTablePageInfo &&
			UIProps.setCurrentTablePageInfo({
				page: page,
				pageSize: pageSize
			})
	}, [rowsToUse, page, pageSize])

	//added for data grid
	// Function to update the current rows based on the current page and page size
	function updateCurrentRows() {
		const startIndex = page * pageSize
		const endIndex = Math.min(startIndex + pageSize, rowsToUse.length)
		const newCurrentRows = rowsToUse.slice(startIndex, endIndex)
		setCurrentRows(newCurrentRows)
		setRowCounter(rowsToUse.length)
		UIProps.setRowsToUse && UIProps.setRowsToUse(rowsToUse) //for printing all data
		//UIProps.setRowsToUse(rowsToUse.slice(page * pageSize, (page + 1) * pageSize)) //data from current page and page size
	}

	//added for data grid
	useEffect(() => {
		setRowCountState(prevRowCountState =>
			rowCounter !== undefined ? rowCounter : prevRowCountState
		)
	}, [rowCounter])

	return (
		// <div className="ml-3 mr-3" data-testid="admin_contact-tracing-contactTracingTable">
		// 	<ToolkitProvider
		// 		search
		// 		wrapperClasses="table-responsive"
		// 		classes="table table-head-custom table-vertical-center"
		// 		bootstrap4
		// 		keyField="id"
		// 		data={entities || []}
		// 		columns={columns}
		// 	>
		// 		{props => (
		// 			<div>
		// 				{/* <Toolbar />
		//   <hr /> */}
		// 				<BootstrapTable
		// 					defaultSorted={UIHelpers.defaultSorted}
		// 					bordered={false}
		// 					pagination={paginationOptions}
		// 					{...props.baseProps}
		// 				></BootstrapTable>
		// 			</div>
		// 		)}
		// 	</ToolkitProvider>
		// </div>
		<div style={{ width: "100%" }} data-testid="admin_contact-tracing-contactTracingTable">
			<div className="row mb-5" style={{ marginRight: "2px" }}>
				<div
					className="col d-flex justify-content-end align-self-end mb-2"
					id="contanctTracingTable"
				>
					{currentRows && currentRows.length > 0 && (
						<SearchBarCustom1
							onSearch={handleSearch}
							searchText={searchText}
							disabled={isLoading ? true : false}
							placeholder="Search"
							size="small"
							style={{
								width: "200px",
								background: "#F2F2F2",
								marginRight: "10px"
							}}
							InputProps={{
								startAdornment: (
									<SearchIcon
										fontSize="small"
										style={{
											color: "#7F8C8D",
											marginRight: "0.7rem"
										}}
									/>
								)
							}}
						/>
					)}
					{contactTracingData && (
						<Button
							variant="contained"
							color="primary"
							text="EXPORT"
							className="ml-2"
							// style={{ height: "31px" }}
							onClick={() => {
								setShowExporModal(true)
							}}
						/>
					)}
				</div>
			</div>
			{isLoading && <ModalProgressBar variant="query" />}
			<div className={classes.root}>
				<Box sx={{ width: "100%" }}>
					<DataGrid
						rows={currentRows}
						columns={columns}
						style={{
							fontFamily: "Poppins",
							fontWeight: 400,
							fontSize: "13px",
							border: "none",
							width: "100%",
							color: "#464e5f"
						}}
						className={classes.root}
						density="standard"
						// disableExtendRowFullWidth={true}
						pagination
						disableSelectionOnClick
						loading={isLoading}
						disableColumnMenu={true}
						// checkboxSelection
						components={{
							Toolbar: QuickToolbar,
							Pagination: CustomPagination
						}}
						rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
						page={page}
						onPageChange={newPage => {
							setPage(newPage)
						}}
						pageSize={pageSize}
						onPageSizeChange={e => {
							setPageSize(e)
						}}
						autoHeight
						rowCount={rowCountState}
						//* to selected only current page rows
						paginationMode="server"
						onSelectionModelChange={newSelectionModel => {
							UIProps.setIds(newSelectionModel)
							setSelectionModel(newSelectionModel)
						}}
						selectionModel={selectionModel}
						keepNonExistentRowsSelected //* to be able to change page and keep selected rows
						getRowClassName={params => (params.row.disabled ? "disabled-row" : "")} //*apply class to disabled assets
						//* for printing with matching table order
						sortingOrder={["desc", "asc"]}
						sortModel={sortModel}
						onSortModelChange={handleSortModelChange}
						//* for printing only visible columns
						onColumnVisibilityModelChange={e => {
							// console.log("ðŸš€ ~ onColumnVisibilityModelChange ~ e:", e)
							UIProps.setColumnVisibilityModel(e)
						}}
						columnVisibilityModel={UIProps.columnVisibilityModel}
					/>
				</Box>
			</div>
		</div>
	)
}

//added for data grid
function QuickToolbar() {
	return (
		<div>
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
			</GridToolbarContainer>
		</div>
	)
}

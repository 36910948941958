import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { actionTypes } from "../snackBar.duck"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"
import { color } from "../../modules/Reporting/occupancy/Zone/SankeyDrag/helpers"
import { Ellipsis } from "react-bootstrap/esm/PageItem"

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
	sidebar: {
		width: "8px",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		borderTopLeftRadius: "4px",
		borderBottomLeftRadius: "4px"
	},

	description: {
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		color: "#4A4A68",
		marginTop: "10px",
		wordSpacing: "4px",
		maxHeight: "100px",
		wordBreak: "break-word",
		whiteSpace: "normal"
	},

	successSnackbar: {
		maxWidth: "310.5px",
		//height: '54px',
		height: "auto",
		backgroundColor: "#E6F4E6 !important",
		borderRadius: "4px",
		"& .MuiAlert-filledSuccess": {
			paddingLeft: "12px",
			display: "flex",
			alignItems: "center",
			position: "relative"
		},

		"& .MuiAlert-message": {
			color: "#53B04F",
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "160%"
		},

		"& .MuiAlert-icon": {
			alignItems: "center"
		},

		"& .MuiAlert-action": {
			color: "#B8BCCA",

			"& .MuiSvgIcon-root": {
				fontSize: "1.5rem"
			}
		}
	},

	warningSnackbar: {
		maxWidth: "310.5px",
		//height: '54px',
		height: "auto",
		backgroundColor: "#FDF1DC !important",
		"& .MuiAlert-filledWarning": {
			paddingLeft: "12px",
			display: "flex",
			alignItems: "center",
			position: "relative"
		},

		"& .MuiAlert-message": {
			color: "#F6B952",
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "160%",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap"
		},

		"& .MuiAlert-icon": {
			alignItems: "center"
		},

		"& .MuiAlert-action": {
			color: "#B8BCCA",

			"& .MuiSvgIcon-root": {
				fontSize: "1.5rem"
			}
		}
	},

	infoSnackbar: {
		maxWidth: "310.5px",
		//height: '54px',
		height: "auto",
		backgroundColor: "#DDE9FD !important",
		"& .MuiAlert-filledInfo": {
			paddingLeft: "12px",
			display: "flex",
			alignItems: "center",
			position: "relative"
		},

		"& .MuiAlert-message": {
			color: "#1065EF",
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "160%"
		},

		"& .MuiAlert-icon": {
			alignItems: "center"
		},

		"& .MuiAlert-action": {
			color: "#B8BCCA",

			"& .MuiSvgIcon-root": {
				fontSize: "1.5rem"
			}
		}
	},

	errorSnackbar: {
		maxWidth: "310.5px",
		//height: '54px',
		height: "auto",
		backgroundColor: "#FBE6DE !important",
		"& .MuiAlert-filledError": {
			paddingLeft: "12px",
			display: "flex",
			alignItems: "center",
			position: "relative"
		},

		"& .MuiAlert-message": {
			color: "#E65019",
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "160%"
		},

		"& .MuiAlert-icon": {
			alignItems: "center"
		},

		"& .MuiAlert-action": {
			color: "#B8BCCA",

			"& .MuiSvgIcon-root": {
				fontSize: "1.5rem"
			}
		}
	}
}))

export default function CustomSnackBar() {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { typeOfSnackbar, snackbarMessage, snackbarOpen, snackBarDescription } = useSelector(
		state => state.ui
	)
	function handleClose(e, reason) {
		if (reason === "clickaway") return
		dispatch({ type: actionTypes.closeSnackBar })
	}

	const icons = {
		success: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="21"
				height="21"
				viewBox="0 0 21 21"
				fill="none"
			>
				<path
					d="M10.4998 2.16669C8.85166 2.16669 7.2405 2.65543 5.87009 3.57111C4.49968 4.48679 3.43157 5.78827 2.80084 7.31099C2.17011 8.83371 2.00509 10.5093 2.32663 12.1258C2.64817 13.7423 3.44185 15.2271 4.60728 16.3926C5.77272 17.558 7.25758 18.3517 8.87409 18.6732C10.4906 18.9948 12.1662 18.8297 13.6889 18.199C15.2116 17.5683 16.5131 16.5002 17.4288 15.1298C18.3444 13.7594 18.8332 12.1482 18.8332 10.5C18.8332 8.28988 17.9552 6.17027 16.3924 4.60746C14.8296 3.04466 12.71 2.16669 10.4998 2.16669ZM9.30936 13.8279L6.33317 10.8517L7.27996 9.90478L9.30936 11.9341L13.7201 7.52383L14.6699 8.46782L9.30936 13.8279Z"
					fill="#53B04F"
				/>
			</svg>
		),
		warning: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="21"
				viewBox="0 0 20 21"
				fill="none"
			>
				<path
					d="M18.6795 18.3366L10.5545 2.71163C10.5017 2.61016 10.4221 2.5251 10.3243 2.46575C10.2266 2.40639 10.1144 2.375 10 2.375C9.88562 2.375 9.77344 2.40639 9.67566 2.46575C9.57789 2.5251 9.49828 2.61016 9.4455 2.71163L1.3205 18.3366C1.27096 18.4319 1.2468 18.5383 1.25034 18.6456C1.25389 18.753 1.28502 18.8576 1.34075 18.9493C1.39647 19.0411 1.4749 19.117 1.56848 19.1697C1.66206 19.2223 1.76763 19.25 1.875 19.25H18.125C18.2324 19.25 18.338 19.2223 18.4315 19.1697C18.5251 19.117 18.6035 19.0411 18.6593 18.9493C18.715 18.8576 18.7461 18.753 18.7497 18.6456C18.7532 18.5383 18.729 18.4319 18.6795 18.3366ZM9.29688 7.37501H10.7031V13.625H9.29688V7.37501ZM10 17.375C9.81458 17.375 9.63333 17.32 9.47916 17.217C9.32499 17.114 9.20482 16.9676 9.13387 16.7963C9.06291 16.625 9.04434 16.4365 9.08052 16.2546C9.11669 16.0728 9.20598 15.9057 9.33709 15.7746C9.4682 15.6435 9.63525 15.5542 9.81711 15.518C9.99896 15.4818 10.1875 15.5004 10.3588 15.5714C10.5301 15.6423 10.6765 15.7625 10.7795 15.9167C10.8825 16.0708 10.9375 16.2521 10.9375 16.4375C10.9375 16.6861 10.8387 16.9246 10.6629 17.1004C10.4871 17.2762 10.2486 17.375 10 17.375Z"
					fill="#F6B952"
				/>
			</svg>
		),
		error: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					d="M10 0C4.485 0 0 4.485 0 10C0 15.515 4.485 20 10 20C15.515 20 20 15.515 20 10C20 4.485 15.515 0 10 0ZM10 18.182C5.272 18.182 1.818 14.728 1.818 10C1.818 5.272 5.272 1.818 10 1.818C14.728 1.818 18.182 5.272 18.182 10C18.182 14.728 14.728 18.182 10 18.182ZM13.182 13.182L10 10L6.818 13.182L5.636 12L8.818 8.818L5.636 5.636L6.818 4.454L10 7.636L13.182 4.454L14.364 5.636L11.182 8.818L14.364 12L13.182 13.182Z"
					fill="#FF0000"
				/>
			</svg>
		),
		info: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="21"
				viewBox="0 0 20 21"
				fill="none"
			>
				<path
					d="M10 1.75C8.26942 1.75 6.57769 2.26318 5.13876 3.22464C3.69983 4.1861 2.57832 5.55267 1.91606 7.15152C1.25379 8.75037 1.08051 10.5097 1.41813 12.207C1.75575 13.9044 2.58911 15.4635 3.81282 16.6872C5.03653 17.9109 6.59563 18.7442 8.29296 19.0819C9.9903 19.4195 11.7496 19.2462 13.3485 18.5839C14.9473 17.9217 16.3139 16.8002 17.2754 15.3612C18.2368 13.9223 18.75 12.2306 18.75 10.5C18.75 8.17936 17.8281 5.95376 16.1872 4.31282C14.5462 2.67187 12.3206 1.75 10 1.75ZM10 5.5C10.1854 5.5 10.3667 5.55498 10.5209 5.658C10.675 5.76101 10.7952 5.90743 10.8661 6.07873C10.9371 6.25004 10.9557 6.43854 10.9195 6.6204C10.8833 6.80225 10.794 6.9693 10.6629 7.10041C10.5318 7.23152 10.3648 7.32081 10.1829 7.35699C10.001 7.39316 9.81254 7.37459 9.64124 7.30364C9.46993 7.23268 9.32351 7.11252 9.2205 6.95835C9.11749 6.80418 9.0625 6.62292 9.0625 6.4375C9.0625 6.18886 9.16128 5.9504 9.33709 5.77459C9.51291 5.59877 9.75136 5.5 10 5.5ZM12.5 15.5781H7.5V14.1719H9.29688V10.5781H8.125V9.17188H10.7031V14.1719H12.5V15.5781Z"
					fill="#1065EF"
				/>
			</svg>
		)
	}

	return (
		<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
			<div style={{ position: "relative", width: "310.5px" }}>
				{/* Side bar */}
				<div
					className={classes.sidebar}
					style={{
						backgroundColor:
							typeOfSnackbar === "success"
								? "#53B04F"
								: typeOfSnackbar === "warning"
								? "#F6B952"
								: typeOfSnackbar === "error"
								? "#E65019"
								: typeOfSnackbar === "info"
								? "#1065EF"
								: "transparent"
					}}
				></div>
				<Alert
					icon={icons[typeOfSnackbar]}
					onClose={handleClose}
					className={
						typeOfSnackbar === "success"
							? classes.successSnackbar
							: typeOfSnackbar === "warning"
							? classes.warningSnackbar
							: typeOfSnackbar === "error"
							? classes.errorSnackbar
							: typeOfSnackbar === "info"
							? classes.infoSnackbar
							: ""
					}
				>
					{snackbarMessage}
					{snackBarDescription && (
						<div className={classes.description}>{snackBarDescription}</div>
					)}
				</Alert>
			</div>
		</Snackbar>
	)
}

import React, { forwardRef } from "react"
import QRCode from "react-qr-code"

const LabelMultipleNodes = forwardRef((props, ref) => {
	const { selected } = props

	return (
		<>
			<div
				className="p-0"
				style={{ width: "auto", height: "auto" }}
				id="hidden-div-node-print-label"
			>
				<div
					ref={ref}
					style={{
						width: "100%",
						height: "100%"
					}}
				>
					{selected &&
						selected.length > 0 &&
						selected.map((val, i) => {
							return (
								// Add this extra div to have the page break for each label
								<div key={i}>
									<div
										className="d-flex justify-content-center align-items-center"
										style={{
											marginTop: "1rem",
											marginLeft: "1rem",
											marginBottom: "1rem",
											marginRight: "1rem",
											width: "260px",
											height: "370px",
											pageBreakAfter: "always"
										}}
									>
										<div
											className="d-flex flex-column justify-content-center align-items-center"
											style={{
												width: "260px",
												height: "370px",
												border: "4px solid #000",
												borderRadius: "20px"
											}}
										>
											<div className="text-center">
												<div
													style={{
														height: "auto",
														margin: "0 auto",
														// maxWidth: 64,
														width: "100%"
													}}
												>
													{val && (
														<QRCode
															size={256}
															style={{
																// height: "auto",
																// maxWidth: "100%",
																// width: "100%"
																height: "160px"
															}}
															value={val}
															viewBox={`0 0 256 256`}
														/>
													)}
												</div>
											</div>
											<div className="text-center" style={{ margin: "2.5rem 0" }}>
												<span
													style={{
														fontFamily: "Poppins",
														fontSize: "14px",
														fontWeight: "800"
													}}
												>
													Node Address
												</span>
												<h2
													className="mt-1"
													style={{
														fontFamily: "Poppins",
														fontSize: "22px",
														fontWeight: "800"
													}}
												>
													{val ? val : ""}
												</h2>
											</div>
											<div className="text-center">
												<img
													src="/media/logos/crowdkeep.png"
													alt="company logo"
													style={{
														width: "90%",
														height: "100%",
														objectFit: "contain"
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							)
						})}
				</div>
			</div>
		</>
	)
})

export default LabelMultipleNodes

import React from "react"
import { Button as ReactButton } from "@material-ui/core"
import { ButtonTextSmall } from "./typography/ButtonTextSmall"

// const useStyles = makeStyles(theme => ({
//    root: {
//       "& > *": {
//          margin: theme.spacing(1),
//       },
//    },
// }))

export function Button({ text, children, variant = "contained", color = "primary", ...props }) {
	// const classes = useStyles()
	return (
		<ReactButton
			variant={variant}
			color={color}
			// style={{
			//    border: "none",
			//    // backgroundColor: "#C3C4DD",
			//    // color: "#323389",
			//    ...style,
			// }}
			{...props}
		>
			{text && <ButtonTextSmall text={text} />}
			{children && children}
		</ReactButton>
	)
}

import React from "react"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { GrGroup } from "react-icons/gr"
import { Button } from "../../../../../../_partials/Button"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import * as liveDataActions from "../../../../../_redux/liveData/liveDataActions"

const OccupancyFilter = ({
	setDrawerOptions,
	showTags,
	setShowTags,
	people,
	tags,
	showRoles,
	setShowRoles,
	handleClickRolesButton,
	roles,
	roleFilterButtonStates,
	setRoleFilterButtonStates,
	user,
	dispatch,
	drawerPreferences
}) => {
	// const [roleFilterButtonStates, setRoleFilterButtonStates] = React.useState(
	// 	roles && roles.map(rol => rol.name)
	// )

	// ━━━━ Toggle button icons according to the state
	const toggleButton = index => {
		setRoleFilterButtonStates(prevState => {
			const newState = [...prevState]
			newState[index] = newState[index] === roles[index].name ? "" : roles[index].name
			return newState
		})
	}

	// ━━━━ Select all roleFilterBUttonStates button
	const selectAllRoles = () => {
		setRoleFilterButtonStates(roles.map(rol => rol.name))
	}

	// React.useEffect(() => {
	// 	// console.log("🚀  showRoles:", showRoles)
	// 	// console.log("🚀  showTags:", showTags)
	// 	console.log("🚀  roleFilterButtonStates:", roleFilterButtonStates)
	// 	// console.log("🚀  roles:", roles)
	// }, [showRoles, showTags, roleFilterButtonStates, roles])

	// ━━━━ Change button icons according to the show props state
	const peopleSelectedIcon = showTags ? (
		<CheckIcon style={{ color: "#FFFFFF" }} />
	) : (
		<AddIcon style={{ color: "#8C8CA2" }} />
	)

	const rolesSelectedIcon =
		showRoles && showTags ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)

	return (
		<div>
			<div className="d-flex w-100 align-items-center justify-content-between">
				<div className="mt-4">
					<GrGroup
						className="drawer_option_title_icon"
						style={{
							filter:
								"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
						}}
					/>
					<span className="drawer_option_title_span">Occupancy</span>
				</div>
				<div>
					<IconButton
						style={{ margin: "0.5rem" }}
						size="small"
						onClick={() =>
							setDrawerOptions(prevState => ({
								...prevState,
								openOccupancy: false
							}))
						}
					>
						<CloseIcon style={{ fontSize: "18px" }} />
					</IconButton>
				</div>
			</div>
			<p className="drawer_option_title_secondary">General</p>
			<div className="d-flex flex-column align-items-center justify-content-center">
				<Button
					className="drawer_options_filter_button"
					endIcon={peopleSelectedIcon}
					style={{
						backgroundColor: showTags ? "#3077D3" : "#DBE1E5",
						color: showTags ? "#FFFFFF" : "#8C8CA2"
					}}
					variant="contained"
					text="People"
					onClick={() => setShowTags(prev => !prev)}
				/>

				<Button
					className="drawer_options_filter_button"
					endIcon={rolesSelectedIcon}
					style={{
						backgroundColor: showRoles && showTags ? "#3077D3" : "#DBE1E5",
						color: showRoles && showTags ? "#FFFFFF" : "#8C8CA2"
					}}
					variant="contained"
					text="Roles"
					onClick={() => {
						setShowRoles(prev => !prev)
						handleClickRolesButton()
					}}
				/>
				<Button
					className="drawer_options_filter_button"
					// TODO: tenho q passar o setState dos props correspondentes e altero background color e color do text
					style={{ backgroundColor: "#DBE1E5", color: "#8C8CA2" }}
					variant="contained"
					text="Assets"
				/>
				{showRoles && showTags && (
					<div>
						{" "}
						<div style={{ width: "100%" }}>
							<p className="drawer_option_title_third">Roles</p>
						</div>
						<div className="drawer_options_roles_buttons">
							{roles &&
								roles.map((rol, index) => {
									const dynamicSelectedIcon =
										showRoles && showTags && roleFilterButtonStates[index] ? (
											<CheckIcon style={{ color: "#FFFFFF" }} />
										) : (
											<AddIcon style={{ color: "#8C8CA2" }} />
										)

									return (
										<Button
											key={index}
											className="drawer_options_filter_button"
											endIcon={dynamicSelectedIcon}
											style={{
												background: roleFilterButtonStates[index]
													? "#3077D3"
													: "#DBE1E5",
												color: roleFilterButtonStates[index] ? "#FFFFFF" : "#8C8CA2",
												cursor: "s-resize"
											}}
											variant="contained"
											text={rol?.name}
											onClick={() => {
												toggleButton(index)
											}}
										/>
									)
								})}
						</div>
					</div>
				)}

				<div
					className="d-flex flex-column align-items-center justify-content-center"
					style={{ marginTop: "1.8rem" }}
				>
					<Button
						className="drawer_options_filter_button_select_all"
						style={{
							backgroundColor: "#FFFFFF",
							color: "#3077D3",
							border: "1px solid #3077D3"
						}}
						variant="contained"
						text="Select All"
						onClick={() => {
							setShowRoles(true)
							setShowTags(true)
							selectAllRoles()
						}}
					/>
					<Button
						className="drawer_options_filter_button_select_all"
						style={{ backgroundColor: "#DDE9FD", color: "#3077D3" }}
						variant="contained"
						text="Save"
						onClick={() => {
							if (drawerPreferences && drawerPreferences.length !== 0) {
								const foundedPreferences = drawerPreferences.find(
									val => val.userId === user?.id
								)

								if (foundedPreferences) {
									dispatch(
										liveDataActions.saveUserDrawerPreferences([
											{
												userId: user?.id,
												preferences: {
													...foundedPreferences.preferences,
													roles: showRoles,
													people: showTags,
													roleFilter: roleFilterButtonStates || []
												}
											}
										])
									)
								} else {
									dispatch(
										liveDataActions.saveUserDrawerPreferences([
											{
												userId: user?.id,
												preferences: {
													roles: showRoles,
													people: showTags,
													roleFilter: roleFilterButtonStates || []
												}
											}
										])
									)
								}
							} else {
								dispatch(
									liveDataActions.saveUserDrawerPreferences([
										{
											userId: user?.id || null,
											preferences: {
												roles: showRoles,
												people: showTags,
												roleFilter: roleFilterButtonStates || []
											}
										}
									])
								)
							}
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default OccupancyFilter

import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap/"
import { Button } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import moment from "moment"

function AlertGeofencing({
	openAlertModal,
	setOpenAlertModal,
	showMockMarkers,
	minutesSinceLastAlert,
	setMinutesSinceLastAlert
}) {
	//   const [show, setShow] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false)

	//   const handleClose = () => setShow(false);
	//   const handleShow = () => setShow(true);

	// useEffect(() => {
	//   console.log(showBLE, "showBLE ALERT");
	//   console.log(openAlertModal, "openAlertModal ALERT");
	// }, [showBLE, openAlertModal]);

	//   open={modalIsOpen || (alertModelOpen && moment().diff(minutesSinceLastAlert, "minutes") > 30)}
	// onOpen=({
	//   setMinutesSinceLastAlert(moment())
	//   setIsOpen(true)
	// })
	// onClose=({
	//    setIsOpen(false)
	// })

	// useEffect(() => {
	//   console.log(moment().diff(minutesSinceLastAlert, "seconds"), "diff");
	//   console.log(
	//     openAlertModal && moment().diff(minutesSinceLastAlert, "seconds") >= 10,
	//     "1"
	//   );
	//   console.log(modalIsOpen, "modalIsOPen");

	//   console.log(
	//     modalIsOpen ||
	//       (openAlertModal &&
	//         moment().diff(minutesSinceLastAlert, "seconds") >= 10)
	//   );

	//   console.log(openAlertModal, "openAlertModal");
	// }, [minutesSinceLastAlert, modalIsOpen, openAlertModal]);

	return (
		<>
			{/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

			{showMockMarkers && (
				<Modal
					// show={openAlertModal}
					show={
						modalIsOpen ||
						(openAlertModal && moment().diff(minutesSinceLastAlert, "seconds") >= 6)
					}
					onShow={() => {
						setModalIsOpen(true)
						setMinutesSinceLastAlert(moment())
					}}
					onHide={() => {
						setOpenAlertModal(false)
						setModalIsOpen(false)
					}}
				>
					{/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
					<Modal.Body>
						{" "}
						<Alert severity="warning" style={{ fontSize: "14px" }}>
							<AlertTitle style={{ fontSize: "16px" }}>Geo-fencing Warning</AlertTitle>
							Bulldozer with id:<strong>123456</strong> is out zone{" "}
							<strong>Central area</strong> on Third floor - check it out!
						</Alert>
					</Modal.Body>
					<Modal.Footer>
						<Button
							color="default"
							variant="contained"
							onClick={() => {
								setOpenAlertModal(false)
								setModalIsOpen(false)
							}}
						>
							Close
						</Button>
						{/* <Button
            color='primary'
            variant='contained'
            onClick={() => setOpenAlertModal(false)}
          >
            Save Changes
          </Button> */}
					</Modal.Footer>
				</Modal>
			)}
		</>
	)
}

export default AlertGeofencing

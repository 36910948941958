import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import Chart from "react-apexcharts"

export function AttendanceTrackingAssets() {
	const { isLoading, allAssetsDistribution } = useSelector(
		state => ({
			isLoading: state.reporting.allAssetsDistributionLoading,
			allAssetsDistribution: state.reporting.allAssetsDistribution
		}),
		shallowEqual
	)

	// States
	const [graphData, setGraphData] = useState({
		labels: [],
		series: [],
		colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
	})

	useEffect(() => {
		if (!allAssetsDistribution) {
			setGraphData({
				series: [],
				labels: [],
				colors: [],
				barColors: [],
				type: "bar"
			})
		} else {
			const firstElement = allAssetsDistribution && allAssetsDistribution?.series[0]

			const top6Series = allAssetsDistribution && allAssetsDistribution?.series.slice(0, 6) // Filter to show only top 6 roles

			// Set graphData // Filter the
			firstElement &&
				allAssetsDistribution &&
				setGraphData({
					series: top6Series,
					labels: allAssetsDistribution?.labels,
					colors: allAssetsDistribution?.colors,
					barColors: allAssetsDistribution?.barColors,
					type: firstElement?.data.length > 1 ? "area" : "bar"
				})
		}
	}, [allAssetsDistribution])

	return (
		<Card isLoading={isLoading}>
			<CardHeader
				title="Top 6 Attendance Tracking"
				subtitle="Attendance of asset by asset type"
			/>
			<CardBody style={{ height: "380px" }}>
				<Chart
					options={getChartOptions(graphData)}
					series={graphData?.series || []}
					// type="area"
					type={graphData && graphData?.type}
					height="100%"
				/>
			</CardBody>
		</Card>
	)
}

function getChartOptions(graphData) {
	return {
		chart: {
			// type: "area",
			// type: graphData?.type || "bar",
			stacked: true,
			toolbar: {
				tools: {
					download: true,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				},
				export: {
					csv: {
						filename: "AttendanceTracking",
						columnDelimiter: ",",
						headerCategory: "Date",
						headerValue: "Value",
						dateFormatter(timestamp) {
							return new Date(timestamp).toDateString()
						}
					},
					svg: {
						filename: "AttendanceTracking"
					},
					png: {
						filename: "AttendanceTracking"
					}
				}
			},
			zoom: {
				enabled: false
			}
		},
		noData: {
			text: "No data to display."
		},
		dataLabels: {
			enabled: false
		},
		// colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"],
		colors: graphData?.barColors || [],
		fill: {
			type: graphData?.type === "bar" ? "solid" : "gradient",
			gradient:
				graphData?.type === "bar"
					? {}
					: {
							shadeIntensity: 0,
							opacityFrom: 0.3,
							opacityTo: 0.6
							// stops: [0, 90, 100]
					  }
		},

		labels: graphData?.labels || [],
		stroke: {
			curve: "smooth",
			width: graphData?.type === "bar" ? 0 : 3
		},
		yaxis: {
			title: {
				text: "Asset Count"
			}
		},
		xaxis: {
			// Default xaxis so it doesnt break when new options arrive
			type: "category",
			tickPlacement: "between",
			labels: {
				// sets colors of the xAxis (if saturday or sunday it changes colors)
				style: {
					colors: graphData?.colors
				}
			}
		},
		tooltip: {
			enabled: true,
			y: {
				formatter: function (value) {
					return Math.round(value)
				}
			}
		}
		// If this is uncommented we have a bug on UI when timeframe is "month"
		// responsive: [
		//   {
		//     breakpoint: 480,

		//   },
		// ],
	}
}

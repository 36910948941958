import React, { forwardRef, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/managePeople/managePeopleActions"
import { Input } from "../../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { SelectMultiCheckbox } from "../../../../_partials/inputs/SelectMultiCheckbox"
import { Button } from "../../../../_partials/Button"
import { AutocompleteWithLazyLoading } from "../../../Admin/manage-customer/Assets/manage-assets/AutocompleteWithLazyLoading"
import DatePicker from "react-datepicker"
import { InputAdornment, TextField, makeStyles } from "@material-ui/core"
import { MdOutlineCalendarToday } from "react-icons/md"
import { checkIfTagsAreAssigned } from "../../../_redux/_helpers/ActionHelpers"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Collapse, IconButton } from "@material-ui/core/"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
// import "../ManagePeople.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import ImageUploading from "react-images-uploading"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { fetchDownloadURL } from "../../../_redux/managePeople/managePeopleHelpers"
import { ExampleCustomInput, ExampleCustomInput2 } from "./PersonDetailedPageForm"
import { uuidv4 } from "../../UIHelpers"
import "./PatientForm.css"
import { GeocoderInput } from "../../../../_partials/inputs/GeocoderInput"

const PatientForm = ({
	header,
	generalInfoOpen,
	setGeneralInfoOpen,
	classes,
	onImageChange,
	getPicture,
	picture,
	values,
	isLoading,
	newImage,
	isSuper,
	personId,
	setCountryCode,
	handleSubmit,
	dirty,
	documentationInfoOpen,
	setDocumentationInfoOpen,
	nationalities,
	selectedNationality,
	setSelectedNationality,
	setFieldValue,
	onboardingOpen,
	setOnboardingOpen,
	vendors,
	selectedCompany,
	setSelectedCompany,
	initialValues,
	roles,
	selectedRole,
	setSelectedRole,
	areas,
	otherContacts,
	setOtherContacts,
	setNewImage
}) => {
	return (
		<Form
			className="form form-label-right"
			data-testid="new-person-modal_PersonDetailedPageForm"
			style={{
				padding: "10px 20px",
				borderRadius: "5px",
				background: "#FFF",
				boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.10)"
			}}
		>
			<div className="row">
				<div
					className="col"
					style={{
						color: "#444",
						fontFamily: "Poppins",
						fontSize: "20px",
						fontStyle: "normal",
						fontWeight: "600",
						lineHeight: "160%"
					}}
				>
					Patient Profile
				</div>
				<div
					className="col"
					style={{
						textAlign: "end"
					}}
				>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip id="firebase-messaging-tooltip">Locate</Tooltip>}
					>
						<Button style={{ padding: "6px" }} color="primaryLight" variant="contained">
							<img alt="Locate" src="/icons/location--person.svg" width="22" height="22" />
						</Button>
					</OverlayTrigger>
					<Button color="primary" variant="contained" text="export" className="ml-2" />
				</div>
			</div>

			<Card style={{ boxShadow: "none" }} margin={false}>
				<CardHeader
					className="create_person_modal_title"
					padding={false}
					line={false}
					title={header("General Information", generalInfoOpen, setGeneralInfoOpen)}
				/>
				<Collapse in={generalInfoOpen} className={classes.collapse}>
					<CardBody style={{ padding: "10px 0" }}>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="d-flex justify-content-center align-items-center mb-lg-0 mb-5">
								<ImageUploading
									onChange={onImageChange}
									dataURLKey="data_url"
									acceptType={["png", "jpg", "jpeg"]}
								>
									{({ onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
										<div className="upload__image-wrapper">
											<div
												className="image-input image-input-outline"
												id="kt_profile_avatar"
												style={{
													backgroundColor: "white",
													paddingRight: "8px",
													paddingTop: "8px",
													paddingBottom: "8px"
												}}
											>
												<div
													className="image-input-wrapper"
													style={{
														borderColor: isDragging ? "#C3C4DD" : "white",
														backgroundImage: `${getPicture()}`,
														backgroundColor: "#DDE9FD",
														backgroundSize: picture
															? "100%"
															: newImage
															? "100%"
															: newImage && !values.picture
															? "50%"
															: "50%",
														backgroundRepeat: "no-repeat",
														backgroundPosition: "center",
														cursor: "pointer",
														height: "100px",
														width: "100px"
													}}
													onClick={!isLoading ? onImageUpload : () => {}}
													{...dragProps}
												/>
												<label
													className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
														isLoading ? "disabled" : ""
													}`}
													data-action="change"
													data-toggle="tooltip"
													title=""
													data-original-title="Change logo"
													onClick={!isLoading ? onImageUpload : () => {}}
												>
													<i className="fa fa-pen icon-sm text-muted"></i>
												</label>
												<span
													onClick={() => {
														if (picture && !isLoading) {
															onImageRemoveAll()
															setFieldValue("picture", null)
														}
														if (!isLoading && newImage) {
															onImageRemoveAll()
															setFieldValue("picture", null)
															setNewImage(null)
														}
													}}
													className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
														isLoading ? "disabled" : ""
													}`}
													data-action="remove"
													data-toggle="tooltip"
													title=""
													data-original-title="Remove logo"
												>
													<i className="ki ki-bold-close icon-xs text-muted"></i>
												</span>
											</div>
											{picture?.file?.size && picture.file.size / (1024 * 1024) > 10 && (
												<span className="form-text text-danger mt-2">
													{`Image is too big (${(
														picture.file.size /
														(1024 * 1024)
													).toFixed(2)}Mb).`}
													<br />
													Max imagesize: 10Mb.
												</span>
											)}
											<span className="form-text text-muted mt-2">
												Allowed file types: png, jpg, jpeg.
											</span>
										</div>
									)}
								</ImageUploading>
							</div>
							<div className="col-lg-10">
								<div className="row pt-10">
									<div className="col-6 mb-5 pl-lg-3 pr-lg-3 ">
										<Field
											disabled={isSuper ? false : true} // access only for superAdmins
											variant="filled"
											name="firstName"
											label="First Name"
											requiredField
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter first name"
										/>
									</div>
									<div className="col-6 mb-5 pl-lg-3 pr-lg-5">
										<Field
											disabled={isSuper ? false : true} // access only for superAdmins
											variant="filled"
											name="lastName"
											label="Last Name"
											requiredField
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter last name"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col-lg-12">
								<div className="row" style={{ width: "100%" }}>
									{personId && (
										<div className="col-6">
											<Field name="phone" placeholder="Enter phone number">
												{({ field, form }) => {
													return (
														<>
															<div
																style={{
																	marginBottom: "10px",
																	height: "18px"
																}}
															>
																<span
																	style={{
																		color: "#4A4A68",
																		fontFamily: "Poppins",
																		fontSize: "12px",
																		fontWeight: "500"
																	}}
																>
																	Phone Number
																</span>
															</div>
															<PhoneInput
																name="phoneNumber"
																{...field}
																/* country={"us"} */
																containerClass="countryCode_containerPeople"
																inputClass="countryCode_inputClassPeople"
																buttonClass="countryCode_buttonClassPeople"
																dropdownClass="countryCode_dropdownClassPeople"
																placeholder="Enter phone number"
																value={initialValues?.phoneNumber}
																onChange={(value, data) => {
																	form.setFieldValue("phoneNumber", value)
																	setCountryCode(data.dialCode)
																}}
															/>
														</>
													)
												}}
											</Field>
										</div>
									)}
									<div className="col-6 mb-5 pl-lg-3 pr-lg-3">
										<Field
											variant="filled"
											name="email"
											label="Email"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter email"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-3 mb-5 pl-lg-3 pr-lg-3">
										<Field
											variant="filled"
											name="address"
											label="Address"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter a street address"
										/>
									</div>
									<div className="col-3 mb-5 pl-lg-3 pr-lg-3">
										<Field
											variant="filled"
											name="building_number"
											label="Building Number"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter a building number"
										/>
									</div>
									<div className="col-3 mb-5 pl-lg-3 pr-lg-3">
										<Field
											variant="filled"
											name="floor"
											label="Floor"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter floor"
										/>
									</div>
									<div className="col-3 mb-5 pl-lg-3 pr-lg-10">
										<Field
											variant="filled"
											name="door_number"
											label="Door Number"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter door number"
										/>
									</div>
									<div className="col-6 mb-5 pl-lg-3 pr-lg-10">
										<Field
											variant="filled"
											name="postal_code"
											label="Postal Code"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter the postal code"
										/>
									</div>
									<div className="col-6 mb-5 pl-lg-3 pr-lg-10">
										<Field
											variant="filled"
											name="city"
											label="City"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter city"
										/>
									</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Collapse>
			</Card>
			<Card style={{ boxShadow: "none" }} margin={false}>
				<CardHeader
					className="create_person_modal_title"
					padding={false}
					line={false}
					title={header(
						"Documentation Information",
						documentationInfoOpen,
						setDocumentationInfoOpen
					)}
				/>
				<Collapse in={documentationInfoOpen} className={classes.collapse}>
					<CardBody style={{ padding: "10px 0" }}>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									variant="filled"
									name="gender"
									label="Gender"
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter gender"
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									id="nationality-autocomplete"
									customColor="#F2F2F2"
									name="nationality"
									label="Nationality"
									component={Autocomplete_People}
									placeholder="Enter nationality"
									options={[
										...nationalities.map(val => {
											return { value: val, name: val }
										})
									]}
									value={selectedNationality}
									onChange={(event, newValue) => {
										setSelectedNationality(newValue)
										setFieldValue("nationality", newValue)
									}}
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<div style={{ marginBottom: "10px" }}>
									<span className="new_person_date_picker_label">Date of Birth</span>
								</div>
								<DatePicker
									className="custom-datepicker"
									calendarClassName="date_picker_range_reporting"
									selected={values.birthdate}
									onChange={e => setFieldValue("birthdate", new Date(e))}
									customInput={<ExampleCustomInput />}
									dateFormat="dd/MM/yyyy"
									wrapperClassName="custom-datepicker-wrapper"
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
								/>
							</div>
						</div>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
								<Field
									variant="filled"
									name="passport"
									label="ID/Passport Number"
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter number"
								/>
							</div>

							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									variant="filled"
									name="sns_number"
									label="SNS Number"
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter number"
								/>
							</div>
						</div>
					</CardBody>
				</Collapse>
			</Card>
			<Card style={{ boxShadow: "none" }} margin={false}>
				<CardHeader
					className="create_person_modal_title"
					padding={false}
					line={false}
					title={header("Emergency Contacts", otherContacts, setOtherContacts)}
				/>
				<Collapse in={otherContacts} className={classes.collapse}>
					<CardBody style={{ padding: "10px 0" }}>
						<div className="form-group row">
							<div className="col">
								<Field
									variant="filled"
									name="emergencyName"
									label="Contact Name"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter contact name"
								/>
							</div>
							<div className="col">
								<Field
									variant="filled"
									name="relationship"
									label="Relationship"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter relantionship"
								/>
							</div>
							<div className="col">
								<Field
									variant="filled"
									name="emergencyNumber"
									label="Phone Number"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter contact phone number"
								/>
							</div>
						</div>
						<div className="form-group row">
							<div className="col">
								<Field
									variant="filled"
									name="emergencyName"
									label="Contact Name"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter contact name"
								/>
							</div>
							<div className="col">
								<Field
									variant="filled"
									name="relationship"
									label="Relationship"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter relantionship"
								/>
							</div>
							<div className="col">
								<Field
									variant="filled"
									name="emergencyNumber"
									label="Phone Number"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter contact phone number"
								/>
							</div>
						</div>
					</CardBody>
				</Collapse>
			</Card>
			<div
				className="d-flex w-100 align-items-center justify-content-center"
				style={{ gap: "10px" }}
			>
				<Button
					variant="outlined"
					color="primary"
					style={{ width: "10%", marginTop: "2.25rem", marginBottom: "1.25rem" }}
					// disabled={!dirty || isLoading}
					text="CANCEL"
					onClick={handleSubmit}
					data-testid="new-person-modal_handleSubmit"
				/>

				<Button
					variant="contained"
					color="primary"
					style={{ width: "10%", marginTop: "2.25rem", marginBottom: "1.25rem" }}
					// disabled={!dirty || isLoading}
					text="SAVE PERSON"
					onClick={handleSubmit}
					data-testid="new-person-modal_handleSubmit"
				/>
			</div>
		</Form>
	)
}

const newPatientForm = ({
	header,
	generalInfoOpen,
	setGeneralInfoOpen,
	classes,
	onImageChange,
	getPicture,
	picture,
	values,
	isLoading,
	newImage,
	isSuper,
	personId,
	setCountryCode,
	handleSubmit,
	dirty,
	documentationInfoOpen,
	setDocumentationInfoOpen,
	nationalities,
	selectedNationality,
	setSelectedNationality,
	setFieldValue,
	onboardingOpen,
	setOnboardingOpen,
	vendors,
	selectedCompany,
	setSelectedCompany,
	initialValues,
	roles,
	selectedRole,
	setSelectedRole,
	areas,
	otherContacts,
	setOtherContacts,
	setNewImage
}) => {
	return (
		<Form
			className="form form-label-right"
			data-testid="new-person-modal_PersonDetailedPageForm"
			style={{
				padding: "10px 20px",
				borderRadius: "5px",
				background: "#FFF",
				boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.10)"
			}}
		>
			<div className="row">
				<div
					className="col"
					style={{
						color: "#444",
						fontFamily: "Poppins",
						fontSize: "20px",
						fontStyle: "normal",
						fontWeight: "600",
						lineHeight: "160%"
					}}
				>
					Patient Profile
				</div>
				<div
					className="col"
					style={{
						textAlign: "end"
					}}
				>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip id="firebase-messaging-tooltip">Locate</Tooltip>}
					>
						<Button style={{ padding: "6px" }} color="primaryLight" variant="contained">
							<img alt="Locate" src="/icons/location--person.svg" width="22" height="22" />
						</Button>
					</OverlayTrigger>
					<Button color="primary" variant="contained" text="export" className="ml-2" />
				</div>
			</div>

			<Card style={{ boxShadow: "none" }} margin={false}>
				<CardHeader
					className="create_person_modal_title"
					padding={false}
					line={false}
					title={header("General Information", generalInfoOpen, setGeneralInfoOpen)}
				/>
				<Collapse in={generalInfoOpen} className={classes.collapse}>
					<CardBody style={{ padding: "10px 0" }}>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col-lg-2 d-flex justify-content-center align-items-center mb-lg-0 mb-5">
								<ImageUploading
									onChange={onImageChange}
									dataURLKey="data_url"
									acceptType={["png", "jpg", "jpeg"]}
								>
									{({ onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
										<div className="upload__image-wrapper">
											<div
												className="image-input image-input-outline"
												id="kt_profile_avatar"
												style={{
													backgroundColor: "white",
													paddingRight: "8px",
													paddingTop: "8px",
													paddingBottom: "8px"
												}}
											>
												<div
													className="image-input-wrapper"
													style={{
														borderColor: isDragging ? "#C3C4DD" : "white",
														backgroundImage: `${getPicture()}`,
														backgroundColor: "#DDE9FD",
														backgroundSize: picture
															? "100%"
															: newImage
															? "100%"
															: newImage && !values.picture
															? "50%"
															: "50%",
														backgroundRepeat: "no-repeat",
														backgroundPosition: "center",
														cursor: "pointer",
														height: "100px",
														width: "100px"
													}}
													onClick={!isLoading ? onImageUpload : () => {}}
													{...dragProps}
												/>
												<label
													className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
														isLoading ? "disabled" : ""
													}`}
													data-action="change"
													data-toggle="tooltip"
													title=""
													data-original-title="Change logo"
													onClick={!isLoading ? onImageUpload : () => {}}
												>
													<i className="fa fa-pen icon-sm text-muted"></i>
												</label>
												<span
													onClick={() => {
														if (picture && !isLoading) {
															onImageRemoveAll()
															setFieldValue("picture", null)
														}
														if (!isLoading && newImage) {
															onImageRemoveAll()
															setFieldValue("picture", null)
															setNewImage(null)
														}
													}}
													className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
														isLoading ? "disabled" : ""
													}`}
													data-action="remove"
													data-toggle="tooltip"
													title=""
													data-original-title="Remove logo"
												>
													<i className="ki ki-bold-close icon-xs text-muted"></i>
												</span>
											</div>
											{picture?.file?.size && picture.file.size / (1024 * 1024) > 10 && (
												<span className="form-text text-danger mt-2">
													{`Image is too big (${(
														picture.file.size /
														(1024 * 1024)
													).toFixed(2)}Mb).`}
													<br />
													Max imagesize: 10Mb.
												</span>
											)}
											<span className="form-text text-muted mt-2">
												Allowed file types: png, jpg, jpeg.
											</span>
										</div>
									)}
								</ImageUploading>
							</div>
							<div className="col-lg-10">
								<div className="row pt-5">
									<div className="col-6 mb-5 pl-lg-3 pr-lg-3 ">
										<Field
											disabled={isSuper ? false : true} // access only for superAdmins
											variant="filled"
											name="firstName"
											label="First Name"
											requiredField
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter first name"
										/>
									</div>
									<div className="col-6 mb-5 pl-lg-3 pr-lg-3">
										<Field
											disabled={isSuper ? false : true} // access only for superAdmins
											variant="filled"
											name="lastName"
											label="Last Name"
											requiredField
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter last name"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col-lg-10">
								<div className="row">
									<div className="col-12 mb-5 pl-lg-3 pr-lg-3">
										<Field
											variant="filled"
											name="email"
											label="Email"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true
											}}
											placeholder="Enter email"
										/>
									</div>
									{!personId && (
										<div className="col-12 mb-5 pl-lg-3 pr-lg-3">
											<Field name="phone">
												{({ field, form }) => {
													return (
														<>
															<div
																style={{
																	marginBottom: "10px",
																	height: "18px"
																}}
															>
																<span
																	style={{
																		color: "#4A4A68",
																		fontFamily: "Poppins",
																		fontSize: "12px",
																		fontWeight: "500"
																	}}
																>
																	Phone Number
																</span>
															</div>
															<PhoneInput
																name="phoneNumber"
																{...field}
																/* country={"us"} */
																containerClass="countryCode_containerPeople"
																inputClass="countryCode_inputClassPeople"
																buttonClass="countryCode_buttonClassPeople"
																dropdownClass="countryCode_dropdownClassPeople"
																placeholder="Phone number"
																onChange={(value, data) => {
																	form.setFieldValue("phoneNumber", value)
																	setCountryCode(data.dialCode)
																}}
															/>
														</>
													)
												}}
											</Field>
										</div>
									)}

									{personId && (
										<div className="col-12">
											<Field name="phone" placeholder="Enter phone number">
												{({ field, form }) => {
													return (
														<>
															<div
																style={{
																	marginBottom: "10px",
																	height: "18px"
																}}
															>
																<span
																	style={{
																		color: "#4A4A68",
																		fontFamily: "Poppins",
																		fontSize: "12px",
																		fontWeight: "500"
																	}}
																>
																	Phone Number
																</span>
															</div>
															<PhoneInput
																name="phoneNumber"
																{...field}
																/* country={"us"} */
																containerClass="countryCode_containerPeople"
																inputClass="countryCode_inputClassPeople"
																buttonClass="countryCode_buttonClassPeople"
																dropdownClass="countryCode_dropdownClassPeople"
																placeholder="Phone number"
																value={initialValues?.phoneNumber}
																onChange={(value, data) => {
																	form.setFieldValue("phoneNumber", value)
																	setCountryCode(data.dialCode)
																}}
															/>
														</>
													)
												}}
											</Field>
										</div>
									)}
								</div>
							</div>
						</div>
					</CardBody>
				</Collapse>
			</Card>
			<Card style={{ boxShadow: "none" }} margin={false}>
				<CardHeader
					className="create_person_modal_title"
					padding={false}
					line={false}
					title={header(
						"Documentation Information",
						documentationInfoOpen,
						setDocumentationInfoOpen
					)}
				/>
				<Collapse in={documentationInfoOpen} className={classes.collapse}>
					<CardBody style={{ padding: "10px 0" }}>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									id="nationality-autocomplete"
									customColor="#F2F2F2"
									name="nationality"
									label="Nationality"
									component={Autocomplete_People}
									placeholder="Nationality"
									options={[
										...nationalities.map(val => {
											return { value: val, name: val }
										})
									]}
									value={selectedNationality}
									onChange={(event, newValue) => {
										setSelectedNationality(newValue)
										setFieldValue("nationality", newValue)
									}}
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<div style={{ marginBottom: "10px" }}>
									<span className="new_person_date_picker_label">Date of Birth</span>
								</div>
								<DatePicker
									className="custom-datepicker"
									calendarClassName="date_picker_range_reporting"
									selected={values.birthdate}
									onChange={e => setFieldValue("birthdate", new Date(e))}
									customInput={<ExampleCustomInput />}
									dateFormat="dd/MM/yyyy"
									wrapperClassName="custom-datepicker-wrapper"
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
								/>
							</div>
						</div>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
								<Field
									variant="filled"
									name="idCard"
									label="ID Number"
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter ID Number"
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
								<Field
									variant="filled"
									name="passport"
									label="Passport"
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Passport Number"
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
								<Field
									variant="filled"
									name="driverLicense"
									label="Drivers License"
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Drivers Licence Number"
								/>
							</div>
						</div>
					</CardBody>
				</Collapse>
			</Card>
			<Card style={{ boxShadow: "none" }} margin={false}>
				<CardHeader
					className="create_person_modal_title"
					padding={false}
					line={false}
					title={header("On-Boarding", onboardingOpen, setOnboardingOpen)}
				/>
				<Collapse in={onboardingOpen} className={classes.collapse}>
					<CardBody style={{ padding: "10px 0" }}>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									name="tag"
									label="Tag"
									component={AutocompleteWithLazyLoading}
									onChange={value => {
										if (!value) {
											setFieldValue("uuid", null)
										} else {
											setFieldValue("uuid", value.uuid)
										}
										// setFieldValue("tag", value)
									}}
									uuid={initialValues.uuid}
									placeholder="Enter Tag"
									customHeight="49px"
									disabled={isSuper ? false : initialValues.uuid ? true : false}
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									// disabled={personId && values.company ? true : false}
									disabled={isSuper ? false : true} // access only for superAdmins
									id="company-autocomplete"
									customColor="#F2F2F2"
									name="company"
									label="Company"
									requiredField
									component={Autocomplete_People}
									placeholder="Company"
									options={[
										...vendors.map(val => {
											return {
												value: val.company,
												name: val.company
											}
										})
									]}
									value={selectedCompany}
									onChange={(event, newValue) => {
										setSelectedCompany(newValue)
										setFieldValue("company", newValue)
									}}
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<div style={{ marginBottom: "10px" }}>
									<span className="new_person_date_picker_label">Start Date</span>
								</div>
								<DatePicker
									className="custom-datepicker"
									// calendarClassName="date_picker_range_reporting"
									// selected={values.startDate ? values.startDate : new Date()}
									selected={values?.startDate || new Date()}
									onChange={e => setFieldValue("startDate", new Date(e))}
									customInput={<ExampleCustomInput2 />}
									dateFormat="dd/MM/yyyy"
									wrapperClassName="custom-datepicker-wrapper"
								/>
							</div>
						</div>
						<div className="form-group d-flex flex-column flex-lg-row w-100">
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									id="role-autocomplete"
									customColor="#F2F2F2"
									name="role"
									label="Role"
									component={Autocomplete_People}
									placeholder="Role"
									requiredField
									options={[
										...roles
											.filter(val => val.id)
											.map(val => {
												return { value: val.name, name: val.name }
											})
									]}
									value={selectedRole}
									onChange={(event, newValue) => {
										console.log("🚀  newValue:", newValue)
										setSelectedRole(newValue)
										setFieldValue("role", newValue)
									}}
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									customColor="#F2F2F2"
									name="geofencing"
									label="Geofencing"
									component={SelectMultiCheckbox}
									selectAllEnabled
									// isGrouped
									// options={groupedGeofencingOptions}
									options={[
										...areas.map(val => {
											return { value: val.id, name: val.name }
										})
									]}
								/>
							</div>
							<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
								<Field
									variant="filled"
									name="helmetId"
									label="Helmet ID"
									size="small"
									newId="helmet_new_person_input"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Helmet ID"
								/>
							</div>
						</div>
					</CardBody>
				</Collapse>
			</Card>
			<Card style={{ boxShadow: "none" }} margin={false}>
				<CardHeader
					className="create_person_modal_title"
					padding={false}
					line={false}
					title={header("Other Contacts", otherContacts, setOtherContacts)}
				/>
				<Collapse in={otherContacts} className={classes.collapse}>
					<CardBody style={{ padding: "10px 0" }}>
						<div className="form-group row">
							<div className="col">
								<Field
									variant="filled"
									name="emergencyName"
									label="Contact Name"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Name"
								/>
							</div>
							<div className="col">
								<Field
									variant="filled"
									name="emergencyNumber"
									label="Contact Phone Number"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Phone Number"
								/>
							</div>
						</div>
						<div className="form-group row">
							<div className="col">
								<Field
									variant="filled"
									name="supervisorName"
									label="Supervisor Name"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Name"
								/>
							</div>
							<div className="col" style={{ marginBottom: "2rem" }}>
								<Field
									variant="filled"
									name="supervisorNumber"
									label="Supervisor Phone Number"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Phone Number"
								/>
							</div>
						</div>
					</CardBody>
				</Collapse>
			</Card>

			<Button
				variant="contained"
				color="primary"
				style={{ width: "100%", marginTop: "2.25rem", marginBottom: "1.25rem" }}
				disabled={!dirty || isLoading}
				text="SAVE PERSON"
				onClick={handleSubmit}
				data-testid="new-person-modal_handleSubmit"
			/>
		</Form>
	)
}

export default PatientForm
